import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { CardBody, Card, Row, Col, CardHeader, Table } from 'reactstrap';
import WorkFlowActionAttachment from './WorkFlowActionAttachment';
import WorkflowActionComments from './WorkflowActionComments';
export default class WorkFlowActions extends Component {
    static get propTypes() {
        return {
            topicId: PropTypes.number,
            workFlowActionDetails: PropTypes.any,
        };
    }
    getAcionDetails(flow, id) {
        const actiondetails = flow.filter(x => x?.actionData?.theme_signal == id);
        if (actiondetails) {
            return actiondetails?.length ? actiondetails : []
        }
        return []
    }
    render() {
        const { topicId, workFlowActionDetails } = this.props;
        return (
            <Card>
                <CardHeader className="bg-transparent border-bottom text-uppercase  text-danger text-center rounded shadow-sm">
                    Action Details
                </CardHeader>
                {
                    this.getAcionDetails(workFlowActionDetails, topicId) && this.getAcionDetails(workFlowActionDetails, topicId).length ?
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <div className='table-responsive border rounded'>
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Assigned To</th>
                                                    <th>State</th>
                                                    <th>Start Date</th>
                                                    <th>Completion Date</th>
                                                    <th>Attachment</th>
                                                    <th>Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.getAcionDetails(workFlowActionDetails, topicId).map((e, i) => {
                                                        const details = e?.actionData;
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <tr >
                                                                    <td>{details?.measure_data?.name}</td>
                                                                    <td>{details?.measure_data?.description}</td>
                                                                    <td>{details?.assigned_to_data?.username}</td>
                                                                    <td><span className='badge p-2 bg-warning'>{details?.status ?? 'No Status'}</span></td>
                                                                    <td>{details?.start_date}</td>
                                                                    <td>{details?.planned_completion_date}</td>
                                                                    <td><span className='badge p-2 bg-warning text-center'>{details?.measure_attachment_count}</span></td>
                                                                    <td><span className='badge p-2 bg-warning text-center'>{details?.measure_comment_count}</span></td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {this.getAcionDetails(workFlowActionDetails, topicId)?.map((e, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <Col lg={6} md={6}>
                                                <WorkFlowActionAttachment workFlowActionDetails={workFlowActionDetails} actionId={e.id} />
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <WorkflowActionComments workFlowActionDetails={workFlowActionDetails} actionId={e.id} />
                                            </Col>
                                        </Fragment>
                                    )
                                }
                                )}
                            </Row>
                        </CardBody>
                        :
                        <CardBody className='text-center'>
                            <span className='text-danger shadow p-2 rounded'>No Action Details Found..</span>
                        </CardBody>
                }
            </Card>
        )
    }
}
