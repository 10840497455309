import React, { Component, Fragment } from 'react';
import { AppNodata, AppPagination } from 'components/Reusable';
import { Service } from '../Appservice';
import { Card, Container } from "reactstrap";
import TabHeader from '../components/tabHeader';
import InlineSearch from './InlineSearch';
import MetaTags from "react-meta-tags";
import ProjectsTable from './ProjectsTable';
import ProjectsAdd from './ProjectsAdd';
import { signalApi } from '../ApiConstant';
export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: ["PROJECT NAME", "DESCRIPTION"],
            menuArray: [
                { name: 'Edit', icon: 'fas fa-pen', id: 1 },
                { name: 'Delete', icon: 'fas fa-trash', id: 2 }
            ],
            tabHeader: [],
            page: 1,
            loading: false,
            search: '',
            workFlowList: [],
            projectList: [],
            projectCopyList: [],
            isAdd: false,
            selectedData: '',
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        this.getWorkflowConfiguration();
        this.getProjects();
    }
    getProjects = async () => {
        const { page, search } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        const getProjects = await Service.appservice('get', signalApi.project + queryParam);
        if (getProjects?.data) {
            this.setState({
                projectList: getProjects.data, projectCopyList: getProjects.data,
                pageNumbers: getProjects.metadata ? Array.from({ length: getProjects.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    getWorkflowConfiguration = async () => {
        const getWorkflowConfiguration = await Service.appservice('get', signalApi.workflowConfiguration);
        if (getWorkflowConfiguration?.data) this.setState({ workFlowList: getWorkflowConfiguration.data });
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    paginate = async (page) => {
        await this.setState({ page, isUpdating: true });
        this.getWorkflowConfiguration();
    }
    render() {
        const { page, pageNumbers, workFlowList, projectCopyList, projectList, loading, tabHeader, menuArray, isAdd,
            selectedData, } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Project | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        <Card className='shadow rounded-3'>
                            <TabHeader
                                key={'Project'}
                                Title={'Project'}
                                headers={tabHeader}
                                isAdd
                                isLoading={loading}
                                onSearch={async (isSearch) => {
                                    await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                    await this.getProjects();
                                }}
                                onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                onAdd={async (isAdd) => await this.setState({ isAdd })}
                            />
                        </Card>
                        {isAdd ?
                            <ProjectsAdd selectedData={selectedData}
                                workFlowList={workFlowList}
                                onClose={(e) => { this.setState({ selectedData: '', isAdd: false }); if (e) { this.getProjects() } }} /> :
                            <Fragment></Fragment>}
                        <Fragment>
                            <Fragment>
                                <InlineSearch
                                    projectCopyList={projectCopyList}
                                    onSearch={projectList => this.setState({ projectList })} />
                                <ProjectsTable
                                    tabHeader={tabHeader}
                                    loading={loading}
                                    filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                    menuArray={menuArray}
                                    projectList={projectList}
                                    onDelete={this.getProjects}
                                    onMenu={async (a, b) => {
                                        if (b == 1) await this.setState({ isAdd: false })
                                        this.setState({
                                            selectedData: a,
                                            isAdd: b == 1 ? true : false
                                        })
                                    }} />
                            </Fragment>
                            {loading ? <Fragment></Fragment> :
                                projectList && projectList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                    nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                    <AppNodata />
                            }
                        </Fragment>
                    </Container>
                </div>
            </React.Fragment >
        )
    }

}
