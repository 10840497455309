import React, { Component } from 'react';
import { Service } from '../Appservice';
import { Row, Col, Form } from 'reactstrap';
import ReactEcharts from "echarts-for-react"
import PropTypes from 'prop-types';
import SignalTable from './SignalTable';
import { chart } from '../Appchart';
import { signalApi } from '../ApiConstant';
import { getKeyItem, sourceId } from '../AppPermission';
export default class SignalbyTimeGraph extends Component {
    static get propTypes() {
        return {
            signalTimeGraph: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            signalTimeGraphFilter: [
                { id: 1, name: 'OnTime' },
                { id: 2, name: 'Delayed' },
            ],
            Filterby: '',
            page: 1,
            signalList: [],
        }
    }
    getSignals = async () => {
        const { page, Filterby } = this.state;
        let queryParam = `?page=${page}&page_size=10&src_identifier=` + getKeyItem(sourceId);
        if (Filterby == 1) queryParam += "&ontime_signals=true";
        if (Filterby == 2) queryParam += "&delayed_signals=true";
        this.setState({ loading: true });
        const signals = await Service.appservice('get', signalApi.signals + queryParam);
        if (signals?.data) {
            this.setState({
                signalList: signals.data.values, signalCopyList: signals.data.values,
                pageNumbers: signals.metadata ? Array.from({ length: signals.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    paginate = async (page) => {
        await this.setState({ page });
        this.getSignals();
    }
    onChartClick = async (e) => {
        await this.setState({ Filterby: e.data.id });
        this.paginate(1);
    };
    render() {
        const { signalTimeGraph } = this.props;
        const { signalList, pageNumbers, page, loading } = this.state;
        return (
            <Row className="no-gutters align-items-center">
                <Col md={3}>
                    <ReactEcharts
                        style={{ textAlign: 'center' }}
                        option={chart(signalTimeGraph)}
                        onEvents={{
                            'click': this.onChartClick,
                        }}
                    />
                </Col>
                <Col md={9}>
                    <SignalTable
                        loading={loading}
                        filterStaticHeaders={e => { }}
                        signalList={signalList}
                        pageNumbers={pageNumbers}
                        page={page}
                        paginate={(e) => this.paginate(e)}
                    />
                </Col>
            </Row>
        )
    }
}
