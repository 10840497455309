import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
export default class AppDropDown extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
            menuArray: PropTypes.any,
            isMenu: PropTypes.bool,
            onToggle: PropTypes.func,
            onClick: PropTypes.func,
            rowId: PropTypes.number,
            selectedId: PropTypes.number,
        };
    }
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        const { isMenu } = this.props;
        if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
            if (isMenu) this.props.onToggle()
        }
    }
    render() {
        const { onClose, menuArray, isMenu, onClick, rowId, selectedId, } = this.props;
        return (
            <Fragment>
                <span className='badge badge-soft-danger p-2 shadow-sm rounded' role={'button'} onClick={onClick}>
                    <i className='mdi mdi-dots-horizontal  '></i>
                </span>
                {rowId == selectedId && isMenu &&
                    <div ref={this.wrapperRef} className='card p-2 shadow position-absolute mb-0' style={{ zIndex: 1000 }}>
                        <ul className='list-unstyled mb-0'>
                            {
                                menuArray && menuArray.map((e, i) => {
                                    return (
                                        <li key={i} className='mb-2 drop-css dropdown-item d-flex top-100' role='button'
                                            onClick={() => { onClose(e.id); }} >
                                            <i className={'me-2 text-primary ' + e.icon}></i>
                                            <span className="ms-2">{e.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>}
            </Fragment>
        )
    }
}
