import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../Appservice';
import { Col, Form, Row } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { signalApi } from '../ApiConstant';
export default class ProductsAdd extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            userList: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { selectedData, userList } = this.props;
        this.state = {
            postData: {
                name: selectedData?.name ?? '',
                description: selectedData?.description ?? '',
                trade_name: selectedData?.trade_name ?? '',
                manufacturer: selectedData?.manufacturer ?? '',
                is_class_drug: selectedData?.is_class_drug ?? false,
                atc_code: selectedData?.atc_code ?? '',
                assigned_to: selectedData?.assigned_to ?? '',
            },
            postErr: {
                name: false,
                description: false,
                trade_name: false,
                manufacturer: false,
                is_class_drug: false,
                atc_code: false,
                assigned_to: false
            },
            userList: userList,
            classList: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
            loading: false
        }
    }
    setValue = (i, e) => {
        const value = i.target.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onProduct = async () => {
        const { selectedData, onClose } = this.props;
        const { postData } = this.state;
        if (!postData.name) return this.setError('name');
        if (!postData.manufacturer) return this.setError('manufacturer');
        if (!postData.trade_name) return this.setError('trade_name');
        if (!postData.atc_code) return this.setError('atc_code');
        if (!String(postData.is_class_drug)) return this.setError('is_class_drug');
        if (!postData.description) return this.setError('description');
        if (!postData.assigned_to) return this.setError('assigned_to');
        this.setState({ loading: true })
        const Product = selectedData?.id ?
            await Service.appservice('patch', `${signalApi.drugs}/${selectedData.id}`, postData) :
            await Service.appservice('post', signalApi.drugs, postData);
        if (Product) {
            toast.success(`Product ${selectedData.id ? 'Updated' : 'Created'} Successfully !...`);
            onClose(true);
        }
        this.setState({ loading: false })
    }
    render() {
        const { postData, postErr, loading, classList, userList } = this.state;
        const { onClose, selectedData } = this.props;
        return (
            <div className='d-flex justify-content-center'>
                <Fragment>
                    <Form>
                        <Row>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="Name *" value={postData.name} isInvalid={postErr.name} onIValid={e => this.onError(e, 'name')} error="Name is required" onChange={e => { this.setValue(e, 'name') }} />
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="Manufacturer Name *" value={postData.manufacturer} isInvalid={postErr.manufacturer} onIValid={e => this.onError(e, 'manufacturer')} error="Manufacturer Name is required" onChange={e => { this.setValue(e, 'manufacturer') }} />
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="Trade Name *" value={postData.trade_name} isInvalid={postErr.trade_name} onIValid={e => this.onError(e, 'trade_name')} error="Trade Name is required" onChange={e => { this.setValue(e, 'trade_name') }} />
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="ATC Code *" value={postData.atc_code} isInvalid={postErr.atc_code} onIValid={e => this.onError(e, 'atc_code')} error="ATC Code is required" onChange={e => { this.setValue(e, 'atc_code') }} />
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="Class Drug *" type="select" value={postData.is_class_drug} isInvalid={postErr.is_class_drug}
                                    error="Class Drug is required" onChange={e => { this.setValue(e, 'is_class_drug') }} iArray={classList} selectType='idpair' />
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="Description *" type="text" value={postData.description} isInvalid={postErr.description} onIValid={e => this.onError(e, 'description')}
                                    error="Description is required" onChange={e => { this.setValue(e, 'description') }} />
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <AppInput label="Assignee *" type="select" value={Number(postData.assigned_to)} isInvalid={postErr.assigned_to}
                                    error="Assignee is required" onChange={e => { this.setValue(e, 'assigned_to') }} iArray={userList} selectType='usernamepair' />
                            </Col>
                            <Col lg={12} className='text-center'>
                                <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.onProduct} showloader active={loading} name={`${selectedData ? 'Update ' : 'Add '} Drugs`} />
                                <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onClose()} tooltip="Close" />
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            </div>
        )
    }
}
