import React, { Component, Fragment } from 'react';
import { AppPagination } from 'components/Reusable';
import { Service } from '../Appservice';
import { Card, Container } from "reactstrap";
import TabHeader from '../components/tabHeader';
import InlineSearch from './InlineSearch';
import MetaTags from "react-meta-tags";
import { epidomilogyDrugs } from '../ApiConstant';
import EpidomilogyTable from './EpidomilogyTable';
import Filter from './Filter';
export default class Epidomilogy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: ["DRUG NAME", "SEVERITY", "ADVERSE EVENT"],
            tabHeader: [],
            page: 1,
            loading: false,
            search: '',
            drugsList: [],
            drugsCopyList: [],
            drugsNameList: [],
            csvFile: '',
            drugname: '',
            isFilter: false,
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        this.getDrugs();
        this.getDrugNames();
    }
    getDrugs = async () => {
        const { page, search, drugname } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        if (drugname) queryParam += "&drugname=" + drugname;
        const getProducts = await Service.appservice('get', epidomilogyDrugs + queryParam);
        if (getProducts?.data) {
            this.setState({
                drugsList: getProducts.data, drugsCopyList: getProducts.data,
                pageNumbers: getProducts.metadata ? Array.from({ length: getProducts.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    getDrugNames = async () => {
        const drugsNameList = await Service.appservice('get', `${epidomilogyDrugs}/drug_names`);
        if (drugsNameList) this.setState({ drugsNameList });
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked));
        return isActiveHeader ? true : false;
    }
    paginate = async (page) => {
        await this.setState({ page, loading: true });
        this.getDrugs();
    }
    render() {
        const { page, pageNumbers, loading, tabHeader, drugsList, drugsCopyList, drugsNameList, isFilter } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Epidomilogy | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        <Card className='shadow rounded-3'>
                            <TabHeader
                                key={'Epidomilogy'}
                                Title={'Epidomilogy'}
                                headers={tabHeader}
                                isFilter
                                isLoading={loading}
                                onSearch={async (isSearch) => {
                                    await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                    await this.getDrugs();
                                }}
                                onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                onFilter={(isFilter) => this.setState({ isFilter })}
                            />
                        </Card>
                        {isFilter && <Filter loading={loading} drugsNameList={drugsNameList}
                            onFilter={async (e) => {
                                if (!e) this.setState({ isFilter: false });
                                await this.setState({ loading: true, page: 1, drugname: e });
                                await this.getDrugs();
                            }} />}
                        <Fragment>
                            <div>
                                <InlineSearch
                                    drugsCopyList={drugsCopyList}
                                    onSearch={drugsList => this.setState({ drugsList })} />
                                <EpidomilogyTable
                                    tabHeader={tabHeader}
                                    loading={loading}
                                    filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                    drugsList={drugsList}
                                />
                            </div>
                            {
                                drugsList && drugsList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                    nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                    <Fragment></Fragment>
                            }
                        </Fragment>
                    </Container>
                </div>
            </React.Fragment >
        )
    }

}
