import React, { Component } from 'react';
import { Table, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppDropDown, AppLoader, AppNodata, AppShowmore } from 'components/Reusable';
import { Service } from '../../Appservice';
import toast from 'react-hot-toast';
import { signalApi } from '../../ApiConstant';
import DeleteAlert from '../../components/DeleteAlert';
export default class TopicsTable extends Component {
  static get propTypes() {
    return {
      tabHeader: PropTypes.any,
      isLoading: PropTypes.bool,
      filterStaticHeaders: PropTypes.func,
      showMenu: PropTypes.any,
      topicList: PropTypes.any,
      onMenu: PropTypes.func,
      onDelete: PropTypes.func,
    };
  }
  constructor() {
    super();
    this.state = {
      isMenu: false,
      updateData: '',
      isDelete: false,
      loading: false
    }
  }
  deleteTopics = async () => {
    const { updateData } = this.state;
    this.setState({ loading: true })
    const deleteTopics = await Service.appservice('delete', `${signalApi.topicSignals}/${updateData.id}`);
    if (deleteTopics) toast.success('Topic deleted successfully!...')
    this.setState({ isDelete: false, updateData: '', loading: false });
    this.props.onDelete();
  }
  render() {
    const { tabHeader, isLoading, filterStaticHeaders, showMenu, topicList, onMenu, } = this.props;
    const { isMenu, updateData, isDelete, loading } = this.state;
    return (
      <div className='shadow table-responsive rounded-3 mt-3'>
        <Table className="table-hover table-borderless mb-0">
          <thead className='shadow'>
            <tr>
              <th className='text-center'>
                <i className='fas fa-bars text-info'></i>
              </th>
              {
                tabHeader.map((e, i) => {
                  return (
                    e.isChecked && <th key={i}>{e.name}</th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {isLoading ?
              <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
              :
              topicList.map((e, i) => {
                return (
                  isDelete && updateData.id == e.id ?
                    <td colSpan={15} key={i} className='shadow border border-danger'>
                      <DeleteAlert loading={loading} title="Topic" onYes={this.deleteTopics}
                        onNo={() => { this.setState({ isDelete: false, updateData: '' }) }} />
                    </td>
                    :
                    <tr key={i} >
                      <td className='text-center'>
                        <AppDropDown
                          menuArray={showMenu}
                          isMenu={isMenu}
                          onToggle={() => { if (e.id == updateData.id) this.setState({ isMenu: !isMenu, updateData: e }); }}
                          onClick={() => { this.setState({ isMenu: true, updateData: e }); }}
                          rowId={e.id}
                          selectedId={updateData.id}
                          onClose={(id) => {
                            this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                            onMenu(updateData, id);
                          }}
                        />
                      </td>
                      <td className={filterStaticHeaders(0) ? '' : 'd-none'}>
                        <AppShowmore text={e?.theme_data?.name} />
                      </td>
                      <td className={filterStaticHeaders(2) ? 'text-center' : 'd-none'}>{e?.assigned_to_data?.name ?? <i className="text-info fas fa-user-slash " />}</td>
                      <td className={filterStaticHeaders(3) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.signal_data?.DRUG} /></td>
                      <td className={filterStaticHeaders(4) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.signal_data?.AE} /></td>
                      <td className={filterStaticHeaders(5) ? '' : 'd-none'}><AppShowmore text={e?.workflow_config?.name} /></td>
                      <td className={filterStaticHeaders(6) ? 'text-center' : 'd-none'}>
                        {e?.project_data?.name ?? <i className='text-info  fas fa-project-diagram font-size-12 ' />}
                      </td>
                      <td className={filterStaticHeaders(7) ? 'text-center' : 'd-none'}>
                        <span className="badge badge-soft-danger rouned p-2 ">{e?.attachment_count}</span>
                      </td>
                      <td className={filterStaticHeaders(7) ? 'text-center' : 'd-none'}>
                        <span className="badge badge-soft-danger rouned p-2 ">{e?.comment_count}</span>
                      </td>
                      <td className={filterStaticHeaders(8) ? 'text-center' : 'd-none'}>
                        <span className="badge bg-warning  rouned p-2 ">{e?.measure_count}</span>
                      </td>
                      <td className={filterStaticHeaders(9) ? '' : 'd-none'}>{!e.is_actions_closed ? "No Status" : "Closed"}</td>
                    </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}
