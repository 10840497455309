import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { CardBody, CardTitle, Row, Col, Card } from 'reactstrap';
import { Service } from '../../Appservice';
import WorkflowTopics from './WorkflowTopics';
import WorkflowTopicAttachment from './WorkflowTopicAttachment';
import WorkflowTopicComments from './WorkflowTopicComments';
import WorkFlowActions from './WorkFlowActions';
import { signalApi } from '../../ApiConstant';
export default class Workflow extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            workFlowlist: [],
            workFlowConfigList: [],
            workFlowDetails: [],
            workFlowActionDetails: [],
            projectId: '',
            loading: false
        }
    }
    componentDidMount() {
        this.getWorkFlows(this.props.selectedData.id)
    }
    getWorkFlows = async (id) => {
        const { projectId } = this.state;
        let queryParam = "?page=1"
        if (projectId) queryParam += "project=" + projectId
        const getWorkFlows = await Service.appservice('get', `${signalApi.signals}/${id}/theme_signals${queryParam}`)
        if (getWorkFlows?.data) {
            this.setState({ workFlowlist: getWorkFlows.data });
            const workflowData = getWorkFlows.data;
            if (workflowData?.length) {
                await this.getWorkFlowConfig(workflowData[0].workflow_config.id);
                workflowData.forEach(e => { this.getWorkflowDetails(e.id); });
            }
        }
    }
    getWorkFlowConfig = async (id) => {
        const getWorkFlowConfig = await Service.appservice('get', `${signalApi.project}?workflow_configuration=${id}`);
        if (getWorkFlowConfig?.data) this.setState({ workFlowConfigList: getWorkFlowConfig.data })
    }
    getWorkflowDetails = async (id) => {
        let themeData, commentData, attachData, measureData = [];
        const singleTheme = await Service.appservice('get', `${signalApi.topicSignals}/${id}`) || []; // topics
        const singleComments = await Service.appservice('get', `${signalApi.topics}/${id}/comments`) || []; // topic comments
        const singleAttach = await Service.appservice('get', `${signalApi.topicAttachmentGet}/${id}`) || []; //topic attachments
        const measureListTheme = await Service.appservice('get', `${signalApi.topicSignals}/${id}/theme_measures`) || []; //get all actions
        if (singleTheme?.data) themeData = singleTheme.data;
        if (singleComments?.data) commentData = singleComments.data;
        if (singleAttach?.data) attachData = singleAttach.data;
        if (measureListTheme?.data) {
            measureData = measureListTheme.data;
            if (measureData?.length) measureData.forEach(e => this.getActionData(e.id));
        }
        this.setState({ workFlowDetails: [...this.state.workFlowDetails, { id, themeData, commentData, attachData, measureData }] })
    }
    getActionData = async (id) => {
        let actionData, commentData, attachData = [];
        const singleTheme = await Service.appservice('get', `${signalApi.topicActions}/${id}`) || [];
        const singleComments = await Service.appservice('get', `${signalApi.topicActions}/${id}/comments`) || [];
        const singleAttach = await Service.appservice('get', `${signalApi.actionAttachmentsGet}/${id}`) || [];
        if (singleTheme) actionData = singleTheme;
        if (singleComments?.data) commentData = singleComments.data;
        if (singleAttach?.data) attachData = singleAttach.data;
        this.setState({ workFlowActionDetails: [...this.state.workFlowActionDetails, { id, actionData, commentData, attachData }] })
    }
    render() {
        const { workFlowlist, workFlowConfigList, workFlowDetails, workFlowActionDetails } = this.state;
        return (
            <Fragment>
                <CardBody>
                    <CardTitle className='text-danger ms-2'>
                        Work Flow
                    </CardTitle>
                    <Row>
                        <Col md="12">
                            <Row>
                                {
                                    workFlowlist?.length ?
                                        workFlowlist.map((topicData, index) => {
                                            return (
                                                <Col lg={12} key={index}>
                                                    <Fragment>
                                                        <WorkflowTopics topicData={topicData} >
                                                            <Row>
                                                                {
                                                                    workFlowDetails && workFlowDetails.length ?
                                                                        <Fragment>
                                                                            <Col lg={6} md={6}>
                                                                                <WorkflowTopicAttachment workFlowDetails={workFlowDetails} topicId={topicData.id} />
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <WorkflowTopicComments workFlowDetails={workFlowDetails} topicId={topicData.id} />
                                                                            </Col>
                                                                            <Col lg={12} md={12}>
                                                                                <WorkFlowActions topicId={topicData.id} workFlowActionDetails={workFlowActionDetails} />
                                                                            </Col>
                                                                        </Fragment>
                                                                        :
                                                                        <CardBody className='text-center'>
                                                                            <span className='text-danger shadow p-2 rounded'>No Workflow Topics Found..</span>
                                                                        </CardBody>
                                                                }
                                                            </Row>
                                                        </WorkflowTopics>
                                                    </Fragment>
                                                </Col>
                                            )
                                        })
                                        :
                                        <CardBody className='text-center'>
                                            <span className='text-danger shadow p-2 rounded'>No Workflow Found..</span>
                                        </CardBody>
                                }

                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Fragment>
        )
    }
}
