import React, { Component, Fragment } from 'react';
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { Service } from '../Appservice';
import { AppInput, AppButton, AppTooltip } from 'components/Reusable';
import { signalApi } from '../ApiConstant';
import { appMonths, appQuaters, emptyObject, yearList } from '../AppConstants';
import { getKeyItem, sourceId } from '../AppPermission';
export default class Header extends Component {
    static get propTypes() {
        return {
            onPdf: PropTypes.func,
            isDownload: PropTypes.bool,
            onshowAction: PropTypes.func,
            onshowTopic: PropTypes.func,
            showAction: PropTypes.bool,
            onFilter: PropTypes.func,
            loading: PropTypes.bool,
            onExclude: PropTypes.func,
            isExcluded: PropTypes.bool,
        };
    }
    postData = {
        drug: '',
        signal_parent_model_id: '',
        duration: '',
        month: '',
        quater: '',
        year: ''
    }
    constructor() {
        super();
        this.state = {
            drugList: [],
            modelList: [],
            durationList: [],
            periodList: [],
            postData: this.postData
        }
    }
    componentDidMount = () => {
        this.getAllDrugs();
        this.getAllModel();
        this.getAllDuration();
    }
    getAllDrugs = async () => {
        const drug = await Service.appservice('get', signalApi.drugs);
        if (drug?.data) this.setState({ drugList: drug.data });
    }
    getAllModel = async () => {
        const model = await Service.appservice('get', signalApi.parentModel);
        if (model?.data) this.setState({ modelList: model.data });
    }
    getAllDuration = async () => {
        const duration = await Service.appservice('get', signalApi.duration);
        if (duration?.data) this.setState({ durationList: duration.data });
    }
    setValue = async (i, e) => {
        const value = i.target?.value == '--Select--' ? '' : i.target?.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
    };
    ondurationchange = (e) => {
        if (e == 2) this.setState({ periodList: appMonths })
        if (e == 3) this.setState({ periodList: appQuaters })
    }
    render() {
        const { drugList, modelList, durationList, postData, periodList } = this.state;
        const { onshowAction, onshowTopic, onFilter, loading, isExcluded } = this.props;
        const periodValue = postData.duration == 1 ? postData.year_id : postData.duration == 2 ? postData.month_id : postData.quarter_id;
        const periodSetValue = postData.duration == 1 ? 'year' : postData.duration == 2 ? 'month' : 'quarter';
        return (
            <Fragment>
                <Card className='shadow'>
                    <CardBody className="p-2">
                        <div className="d-flex flex-row justify-content-center justify-content-sm-between align-items-center">
                            <div className="text-primary fw-bold d-none d-sm-block">
                                <i className="fas fa-table me-2" />
                                Dashboard
                            </div>
                            <div>
                                <AppTooltip tooltip="Dashboard Filter">
                                    <span className='btn btn-sm' data-bs-toggle="collapse" href="#filterCollapse" id="filter">
                                        <i className={"fas fa-filter text-danger"}></i>
                                    </span>
                                </AppTooltip>
                                <AppButton  className="me-2 border border-danger" id="pdf" tooltip='Pdf Download' icon='fas fa-download'
                                    onClick={this.props.onPdf} disabled={this.props.isDownload} />
                                {isExcluded ?
                                    <AppButton  icon='fas fa-undo' name="Reset"
                                        onClick={() => this.props.onExclude(false)} className="me-2" /> :
                                    <AppButton tooltip='Remove Refuted,Recommented and Invalid' icon='fas fa-ban' name="Remove"
                                        onClick={() => this.props.onExclude(true)} className="me-2" hideName/>}
                                <AppButton  tooltip="View Topics" name='Topics' icon='fas fa-eye' onClick={() => onshowTopic(true)} className="me-2" />
                                <AppButton tooltip="View Action" name='Action' icon='fas fa-eye' onClick={() => onshowAction(true)} />
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div className="collapse" id="filterCollapse">
                    <Form className='d-flex justify-content-center'>
                        <Row>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <AppInput disabled label="Source" type="text" value={getKeyItem(sourceId)} />
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <AppInput label="Drug *" type="select" value={postData.drug}
                                    onChange={e => { this.setValue(e, 'drug') }} iArray={drugList} selectType='namepair' />
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <AppInput label="Model *" type="select" value={postData.signal_parent_model_id}
                                    onChange={e => { this.setValue(e, 'signal_parent_model_id'); }} iArray={modelList} selectType='idpair' />
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <AppInput label="Duration *" type="select" value={postData.duration}
                                    onChange={e => { this.setValue(e, 'duration'); this.ondurationchange(e.target.value) }} iArray={durationList} selectType='idpair' />
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12} className={postData.duration ? 'd-block' : 'd-none'}>
                                <AppInput label="Year *" type="select" value={postData.year}
                                    onChange={e => { this.setValue(e, 'year'); }} iArray={yearList().reverse()} selectType='idpair' />
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12} className={postData.duration && postData.duration != 1 ? 'd-block' : 'd-none'}>
                                <AppInput label="Period *" type="select" value={periodValue}
                                    onChange={e => { this.setValue(e, periodSetValue); }} iArray={periodList} selectType='idpair' />
                            </Col>
                            <Col lg={12} className='text-center mb-3'>
                                <AppButton name='Filter' icon='fas fa-filter' onClick={() => onFilter(postData)}
                                    disabled={loading || emptyObject(postData)} />
                                <span data-bs-toggle="collapse" href="#filterCollapse">
                                    <AppButton  iconbutton tooltip='Reset & Close Filter' icon='fas fa-times' disabled={loading || emptyObject(postData)} className="btn-danger ms-2"
                                        onClick={async () => { await this.setState({ postData: { ...this.state.postData, ...this.postData } }); onFilter(this.postData) }} />
                                </span>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </Fragment>
        )
    }
}
