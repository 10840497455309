import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Tooltip, Whisper } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
export default class AppTooltip extends Component {
    static get propTypes() {
        return {
            tooltip: PropTypes.string,
            children: PropTypes.any,
        };
    }
    render() {
        const { tooltip,children} = this.props;
        return (
            <Whisper  placement="auto" trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>}>
                {children}
            </Whisper>
        )
    }
}