import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CardBody, CardTitle, Row, Col, Card } from 'reactstrap';
import { setStatus } from '../../AppConstants';

export default class Status extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
        };
    }
    render() {
        const { selectedData } = this.props;
        return (
            <React.Fragment>
                <CardBody>
                    <CardTitle className='text-success ms-2'>Status</CardTitle>
                    <div className='row justify-content-around align-content-center'>
                        <Col lg={2}>
                            <Card>
                                <CardBody className='text-center'>
                                    <CardTitle> Validation </CardTitle>
                                    {selectedData?.validation_status ?
                                        <p className='badge bg-success p-2'>
                                            {setStatus(selectedData?.validation_status, 'Validation')}
                                        </p> :
                                        <p className='badge badge-soft-success p-2 '>
                                            <i className='fas fa-ban me-2' />  Not started
                                        </p>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2}>
                            <Card>
                                <CardBody className='text-center'>
                                    <CardTitle> Priority </CardTitle>
                                    {selectedData?.priority?.name ?
                                        <p className='badge bg-success p-2'>
                                            {selectedData?.priority?.name}
                                        </p> :
                                        <p className='badge  badge-soft-success p-2'>
                                            <i className='fas fa-ban me-2' />  Not started
                                        </p>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2}>
                            <Card>
                                <CardBody className='text-center'>
                                    <CardTitle> Evaluation </CardTitle>
                                    {selectedData?.evaluation_confirmation_status ?
                                        <p className='badge bg-success p-2'>
                                            {setStatus(selectedData?.evaluation_confirmation_status, 'Evaluvate')}
                                        </p> :
                                        <p className='badge  badge-soft-success p-2'>
                                            <i className='fas fa-ban me-2' />  Not started
                                        </p>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2}>
                            <Card>
                                <CardBody className='text-center'>
                                    <CardTitle> Risk Categorisation </CardTitle>
                                    {selectedData?.risk_categorisation ?
                                        <p className='badge bg-success p-2'>
                                            {setStatus(selectedData?.risk_categorisation, 'Risk')}
                                        </p> :
                                        <p className='badge  badge-soft-success p-2'>
                                            <i className='fas fa-ban me-2' />  Not started
                                        </p>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2}>
                            <Card>
                                <CardBody className='text-center'>
                                    <CardTitle> Recommentation </CardTitle>
                                    {selectedData?.recommendation_status ?
                                        <p className='badge bg-success p-2'>
                                            {setStatus(selectedData?.recommendation_status, 'Recomment')}
                                        </p> :
                                        <p className='badge  badge-soft-success p-2'>
                                            <i className='fas fa-ban me-2' />  Not started
                                        </p>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </div>
                </CardBody>
            </React.Fragment>
        )
    }
}
