import React from "react"
import MetaTags from 'react-meta-tags';
import { useLocation } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
const Powerbi = () => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const url = query.get("url");
  var iframeURl = url;
  const pagename = query.get("pageName") ? query.get("pageName") : 'Page';
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{pagename} | 3 Analytics</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-12">
                        <iframe style={{ width: "100%", height: "850px" }} src={iframeURl}></iframe>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Powerbi;
