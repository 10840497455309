import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import { Container, Card} from "reactstrap"
import { Service } from '../Appservice';
import ViewedPages from './ViewedPages';
import UserPages from './UserPages';
import { signalApi, userApi } from '../ApiConstant';
import { AppButton } from 'components/Reusable';
export default class Auditlog extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            headers: ["DATE", "USER", "PROCESS", "DETAILS"],
            logList: [],
            downloading: false,
            userList: [],
            showchangeLog: true
        }
    }
    componentDidMount = () => {
        this.setState({ loading: true });
        this.getUsers();
        this.getLogs();
    }
    getLogs = async () => {
        const logList = await Service.appservice('get', signalApi.auditLogGraph);
        if (logList) this.setState({ logList, loading: false })
    }
    getUsers = async () => {
        const user = await Service.appservice('get', `${userApi.users}/list_basics`);
        if (user) this.setState({ userList: user })
    }
    downloadXl = async () => {
        this.setState({ downloading: true })
        const download = await Service.appservice('get', signalApi.auditLogCsv);
        if (download?.file_url) { window.open(download.file_url) }
        this.setState({ downloading: false })
    }
    render() {
        const { loading, headers, logList, downloading, userList, showchangeLog } = this.state;
        return (
            <Fragment>
                <MetaTags>
                    <title>
                        Auditlog | 3 Analytics
                    </title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid>
                        <Card className='shadow rounded p-2'>
                            <div className=" d-flex flex-row justify-content-between align-items-center">
                                <div className="text-primary fw-bold">
                                    <i className="fas fa-table me-2" />
                                    Auditlog
                                </div>
                                <AppButton icon="fas fa-download" name="Logs" tooltip="Download" disabled={downloading} showloader onClick={this.downloadXl}/>
                            </div>
                        </Card>
                        <Fragment>
                            {showchangeLog ?
                                <ViewedPages
                                    headers={headers}
                                    graphData={logList?.menu_details}
                                    loading={loading}
                                    userList={userList}
                                    onLog={() => this.setState({ showchangeLog: false })} /> :
                                <UserPages
                                    headers={headers}
                                    graphData={logList?.user_details}
                                    loading={loading}
                                    userList={userList}
                                    onLog={() => this.setState({ showchangeLog: true })} />}
                        </Fragment>
                    </Container>
                </div>
            </Fragment>
        )
    }
}
