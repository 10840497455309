import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import user from "assets/images/avatar.png";
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setusername(obj.username)
    }
  }, [props.success]);
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
          <div className="shadow rounded p-1">
            <img className="rounded-circle header-profile-user" src={user} alt="Header Avatar" />
            <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end p-2 rounded">
          <span role="button" onClick={props.onChangePassword} className="dropdown-item font-size-12">
            <i className="fas fa-key  align-middle me-1 text-danger" />
            <span className="ms-2">Change Password</span>
          </span>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item font-size-12">
            <i className="fas fa-power-off  align-middle me-1 text-danger" />
            <span className="ms-2">Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  onChangePassword: PropTypes.func
}



export default ProfileMenu;
