import React, { Component } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppButton, AppLoader, AppShowmore } from 'components/Reusable';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';

import { signalApi } from '../ApiConstant';
import { setStatus } from '../AppConstants';
import DeleteAlert from '../components/DeleteAlert';
import AppDropDown from 'components/Reusable/Dropdown';
export default class SignalTable extends Component {
  static get propTypes() {
    return {
      tabHeader: PropTypes.any,
      loading: PropTypes.bool,
      filterStaticHeaders: PropTypes.func,
      signalList: PropTypes.any,
      onMenu: PropTypes.func,
    };
  }
  constructor() {
    super();
    this.state = {
      isMenu: false,
      menuList: [],
      updateData: '',
      menuArray: [
        { name: 'View', icon: 'fas fa-eye', id: 1 },
        { name: 'Generate Data', icon: 'fas fa-file-export', id: 2 },
        { name: 'ICSR Review', icon: 'far fa-comment-alt', id: 3 },
        { name: 'Literature Search', icon: 'fas fa-search', id: 4 },
        { name: 'View All Action', icon: 'fas fa-eye', id: 5 },
        { name: 'Automate Workflow', icon: 'fab fa-autoprefixer', id: 6 },
        { name: 'Case Summary', icon: 'fas fa-list-alt', id: 7 },
      ],
      isAutomate: false,
      isAutomateLoading: false,
    }
  }
  hideMenu = async (e) => {
    const { menuArray } = this.state;
    let removemenu = menuArray;
    if (!e.priority && !e.evaluation_comment) removemenu = removemenu.filter(e => e.id != 5);
    if (e.is_signal_exists) removemenu = removemenu.filter(e => e.id != 6);
    this.setState({ isMenu: true, updateData: e, menuList: removemenu });
  }
  automateSignal = async () => {
    const { updateData } = this.state;
    this.setState({ isAutomateLoading: false })
    const automate = await Service.appservice('post', `${signalApi.signalAutomate}?signal_ids=${updateData.id}`);
    if (automate) {
      toast.success('Signal automated successfully!...');
      this.props.onMenu(updateData, 6)
      this.setState({ updateData: '' });
    }
    this.setState({ isAutomateLoading: false, isAutomate: false })
  }
  render() {
    const { tabHeader, loading, filterStaticHeaders, signalList, onMenu } = this.props;
    const { isMenu, updateData, menuList, isAutomate, isAutomateLoading } = this.state;
    return (
      <div className='shadow table-responsive rounded-3'>
        <Table className="table-hover table-borderless mb-0">
          <thead className='shadow'>
            <tr>
              <th className='text-center'><i className='fas fa-bars text-info'></i></th>
              {
                tabHeader.map((e, i) => {
                  return (
                    e.isChecked && <th key={i}>{e.name}</th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {loading ?
              <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
              :
              signalList.map((e, i) => {
                const duplicateClass = e.is_duplicated ? 'shadow-sm bg-danger  text-white' : '';
                const removeBorder = e.is_duplicated ? 'rounded-0' : '';
                return (
                  isAutomate && updateData.id == e.id ?
                    <td colSpan={15} key={i} className='shadow border border-danger'>
                      {isAutomateLoading ?
                        <AppButton name="Automating..." disabled showloader />
                        :
                        <DeleteAlert loading={loading} title="Project" onYes={this.automateSignal}
                          onNo={() => { this.setState({ isAutomate: false, updateData: '' }) }} />
                      }
                    </td>
                    :
                    <tr key={i} className={duplicateClass}>
                      <td className={`text-center ${removeBorder}`}>
                        <AppDropDown
                          menuArray={menuList}
                          isMenu={isMenu}
                          onToggle={() => { if (e.id == updateData.id) this.setState({ isMenu: !isMenu, updateData: e }); }}
                          onClick={() => { this.hideMenu(e); }}
                          rowId={e.id}
                          selectedId={updateData.id}
                          onClose={(id) => {
                            this.setState({ isMenu: false });
                            if (id == 6) this.setState({ isAutomate: true, updateData: e });
                            else onMenu(updateData, id);
                          }}
                        />
                      </td>
                      <td className={filterStaticHeaders(0) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.SRC_IDENTIFIER} id={'tool' + i} /></td>
                      <td className={filterStaticHeaders(1) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.DRUG} id={'tool1' + i} /></td>
                      <td className={filterStaticHeaders(2) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.AE} id={'tool2' + i} width={100} /></td>
                      <td className={filterStaticHeaders(3) ? '' : 'd-none'}><AppShowmore text={e?.parent_model} id={'tool21' + i} width={100} /></td>
                      <td className={filterStaticHeaders(4) ? 'text-center' : 'd-none'}><AppShowmore text={e?.signal_data?.PRESENT_IN_IME_LIST} id={'tool3' + i} width={100} /></td>
                      <td className={filterStaticHeaders(5) ? 'text-center' : 'd-none'}><AppShowmore text={e?.signal_data?.PRESENT_IN_DME_LIST} id={'tool4' + i} width={100} /></td>
                      <td className={filterStaticHeaders(6) ? 'text-center' : 'd-none'}>
                        <span className='fw-bold ms-2'>
                          {
                            (!e.validation_topic_added && !e.validation_status) ?
                              <span onClick={() => onMenu(e, 1, 1)} className='badge badge-soft-info p-2  font-size-12' role={'button'}>
                                <i className="fas fa-plus me-2"></i>Start Validation
                              </span>
                              :
                              !e.validation_status ?
                                <span onClick={() => onMenu(e, 1, 2)} className='badge badge-soft-success p-2  font-size-12' role={'button'}>
                                  <i className="fas fa-plus me-2"></i>
                                  Mark Validation
                                </span> :
                                e.validation_status != 'V' ?
                                  <span className='text-danger'>
                                    <i className="fas fa-ban me-2"></i>
                                    {setStatus(e?.validation_status, 'Validation')}
                                    <span onClick={() => onMenu(e, 1, 3)} className='badge badge-soft-danger p-2  font-size-12 ms-2' role={'button'}>
                                      <i className="fas fa-pen" />
                                    </span>
                                  </span>
                                  :
                                  <span className='text-success'>
                                    <i className="fas fa-check me-2"></i> {setStatus(e?.validation_status, 'Validation')}
                                  </span>
                          }
                        </span>
                      </td>
                      <td className={filterStaticHeaders(7) ? 'text-center' : 'd-none'}>
                        <span className='fw-bold ms-2'>
                          {e.priority
                            ? <span className={e?.priority?.name?.includes('High') ? 'text-danger' : e?.priority?.name?.includes('Low') ? 'text-dark' : 'text-warning'}>
                              <i className={`fas ${e?.priority?.name?.includes('High') ? 'fa-arrow-up' : e?.priority?.name?.includes('Low') ? 'fa-arrow-down' : 'fa-equals'}`} />  {e?.priority?.name}
                            </span>
                            : <i className="fas fa-hand-point-left me-2 text-info"></i>}
                        </span>
                      </td>
                      <td className={filterStaticHeaders(8) ? 'text-center' : 'd-none'}>
                        <span className='fw-bold ms-2'>
                          {
                            !e.validation_status || e.validation_status != 'V' ?
                              <span className='badge badge-soft-dark p-2  font-size-12'> <i className="fas fa-info-circle me-2"></i>Not started</span>
                              :
                              !e.evaluation_topic_added && !e.evaluation_confirmation_status ?
                                <span onClick={() => onMenu(e, 1, 4)} className='badge badge-soft-info p-2  font-size-12' role={'button'}>
                                  <i className="fas fa-plus me-2"></i>Start Evaluvation
                                </span>
                                :
                                !e.evaluation_confirmation_status ?
                                  <span onClick={() => onMenu(e, 1, 5)} className='badge badge-soft-success p-2  font-size-12' role={'button'}>
                                    <i className="fas fa-plus me-2"></i>
                                    Mark Evaluation
                                  </span> :
                                  e.evaluation_confirmation_status != 'C' ?
                                    <span className='text-danger'>
                                      <i className="fas fa-ban me-2"></i>
                                      {setStatus(e?.evaluation_confirmation_status, 'Evaluvate')}
                                      <span onClick={() => onMenu(e, 1, 6)} className='badge badge-soft-danger p-2  font-size-12 ms-2' role={'button'}>
                                        <i className="fas fa-pen" />
                                      </span>
                                    </span>
                                    :
                                    <span className='text-success'>
                                      <i className="fas fa-check me-2"></i> {setStatus(e?.evaluation_confirmation_status, 'Evaluvate')}
                                    </span>
                          }
                        </span>
                      </td>
                      <td className={filterStaticHeaders(9) ? 'text-center' : 'd-none'}>
                        <span className='fw-bold ms-2'>
                          {e.risk_categorisation ?
                            <span className={e?.risk_categorisation == 'NIIR' ? 'text-danger' : e?.risk_categorisation == 'NIR' ? 'text-warning' : 'text-info'}>
                              <i className={`fas fa-exclamation-triangle`} />    {setStatus(e?.risk_categorisation, 'Risk')}
                            </span>
                            : <i className="fas fa-hand-point-left me-2 text-info"></i>}
                        </span>

                      </td>
                      <td className={filterStaticHeaders(10) ? `text-center ${removeBorder}` : 'd-none'}>
                        <span className='fw-bold ms-2'>
                          {e.validation_status && !e.recommendation_status ?
                            <span onClick={() => onMenu(e, 1, 7)} className='badge badge-soft-info p-2  font-size-12' role={'button'}>
                              <i className="fas fa-plus me-2"></i>Start Recommendation
                            </span>
                            :
                            e.recommendation_status ?
                              <span className={e?.recommendation_status == 'PU' ? 'text-danger' : e?.recommendation_status == 'RU' ? 'text-warning' : 'text-info'}>
                                <i className={`fas fa-thumbs-up`} />    {setStatus(e?.recommendation_status, 'Recomment')}
                              </span>
                              : <span className='badge badge-soft-dark p-2  font-size-12'> <i className="fas fa-info-circle me-2"></i>Not started</span>}
                        </span>
                      </td>
                    </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div >
    )
  }
}
