import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import Details from './Details';
import Histroy from './Histroy';
import Status from './Status';
import Workflow from './Workflow';
import toast from 'react-hot-toast';
import domToPdf from "dom-to-pdf"
import DocView from './DocView';
export default class FullViewSignal extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            signalHistory: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pdfDownload: false,
            docDownload: false
        }
    }
    downloadPdf = async () => {
        this.setState({ loading: true })
        const element = document.querySelector('.pdfDownload');
        const options = { filename: "Signal View -" + Date.now() + ".pdf" };
        toast('Pdf preparing!', { icon: '🧑‍🍳', });
        return domToPdf(element, options, () => {
            this.setState({ loading: false })
            toast.success("Pdf downloaded...")
        });
    }
    downloadDoc = async () => {
        this.setState({ loading: true })
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html = preHtml + document.getElementById('docDownload').innerHTML + postHtml;
        var blob = new Blob(['\ufeff', html], { type: 'application/msword' });
        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        // Specify file name
        const filename = "Signal View -" + Date.now() + '.doc';
        // Create download link element
        var downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            downloadLink.href = url;
            downloadLink.download = filename;
            downloadLink.click();
        }
        document.body.removeChild(downloadLink);
        this.setState({ loading: false })
        toast.success("Document downloaded...")
    }

    render() {
        const { selectedData, onClose, signalHistory } = this.props;
        const { loading, pdfDownload, docDownload } = this.state;
        return (
            <div>
                <div className='d-sm-flex justify-content-sm-between'>
                    <span>
                        <AppButton icon='fas fa-arrow-left' tooltip="Back to details" onClick={onClose} name={`Full View details of ${selectedData?.id}`} />
                    </span>
                    <div className='mt-3 mt-sm-0'>
                        <AppButton icon={'fas fa-download'} className="me-3" name={'Pdf Download'}
                            disabled={loading}
                            showloader
                            onClick={this.downloadPdf} />
                        <AppButton icon={'fas fa-download'} name={'Document Download'}
                            // disabled={loading}
                            showloader
                            onClick={this.downloadDoc}
                            disabled />
                    </div>
                </div>
                <div className='pdfDownload' >
                    <Details selectedData={selectedData} />
                    <Histroy signalHistory={signalHistory} />
                    <Status selectedData={selectedData} />
                    <Workflow selectedData={selectedData} />
                </div>
                {/* <span id='docDownload'><DocView selectedData={selectedData} signalHistory={signalHistory} /></span> */}
            </div>
        )
    }
}
