import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import Logo from "assets/illusion/add.png";
import { Row, Col, CardImg, Form } from 'reactstrap';
import { Service } from '../../Appservice';
import toast from 'react-hot-toast';
import { signalApi } from '../../ApiConstant';
export default class ActionStatus extends Component {
    static get propTypes() {
        return {
            updateData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        const { updateData } = this.props;
        this.state = {
            postData: {
                status: updateData && updateData.status ? updateData.status : '',
                status_change_comment: updateData && updateData.status_change_comment ? updateData.status_change_comment : ''
            },
            postErr: {
                status: false, status_change_comment: false
            },
            isUpdating: false
        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    updateAction = async () => {
        const { updateData, onClose } = this.props;
        const { postData } = this.state;
        if (!postData.status || postData.status === "--Select--") return this.setError('status');
        if (!postData.status_change_comment) return this.setError('status_change_comment');
        let data = { ...updateData, ...postData };
        const changeActionState = {
            assigned_to: data.assigned_to,
            measure_order: data.measure_order,
            start_date: data.start_date,
            status: data.status,
            status_change_comment: data.status_change_comment,
            measure: {
                name: data.name,
                description: data.description,
            }
        }
        this.setState({ isUpdating: true })
        const updateAction = await Service.appservice('patch', `${signalApi.topicActions}/${data.id}`, changeActionState);
        if (updateAction) {
            toast.success('Action state updated!...', '3Analytics');
            await onClose(true);
        }
        this.setState({ isUpdating: false })
    }
    render() {
        const { onClose, updateData } = this.props;
        const { postData, postErr, isUpdating } = this.state;
        const iArray = updateData && updateData.measure_type_data ? updateData.measure_type_data : [];
        return (
            <Fragment>
                <div className='d-flex justify-content-between'>
                    <AppButton name={`Update Action Status of ${updateData?.measure_data?.name}`} tooltip="Back to Action List" icon='fas fa-arrow-left' onClick={onClose} />
                </div>
                <Row className="no-gutters align-items-center mt-5 mt-sm-0">
                    <Col md={4} className='d-none d-sm-block '>
                        <CardImg className="img-fluid  p-5" src={Logo} alt="Skote" />
                    </Col>
                    <Col md={6} className='p-2 rounded'>
                        <Form  className='card-body '>
                            <Row >
                                <Col md={6}>
                                    <AppInput label="Action State Status *" type="select" value={postData.status} isInvalid={postErr.status}
                                        error="Action State is required" onChange={e => { this.setValue(e, 'status') }} iArray={iArray} selectType='valuepair' /></Col>
                                <Col md={6}>
                                    <AppInput label="Comment *" type="text" value={postData.status_change_comment} isInvalid={postErr.status_change_comment}
                                        error="Comment is required" onChange={e => { this.setValue(e, 'status_change_comment') }} /></Col>
                                <Col md={12} className='text-center'>
                                    <AppButton name='Update Status' icon='fas fa-check' onClick={this.updateAction}  disabled={isUpdating} />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
