import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { AppButton, AppLoader, AppNodata, AppShowmore } from 'components/Reusable';
import { Row, Col, CardBody, Card, Table } from 'reactstrap';
import { urlValidation } from '../AppConstants';
import { literature } from '../ApiConstant';
import { Service } from '../Appservice';
export default class SignalLiteratureView extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bhcResponse: null,
        }
    }
    openLink = async (e) => {
        let link = "";
        if (e?.link) {
            link = e?.link;
        } else if (e?.sitelink) {
            link = e?.sitelink;
        } else if (e?.pdflink) {
            link = e?.pdflink;
        }
        if (link)
            window.open(link, '_blank')
    }
    componentDidMount() {
        this.getBhc()
    }
    getBhc = async () => {
        const { selectedData } = this.props;
        let data = {
            dataSource: "pubmed",
            drugName: selectedData.signal_data.DRUG,
            sideEffect: [selectedData.signal_data.AE],
            bhc: "no",
            userId: "sujoy",
            classActionDrug: [],
            numberOfSearch: "4"
        }
        console.log('data', data);
        this.setState({ loading: true });
        const bhcResponse = await Service.appservice('post', literature, data);
        console.log('bhcResponse', bhcResponse);
        this.setState({ bhcResponse, loading: false })
    }
    render() {
        const { selectedData, onClose } = this.props;
        const { bhcResponse, loading } = this.state;
        return (
            <Fragment>
                <AppButton icon='fas fa-arrow-left' name={`Literature of ${selectedData.id}`} onClick={async () => { onClose() }} />
                {
                    loading ?
                        <div className='d-flex justify-content-center'>
                            <AppButton name="Searching... " icon="fas fa-search bx-spin" />
                        </div> :
                        <>
                            <div className='p-2 mb-4 text-center shadow-sm rounded mt-3' >
                                Messaage:   {bhcResponse?.message ? bhcResponse?.message :
                                    <span>{bhcResponse?.adrDetails && bhcResponse?.adrDetails?.length > 0 &&
                                        bhcResponse?.adrDetails[0]?.message}</span>}
                            </div>
                            {bhcResponse && bhcResponse?.adrDetails && bhcResponse?.adrDetails.length ?
                                bhcResponse.adrDetails.map((e, i) => {
                                    return (
                                        <>
                                            <div className='d-flex flex-row justify-content-between align-items-center' key={i}>
                                                <span className='mb-4'><i className='fas fa-info-circle me-2' />Drug : {bhcResponse?.drugName}</span>
                                                <span className='mb-4'><i className='fas fa-info-circle me-2' />Side Effect : {e.sideEffect}</span>
                                            </div>
                                            {e.adrListWithLink && e.adrListWithLink.length ?
                                                this.bhcDetails(e.adrListWithLink) :
                                                <AppNodata />}
                                        </>
                                    )
                                })
                                :
                                <AppNodata />}
                        </>
                }
            </Fragment>
        )
    }
    bhcDetails = (list) => {
        return (
            <Row>
                <Col lg={12}>
                    <div className='shadow table-responsive rounded-3'>
                        <Table className="table-hover table-borderless mb-0">
                            <thead className='shadow'>
                                <tr>
                                    <th>Text</th>
                                    <th>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((e, i) => {
                                        return (
                                            <tr key={i}>
                                                <td ><AppShowmore text={e?.text} /></td>
                                                <td>{(urlValidation(e?.link) || urlValidation(e?.sitelink) || urlValidation(e?.pdflink)) ?
                                                    <span role={'button'} onClick={() => this.openLink(e)} className='badge p-2 badge-soft-danger'><i className='fas fa-external-link-alt'></i></span>
                                                    : e?.link}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        )
    }
}
