import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../../Appservice';
import Logo from "assets/illusion/add.png";
import { Col, Form, Row, CardImg } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { signalApi } from '../../ApiConstant';
export default class AddEditTopics extends Component {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      onClose: PropTypes.func,
      workFlowTeamList: PropTypes.any,
      workFlowConfList: PropTypes.any,
      projectList: PropTypes.any,
    };
  }
  constructor(props) {
    super(props);
    const { selectedData } = this.props;
    this.state = {
      postData: {
        name: selectedData?.theme_data?.name ?? '',
        description: selectedData?.theme_data?.description ?? '',
        project: selectedData?.project ?? '',
      },
      postErr: {
        name: false,
        description: false,
        project: false
      },
      loading: false
    }
  }
  setValue = (i, e) => {
    const value = i.target.value;
    this.setState({ postData: { ...this.state.postData, [e]: value } });
    if (!value) this.setError(e);
    else this.removeErr(e);
  };
  setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  onTopics = async () => {
    const { selectedData, onClose } = this.props;
    const { postData } = this.state;
    if (!postData.name) return this.setError('name');
    if (!postData.description) return this.setError('description');
    if (!postData.project) return this.setError('project');
    const themes = {
      theme: {
        name: postData.name,
        description: postData.description
      },
      project: Number(postData.project)
    }
    this.setState({ loading: true })
    const Topics = await Service.appservice('patch', `${signalApi.topicSignals}/${selectedData.id}`, themes)
    if (Topics) {
      toast.success(`Topics ${selectedData.id ? 'Updated' : 'Created'} Successfully !...`);
      onClose(true);
    }
    this.setState({ loading: false })
  }
  render() {
    const { postData, postErr, loading } = this.state;
    const { onClose, selectedData, workFlowConfList, workFlowTeamList, projectList } = this.props;
    console.log('selectedData', selectedData);
    return (
      <Fragment>
        <div className='d-flex justify-content-between'>
          <AppButton name={`Update Topic Data of ${selectedData?.theme_data.name}`} tooltip="Back to Topics List" icon='fas fa-arrow-left' onClick={onClose} />
        </div>
        <Row className="no-gutters align-items-center">
          <Col md={4} className='d-none d-sm-block'>
            <CardImg className="img-fluid  p-5" src={Logo} alt="Skote" />
          </Col>
          <Col md={6} className='mt-3 mt-sm-0'>
            <div className='d-flex justify-content-center'>
              <Fragment>
                <Form>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <AppInput disabled label="Workflow Configuration *" type="select" value={selectedData?.workflow_config?.id} iArray={workFlowConfList} selectType='idpair' />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <AppInput disabled label="Workflow Team *" type="select" value={selectedData?.assigned_to} iArray={workFlowTeamList} selectType='idpair' />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <AppInput label="Project *" type="select" value={postData.project} isInvalid={postErr.project}
                        error="Project is required" onChange={e => { this.setValue(e, 'project') }} iArray={projectList} selectType='idpair' />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <AppInput label="Name *" value={postData.name} isInvalid={postErr.name} error="Name is required" onChange={e => { this.setValue(e, 'name') }} />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <AppInput label="Description asa*" type="text" value={postData.description} isInvalid={postErr.description}
                        error="Description is required" onChange={e => { this.setValue(e, 'description') }} />
                    </Col>
                    <Col lg={12} className='text-center'>
                      <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.onTopics} showloader active={loading} name={`${selectedData ? 'Update ' : 'Add '} Topic`} />
                      <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onClose()} tooltip="Close" />
                    </Col>
                  </Row>
                </Form>
              </Fragment>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}
