import React, { Component, Fragment } from 'react';
import { Service } from '../Appservice';
import { Card, CardBody, Container } from "reactstrap";
import MetaTags from "react-meta-tags";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import DateDetails from './DateDetails';
import EventDetails from './EventDetails';
import { signalApi } from '../ApiConstant';
export default class Calender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            calenderList: [],
            isDateDetails: false,
            isEventDetails: false,
            selectedData: []
        }
    }
    componentDidMount = async () => {
        this.setState({ loading: true });
        this.getCalender();
    }
    renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }
    getCalender = async () => {
        const getCalender = await Service.appservice('get', signalApi.calender);
        if (getCalender) {
            this.setState({ loading: false });
            let calenderList = getCalender.map((item) => ({
                ...item,
                className: item['is_done'] == false &&
                    item['end_date'] < new Date().toISOString().split('T')[0] ? "bg-danger border-danger text-center text-white shadow p-2" :
                    item['is_done'] == true ? "bg-success text-center text-white shadow p-2" : "bg-info text-center text-white shadow p-2",
                description: item.description
            }));
            calenderList.forEach(obj => this.renameKey(obj, 'end_date', 'start'));
            calenderList.forEach(obj => this.renameKey(obj, 'name', 'title'));
            this.setState({ calenderList });
        }
    }
    onEventsClick = async (e) => {
        const getActionbyEvent = await Service.appservice('get', `${signalApi.topicActions}/${e.event.id}`);
        if (getActionbyEvent) this.setState({ selectedData: getActionbyEvent, isEventDetails: true })
    }
    onDateClick = async (e) => {
        const getActionbyDate = await Service.appservice('get', `${signalApi.topicActions}?planned_completion_date=${e.dateStr}`);
        if (getActionbyDate?.data) this.setState({ selectedData: getActionbyDate?.data, isDateDetails: true })
    }
    render() {
        const { loading, calenderList, selectedData, isDateDetails, isEventDetails } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Calender | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        {
                            isEventDetails ?
                                <EventDetails selectedData={selectedData} onClose={() => this.setState({ isEventDetails: false })} /> :
                                isDateDetails ?
                                    <DateDetails selectedData={selectedData} onClose={() => this.setState({ isDateDetails: false })} /> :
                                    <Card className='shadow'>
                                        <CardBody>
                                            <FullCalendar
                                                loading={loading}
                                                contentHeight={'75vh'}
                                                plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                                showNonCurrentDates
                                                editable
                                                selectable
                                                selectMirror
                                                droppable
                                                dayMaxEvents={2}
                                                themeSystem="bootstrap"
                                                headerToolbar={{
                                                    left: "prev,next today",
                                                    center: "title",
                                                    right: "dayGridMonth,dayGridWeek,dayGridDay",
                                                }}
                                                events={calenderList}
                                                eventClick={this.onEventsClick}
                                                dateClick={this.onDateClick}
                                                aspectRatio={0.5}
                                                initialView='dayGridMonth'
                                            />
                                        </CardBody>
                                    </Card>
                        }
                    </Container>
                </div>
            </React.Fragment >
        )
    }

}
