import React, {  Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../../Appservice';
import { Col, Form, Row } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { signalApi } from '../../ApiConstant';
export default class WorkflowTeamLead extends PureComponent {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            userList: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { selectedData } = props;
        this.state = {
            postData: {
                name: selectedData.name ?? '',
                team_lead: selectedData.team_lead ?? '',
                users: selectedData.users ?? [],
            },
            postErr: {
                name: false, team_lead: false, users: false
            },
            loading: false,
        }

    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onTeamlead = async () => {
        const { postData } = this.state;
        const { selectedData, onClose } = this.props;
        if (!postData.name) return this.setError('name');
        if (!postData.team_lead) return this.setError('team_lead');
        this.setState({ loading: true });
        const postAction = await Service.appservice('put', `${signalApi.workflowTeam}/${selectedData.id}`, postData);
        if (postAction) {
            toast.success(`Team lead Updated Successfully !...`);
            onClose(true);
        }
        this.setState({ loading: false });
    }
    render() {
        const { postData, postErr, loading } = this.state;
        const { onClose, selectedData } = this.props;
        return (
            <div className='row  ps-3'>
                <Fragment>
                    <Form>
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={12}>
                                <AppInput disabled label="Name *" value={postData.name} isInvalid={postErr.name} error="Name is required" onChange={e => { this.setValue(e, 'name') }} />
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={12}>
                                <AppInput label="Team Lead *" type="select" value={postData.team_lead} isInvalid={postErr.team_lead}
                                    error="Team Lead is required" onChange={e => { this.setValue(e, 'team_lead') }} iArray={selectedData?.user_data ?? []} selectType='firstnamepair' />
                            </Col>
                            <Col lg={12} className='text-center'>
                                <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.onTeamlead} showloader active={loading} name={`${selectedData ? 'Update ' : 'Add '} Workflow Team Lead`} />
                                <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onClose()} tooltip="Close" />
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            </div>
        )
    }
}
