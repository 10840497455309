import moment from 'moment';
const appDate = (date) => {
    return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY")
}
const queryDate = (date) => {
    return moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY-MM-DD")
}
const urlValidation = (url) => {
    const res = url?.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}
const setStatus = (status, page) => {
    if (page == 'Validation') {
        switch (status) {
            case 'V': return 'Valid';
            case 'IN': return 'Invalid';
            case 'M': return 'Monitor';
            case 'D': return 'Discuss';
            case 'O': return 'Ongoing';
            case 'R': return 'Review';
            default: return null;
        }
    }
    if (page == 'Evaluvate') {
        switch (status) {
            case 'C': return 'Confirmed';
            case 'M': return 'Monitoring';
            case 'OR': return 'Ongoing Review';
            case 'R': return 'Refuted';
            default: return null;
        }
    }
    if (page == 'Recomment') {
        switch (status) {
            case 'PU': return 'PI update';
            case 'RU': return 'RMP Update';
            case 'RPP': return 'Routine PV-monitor within PSUR';
            case 'ARR': return 'aRMM';
            case 'AR': return 'aRMM';
            default: return null;
        }
    }
    if (page == 'Risk') {
        switch (status) {
            case 'NIIR': return 'New Important Identified Risk';
            case 'NIR': return 'New Identified Risk';
            case 'MR': return 'Modified Risk';
            default: return null;
        }
    }
}
const validationList = [{ id: 'V', name: 'Valid' }, { id: 'IN', name: 'Invalid' }, { id: 'M', name: 'Monitor' }, { id: 'D', name: 'Discuss' },
{ id: 'O', name: 'Ongoing' }, { id: 'R', name: 'Review' },];
const priorityList = [{ id: 1, name: 'High' }, { id: 2, name: 'Meduim' }, { id: 3, name: 'Low' },];
const evaluationList = [{ id: 'C', name: 'Confirmed' }, { id: 'R', name: 'Refuted' }, { id: 'M', name: 'Monitoring' },
{ id: 'OR', name: 'Ongoing Review' }];
const riskList = [{ id: 'NIIR', name: 'New Important Identified Risk' }, { id: 'NIR', name: 'New Identified Risk' }, { id: 'MR', name: 'Modified Risk' },];
const recommendList = [{ id: 'PU', name: 'PI update' }, { id: 'RU', name: 'RMP Update' }, { id: 'RPP', name: 'Routine PV-monitor within PSUR' },
{ id: 'AR', name: 'aRMM' }];
const appMonths = moment.months().map((e, i) => {
    return { id: i + 1, name: e }
});
const appQuaters = [
    { id: 1, name: 'Quarter 1' },
    { id: 2, name: 'Quarter 2' },
    { id: 3, name: 'Quarter 3' },
    { id: 4, name: 'Quarter 4' },
];
const seriousnessList = [{ id: 'S', name: 'Seriousness' }, { id: 'NS', name: 'Non Seriousness' }]
const labelExpectedList = [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }];
const yearList = (startYear) => {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
        const y = startYear++;
        years.push({ id: y, name: y });
    }
    return years;
}
const emptyObject = (object) => Object.values(object).every(x => !x);
//Product page - risk tracking check list
const riskTrackingList = [
    {id: "inter_national_birthdate", name: "International Birth date ? Time since first authorization"},
    {id: "product_type_vaccine_device_biologic", name: "Product type biologic/medical device/vaccine etc"},
    {id: "annual_icsr_volume", name: "Annual ICSR volumes in Safety Database"},
    {id: "aditional_pv_or_risk_minimisation", name: "Does product have additional PV or Risk Minimization measures as part of RMP or REMS ?"},
    {id: "new_molicular_entity", name: "New molecular entity launched in last 5 years ?"},
    {id: "new_indication_new_formula", name: "New indication, new Formulation, New route approved in last 3 years ?"},
    {id: "conditional_monitoring_requirements", name: "Continual monitoring requirements imposed by Regulatory authority or Company ?"},
    {id: "product_is_active_per_ema", name: "Product in active monitoring list per EMA ?"},
    {id: "potential_for_off_label_use", name: "Potential for off-label use, abuse, misuse, medication errors etc ?"},
    {id: "product_with_high_risk", name: "Product with high risk profile ?"},
    {id: "indication_for_life_threatening", name: "Indication for life-threatening use ?"},
    {id: "controlled_substance", name: "Controlled substance ?"},
    {id: "ongoing_clinical_study", name: "Does product have any clinical studies ongoing as part of development program or Post-authorization Safety studies ?"},
    {id: "high_volume_of_literature_citations", name: "Product with high volume of literature citations ?"},
    {id: "any_other_company_determined_critria", name: "Any other Company determined criteria ?"}
];
const frequencyList=[ "QUATERLY", "MONTHLY", "YEARLY" ];
const caseSummery = {
    default:"https://app.powerbi.com/view?r=eyJrIjoiZjY3ZmQyZTktZmVhMi00YTYyLTk1YmItNjA0N2JiMjA1ODdjIiwidCI6IjNhMzQ0NmI3LTAxZDktNGUxYy1iNTgwLWYyZWFlMzdlY2U5NiJ9",
    acuteKidney:"https://app.powerbi.com/view?r=eyJrIjoiMWUzMmVmYzYtZGNiMi00ZWI2LTg5OTYtY2QwNjNlMzRmMzQzIiwidCI6IjNhMzQ0NmI3LTAxZDktNGUxYy1iNTgwLWYyZWFlMzdlY2U5NiJ9",
    myocardial:"https://app.powerbi.com/view?r=eyJrIjoiYTM0ODhlOTctZDA5Ny00NDZkLWIyY2EtZjU5MWM0OWQxMmUwIiwidCI6IjNhMzQ0NmI3LTAxZDktNGUxYy1iNTgwLWYyZWFlMzdlY2U5NiJ9"
}
export {
    appDate, queryDate, urlValidation, setStatus, validationList, priorityList, evaluationList, riskList, recommendList,
    appMonths, appQuaters, seriousnessList, labelExpectedList, yearList, emptyObject, riskTrackingList,frequencyList,caseSummery
}