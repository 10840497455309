import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppShowmore } from 'components/Reusable';
import { Row, Col } from 'reactstrap';
import { setStatus } from '../AppConstants';

export default class ViewSignalStatus extends Component {
    signalCard;
    static get propTypes() {
        return {
            selectedData: PropTypes.any
        };
    }
    constructor() {
        super()
        this.state = {
            aboutHeight: 300
        }
    }
    componentDidMount() {
        const aboutHeight = this.signalCard.clientHeight;
        this.setState({ aboutHeight })
    }
    removeHypen = (str) => {
        if (typeof str == "string" && str.includes('_'))
            return str.split('_')[1];
        return str;
    }
    render() {
        const { selectedData } = this.props;
        return (
            <Row className='mb-3 mt-3'>
                <Col lg={3}>
                    <div className="shadow-none border mb-2 card" >
                        <div className="card-header shadow btn-danger">
                            <h5 className="font-size-14 m-0 text-white">
                                <i className="ri-user-2-line me-2 align-middle d-inline-block"></i>Signal Status
                                <i className="mdi mdi-chevron-up float-end accor-plus-icon"></i>
                            </h5>
                        </div>
                        <div className="collapse show" ref={(e) => { this.signalCard = e }}>
                            <div className="card-body">
                                <div >
                                    <p className="text-muted mb-1">Validation</p>
                                    <h5 className="font-size-14">
                                        {selectedData.validation_status
                                            ?
                                            <span className='ms-3'>
                                                <span className={selectedData?.validation_status == 'V' ? 'text-success' : selectedData?.validation_status == 'IN' ? 'text-danger' : 'text-info'}>
                                                    <i className={`me-2 fas ${selectedData?.validation_status == 'V' ? 'fa-check' : selectedData?.validation_status == 'IN' ? 'fa-ban' : 'fa-info-circle'}`} />
                                                    {setStatus(selectedData?.validation_status, 'Validation')}
                                                </span>
                                            </span>
                                            :
                                            <span className='badge badge-soft-danger p-2 mt-2 font-size-12'>
                                                <i className="fas fa-info-circle me-2"></i>Not started
                                            </span>
                                        }</h5>
                                </div>
                                <div >
                                    <p className="text-muted mb-1">Priority</p>
                                    <h5 className="font-size-14">
                                        {selectedData.priority
                                            ?
                                            <span className='ms-3'>
                                                <span className={selectedData?.priority?.name?.includes('High') ? 'text-danger' : selectedData?.priority?.name?.includes('Low') ? 'text-danger' : 'text-warning'}>
                                                    <i className={`me-2  fas ${selectedData?.priority?.name?.includes('High') ? 'fa-arrow-up' : selectedData?.priority?.name?.includes('Low') ? 'fa-arrow-down' : 'fa-equals'}`} />
                                                    {selectedData?.priority?.name}
                                                </span>
                                            </span>
                                            :
                                            <span className='badge badge-soft-danger p-2 mt-2 font-size-12'>
                                                <i className="fas fa-info-circle me-2"></i>Not started
                                            </span>
                                        }</h5>
                                </div>
                                <div >
                                    <p className="text-muted mb-1">Evaluation</p>
                                    <h5 className="font-size-14">
                                        {selectedData.evaluation_confirmation_status
                                            ?
                                            <span className='ms-3'>
                                                <span className={selectedData?.evaluation_confirmation_status == 'C' ? 'text-success' : selectedData?.evaluation_confirmation_status == 'R' ? 'text-danger' : 'text-info'}>
                                                    <i className={`me-2  fas ${selectedData?.evaluation_confirmation_status == 'C' ? 'fa-check' : selectedData?.evaluation_confirmation_status == 'R' ? 'fa-ban' : 'fa-info-circle'}`} />    {setStatus(selectedData?.evaluation_confirmation_status, 'Evaluvate')}
                                                </span>
                                            </span>
                                            :
                                            <span className='badge badge-soft-danger p-2 mt-2 font-size-12'>
                                                <i className="fas fa-info-circle me-2"></i>Not started
                                            </span>
                                        }</h5>
                                </div>
                                <div >
                                    <p className="text-muted mb-1">Risk</p>
                                    <h5 className="font-size-14">
                                        {selectedData.risk_categorisation
                                            ?
                                            <span className='ms-3'>
                                                <span className={selectedData?.risk_categorisation == 'NIIR' ? 'text-danger' : selectedData?.risk_categorisation == 'NIR' ? 'text-warning' : 'text-info'}>
                                                    <i className={`me-2  fas fa-exclamation-triangle`} />    {setStatus(selectedData?.risk_categorisation, 'Risk')}
                                                </span>
                                            </span>
                                            :
                                            <span className='badge badge-soft-danger p-2 mt-2 font-size-12'>
                                                <i className="fas fa-info-circle me-2"></i>Not started
                                            </span>
                                        }
                                    </h5>
                                </div>
                                <div >
                                    <p className="text-muted mb-1">Recommendation</p>
                                    <h5 className="font-size-14">
                                        {selectedData.recommendation_status
                                            ?
                                            <span className='ms-3'>
                                                <span className={selectedData?.recommendation_status == 'PU' ? 'text-danger' : selectedData?.recommendation_status == 'RU' ? 'text-warning' : 'text-info'}>
                                                    <i className={`me-2  fas fa-thumbs-up`} />    {setStatus(selectedData?.recommendation_status, 'Recomment')}
                                                </span>
                                            </span>
                                            :
                                            <span className='badge badge-soft-danger p-2 mt-2 font-size-12'>
                                                <i className="fas fa-info-circle me-2"></i>Not started
                                            </span>
                                        }
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={9} >
                    <div className="shadow-none border mb-2 card" >
                        <div className="card-header shadow  ">
                            <h5 className="font-size-14 m-0 ">
                                <i className="ri-user-2-line me-2 align-middle d-inline-block"></i>Signal Data
                                <i className="mdi mdi-chevron-up float-end accor-plus-icon"></i>
                            </h5>
                        </div>
                        <div className="collapse show" style={{ maxHeight: this.state.aboutHeight, overflowY: 'scroll' }}>
                            <div className="card-body" >
                                {selectedData?.signal_data && Object.keys(selectedData.signal_data).map((e1, i1) => {
                                    return <div key={i1} className='col-lg-2 col-md-4 col-sm-6 col-xs-12 p-2 btn-wrap-text'>
                                        <p className="text-muted mb-1">{e1}</p>
                                        <h5 className="font-size-14"><AppShowmore text={this.removeHypen(selectedData.signal_data[e1])} /></h5>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
