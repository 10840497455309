import React, { Component, Fragment } from 'react';
import { Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppShowmore,} from 'components/Reusable';
export default class EpidomilogyTable extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            loading: PropTypes.bool,
            filterStaticHeaders: PropTypes.func,
            drugsList: PropTypes.any,
        };
    }
    constructor() {
        super();
    }
    render() {
        const { tabHeader, filterStaticHeaders,drugsList,  loading } = this.props;
        return (
            <div className='table-responsive shadow rounded-3 mt-3'>
                <Table className="table-hover table-borderless mb-0">
                    <thead className='shadow'>
                        <tr>
                            {
                                tabHeader.map((e, i) => {
                                    return (
                                        e.isChecked && <th key={i}>{e.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody >
                        {loading ?
                            <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                            :
                            drugsList.map((e, i) => {
                                return (
                                        <tr key={i} >
                                            <td className={filterStaticHeaders(0) ? null : 'd-none'}><AppShowmore text={e?.drugname} /></td>
                                            <td className={filterStaticHeaders(1) ? null : 'd-none'}><AppShowmore text={e?.severity} /></td>
                                            <td className={filterStaticHeaders(2) ? null : 'd-none'}><AppShowmore text={e?.adverse_event} /></td>
                                        </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
