const getKeyItem = (key) => localStorage.getItem([key]);
const parsedKeyItem = (key) => JSON.parse(localStorage.getItem([key]));
const permissions = JSON.parse(localStorage.getItem('permission'));
const user = "authUser";
const superuser = "superUser";
const sourceId = "sourceIdentifier";

const appPermisssion = {
  addAction: permissions ? permissions["signalapp.add_measure"] : false,
  addWorkFlow: permissions ? permissions["signalapp.add_workflowconfiguration"] : false,
  addActionState: permissions ? permissions["signalapp.add_measuretype"] : false,
  addProject: permissions ? permissions["signalapp.add_project"] : false,
  addProduct: permissions ? permissions["signalapp.add_drug"] : false,
  addTopics: permissions ? permissions["signalapp.add_theme"] : false,
  addTopicAttachment: permissions ? permissions["signalapp.add_themeattachment"] : false,
  addTopicComment: permissions ? permissions["signalapp.add_themecomment"] : false,
  addUser: permissions ? permissions["auth.add_user"] : false,
  addWorkTeam: permissions ? permissions["signalapp.add_workflowteam"] : false,
  addActionAttachment: permissions ? permissions["signalapp.add_measureattachment"] : false,
  addActionComment: permissions ? permissions["signalapp.add_measurecomment"] : false,
  addSignal: permissions ? permissions["signalapp.add_theme"] : false,


  deleteAction: permissions ? permissions["signalapp.delete_measure"] : false,
  deleteWorkFlow: permissions ? permissions["signalapp.delete_workflowconfiguration"] : false,
  deleteActionState: permissions ? permissions["signalapp.delete_measuretype"] : false,
  deleteProject: permissions ? permissions["signalapp.delete_project"] : false,
  deleteProduct: permissions ? permissions["signalapp.delete_drug"] : false,
  deleteTopics: permissions ? permissions["signalapp.delete_theme"] : false,
  deleteTopicAttachment: permissions ? permissions["signalapp.delete_themeattachment"] : false,
  deleteTopicComment: permissions ? permissions["signalapp.delete_themecomment"] : false,
  deleteUser: permissions ? permissions["auth.delete_user"] : false,
  deleteWorkTeam: permissions ? permissions["signalapp.delete_workflowteam"] : false,
  deleteActionAttachment: permissions ? permissions["signalapp.delete_measureattachment"] : false,
  deleteActionComment: permissions ? permissions["signalapp.delete_measurecomment"] : false,
  deleteSignal: permissions ? permissions["signalapp.delete_theme"] : false,



  updateAction: permissions ? permissions["signalapp.change_measure"] : false,
  updateWorkFlow: permissions ? permissions["signalapp.change_workflowconfiguration"] : false,
  updateActionState: permissions ? permissions["signalapp.change_measuretype"] : false,
  updateProject: permissions ? permissions["signalapp.change_project"] : false,
  updateProduct: permissions ? permissions["signalapp.change_drug"] : false,
  updateTopics: permissions ? permissions["signalapp.change_theme"] : false,
  updateTopicAttachment: permissions ? permissions["signalapp.change_themeattachment"] : false,
  updateTopicComment: permissions ? permissions["signalapp.change_themecomment"] : false,
  updateUser: permissions ? permissions["auth.change_user"] : false,
  updateWorkTeam: permissions ? permissions["signalapp.change_workflowteam"] : false,
  updateActionAttachment: permissions ? permissions["signalapp.change_measureattachment"] : false,
  updateActionComment: permissions ? permissions["signalapp.change_measurecomment"] : false,
  updateSignal: permissions ? permissions["signalapp.change_theme"] : false,


  viewAction: permissions ? permissions["signalapp.view_measure"] : false,
  viewWorkFlow: permissions ? permissions["signalapp.view_workflowconfiguration"] : false,
  viewActionState: permissions ? permissions["signalapp.view_measuretype"] : false,
  viewProject: permissions ? permissions["signalapp.view_project"] : false,
  viewProduct: permissions ? permissions["signalapp.view_drug"] : false,
  viewTopics: permissions ? permissions["signalapp.view_theme"] : false,
  viewTopicAttachment: permissions ? permissions["signalapp.view_themeattachment"] : false,
  viewTopicComment: permissions ? permissions["signalapp.view_themecomment"] : false,
  viewUser: permissions ? permissions["auth.view_user"] : false,
  viewWorkTeam: permissions ? permissions["signalapp.view_workflowteam"] : false,
  viewActionAttachment: permissions ? permissions["signalapp.view_measureattachment"] : false,
  viewActionComment: permissions ? permissions["signalapp.view_measurecomment"] : false,
  viewSignal: permissions ? permissions["signalapp.view_theme"] : false,


}
export { appPermisssion, user, superuser, sourceId, getKeyItem,parsedKeyItem }