import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput, AppPagination, AppShowmore, AppTooltip } from 'components/Reusable';
import Logo from "assets/illusion/add.png";
import { Row, Col, Card, CardBody, CardImg, Collapse, Form, UncontrolledTooltip } from 'reactstrap';
import { Service } from '../../Appservice';
import toast from 'react-hot-toast';
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { signalApi } from '../../ApiConstant';
import { appDate } from '../../AppConstants';
import DeleteAlert from '../../components/DeleteAlert';
export default class TopicAttachment extends Component {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      onClose: PropTypes.func,
    };
  }
  constructor(props) {
    super(props);
    const { selectedData } = props;
    this.state = {
      postData: {
        title: "",
        description: "",
        attachment: "",
        theme: selectedData.id,
      },
      postErr: {
        title: false,
        description: false,
        attachment: false,
      },
      page: 1,
      pageNumbers: [],
      loading: true,
      attachmentList: [],
      isDeleteTopic: false,
      search: "",
      attachName: "",
      showAdd: false,
      editAttachId: null,
      isAttachDelete: false,
      isAddOpen: false,
      isListOpen: true,
      isModified: false
    }
  }
  componentDidMount = async () => {
    await this.getAttachments()
  }
  paginate = async (page) => {
    await this.setState({ page, loading: true });
    this.getAttachments();
  }
  getAttachments = async () => {
    const { page, postData, search } = this.state;
    let queryParam = `?page=${page}&page_size=10`;
    if (search) queryParam += "&search=" + search;
    const topics = await Service.appservice('get', `${signalApi.topicAttachmentGet}/${postData.theme}` + queryParam);
    if (topics && topics.data) {
      this.setState({
        attachmentList: topics.data, loading: false, isDeleteTopic: false, pageNumbers: topics.metadata
          ? Array.from(
            { length: topics.metadata.total_pages },
            (v, k) => k + 1
          )
          : [],
      });

    }
  }
  setValue = async (i, e, isupload) => {
    const value = isupload ? i.target.files[0] : i.target.value;
    this.setState({
      postData: { ...this.state.postData, [e]: value },
    });
    if (isupload)
      this.setState({ attachName: value.name });
    if (!value) this.setError(e);
    // Check if the file size is greater than 10 MB (10,485,760 bytes)
    if (isupload && i.target.files[0].size > 10485760) {
      // Display an error message
      toast.error("Error: File size cannot exceed 10 MB.");
      // Clear the file input value
      i.target.value = "";
      this.setError(e);
    } else {
      this.removeErr(e);
    }
  };
  onError = async (e, name) => this.setState({ postErr: { ...this.state.postErr, [name]: e } });
  setError = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  setEdit = async (e) => {
    this.setState({ postData: { ...this.state.postData, title: e.title, description: e.description, attachment: e.url_attachment }, isAddOpen: true, isListOpen: false, attachName: e.url_attachment, editAttachId: e.id })
  }
  getFormData(object) {
    const formData = new FormData()
    Object.keys(object).forEach(key => formData.append(key, object[key]))
    return formData
  }
  onSubmit = async (delId) => {
    const { isDeleteTopic, postData, postErr, editAttachId } = this.state;
    const errorExist = Object.values(postErr).find(v => v);
    if (!isDeleteTopic) {
      if (!postData.title) return this.setError('title');
      if (!postData.description) return this.setError('description');
      if (!postData.attachment) return this.setError('attachment');
      if (errorExist) return false;
    }
    let data = { ...postData };
    if (editAttachId) {
      if (!data.attachment.name) {
        let isUrl = data.attachment.includes('http')
        if (isUrl) delete data.attachment
      }
    }
    this.setState({ loading: true });
    const postAction = isDeleteTopic ?
      await Service.appservice('delete', `${signalApi.topicsAttachment}/${delId}`) :
      !editAttachId ? await Service.appservice('post', `${signalApi.topicsAttachment}`, this.getFormData(data), true) :
        await Service.appservice('put', `${signalApi.topicsAttachment}/${editAttachId}`, this.getFormData(data), true);
    if (postAction) {
      toast.success(`Attachment ${isDeleteTopic ? 'Deleted' : editAttachId ? "Updated" : 'Created'} Successfully !...`);
      if (!isDeleteTopic) this.resetState();
      else await this.setState({ isDeleteTopic: false })
      this.setState({ isAddOpen: false, isListOpen: true, isModified: true })
    }
    await this.getAttachments();
  }
  resetState = async () => {
    await this.setState({ postData: { ...this.state.postData, title: '', description: '', attachment: '' }, showAdd: !this.state.showAdd, attachName: '', editAttachId: null, isAddOpen: false, isListOpen: true });
  }
  render() {
    const { onClose, selectedData } = this.props;
    const { isAddOpen, isListOpen, postData, postErr, attachmentList, isModified, page, pageNumbers, editAttachId, attachName, isAttachDelete, isDeleteTopic, loading } = this.state;
    const isSubmitDisabled = Object.values(postErr).some((value) => value);
    return (
      <Fragment>
        <div className='d-flex justify-content-between'>
          <AppButton name={`Topics Attachments of ${selectedData?.theme_data?.name}`} tooltip="Back to Topics List" icon='fas fa-arrow-left' onClick={onClose} />
        </div>
        <Row className="no-gutters align-items-center mt-4">
          <Col md={4} className='d-none d-sm-block'>
            <CardImg className="img-fluid" src={Logo} alt="Skote" />
          </Col>
          <Col md={6}>
            <div className="accordion-body">
              <span className='shadow rounded p-2 text-danger'>Add Topic Attachment</span>
              <Form className='mt-4'>
                <Row>
                  <Col md={6}>
                    <AppInput label="Title *" error="Title is required" onChange={e => { this.setValue(e, 'title') }} value={postData.title} isInvalid={postErr.title} onIValid={e => this.onError(e, 'title')} />
                  </Col>
                  <Col md={6}>
                    <AppInput label="Description *" error="Description is required" onChange={e => { this.setValue(e, 'description') }} value={postData.description} isInvalid={postErr.description} type="text" onIValid={e => this.onError(e, 'description')} />
                  </Col>
                  <Col md={12}>
                    {
                      attachName ?
                        <>
                          {
                            isAttachDelete ?
                              <Fragment key={'Delete File'}>
                                <DeleteAlert loading={loading} title="Project" onYes={() => { this.setState({ postData: { ...this.state.postData, attachment: "" }, attachName: "", isAttachDelete: false }) }}
                                  onNo={() => { this.setState({ isAttachDelete: false }) }} />
                              </Fragment> :
                              attachName?.includes("https") ?
                                <p className='shadow p-2 rounded'>
                                  <a rel="noreferrer" target="_blank" href={attachName} download={attachName} >
                                    Attached file  <i role='button' id="view" onClick={async () => { attachName }} className='fas ms-2  fa-solid fa-paperclip'></i>
                                  </a>
                                  <i role={'button'} id="delete" className='fa fa-trash-alt text-danger float-end' onClick={() => { this.setState({ isAttachDelete: true }) }} />
                                  <UncontrolledTooltip target={"view"}>View file</UncontrolledTooltip>
                                  <UncontrolledTooltip target={"delete"}>Delete</UncontrolledTooltip>
                                </p> :
                                <p className='comment-div'>
                                  {attachName.substring(attachName.lastIndexOf('/') + 1)}
                                  <i role={'button'} id="delete" className='fa fa-trash-alt text-danger float-end' onClick={() => { this.setState({ isAttachDelete: true }) }} />
                                  <UncontrolledTooltip target={"delete"}>Delete</UncontrolledTooltip>
                                </p>
                          }
                        </>
                        :
                        <AppInput label="Attachment *" type="file" error="Attachment is required" onChange={e => { this.setValue(e, 'attachment', true) }} isInvalid={postErr.attachment} infoText="Upload any files less then 10 mb" />
                    }
                  </Col>
                  <Col md={12} className='text-center'>
                    <AppButton name='Attachment' icon='fas fa-check' onClick={this.onSubmit} disabled={loading} />
                  </Col>
                </Row>

              </Form>
            </div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header shadow rounded " id="headingFlushTwo">
                  <AppButton name="Attachment List" onClick={() => this.setState({ isListOpen: true, isAddOpen: false })}
                    className={classnames("accordion-button", "fw-medium", { collapsed: isListOpen })} />
                </h2>
                <Collapse isOpen={isListOpen} className="accordion-collapse">
                  <PerfectScrollbar >
                    <div className="accordion-body" >
                      {attachmentList?.length ?
                        attachmentList.map((e, i) => {
                          return (
                            isDeleteTopic == e.id ?
                              <Fragment key={i}>
                                <DeleteAlert loading={loading} title="Project" onYes={() => { this.onSubmit(isDeleteTopic) }}
                                  onNo={() => { this.setState({ isDeleteTopic: 0, updateData: '' }) }} />
                              </Fragment> :
                              <Fragment key={i}>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                  <span className='mb-2 text-center text-danger'>
                                    <i className='fas fa-calendar-day me-2 '></i>{appDate(e?.created_at)}
                                  </span>
                                  <span className='badge badge-soft-danger p-2'>
                                    <AppTooltip tooltip="Edit">
                                      <i className='fas fa-pen me-3 ' onClick={() => { this.setEdit(e) }}></i>
                                    </AppTooltip>
                                    <AppTooltip tooltip="Delete">
                                      <i className='fas fa-trash ' onClick={() => { this.setState({ isDeleteAction: e.id }); }}></i>
                                    </AppTooltip>
                                  </span>
                                </div>
                                <div className='card shadow-sm p-2' >
                                  <span className='mt-3'>
                                    <h5>{e?.title}</h5>
                                    <AppShowmore text={e?.description} id={'toolsattachdesc' + i} />
                                    <small className='blockquote-footer'>Posted by {e?.created_by_data?.first_name + ' ' + e?.created_by_data?.last_name}</small>
                                  </span>
                                </div>
                              </Fragment>)
                        }) :
                        <div className='text-center'>
                          <span className='shadow rounded p-2 text-danger'>No  Topics Attachment found..</span>
                        </div>
                      }
                    </div>
                  </PerfectScrollbar>
                </Collapse>
                {
                  attachmentList?.length ?
                    <AppPagination previousPage={e => { this.paginate(e) }}
                      nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                    <Fragment></Fragment>
                }
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}
