import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import Header from './Header';
import { Service } from '../Appservice';
import SignalTable from './SignalTable';
import InlineSearch from './InlineSearch';
import { AppPagination, AppNodata } from 'components/Reusable';
import SignalReport from './SignalReport';
import ViewSignals from './ViewSignals';
import SignalICSRReview from './SignalICSRReview';
import SignalLiteratureView from './SignalLiteratureView';
import SignalAllActionsView from './SignalAllActionsView';
import { connect } from 'react-redux';
import { signalPage } from 'store/actions';
import PropTypes from 'prop-types';
import { signalApi } from '../ApiConstant';
import SignalCaseSummery from './SignalCaseSummery';
import { getKeyItem, parsedKeyItem, sourceId, superuser, user } from '../AppPermission';
class Signals extends Component {
    static get propTypes() {
        return {
            signalPage: PropTypes.func,
            currentPage: PropTypes.any,
            signalHeaderFilter: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { currentPage, signalHeaderFilter } = this.props;
        console.log('signalHeaderFilter', signalHeaderFilter);
        this.state = {
            loading: false,
            drugList: [],
            modelList: [],
            durationList: [],
            header: ["SRC IDENTIFIER", "DRUG", "AE", "MODEL", "IS IME", "IS DME",
                "VALIDATION", "PRIORITY", "EVALUATION / SIGNAL", "RISK CATEGORISATION", "RECOMMENDATION"],
            tabHeader: [],
            signalList: [],
            signalCopyList: [],
            page: currentPage ? currentPage : 1,
            postData: {
                signal_parent_model_id: signalHeaderFilter?.signal_parent_model_id ?? '',
                drug: signalHeaderFilter?.drug ?? '',
                duration_id: signalHeaderFilter?.duration_id ?? '',
                month_id: signalHeaderFilter?.month_id ?? '',
                quarter_id: signalHeaderFilter?.quarter_id ?? '',
                year_id: signalHeaderFilter?.year_id ?? 2021,
                src_identifier: getKeyItem(sourceId),
                seriousness: signalHeaderFilter?.seriousness ?? '',
                label_expected: signalHeaderFilter?.label_expected ?? '',
                assigned_to: getKeyItem(superuser) == "true" ? '' : parsedKeyItem(user)?.uid,
            },
            signalReport: false,
            isSummeryReport: false,
            viewSignal: false,
            selectedData: '',
            workFlowTeamList: [], workFlowEvaluationList: [], workFlowValidationList: [], themesList: [], projectsList: [], validationTopicList: [], evaluationTopicList: [],
            generateData: {
                loading: false,
                show: false,
                file: ''
            },
            signalIcsrReview: false,
            signalLitreacture: false,
            signalViewAllActions: false,
            signalCaseSummery: false,
            selectedStatus: 0,
            search: '',
            isFilterOpen: false
        }
    }
    componentDidMount = async () => {
        this.setState({ loading: true });
        this.setHeader();
        await this.getAllDrugs();
        await this.getAllModel();
        await this.getAllDuration();
        await this.getSignals();
        this.setState({ loading: false });
        this.getApi();
    }
    //app service calls
    getSignals = async (addFilters = null) => {
        const { page, search, postData } = this.state;
        console.log('postData', postData);
        let queryParam = `?page=${page}&page_size=10`;
        if (search) { queryParam += "&search=" + search; }
        if (postData.signal_parent_model_id) queryParam += "&signal_parent_model_id=" + postData.signal_parent_model_id;
        if (postData.drug) queryParam += "&drug=" + postData.drug;
        if (postData.duration_id) queryParam += "&duration=" + postData.duration_id;
        if (postData.month_id) queryParam += "&month=" + postData.month_id;
        if (postData.quarter_id) queryParam += "&quarter=" + postData.quarter_id;
        if (postData.year_id) queryParam += "&year=" + postData.year_id;
        if (postData.src_identifier) queryParam += "&src_identifier=" + postData.src_identifier;
        if (postData.seriousness) queryParam += "&seriousness=" + postData.seriousness;
        if (postData.label_expected) queryParam += "&label_expected=" + postData.label_expected;
        if (postData.assigned_to) queryParam += "&assigned_to=" + postData.assigned_to;
        if (addFilters) {
            if (addFilters.validation) queryParam += "&validation_status=" + addFilters.validation;
            if (addFilters.priority) queryParam += "&priority=" + addFilters.priority;
            if (addFilters.evaluvation) queryParam += "&evaluation_confirmation_status=" + addFilters.evaluvation;
            if (addFilters.risk) queryParam += "&risk_categorisation=" + addFilters.risk;
            if (addFilters.recommend) queryParam += "&recommendation_status=" + addFilters.recommend;
        }
        this.setState({ loading: true });
        const signals = await Service.appservice('get', signalApi.signals + queryParam);
        if (signals?.data) {
            await this.setState({
                signalList: signals.data.values, signalCopyList: signals.data.values,
                pageNumbers: signals.metadata ? Array.from({ length: signals.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    getAllDrugs = async () => {
        const drug = await Service.appservice('get', signalApi.drugs);
        if (drug?.data) this.setState({ drugList: drug.data, postData: { ...this.state.postData, drug: this.props.signalHeaderFilter?.drug ?? drug?.data?.slice(-1)[0]?.name } });
    }
    getAllModel = async () => {
        const model = await Service.appservice('get', signalApi.parentModel);
        if (model?.data) this.setState({ modelList: model.data, postData: { ...this.state.postData, signal_parent_model_id: this.props.signalHeaderFilter?.signal_parent_model_id ?? model.data.slice(-1)[0].id } });
    }
    getAllDuration = async () => {
        const duration = await Service.appservice('get', signalApi.duration);
        if (duration?.data) this.setState({ durationList: duration.data, postData: { ...this.state.postData, duration_id: this.props.signalHeaderFilter?.duration_id ?? duration.data.slice(-1)[0].id } });
    }
    filterSignals = async (postData) => {
        this.setState({ loading: true, postData: { ...this.state.postData, ...postData } });
        let queryParam = `?page=1&page_size=10`;
        if (postData.signal_parent_model_id) queryParam += "&signal_parent_model_id=" + postData.signal_parent_model_id;
        if (postData.drug) queryParam += "&drug=" + postData.drug;
        if (postData.duration_id) queryParam += "&duration=" + postData.duration_id;
        if (postData.month_id) queryParam += "&month=" + postData.month_id;
        if (postData.quarter_id) queryParam += "&quarter=" + postData.quarter_id;
        if (postData.year_id) queryParam += "&year=" + postData.year_id;
        if (postData.src_identifier) queryParam += "&src_identifier=" + postData.src_identifier;
        if (postData.seriousness) queryParam += "&seriousness=" + postData.seriousness;
        if (postData.label_expected) queryParam += "&label_expected=" + postData.label_expected;
        if (postData.assigned_to) queryParam += "&assigned_to=" + postData.assigned_to;
        const signals = await Service.appservice('get', `${process.env.REACT_APP_API_ENDPOINT_SIGNAL}signals` + queryParam);
        if (signals?.data) {
            this.setState({
                signalList: signals.data.values, signalCopyList: signals.data.values,
                pageNumbers: signals.metadata ? Array.from({ length: signals.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false,
                page: 1
            });
        }
    }
    getApi = async () => {
        const workFlowTeam = await Service.appservice('get', signalApi.workflowTeam);
        const workFlowEvaluation = await Service.appservice('get', `${signalApi.workflowConfiguration}?search=&name=&stage=ev&page=1`);
        const workFlowValidation = await Service.appservice('get', `${signalApi.workflowConfiguration}?search=&name=&stage=val&page=1`);
        const themes = await Service.appservice('get', signalApi.topicStructure);
        const projects = await Service.appservice('get', signalApi.project);
        const validationTopicList = await Service.appservice('get', `${signalApi.topics}?workflow_configuration__stage=val`);
        const evaluationTopicList = await Service.appservice('get', `${signalApi.topics}?workflow_configuration__stage=ev`);
        this.setState({
            workFlowTeamList: workFlowTeam?.data, workFlowEvaluationList: workFlowEvaluation?.data, workFlowValidationList: workFlowValidation?.data,
            themesList: themes?.data, projectsList: projects?.data, validationTopicList, evaluationTopicList
        })
    }
    getAddedValidationTopic = async () => {
        const validationTopicList = await Service.appservice('get', `${signalApi.topics}?workflow_configuration__stage=val`);
        this.setState({ validationTopicList });
    }
    getAddedEvaluationTopic = async () => {
        const evaluationTopicList = await Service.appservice('get', `${signalApi.topics}?workflow_configuration__stage=ev`);
        this.setState({ evaluationTopicList })
    }
    getSignalById = async () => {
        const { selectedData, signalList } = this.state;
        const getSignal = await Service.appservice('get', `${signalApi.signals}/${selectedData.id}`);
        signalList[signalList.findIndex(el => el.id === getSignal.id)] = getSignal;
        if (getSignal) this.setState({ selectedData: getSignal, signalList, signalCopyList: signalList })
    }
    getGenerateData = async (e) => {
        this.setState({ generateData: { loading: true, show: true } })
        const generateDataReport = await Service.appservice('get', `${signalApi.signalsCsv}?signal_id=${e.id}`);
        if (generateDataReport?.file_url) this.setState({ generateData: { loading: false, file: generateDataReport?.file_url, show: true } })
        else this.setState({ generateData: { loading: false, file: 'notFound', show: true } })
    }
    // app functions
    setHeader = async () => {
        const header = this.state.header;
        let addHeader = [...header];
        const tabHeader = addHeader.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader });
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state;
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked));
        return isActiveHeader ? true : false;
    }
    paginate = async (page) => {
        await this.setState({ page, loading: true });
        this.props.signalPage(page)
        await this.getSignals();
        this.setState({ loading: false });
    }
    render() {
        const { loading, drugList, modelList, durationList, tabHeader, signalList, signalCopyList, page, pageNumbers,
            isSummeryReport, signalReport, postData, viewSignal, selectedData, workFlowTeamList, workFlowEvaluationList, workFlowValidationList,
            validationTopicList, evaluationTopicList, projectsList, generateData, signalIcsrReview, signalLitreacture, signalViewAllActions,
            selectedStatus, isFilterOpen, signalCaseSummery } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Signals | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        {viewSignal ?
                            <ViewSignals
                                selectedData={selectedData}
                                onClose={() => this.setState({ viewSignal: false, selectedStatus: 0 })}
                                workFlowTeamList={workFlowTeamList}
                                workFlowValidationList={workFlowValidationList}
                                workFlowEvaluationList={workFlowEvaluationList}
                                validationTopicList={validationTopicList}
                                evaluationTopicList={evaluationTopicList}
                                projectsList={projectsList}
                                onAddValidationTopic={this.getAddedValidationTopic}
                                onMarkValidation={this.getSignalById}
                                onMarkEvaluation={this.getSignalById}
                                onAddEvaluationTopic={this.getAddedEvaluationTopic}
                                onRecommendation={this.getSignalById}
                                selectedStatus={selectedStatus} /> :
                            <Fragment>
                                <Header
                                    tabHeader={tabHeader}
                                    drugList={drugList}
                                    modelList={modelList}
                                    durationList={durationList}
                                    loading={loading}
                                    onHeader={e => { this.setState({ tabHeader: e }) }}
                                    filterData={postData}
                                    onFilter={async e => {
                                        await this.filterSignals(e);
                                    }}
                                    onAdd={async () => {
                                        await this.setState({ page: 1, loading: true });
                                        this.getSignals()
                                    }}
                                    onSearch={async e => {
                                        await this.setState({ search: e ? e : '', page: e ? 1 : page, loading: true });
                                        this.getSignals()
                                    }}
                                    onFilterOpen={(e) => { this.setState({ isFilterOpen: e ? false : true }) }}
                                    hideFilter={isFilterOpen}
                                />
                                <Fragment>
                                    {signalCaseSummery ?
                                        <SignalCaseSummery selectedData={selectedData}
                                            onClose={() => this.setState({ signalCaseSummery: false, selectedData: '' })} /> :
                                        signalViewAllActions ?
                                            <SignalAllActionsView selectedData={selectedData}
                                                onClose={() => this.setState({ signalViewAllActions: false, selectedData: '' })} /> :
                                            signalLitreacture ?
                                                <SignalLiteratureView selectedData={selectedData}
                                                    onClose={() => this.setState({ signalLitreacture: false, selectedData: '' })} /> :
                                                signalIcsrReview ?
                                                    <SignalICSRReview selectedData={selectedData}
                                                        onClose={() => this.setState({ signalIcsrReview: false, selectedData: '' })} /> :
                                                    signalReport ?
                                                        <SignalReport
                                                            isSummeryReport={isSummeryReport}
                                                            onClose={() => this.setState({ signalReport: false, isSummeryReport: false })}
                                                            drugList={drugList}
                                                            modelList={modelList}
                                                            reportData={postData} /> :
                                                        <Fragment>
                                                            {isFilterOpen ? null :
                                                                <InlineSearch
                                                                    loading={loading}
                                                                    signalCopyList={signalCopyList}
                                                                    onSearch={signalList => this.setState({ signalList })}
                                                                    onFilter={(e) => this.getSignals(e)}
                                                                    onSummery={() => { this.setState({ signalReport: true, isSummeryReport: true }) }}
                                                                    onPbrer={() => { this.setState({ signalReport: true, isSummeryReport: false }) }}
                                                                    onClear={() => this.getSignals()}
                                                                    generateData={generateData}
                                                                    onCloseGenerateData={() => this.setState({ generateData: { ...this.state.generateData, show: false, loading: false, file: '' } })} />
                                                            }
                                                            <SignalTable
                                                                tabHeader={tabHeader}
                                                                loading={loading}
                                                                filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                                                signalList={signalList}
                                                                onMenu={(a, b, c) => {
                                                                    if (b) { this.setState({isFilterOpen:false}) }
                                                                    if (b == 1) this.setState({ viewSignal: true, selectedData: a, selectedStatus: c });
                                                                    if (b == 2) { this.getGenerateData(a); }
                                                                    if (b == 3) this.setState({ signalIcsrReview: true, selectedData: a });
                                                                    if (b == 4) this.setState({ signalLitreacture: true, selectedData: a });
                                                                    if (b == 5) this.setState({ signalViewAllActions: true, selectedData: a });
                                                                    if (b == 6) this.getSignals();
                                                                    if (b == 7) this.setState({ signalCaseSummery: true, selectedData: a });
                                                                }} />
                                                            {
                                                                signalList && signalList.length ?
                                                                    <AppPagination
                                                                        previousPage={e => { this.paginate(e) }}
                                                                        nextPage={e => { this.paginate(e) }}
                                                                        selectedPage={e => { this.paginate(e) }}
                                                                        page={page}
                                                                        pageNumbers={pageNumbers} /> :
                                                                    !loading ? <AppNodata isTable /> : <Fragment></Fragment>
                                                            }
                                                        </Fragment>
                                    }
                                </Fragment>
                            </Fragment>
                        }

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentPage: state.Signal.page,
        signalHeaderFilter: state.Signal.signalHeaderFilter,
    }
}
export default connect(mapStateToProps, { signalPage })(Signals)
