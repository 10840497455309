import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux";
import './Appstyle.css';
import store from "./store";
import { Toaster } from "react-hot-toast"
import 'simplebar/dist/simplebar.css';
const server = process.env.REACT_APP_SERVER;
// if (server == "labcorp")
//   import('./labcorpTheme.css');
// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <Toaster />
    </BrowserRouter >
  </Provider >
);
ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
