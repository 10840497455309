import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Logo from "assets/illusion/add.png";
import { Row, Col, Card, CardImg, Form, Label } from 'reactstrap';
import { AppButton, AppInput } from 'components/Reusable';
import { DateRangePicker } from 'rsuite';
import { Service } from '../Appservice';
import 'rsuite/dist/rsuite-no-reset.min.css';
import toast from 'react-hot-toast';
import { signalApi } from '../ApiConstant';
import { queryDate } from '../AppConstants';
export default class SignalReport extends Component {
    static get propTypes() {
        return {
            isSummeryReport: PropTypes.any,
            onClose: PropTypes.func,
            drugList: PropTypes.any,
            modelList: PropTypes.any,
            reportData: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { reportData } = props;
        this.state = {
            postData: {
                drug: reportData?.drug ?? '',
                signal_parent_model_id: reportData?.signal_parent_model_id ?? '',
                startDate: '',
                endDate: ''
            },
            postErr: {
                drug: '',
                signal_parent_model_id: ''
            },
            loading: false,
            reportFile: '',

        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    signalReport = async () => {
        const { postData } = this.state;
        if (!postData.startDate || !postData.endDate) return toast.error("Date should not be empty..")
        this.setState({ loading: true })
        let queryParam = `?end_date=${queryDate(postData.endDate)}&start_date=${queryDate(postData.startDate)}&drug=${postData.drug}&signal_parent_model_id=${postData.signal_parent_model_id}`;
        const report = await Service.appservice('get', signalApi.signalsEaluvationSummery + queryParam);
        if (report && report.file_url) return await this.setState({ loading: false, reportFile: report.file_url })
        this.setState({ loading: false })
    }
    pbrerReport = async () => {
        const { postData } = this.state;
        if (!postData.startDate || !postData.endDate) return toast.error("Date should not be empty..")
        this.setState({ loading: true });
        let queryParam = `?end_date=${queryDate(postData.endDate)}&start_date=${queryDate(postData.startDate)}`;
        const report = await Service.appservice('get', signalApi.signalsSummery + queryParam);
        if (report && report.file_url) return await this.setState({ loading: false, reportFile: report.file_url })
        this.setState({ loading: false })
    }
    render() {
        const { onClose, drugList, modelList, isSummeryReport } = this.props;
        const { postData, loading, reportFile } = this.state;
        let isDisabled = postData.startDate && postData.endDate ? false : true;
        return (
            <Fragment >
                <AppButton name={`${isSummeryReport ? 'Signal Summary ' : 'Pbrer '}  Report`} tooltip="Back to Signal List" icon='fas fa-arrow-left' onClick={onClose} />
                <Row >
                    <Col md={4} className='d-none d-sm-block'>
                        <CardImg className="img-fluid p-2" src={Logo} />
                    </Col>
                    <Col md={6} className='justify-content-center mt-3 mt-sm-0'>
                        <Form className='p-2 rounded  align-items-center'>
                            <Row>
                                {isSummeryReport ?
                                    <Fragment>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <AppInput label="Drug *" type="select" value={postData.drug} onChange={e => { this.setValue(e, 'drug') }} iArray={drugList} selectType='namepair' />
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <AppInput label="Model *" type="select" value={postData.signal_parent_model_id} onChange={e => { this.setValue(e, 'signal_parent_model_id'); }} iArray={modelList} selectType='idpair' />
                                        </Col>
                                    </Fragment> :
                                    <Fragment></Fragment>}
                                <Col lg={12}>
                                    <Label>Choose Dates *</Label>
                                    <DateRangePicker
                                        preventOverflow
                                        block placeholder="Choose Dates"
                                        onChange={(e) => { this.setState({ postData: { ...this.state.postData, startDate: e[0], endDate: e[1] } }) }}
                                        onOk={(e) => { this.setState({ postData: { ...this.state.postData, startDate: e[0], endDate: e[1] } }) }}
                                        onClean={() => this.setState({ postData: { ...this.state.postData, startDate: '', endDate: '' } })} />
                                </Col>
                                <Col lg={12} className='text-center mt-3'>
                                    <AppButton name="Generate Report" icon='fas fa-file-alt'
                                        onClick={() => isSummeryReport ? this.signalReport() : this.pbrerReport()} disabled={loading} showloader />

                                    {
                                        reportFile ?
                                            <a role='btn'
                                                className='btn btn-sm btn-outline-danger shadow ms-3 '
                                                href={reportFile} download={reportFile}
                                                onClick={() => this.setState({ reportFile: '' })}>
                                                <i className="fas fa-download" />
                                            </a> :
                                            <Fragment></Fragment>
                                    }
                                </Col>

                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
