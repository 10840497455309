import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput, AppLoader, AppShowmore } from 'components/Reusable';
import { Row, Col, CardImg, CardText, CardBody, Card, Form } from 'reactstrap';
import Logo from "assets/illusion/add.png";
import { Service } from '../Appservice';
import Download from "assets/images/background.jpg";
import toast from "react-hot-toast";
import { signalApi } from '../ApiConstant';
import { appDate } from '../AppConstants';
export default class SignalICSRReviewAddCommentDownload extends Component {
    static get propTypes() {
        return {
            rowData: PropTypes.any,
            onClose: PropTypes.func,
            isAdd: PropTypes.bool,
            isComment: PropTypes.bool,
            isDownload: PropTypes.bool,
            selectedData: PropTypes.any,
            report_type: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { isAdd, isComment, isDownload, selectedData, rowData, report_type } = props;
        this.state = {
            isAdd: isAdd,
            isComment: isComment,
            isDownload: isDownload,
            loading: false,
            commentsList: [],
            downloadFile: '',
            postData: {
                signal: selectedData.id,
                snowflake_id: rowData.ID,
                report_type: report_type,
                comment: '',
            },
            postErr: {
                comment: false
            }
        }
    }
    componentDidMount = () => {
        this.getSignalData();
    }
    getSignalData = async (isNewcomment = false) => {
        const { isComment, isDownload, postData } = this.state;
        let queryParam = "?"
        if (postData.signal) queryParam += "&signal_id=" + postData.signal;
        if (postData.report_type) queryParam += "&report_type=" + postData.report_type;
        if (postData.snowflake_id) queryParam += "&snowflake_id=" + postData.snowflake_id;
        this.setState({ loading: true });
        let getSignalData;
        if (isComment || isNewcomment) getSignalData = await Service.appservice('get', signalApi.signalIcsrHistory + queryParam);
        if (isDownload) getSignalData = await Service.appservice('get', signalApi.signalIcsrHistoryExcel + queryParam);
        if (getSignalData) {
            if (isComment || isNewcomment) this.setState({ commentsList: getSignalData })
            if (isDownload) this.setState({ downloadFile: getSignalData?.file_url })
        }
        this.setState({ loading: false });
    }
    setValue = (i, e) => {
        const value = i.target.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    saveComments = async () => {
        const { postData } = this.state;
        if (!postData.comment) return this.setError('comment');
        this.setState({ loading: true });
        let getSignalData = await Service.appservice('post', signalApi.signalIcsrHistory, postData);
        if (getSignalData) {
            this.setState({ postData: { ...this.state.postData, comment: '' } });
            toast.success('Comments added successfully', 'Comments');
            this.getSignalData(true);
        }
        this.setState({ loading: false });
    }
    render() {
        const { rowData, onClose, } = this.props;
        const { isAdd, isComment, isDownload, commentsList, downloadFile, loading, postData, postErr } = this.state;
        console.log('commentsList', commentsList);
        return (
            <div>
                <div className='d-flex  justify-content-sm-between btn-wrap-text'>
                    <AppButton name={`Details of ${rowData.ID}  ICSR Review`} tooltip="Back" icon='fas fa-arrow-left' onClick={() => { onClose() }} />
                    <div className='ms-2 ms-sm-0'>
                        <AppButton iconbutton tooltip="Details" icon='fas fa-bullseye' className="ms-2 btn-danger" iName={'Comments'} disabled={isComment} onClick={() => { this.setState({ isComment: true, isAdd: false, isDownload: false }); }} />
                        <AppButton iconbutton tooltip="Download" icon='fas fa-download' className=" ms-2 btn-danger" iName={'Download'} disabled={isDownload} onClick={() => { this.setState({ isComment: false, isAdd: false, isDownload: true }) }} />
                        <AppButton iconbutton tooltip="Add Comment" icon='fas fa-plus' className=" ms-2 btn-danger" iName={'Add'} disabled={isAdd} onClick={() => { this.setState({ isComment: false, isAdd: true, isDownload: false }) }} />
                    </div>
                </div>
                <Row className="no-gutters align-items-center">
                    <Col md={3} className='d-none d-sm-block'>
                        <CardImg className="img-fluid" src={Logo} alt="Skote" />
                    </Col>
                    <Col md={6}>
                        <CardBody>
                            <CardText>
                                <Row className='border rounded'>
                                    {Object.keys(rowData).map(key => {
                                        return (
                                            <Col lg={4} md={6} sm={6} key={key} className='p-2'>
                                                <AppShowmore  text={key + ' : ' + rowData[key]} id={key} />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </CardText>
                        </CardBody>
                    </Col>
                    <Col md={3}>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Fragment>
                                <Card className='shadow'>
                                    <CardImg top className="img-fluid" src={Download} alt="Skote" />
                                    <CardBody>
                                        {isAdd ?
                                            <Fragment>
                                                <Form>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <AppInput label="Add Comment *" error="Comment is required"
                                                                onChange={e => { this.setValue(e, 'comment') }}
                                                                value={postData.comment} isInvalid={postErr.comment} />
                                                        </Col>
                                                        <Col lg={12} className='text-center'>
                                                            <AppButton className="btn-danger" name="Add" icon='fas fa-plus' tooltip="Add Comment" disabled={loading} showloader onClick={this.saveComments} />
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Fragment>
                                            :
                                            isComment ?
                                                <Fragment>
                                                    {loading ?
                                                        <AppLoader isGlow /> :
                                                        commentsList?.length ?
                                                            <Fragment>
                                                                {commentsList?.map((e, i) => {
                                                                    return (
                                                                        <>
                                                                            <Fragment key={i}>
                                                                                <span className='mb-2 text-center'>
                                                                                    <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                                                </span>
                                                                                <div className='card shadow-sm p-2' >
                                                                                    <span className='mt-3'>
                                                                                        <h5>{e?.comment}</h5>
                                                                                        <small className='blockquote-footer'>Created by {e?.created_by}</small>
                                                                                    </span>
                                                                                </div>
                                                                            </Fragment>
                                                                        </>)
                                                                })}
                                                            </Fragment> :
                                                            <p className='text-danger text-center '>No new comments added..</p>}
                                                </Fragment> :
                                                <CardText className='text-center'>
                                                    {downloadFile ?
                                                        <a role='btn' disabled={loading}
                                                            className='btn btn-sm btn-success ms-3'
                                                            href={downloadFile} download={downloadFile}>
                                                            <i className="fas fa-download me-2" /> Click here to download
                                                        </a>
                                                        :
                                                        <AppButton name="Download" disabled={loading} showloader onClick={this.getSignalData} />
                                                    }
                                                </CardText>
                                        }
                                    </CardBody>
                                </Card>
                            </Fragment>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
