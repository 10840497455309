import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Badge, } from 'reactstrap';
import { AppShowmore, AppLoader, AppButton } from 'components/Reusable';
import { appDate } from '../AppConstants';
export default class SignalHistoryGrid extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            signalHistory: PropTypes.any,
            loading: PropTypes.bool,
            onClose:PropTypes.object
        };
    }
    render() {
        const { loading, signalHistory,onClose } = this.props;
        return (
            <Fragment>
                <AppButton name={`Back to Action`} tooltip="Back to Action List" icon='fas fa-arrow-left' onClick={onClose} />
                <div className='shadow rounded-3 table-responsive mt-3'>
                    <Table className="table-hover table-borderless mb-0">
                        <thead className='shadow'>
                            <tr>
                                <th>Id</th>
                                <th>Activity</th>
                                <th>Status</th>
                                <th>Comments</th>
                                <th>Created At</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                <tr><td colSpan={15}><AppLoader isGlow={true} count={2} /></td></tr>
                                :
                                signalHistory?.data?.length ?
                                    signalHistory?.data.map((e, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <tr key={i} >
                                                    <td ><AppShowmore text={i + 1} /></td>
                                                    <td ><Badge className="me-2 p-2 bg-dark">{e?.status_type}</Badge></td>
                                                    <td ><Badge className="me-2 p-2 bg-info">{e?.actual_status}</Badge></td>
                                                    <td ><AppShowmore text={e?.comment} id={'tool5' + i} /></td>
                                                    <td ><AppShowmore text={appDate(e?.created_at)} id={'tool2' + i} /></td>
                                                    <td ><AppShowmore text={e?.owner?.username} id={'tool3' + i} /></td>
                                                </tr>
                                            </Fragment>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={15}>
                                            <p className='text-center text-danger'>No history Found...</p>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Fragment>
        )
    }
}
