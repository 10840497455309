import React, { Component } from 'react';
import { InputGroup, Form, Row, Input, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import { emptyObject } from '../../AppConstants';
export default class Filter extends Component {
    static get propTypes() {
        return {
            userList: PropTypes.any,
            onFilter: PropTypes.func,
            loading: PropTypes.bool
        };
    }
    constructor() {
        super();
        this.state = { assigned_to: '' }
    }
    render() {
        const { userList, onFilter, loading } = this.props;
        const { assigned_to } = this.state;
        return (
            <div className="d-flex justify-content-center card-body">
                <Form >
                    <Row>
                        <Col lg="12">
                            <InputGroup>
                                <div className="input-group-text p-1 shadow">Assigned To</div>
                                <Input className='form-control' onChange={e => this.setState({ assigned_to: e.target.value })} type="select" name="select" value={assigned_to}>
                                    <option value="">All</option>
                                    {
                                        userList.map((e, i) => {
                                            return <option value={e.id} key={i}>{e.first_name + ' ' + e.last_name}</option>
                                        })
                                    }
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col lg="12" className='mt-3 text-center'>
                            <AppButton name="Action Filter" disabled={loading || emptyObject(assigned_to)} icon='fas fa-filter' className="me-2" tooltip="Filter" onClick={() => { onFilter(assigned_to) }} />
                            <span data-bs-toggle="collapse" href="#filterCollapse">
                                <AppButton disabled={loading || emptyObject(assigned_to)} iconbutton className="ms-1 btn-danger" icon='fas fa-times' tooltip="Clear & Close Filter" onClick={async () => { await this.setState({ assigned_to: '' }); onFilter() }} />
                            </span>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}
