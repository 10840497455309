import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CardBody, Card, Row, Col, CardHeader, Table } from 'reactstrap';
export default class WorkflowTopics extends Component {
    static get propTypes() {
        return {
            topicData: PropTypes.any,
            children: PropTypes.any,
        };
    }
    render() {
        const { topicData, children } = this.props;
        return (
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase  text-danger text-center rounded shadow-sm">
                    Topic Name : {topicData.theme_data.name}
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <div className='table-responsive border rounded'>
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Assigned To</th>
                                            <th>Project</th>
                                            <th>Attachment</th>
                                            <th>Comments</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{topicData?.theme_data?.description}</td>
                                            <td>{topicData?.assigned_to_data?.name}</td>
                                            <td>{topicData?.project_data?.name}</td>
                                            <td><span className='badge p-2 bg-warning text-center'>{topicData?.attachment_count}</span></td>
                                            <td><span className='badge p-2 bg-warning text-center'>{topicData?.comment_count}</span></td>
                                            <td><span className='badge p-2 bg-warning text-center'>{topicData?.measure_count}</span></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {children}
            </Card>
        )
    }
}
