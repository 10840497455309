import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { Col, Form, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import { Service } from '../Appservice';
import { signalApi } from '../ApiConstant';
export default class SignalMarkEvaluation extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            isGrid: PropTypes.bool,
        };
    }
    constructor(props) {
        super(props);
        const { selectedData } = props;
        this.state = {
            postData: {
                evaluation_confirmation_status: selectedData.evaluation_confirmation_status ?? '',
                risk_categorisation: selectedData.risk_categorisation ?? '',
                evaluation_comment: selectedData.evaluation_comment ?? '',
                risk_status_comment: selectedData.risk_status_comment ?? '',
            },
            postErr: {
                evaluation_confirmation_status: false,
                risk_categorisation: false,
                evaluation_comment: false,
                risk_status_comment: false,
            },
            evalList: [{ id: 'C', name: 'Confirmed' }, { id: 'R', name: 'Refuted' }, { id: 'M', name: 'Monitoring' },
            { id: 'OR', name: 'Ongoing Review' }],
            riskList: [{ id: 'NIIR', name: 'New Important Identified Risk' }, { id: 'NIR', name: 'New Identified Risk' }, { id: 'MR', name: 'Modified Risk' },],
            loading: false
        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onMarkEvaluation = async () => {
        const { selectedData, onClose } = this.props;
        const { postData } = this.state;
        if (!postData.evaluation_confirmation_status) return this.setError('evaluation_confirmation_status');
        if (postData.evaluation_confirmation_status == 'C' && !postData.risk_categorisation) return this.setError('risk_categorisation');
        if (!postData.evaluation_comment) return this.setError('evaluation_comment');
        if (postData.evaluation_confirmation_status == 'C' && !postData.risk_status_comment) return this.setError('risk_status_comment');

        this.setState({ loading: true });
        let evlData = { evaluation_confirmation_status: postData.evaluation_confirmation_status, comment: postData.evaluation_comment };
        await Service.appservice('patch', `${signalApi.signals}/${selectedData.id}/update_status`, evlData);
        if (postData.evaluation_confirmation_status == 'C') {
            let riskData = { risk_categorisation: postData.risk_categorisation, comment: postData.risk_status_comment }
            await Service.appservice('patch', `${signalApi.signals}/${selectedData.id}/update_status`, riskData);
        }
        toast.success('Evaluation Status Updated Successfully !...');
        this.setState({ loading: true });
        onClose(true);
    }
    render() {
        const { selectedData, onClose,isGrid } = this.props;
        const { postData, postErr, evalList, riskList, loading } = this.state;
        return (
            <Fragment>
                {isGrid ? <AppButton icon="fas fa-arrow-left" tooltip="Back" onClick={()=>onClose()}/> :
                    <span className='shadow rounded p-2'>{`Mark Evaluvation of ${selectedData?.id}`}</span>}
                <Form className='mt-3 me-5 ms-5'>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AppInput label="Evaluation/Signal status *" type="select" value={postData.evaluation_confirmation_status} isInvalid={postErr.evaluation_confirmation_status}
                                error="Evaluation/Signal status is required" onChange={e => { this.setValue(e, 'evaluation_confirmation_status') }} iArray={evalList} selectType='idpair' />
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AppInput label="Comment *" type="text" value={postData.evaluation_comment} isInvalid={postErr.evaluation_comment}
                                error="Evaluation Comment is required" onChange={e => { this.setValue(e, 'evaluation_comment') }} />
                        </Col>
                        {postData.evaluation_confirmation_status == 'C' &&
                            <Fragment>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <AppInput label="Risk categorisation *" type="select" value={postData.risk_categorisation} isInvalid={postErr.risk_categorisation}
                                        error="Risk categorisation is required" onChange={e => { this.setValue(e, 'risk_categorisation') }} iArray={riskList} selectType='idpair' />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <AppInput label="Comment *" type="text" value={postData.risk_status_comment} isInvalid={postErr.risk_status_comment}
                                        error="Risk categorisation Comment is required" onChange={e => { this.setValue(e, 'risk_status_comment') }} />
                                </Col>
                            </Fragment>}
                        <Col className='text-center'>
                            <AppButton icon='fas fa-check me-2' name="Mark Evaluation" disabled={loading} showloader onClick={this.onMarkEvaluation} />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        )
    }
}
