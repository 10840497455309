import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import SignalbyStatusGraph from './SignalbyStatusGraph';
import SignalbyWorkFlowGraph from './SignalbyWorkFlowGraph';
import SignalbyTimeGraph from './SignalbyTimeGraph';
import SignalActionsGraph from './SignalActionsGraph';

export default class FullGraph extends Component {
    static get propTypes() {
        return {
            updateData: PropTypes.any,
            onClose: PropTypes.func,
            signalStatusGraph: PropTypes.any,
            signalWorkFlowGraph: PropTypes.any,
            signalActionGraph: PropTypes.any,
            drugName: PropTypes.any,
            signalTimeGraph: PropTypes.any,
            graphId: PropTypes.number,
            drugList: PropTypes.number,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            graphId: this.props.graphId
        }
    }

    render() {
        const { drugName, signalActionGraph, signalStatusGraph, signalTimeGraph, signalWorkFlowGraph, onClose, drugList } = this.props;
        const { graphId } = this.state;
        return (
            <Fragment>
                <div className='d-flex justify-content-start ms-3'>
                    <AppButton iconbutton className="float-end btn-danger" icon='fas fa-arrow-left' onClick={onClose} tooltip="Back to Dashboard"
                        name={graphId == 4 ? 'Actions Information' : `Signals by ${graphId == 1 ? 'Status' : graphId == 2 ? 'Workflow' : 'Time'}`} />
                </div>
                {
                    graphId == 1 ?
                        <SignalbyStatusGraph signalStatusGraph={signalStatusGraph} /> :
                        graphId == 2 ?
                            <SignalbyWorkFlowGraph signalWorkFlowGraph={signalWorkFlowGraph} /> :
                            graphId == 3 ?
                                <SignalbyTimeGraph signalTimeGraph={signalTimeGraph} /> :
                                graphId == 4 ?
                                    <SignalActionsGraph signalActionGraph={signalActionGraph} drugName={drugName} onFilter={() => { }} drugList={drugList} /> :
                                    <Fragment></Fragment>
                }
            </Fragment>
        )
    }
}
