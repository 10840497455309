import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Row, Col,CardHeader,CardText } from 'reactstrap';

export default class Details extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
        };
    }
    render() {
        const { selectedData } = this.props;
        return (
            <div className='mt-3'>
                <CardBody>
                    <CardTitle className='ms-2 mb-2 text-danger'>Details</CardTitle>
                    <Row>
                        <Col md="12">
                            <Row>
                                {
                                    selectedData?.signal_data && Object.keys(selectedData?.signal_data).length ?
                                        Object.entries(selectedData?.signal_data).map((j, index) => {
                                            return (
                                                <Col lg={3} key={index}>
                                                    <Card>
                                                        <CardHeader className="bg-transparent border-bottom text-uppercase card-title">{j[0].replace(/_+/g, " ")}</CardHeader>
                                                        <CardBody>
                                                            <blockquote className="card-blockquote mb-0">
                                                                <CardText>
                                                                    {j[1] ? (j[1].toString().replace(/_+/g, " ")) : "-"}
                                                                </CardText>
                                                            </blockquote>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })

                                        :
                                        <p className='text-center'>No Details found</p>
                                }
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </div>
        )
    }
}
