import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Col, Form, Row, Table } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import SimpleBar from "simplebar-react";
import { Service } from '../Appservice';
import moment from 'moment';
import { AppPagination, AppShowmore, AppInput, AppButton } from 'components/Reusable';
import { signalApi } from '../ApiConstant';
export default class ViewedPages extends Component {
    static get propTypes() {
        return {
            loading: PropTypes.bool,
            headers: PropTypes.any,
            graphData: PropTypes.any,
            userList: PropTypes.any,
            onLog: PropTypes.func,
        };
    }
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            viewloading: false,
            viewList: [],
            assigned_to: '',
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
                offsetY: -10,
            },
            isDetails: false,
            detailsData: null
        }
    }
    componentDidMount() {
        this.setState({ viewloading: true });
        this.getViewData();
    }
    getViewData = async () => {
        const { page, assigned_to } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (assigned_to) queryParam += "&actor__id=" + assigned_to;
        const getViewData = await Service.appservice('get', signalApi.auditLogEntry + queryParam);
        if (getViewData?.data)
            this.setState({
                viewList: getViewData.data,
                viewloading: false,
                pageNumbers: getViewData.metadata ? Array.from({ length: getViewData.metadata.total_pages }, (v, k) => k + 1) : []
            });
    }
    paginate = async (page) => {
        this.setState({ page, viewloading: true });
        this.getViewData();
    }
    render() {
        const { page, pageNumbers, viewList, viewloading, assigned_to, legend, isDetails, detailsData } = this.state;
        const { graphData, loading, headers, userList, onLog } = this.props;
        const labels = graphData?.map(a => a.content_type) ?? [];
        const series = graphData?.map(a => a.count) ?? undefined;
        const graph = { options: { labels, series, legend } };
        return (
            <Fragment>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    <div className="text-primary fw-bold d-none d-sm-block shadow p-2 rounded">
                        <i className="fas fa-user me-2" />
                        User changes logs
                    </div>
                    <div className=" d-flex flex-row justify-content-between align-items-center">
                        <AppButton icon="fas fa-eye" name="Event Logs" onClick={onLog} className="me-2" />
                        <Form>
                            <AppInput label="Filter" type="select" value={assigned_to} iArray={userList} selectType='usernamepair'
                                onChange={async e => { console.log('e', isNaN(e.target.value)); await this.setState({ assigned_to: isNaN(e.target.value) ? '' : e.target.value }); this.getViewData() }} />
                        </Form>
                    </div>
                </div>
                <Row>
                    <Col lg={4} >
                        {loading ?
                            <Fragment></Fragment> :
                            series?.length && labels?.length ?
                                <ReactApexChart options={graph.options} series={graph.options.series} type="pie" height={500} /> :
                                <p>No graph data found..</p>}
                    </Col>
                    <Col lg={8}>
                        {isDetails ?
                            <div className='card-body justify-content-center'>
                                <AppButton icon="fas fa-arrow-left" name={`Change details for ${detailsData?.actor?.first_name + ' ' + detailsData?.actor?.last_name}`} onClick={() => this.setState({ isDetails: false, detailsData: '' })} />
                                <ul className='list-unstyled mt-3' >
                                    {Object.entries(JSON.parse(detailsData?.changes)).map((e, i) => {
                                        return (
                                            <li key={i} className='mb-3 border p-2 rounded overflow-hidden'>
                                                <strong className='me-4'>{e[0]}:</strong> from {e[1][0]} to {e[1][1]}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div> :
                            <SimpleBar className='shadow rounded-3 mt-3'>
                                <Table className="table-hover table-borderless mb-0">
                                    <thead className='shadow'>
                                        <tr>{headers.map((e, i) => <th  key={i}>{e}</th>)}</tr>
                                    </thead>
                                    <tbody>
                                        {viewloading ?
                                            <tr>
                                                <td colSpan={5} className='text-center'>
                                                    <AppButton name="Loading" disabled showloader />
                                                </td>
                                            </tr> :
                                            viewList?.length ?
                                                viewList.map((e, i) => {
                                                    return (
                                                        <tr key={i} >
                                                            <td className='text-right'>{moment(e?.timestamp, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY")}</td>
                                                            <td >{e?.actor?.first_name}{' '}{e?.actor?.last_name}</td>
                                                            <td><AppShowmore text={e?.content_type?.app_label} id={'tool' + i} /></td>
                                                            <td className='text-center'>
                                                                <span className='badge badge-soft-danger shadow p-2 rounded' role="button" onClick={() => { this.setState({ isDetails: true, detailsData: e }) }}>
                                                                    <i className='fa fa-info' />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                <tr>
                                                    <td colSpan={5} className='text-center'>
                                                        <p className='  p-2 text-center text-danger'>No view logs found..</p>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                                {
                                    viewList?.length ?
                                        <AppPagination previousPage={e => { this.paginate(e) }} nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} />
                                        : <Fragment></Fragment>
                                }
                            </SimpleBar>
                        }
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
