import React, { Component } from 'react';
import { Form, InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
export default class InlineSearch extends Component {
    static get propTypes() {
        return {
            intelCopyList: PropTypes.any,
            onSearch: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            inlineSearch: ''
        }
    }
    onInlineSearch = (e) => {
        const searchText = e;
        function matchValue(input) {
            let result = input?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            return result;
        }
        console.log('this.props.intelCopyList', this.props.intelCopyList);
        const filteredData = this.props.intelCopyList.filter((row) => {
            return (
                matchValue(row.AGENCY) ||
                matchValue(row.PRODUCTNAME_PRODUCTCLASS_ACTIVE_INGREDIENTS) ||
                matchValue(row.SOURCE_LINK1) ||
                matchValue(row.STATUS)
            );
        });
        this.props.onSearch(filteredData);
        this.setState({ inlineSearch: searchText });
    }
    render() {
        const { inlineSearch } = this.state;
        return (
            <Form className="row col-lg-4 col-md-4 col-sm-12 col-xs-12" key={'Search'}>
                <InputGroup className=''>
                    <div className="input-group-text bg-white shadow" onClick={() => { this.setState({ inlineSearch: '' }); this.props.onSearch(this.props.intelCopyList); }}>
                        {inlineSearch ? <i className='fas fa-times text-danger' /> : <i className='bx bx-search-alt-2 text-danger' />}
                    </div>
                    <input onKeyPress={(e) => { e.key === 'Enter' ? e.preventDefault() : ''; }}
                        onChange={async (e) => this.onInlineSearch(e.target.value)}
                        value={inlineSearch} type="text" className="form-control" id="autoSizingInputGroup" placeholder="Search in table..." />
                </InputGroup>
            </Form>
        )
    }
}
