import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink, Row, Col } from 'reactstrap'
import SimpleBar from "simplebar-react";
export default class AppPagination extends Component {
    static get propTypes() {
        return {
            pageNumbers: PropTypes.array,
            page: PropTypes.number,
            previousPage: PropTypes.any,
            nextPage: PropTypes.any,
            selectedPage: PropTypes.any,
            isStart: PropTypes.bool
        };
    }
    render() {
        const { pageNumbers, page, previousPage, nextPage, selectedPage, isStart } = this.props;
        let pageLimit = 10 // number of page links in pagination
        let start = 0 // starting page
        let end = pageLimit // ending page
        if (pageNumbers.length <= pageLimit) {
            pageLimit = pageNumbers.length
        }
        // increment start page when current page is greater than 5
        if (page - 5 >= 0) {
            start = page - 4
        }
        // if reaching end of pagination stop increment
        if (start + pageLimit >= pageNumbers.length) {
            start = pageNumbers.length - pageLimit
        }
        // increment end page when current + 5 exceeds page limit
        if (page + 5 >= pageLimit) {
            end = page + 6
            pageLimit = end
            if (pageNumbers.length <= pageLimit) {
                pageLimit = pageNumbers.length
            }
        }
        if(pageNumbers.length <= 1) return null;
        return (
            <SimpleBar>
                <div className='mt-3'>
                    <Pagination size="sm" className={`pagination pagination-danger pagination-rounded justify-content-center mb-2 ms-5 ms-sm-0`} >
                        {page != 1 && (
                            <PaginationItem>
                                <PaginationLink
                                    previous
                                    onClick={async () => { await previousPage(1) }}
                                >
                                    <i className='fas fa-angle-double-left' />
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        {page != 1 && (
                            <PaginationItem>
                                <PaginationLink
                                    previous
                                    onClick={async () => { await previousPage(page - 1) }}
                                >
                                    <i className='fas fa-angle-left' />
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        {pageNumbers.map(i => {
                            if (i >= start && i < end) {
                                return (
                                    <PaginationItem className='page-inner-item' active={page == i} key={i} id={i}>
                                        <PaginationLink
                                            onClick={async () => { if (page != i) await selectedPage(i) }}>
                                            {i}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            }
                        })}
                        {page != pageNumbers.length && (
                            <PaginationItem>
                                <PaginationLink
                                    next
                                    onClick={async () => { await nextPage(page + 1) }}>
                                    <i className='fas fa-angle-right' />
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        {page != pageNumbers.length && (
                            <PaginationItem>
                                <PaginationLink
                                    next
                                    onClick={async () => { await nextPage(pageNumbers.length) }}>
                                    <i className='fas fa-angle-double-right' />
                                </PaginationLink>
                            </PaginationItem>
                        )}
                    </Pagination>
                </div>
            </SimpleBar>
        )
    }
}
