import { AppButton, AppInput } from 'components/Reusable';
import React, { Component, Fragment } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Card, Container, Form, Table, Row, Input, Col, FormGroup, Label } from 'reactstrap';
import { Service } from '../Appservice';
import Search from 'assets/illusion/search.png';
import Find from 'assets/illusion/change.png';
import { imdrf } from '../ApiConstant';
export default class Imdrf extends Component {
    constructor() {
        super();
        this.state = {
            postData: {
                text: '',
                includeMeddra: 0,
                itemReturned: 0
            },
            commentsErr: false,
            loading: false,
            imdrfList: {},
            isSearched: false,
            searchId: 0,
            searchFrom: '',
            filterCodeTerm: [],
            selectedCode: '',
            selectedCodeErr: false
        }
    }
    onComment = async (tableSearch = false) => {
        const { postData } = this.state;
        if (!postData.text) return this.setState({ commentsErr: true });
        this.setState({ loading: true })
        const onComment = await Service.appservice('post', imdrf, this.state.postData);
        if (onComment?.result) {
            if (tableSearch) {
                let filterCodeTerm = Object.keys(onComment?.result).map(a => {
                    return onComment?.result[a].map((b) => {
                        const code = this.setCode(b);
                        if (code) return b;
                        else return;
                    })?.filter(e => e);
                })?.filter(e => e.length);
                filterCodeTerm = filterCodeTerm?.flat(1);
                this.setState({ filterCodeTerm })
            }
            else this.setState({ imdrfList: onComment?.result });
        }
        this.setState({ loading: false, isSearched: true })
    }
    formatObject = (obj) => {
        let result = {};
        Object.keys(obj).forEach(function (key) {
            result[key.replaceAll(" ", "_")] = obj[key];
        });
        return result;
    }
    setCode = (codes) => {
        const format = this.formatObject(codes);
        if (format.Level_3_Code) return format.Level_3_Code;
        if (format.Level_2_Code) return format.Level_2_Code;
        if (format.Level_1_Code) return format.Level_1_Code;
    }
    setTerm = (codes) => {
        const format = this.formatObject(codes);
        if (format.Level_3_Term) return format.Level_3_Term;
        if (format.Level_2_Term) return format.Level_2_Term;
        if (format.Level_1_Term) return format.Level_1_Term;
    }
    applyCode = () => {
        const { selectedCode, searchId, searchFrom, imdrfList } = this.state;
        if (!selectedCode) return this.setState({ selectedCodeErr: true });
        imdrfList[searchFrom][searchId - 1] = selectedCode;
        this.setState({ imdrfList, selectedCode: '', searchFrom: '', searchId: 0, filterCodeTerm: [] })
    }
    render() {
        const { comments, commentsErr, loading, imdrfList, isSearched, searchFrom, searchId, filterCodeTerm, selectedCodeErr } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>IMDRF | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        <Card className='ps-2 pe-2 shadow rounded-2'>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="text-primary fw-bold">
                                    <i className="fas fa-table me-2" />
                                    IMDRF Codings
                                </div>
                                <Form className="d-flex flex-row justify-content-between align-items-start mt-2">
                                    <AppInput error="Comments is required" placeholder="Enter your complaint ..."
                                        onChange={e => { this.setState({ postData: { ...this.state.postData, text: e.target.value }, commentsErr: e.target.value ? false : true }) }} value={comments} isInvalid={commentsErr}
                                    />
                                    <AppButton showloader iconbutton disabled={loading} icon="fas fa-search" tooltip="Search" onClick={() => this.onComment()}
                                        className={'ms-2 mt-2 btn-danger'} />
                                </Form>
                            </div>
                        </Card>
                        {
                            loading ?
                                <Fragment>
                                    <div className='d-flex justify-content-center'>
                                        <AppButton name="Searching... " icon="fas fa-search bx-spin" />
                                    </div>
                                </Fragment> :
                                filterCodeTerm?.length ?
                                    <div className='card-body'>
                                        <Row>
                                            {filterCodeTerm.map((e, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <Col lg={6}>
                                                            <FormGroup check className='shadow rounded'>
                                                                <Label className='p-3'>
                                                                    <Input type="radio" name="radioFilter"
                                                                        onClick={() => this.setState({ selectedCode: e, selectedCodeErr: false })} />
                                                                    <div className='ms-4'>
                                                                        <p><b>{this.setCode(e)} - {this.setTerm(e)}</b></p>
                                                                        {e.Definition}
                                                                    </div>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Fragment>
                                                )
                                            })}
                                        </Row>
                                        <div className='float-end mt-3'>
                                            <AppButton icon="fas fa-check" name="Apply code" onClick={this.applyCode} className="me-3"/>
                                            <AppButton name="Cancel" icon="fas fa-times " onClick={() => { this.setState({ selectedCode: '', searchFrom: '', searchId: 0, filterCodeTerm: [] }) }} />
                                            {selectedCodeErr && <span className='mt-4 text-danger shadow rounded p-2 border border-danger'>Choose atleast one code</span>}
                                        </div>

                                    </div> :
                                    isSearched ?
                                        <Fragment>
                                            {Object.keys(imdrfList).length ?
                                                <Fragment>
                                                    {Object.keys(imdrfList).map((a, b) => {
                                                        return (
                                                            <div key={b} className='m-3'>
                                                                <span className='shadow p-2 text-danger text-center rounded mb-4 mt-3'>{a}</span>
                                                                <div className='shadow table-responsive rounded-3 mt-3'>
                                                                    <Table className="table-hover table-borderless mb-0">
                                                                        <thead className='shadow'>
                                                                            <tr className='w-100'>
                                                                                <th className='w-25'>Choice</th>
                                                                                <th className='w-25'>Code</th>
                                                                                <th className='w-50'>Definition</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {imdrfList[a].map((c, d) => {
                                                                                return (
                                                                                    <tr key={d}>
                                                                                        <td>{`Choice ${d + 1}`} {d == 0 && <span className='badge badge-soft-success ms-3'>Most relevant</span>}</td>
                                                                                        <td>{
                                                                                            searchFrom == a && searchId == d + 1 ?
                                                                                                <Form className="d-flex flex-row align-items-center mt-2">
                                                                                                    <AppInput error="Comments is required" placeholder="Enter your complaint ..."
                                                                                                        onChange={e => { this.setState({ postData: { ...this.state.postData, text: e.target.value } }) }} value={comments} isInvalid={commentsErr}
                                                                                                    />
                                                                                                    <AppButton tooltip="Search" disabled={comments || loading} icon="fas fa-search" iconbutton className="btn-danger ms-3" onClick={this.onComment}/>
                                                                                                </Form>
                                                                                                :
                                                                                                this.setCode(c) ?
                                                                                                    this.setCode(c) :
                                                                                                    <i className='fas fa-search shadow p-2 rounded ms-2' onClick={() => this.setState({ searchId: d + 1, searchFrom: a })} />
                                                                                        } </td>
                                                                                        <td>{this.setCode(c) ?
                                                                                            <Fragment>{`${this.setCode(c)} - ${this.setTerm(c)}`} <br /> {c?.Definition}</Fragment> :
                                                                                            `Searching the code number or keywords to get codes..`}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Fragment> :
                                                <span className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='mt-4 text-danger text-center shadow rounded p-2 border border-danger mb-4'>Try with new code..</span>
                                                    <img src={Find} alt='search' />
                                                </span>
                                            }
                                        </Fragment> :
                                        <span className='h-50 d-flex justify-content-center' >
                                            <img src={Search} alt='search' />
                                        </span>
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
