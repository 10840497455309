import React, { Component, Fragment } from 'react'
import { Input, FormGroup, Label, FormFeedback, FormText } from "reactstrap";
import PropTypes from 'prop-types';
var patternTest = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g;

export default class AppInput extends Component {
    constructor() {
        super();
        this.state = {
            iInvalid: false,
            isShowPassword: false,
            iError: ''
        }
    }
    static get propTypes() {
        return {
            label: PropTypes.string,
            isInvalid: PropTypes.bool,
            error: PropTypes.string,
            placeholder: PropTypes.string,
            type: PropTypes.string,
            onChange: PropTypes.func,
            iArray: PropTypes.any,
            value: PropTypes.any,
            selectType: PropTypes.string,
            disabled: PropTypes.bool,
            infoText: PropTypes.string,
            isMultiple: PropTypes.bool,

            showAdd: PropTypes.bool,
            onAddClick: PropTypes.func,
            addTitle: PropTypes.string,
            addIcon: PropTypes.string,
            onClick: PropTypes.func,
            onKeyPress: PropTypes.func,
            isPasswordShow: PropTypes.bool,
        };
    }
    async checkSpecialCharOnly(e) {
        if (!e) return;
        if (!this.props.type || this.props.type == 'text' || this.props.type == 'textarea') {
            if (e.match(patternTest)) {
                await this.setState({ iInvalid: true, iError: this.props.label + " does not allow only special characters and numbers" });
            } else {
                await this.setState({ iInvalid: false, iError: "" });
            }
        }
    }

    handleTogglePassword = () => {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }
    handleTextChange = async (e) => {
        // if text input having only special chars removing it
        if (!this.props.type || this.props.type == 'text' || this.props.type == 'textarea') {
            const valueOfInput = e.target.value;
            if (valueOfInput.match(patternTest)) {
                this.checkSpecialCharOnly(valueOfInput); //to show valiation error
                const replacedText = valueOfInput.replace(patternTest, '');
                e.target.value = replacedText;
            }
        }else if(this.props.type === "number" && this.props.label.includes("Action Order")) {
            e.target.value = e.target.value.replace(/[^1-9]/g, '')
        }
        if (this.props.onChange)
            this.props.onChange(e);
    }

    render() {
        const { onKeyPress, onClick, addIcon, addTitle, showAdd, onAddClick, label, isInvalid, error, placeholder, onChange, iArray, type, value, selectType, disabled, infoText, isMultiple, isPasswordShow } = this.props;
        const { iError, iInvalid, isShowPassword } = this.state;
        return (
            <FormGroup className='me-2'>
                {label ?
                    <span>
                        <Label for={label} className="me-4">
                            {label}
                        </Label>
                        {showAdd ?
                            <span role='button' onClick={onAddClick} className='btn btn-sm btn-danger float-end ms-1 font-size-12 l-text cal-w-40'>
                                <i className={`me-1 fas fa-${addIcon ? addIcon : 'plus'}`}></i> {addTitle ? addTitle : 'Add'} </span>
                            :
                            <Fragment></Fragment>
                        }
                    </span>
                    : <></>}
                {
                    (type != 'select') ?
                        <div style={{ position: isPasswordShow ? "relative" : "auto", display: isPasswordShow ? "auto" : "inline" }}>
                            <Input
                                onKeyPress={onKeyPress}
                                onChange={e => { this.setState({ iInvalid: false, iError: "" }); this.handleTextChange(e); }}
                                // onBlur={() => this.checkSpecialCharOnly(value)}
                                type={(type && !isShowPassword) ? type : 'text'}
                                name={label}
                                value={value}
                                autoComplete={type === "password" ? 'new-password' : 'on'}
                                placeholder={placeholder ? placeholder : 'Enter  ' + label + ' '}
                                invalid={iInvalid ? iInvalid : isInvalid}
                                disabled={disabled ? disabled : false}
                                key={label}
                                rows='3'
                                className='app-input-sh'
                                min="1"
                                accept={type === 'file' ? '.xls, .xlsx, .csv' : ''}
                                onClick={onClick}
                            />
                            {isPasswordShow && value &&
                                <i className={isShowPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={this.handleTogglePassword} style={{ position: "absolute", right: "10px", top: "11px", cursor: "pointer", fontSize: "16px" }} aria-hidden="true"></i>}
                        </div>
                        :
                        <Input
                            onChange={e => { onChange(e) }}
                            type={type}
                            key={label}
                            name={label}
                            value={value}
                            placeholder={placeholder ? placeholder : label}
                            invalid={isInvalid}
                            disabled={disabled ? disabled : false}
                            multiple={isMultiple ? true : false}
                            className='app-input-sh'

                        >
                            {
                                type == 'select' &&
                                    iArray && iArray.length
                                    ?
                                    <>
                                        {!isMultiple && <option value={''}>--Select--</option>}
                                        {iArray && iArray.map((e, i) => {
                                            return (<>
                                                {selectType == 'valuepair' && e && <option key={i} value={e}>{e}</option>}
                                                {selectType == 'namepair' && e.name && <option key={i} value={e.name}>{e.name}</option>}
                                                {selectType == 'idpair' && e.name && <option key={i} value={e.id}>{e.name}</option>}
                                                {selectType == 'usernamepair' && e.username && <option key={i} value={e.id}>{e.username}</option>}
                                                {selectType == 'firstnamepair' && e.first_name && <option key={i} value={e.id}>{e.first_name}</option>}
                                                {selectType == 'themepair' && e.theme_data?.name && <option key={i} value={e.theme}>{e.theme_data.name}</option>}
                                            </>)


                                        })}
                                    </>
                                    :
                                    <option style={{ color: 'red' }}>Loading...</option>

                            }
                        </Input>
                }
                {infoText && <FormText>{infoText}</FormText>}
                <FormFeedback valid={!(isInvalid || iInvalid)}>{iError ? iError : error}</FormFeedback>
            </FormGroup>
        )
    }
}
