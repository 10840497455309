import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { AppButton, AppLoader, AppPagination } from 'components/Reusable';
import { Card, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Service } from '../Appservice';
import BootstrapTable from 'react-bootstrap-table-next';
import SignalICSRReviewAddCommentDownload from './SignalICSRReviewAddCommentDownload';
import { signalApi } from '../ApiConstant';
export default class SignalICSRReview extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            navLinks: [
                { id: 1, name: "Patient Master", report_type: "PM" },
                { id: 2, name: "Seriousness Criteria", report_type: "SC" },
                { id: 3, name: "Concomitant Drug", report_type: "CD" },
                { id: 4, name: "Suspect Drug", report_type: "SD" },
            ],
            activeIndex: 1,
            isDownloadHistory: false,
            downloadFile: '',
            loading: false,
            page: 1,
            icsrList: [],
            tabIds: [1, 2, 3, 4],
            downloadRowId: null,
            rowData: '',
            isAdd: false,
            isComment: false,
            isDownload: false,
            showIcsrView: false
        }
    }
    componentDidMount = () => {
        this.getIcsrReport();
    }
    downloadHistory = async (downloadRowId = null) => {
        let report_type = this.state.navLinks.find(x => x.id === this.state.activeIndex).report_type;
        let queryParam = "?"
        if (this.props.selectedData.id) queryParam += "&signal_id=" + this.props.selectedData.id;
        if (report_type) queryParam += "&report_type=" + report_type;
        if (downloadRowId) queryParam += "&snowflake_id=" + downloadRowId;
        this.setState({ isDownloadHistory: true, downloadHistoryreport: false })
        const downloadHistoryreport = await Service.appservice('get', signalApi.signalIcsrHistoryExcel + queryParam);
        if (downloadHistoryreport && downloadHistoryreport?.file_url) return this.setState({ isDownloadHistory: false, downloadFile: downloadHistoryreport.file_url, downloadRowId })
        this.setState({ isDownloadHistory: false })
    }
    getIcsrReport = async () => {
        let report_type = this.state.navLinks.find(x => x.id === this.state.activeIndex).report_type;
        let queryParam = "?pageSize=" + 10;
        if (this.state.page) queryParam += "page=" + this.state.page;
        if (this.props.selectedData.id) queryParam += "&signal_id=" + this.props.selectedData.id
        if (report_type) queryParam += "&report_type=" + report_type
        this.setState({ loading: true });
        const getIcsrReport = await Service.appservice('get', signalApi.signalIcsr + queryParam);

        if (getIcsrReport?.metadata) this.setState({
            pageNumbers: getIcsrReport.metadata ? Array.from({ length: getIcsrReport.metadata.total_pages }, (v, k) => k + 1) : [],
        })
        if (getIcsrReport?.header) {
            await this.setHeader(getIcsrReport)
        }
        this.setState({ loading: false });
    }

    setHeader = async (getIcsrReport) => {
        const history = {
            "dataField": "HISTORY", "text": "HISTORY", isDummyField: true, csvExport: false, editable: false,
            headerStyle: { backgroundColor: '#ef476f', color: 'white', padding: '15px' },
            formatter: (cellContent, row) => {
                return (
                    <div className="d-flex justify-content-between">
                        <AppButton className="btn-danger" icon="fas fa-comment-alt" iconbutton tooltip="View Comment Details" onClick={() => this.setState({ rowData: row, showIcsrView: true, isComment: true })} />
                        <AppButton className="btn-danger" icon="fas fa-download" iconbutton tooltip="Download Comment" onClick={() => this.setState({ rowData: row, showIcsrView: true, isDownload: true })} />
                    </div>
                );
            },
        }
        const comments = {
            "dataField": "COMMENTS", "text": "COMMENTS", isDummyField: true, csvExport: false, editable: false,
            formatter: (cellContent, row) => {
                return (
                    <div className="d-flex justify-content-center">
                        <AppButton className="btn-danger" icon="fas fa-plus" iconbutton tooltip="Add Comment" onClick={() => this.setState({ rowData: row, showIcsrView: true, isAdd: true })} />
                    </div>
                );
            },
        }
        getIcsrReport.header = [history, comments, ...getIcsrReport.header];
        if (getIcsrReport?.data) this.setState({ icsrList: getIcsrReport });
    }
    paginate = async (page) => {
        this.setState({ page, loading: true });
        await this.getIcsrReport();
        this.setState({ loading: false });
    }
    render() {
        const { selectedData, onClose } = this.props;
        const { navLinks, activeIndex, isDownloadHistory, downloadFile, icsrList, loading, tabIds, page, pageNumbers,
            showIcsrView, downloadRowId, isAdd, isComment, isDownload, rowData } = this.state;
        return (
            <Fragment>
                {showIcsrView ?
                    <SignalICSRReviewAddCommentDownload
                        report_type={this.state.navLinks.find(x => x.id === this.state.activeIndex).report_type}
                        rowData={rowData}
                        isDownload={isDownload}
                        isComment={isComment}
                        isAdd={isAdd}
                        selectedData={selectedData}
                        onClose={() => this.setState({ rowData: '', showIcsrView: false, isDownload: false, isAdd: false, isComment: false })}
                    /> :
                    <Fragment>
                        <div className='d-flex justify-content-sm-between text-center'>
                            <AppButton name={`Signal ICSR Review of ${selectedData.id}`} tooltip="Back" icon='fas fa-arrow-left' onClick={onClose} />
                            <div className="ms-2 ms-sm-0">
                                {downloadFile ?
                                    <a role='btn'
                                        className='btn btn-sm btn-success btn-wrap-text'
                                        href={downloadFile} download={downloadFile}>
                                        <i className="fas fa-download" /> Download
                                    </a> :
                                    <AppButton icon="fas fa-download" showloader disabled={isDownloadHistory || loading} name="Download All History" onClick={()=>{this.downloadHistory()}} />
                                }
                            </div>
                        </div>
                        <Row >
                            <Col md={12}>
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    {navLinks.map(e => {
                                        return (
                                            <NavItem key={e.id}>
                                                <NavLink style={{ cursor: "pointer" }} className={activeIndex == e.id ? 'active' : ''}
                                                    onClick={() => {
                                                        this.setState({ activeIndex: e.id, page: 1 })
                                                        this.getIcsrReport()
                                                    }}>
                                                    <span className="d-none d-sm-block">{e.name}</span>
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                                <TabContent activeTab={activeIndex} className="p-3 text-muted" >
                                    {tabIds.map((e, i) => {
                                        return (
                                            <TabPane tabId={e} key={i}>
                                                {loading ?
                                                    <AppLoader isGlow={true} count={2} />
                                                    :
                                                    <Fragment>
                                                        {
                                                            icsrList?.data?.length > 0 ?
                                                                <div className="shadow table-responsive rounded-3">
                                                                    <BootstrapTable
                                                                        bordered={false}
                                                                        keyField="INDEX"
                                                                        data={icsrList && icsrList?.data}
                                                                        columns={icsrList && icsrList?.header}
                                                                    // cellEdit={cellEditFactory({ mode: "click", blurToSave: true, afterSaveCell: this.handleChange })}
                                                                    />
                                                                    <AppPagination
                                                                        previousPage={e => { this.paginate(e) }}
                                                                        nextPage={e => { this.paginate(e) }}
                                                                        selectedPage={e => { this.paginate(e) }}
                                                                        page={page}
                                                                        pageNumbers={pageNumbers} />
                                                                </div>
                                                                :
                                                                <p className='text-danger text-center'>No ICSR data available..</p>
                                                        }
                                                    </Fragment>}
                                            </TabPane>
                                        )
                                    })}
                                </TabContent>
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
