import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../Appservice';
import { Col, Form, Row } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { signalApi } from '../ApiConstant';
export default class ProjectsAdd extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            workFlowList: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { selectedData } = props;
        this.state = {
            postData: {
                id: selectedData?.id ?? '',
                name: selectedData?.name ?? '',
                description: selectedData?.description ?? '',
                workflow_configuration: selectedData?.workflow_configuration ?? '',
            },
            postErr: { name: false, description: false, workflow_configuration: false },
            loading: false
        }
    }
    setValue = (i, e) => {
        const value = i.target.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onProject = async () => {
        const { selectedData, onClose } = this.props;
        const { postData } = this.state;
        if (!postData.name || !postData.name.replace(/[^\p{L}]/gu, '')) return this.setError('name');
        if (!postData.description || !postData.description.replace(/[^\p{L}]/gu, '')) return this.setError('description');
        if (!postData.workflow_configuration) return this.setError('workflow_configuration');
        this.setState({ loading: true })
        const postAction = selectedData?.id ?
            await Service.appservice('patch', `${signalApi.project}/${selectedData.id}`, postData) :
            await Service.appservice('post', signalApi.project, postData);
        if (postAction) {
            toast.success(`Project ${selectedData.id ? 'Updated' : 'Created'} Successfully !...`);
            onClose(true);
        }
        this.setState({ loading: false })
    }
    render() {
        const { postData, postErr, loading } = this.state;
        const { onClose, workFlowList, selectedData } = this.props;
        return (
            <div className='d-flex justify-content-center'>
                <Fragment>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <AppInput label="Workflow Configuration *" type="select" value={postData.workflow_configuration} isInvalid={postErr.workflow_configuration}
                                    error="Workflow Configuration is required" onChange={e => { this.setValue(e, 'workflow_configuration') }} iArray={workFlowList} selectType='idpair' />
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <AppInput label="Name *" value={postData.name} isInvalid={postErr.name} error="Name is required" onChange={e => { this.setValue(e, 'name') }} />
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <AppInput label="Description *" type="text" value={postData.description} isInvalid={postErr.description}
                                    error="Description is required" onChange={e => { this.setValue(e, 'description') }} />
                            </Col>
                            <Col lg={12} className='text-center'>
                                <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.onProject} showloader active={loading} name={`${selectedData ? 'Update ' : 'Add '} Project`} />
                                <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onClose()} tooltip="Close" />
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            </div>
        )
    }
}
