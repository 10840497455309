const chart = (graphData) => {
    const graphs = {
        toolbox: {
            show: true,
        },
        tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
        series: [
            {
                name: "",
                type: "pie",
                radius: "55%",
                center: ["50%", "60%"],
                data: graphData,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };
    return graphs;
}

const lineChart = (graphName, onChange) => {
    const graphs = {
        chart: {
            toolbar: {
                show: false,
            },
            events: {
                click: function (event, chartContext, config) {
                    // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                    onChange(config)
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "45%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        colors: ["#556ee6"],
        xaxis: {
            categories: graphName,
        },
        yaxis: {
            title: {
                text: " (count)",
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
    };
    return graphs;
}

export { chart, lineChart }