import { combineReducers } from "redux"
// Front
import Layout from "./layout/reducer"
// Authentication
import Login from "./auth/login/reducer"
//signals
import Signal from "./signals/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Signal
})

export default rootReducer
