import React, { Component } from 'react';
import { Form, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { emptyObject } from '../../AppConstants';
export default class Filter extends Component {
    static get propTypes() {
        return {
            workFlowTeamList: PropTypes.any,
            workFlowConfList: PropTypes.any,
            projectList: PropTypes.any,
            onFilter: PropTypes.func,
            loading: PropTypes.bool
        };
    }
    postData = { assigned_to: '', workflow_theme: '', project: '' };
    constructor() {
        super();
        this.state = {
            postData: this.postData,
        }
    }
    setValue = async (i, e, isArray) => {
        const value = isArray ? Array.from(i.target.selectedOptions, option => option.value) : i.target.value === "--Select--" ? "" : i.target.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    render() {
        const { workFlowTeamList, onFilter, workFlowConfList, projectList, loading } = this.props;
        const { postData } = this.state;
        return (
            <div className="d-flex justify-content-center card-body">
                <Form >
                    <Row>
                        <Col lg={4}>
                            <AppInput label="Workflow Team" type="select" value={postData.assigned_to} onChange={e => { this.setValue(e, 'assigned_to') }} iArray={workFlowTeamList} selectType='idpair' />
                        </Col>
                        <Col lg={4}>
                            <AppInput label="Workflow Configuration" type="select" value={postData.workflow_theme} onChange={e => { this.setValue(e, 'workflow_theme') }} iArray={workFlowConfList} selectType='idpair' />
                        </Col>
                        <Col lg={4}>
                            <AppInput label="Project List" type="select" value={postData.project} onChange={e => { this.setValue(e, 'project') }} iArray={projectList} selectType='idpair' />
                        </Col>
                        <Col lg={12} className='text-center'>
                            <AppButton disabled={loading || emptyObject(postData)} icon='fas fa-filter' className="ms-2" name="Filter Topics" tooltip="Filter" onClick={() => { onFilter(postData) }} />
                            <span data-bs-toggle="collapse" href="#filterCollapse">
                                <AppButton disabled={loading || emptyObject(postData)} iconbutton className="ms-3 btn-danger" icon='fas fa-times' tooltip="Clear & Close Filter" onClick={async () => { await this.setState({ postData: { ...this.state.postData, ...this.postData } }); onFilter(this.postData) }} />
                            </span>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}
