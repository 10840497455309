import {
  SIGNAL_MAIN_FILTER,
  SIGNAL_PAGE
} from "./actionTypes"

const INIT_STATE = {
  signalHeaderFilter: null,
  page: 1
}

const SignalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNAL_MAIN_FILTER:
      return {
        ...state,
        signalHeaderFilter: action.payload,
      }
    case SIGNAL_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
  }
}

export default SignalReducer
