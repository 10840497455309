import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { AppButton, AppShowmore, AppTooltip } from 'components/Reusable';
import { Row, Col, CardBody, Table } from 'reactstrap';
import { Service } from '../Appservice';
import { signalApi } from '../ApiConstant';
export default class SignalAllActionsView extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            actionList: [],
            actionDetails: [],
            loading: false,
        }
    }
    componentDidMount = () => {
        this.getActions();
    }
    getActions = async () => {
        const { page } = this.state;
        const { selectedData } = this.props;
        let queryParam = `&page=${page}&page_size=10`;
        this.setState({ loading: true })
        var action = await Service.appservice('get', `${signalApi.topicActions}?theme_signal__signal=${selectedData.id}` + queryParam);
        if (action?.data) {
            if (action.data.length) { action.data.forEach(async e => { await this.getActionData(e.id); }); }
            this.setState({
                actionList: action.data,
                pageNumbers: action.metadata ? Array.from({ length: action.metadata.total_pages }, (v, k) => k + 1) : [],
            });
        }
        this.setState({ loading: false })
    }
    getActionData = async (id) => {
        let commentData, attachData = [];
        commentData = await Service.appservice('get', `${signalApi.topicActions}/${id}/comments`) || [];
        attachData = await Service.appservice('get', `${signalApi.actionAttachmentsGet}/${id}`) || [];
        this.setState({ actionDetails: [...this.state.actionDetails, { id, commentData, attachData }] });
    }
    render() {
        const { selectedData, onClose } = this.props;
        const { actionDetails, actionList, loading } = this.state;
        const validationAction = actionList?.filter(e => e.workflow_config.stage == "val");
        const evaluationAction = actionList?.filter(e => e.workflow_config.stage == "ev");
        return (
            <Fragment>
                <AppButton tooltip="back" icon='fas fa-arrow-left' name={`Action view of ${selectedData.id}`} onClick={async () => { onClose() }} />

                <Row className="no-gutters align-items-center">
                    <Col md={6}>
                        <CardBody>
                            <span className='shadow p-2 text-center rounded '>Validation Action List</span>
                            <Fragment>
                                {(validationAction && validationAction?.length) ? validationAction.map((e, i) => {
                                    let validationActionDetails = actionDetails.filter(e1 => e1.id == e.id);
                                    return (
                                        <Fragment key={i}>
                                            <div className='comment-div border-danger mt-3' key={i}>
                                                <h4>{e.measure_data.name}</h4>
                                                <p>{e.measure_data.description}</p>
                                                <span className='mt-3'>
                                                    <span className='badge p-2 badge-soft-info'>{e.status ? e.status : 'New'}</span>
                                                    <div className="vr ms-3 me-3"></div>
                                                    <span>Action order - <span className='badge badge-soft-dark p-2'>{e.measure_order}</span></span>
                                                </span>
                                                <h5 className='mt-2'>{e.assigned_to_data.first_name}</h5>
                                                <div className='table-responsive shadow rounded-3 mt-3'>
                                                    <Table className="table-hover table-borderless mb-0">
                                                        <thead className='shadow'>
                                                            <tr>
                                                                <th >Comments</th>
                                                                <th >Attachments</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {validationActionDetails && validationActionDetails.map((e, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td >{e.commentData.data.map((e1, k1) => {
                                                                            return (<><AppShowmore text={e1.comment} key={k1} /><br /></>)
                                                                        })}</td>
                                                                        <td >
                                                                            <Row>
                                                                                {
                                                                                    e.attachData.data.map((e1, k1) => {
                                                                                        return (
                                                                                            <Fragment key={k1}>
                                                                                                <Col lg={3} className='d-flex flex-row'>
                                                                                                    <AppShowmore text={e1.title} />
                                                                                                    {
                                                                                                        e1.url_attachment ?
                                                                                                            // <AppTooltip tooltip="Download Validation Attachment">
                                                                                                                <a className='badge badge-soft-danger p-2 ms-2' rel="noreferrer" target='_blank' href={e1.url_attachment} download>
                                                                                                                    <i className='fas fa-paperclip' />
                                                                                                                </a>
                                                                                                            // </AppTooltip>
                                                                                                            :
                                                                                                            <Fragment></Fragment>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Fragment>
                                                                                        )
                                                                                    })}

                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div >
                                            <hr />
                                        </Fragment>
                                    )
                                }) :
                                    <p className='comment-div border-danger'>
                                        No Validation Action Found...
                                    </p>
                                }
                            </Fragment>
                        </CardBody>
                    </Col>
                    <Col md={6}>
                        <CardBody>
                            <span className='shadow p-2 text-center rounded'>Evaluation Action List</span>
                            <Fragment>
                                {(evaluationAction && evaluationAction?.length) ? evaluationAction.map((e, i) => {
                                    let evaluationActionDetails = actionDetails.filter(e1 => e1.id == e.id);
                                    return (
                                        <Fragment key={i}>
                                            <div className='comment-div mt-3 border-danger' key={i}>
                                                <h4>{e.measure_data.name}</h4>
                                                <p>{e.measure_data.description}</p>
                                                <span className='mt-3'>
                                                    <span className='badge p-2 badge-soft-info'>{e.status ? e.status : 'New'}</span>
                                                    <div className="vr ms-3 me-3"></div>
                                                    <span>Action order - <span className='badge badge-soft-dark p-2'>{e.measure_order}</span></span>
                                                </span>
                                                <h5 className='mt-2'>{e.assigned_to_data.first_name}</h5>
                                                <hr />
                                                <div className='table-responsive shadow rounded-3 mt-3'>
                                                    <Table className="table-hover table-borderless mb-0">
                                                        <thead className='shadow'>
                                                            <tr>
                                                                <th >Comments</th>
                                                                <th >Attachments</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {evaluationActionDetails && evaluationActionDetails.map((e, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td >{e.commentData.data.map((e1, k1) => {
                                                                            return (<><AppShowmore text={e1.comment} key={k1} /><br /></>)
                                                                        })}</td>
                                                                        <td >
                                                                            <Row>
                                                                                {
                                                                                    e.attachData.data.map((e1, k1) => {
                                                                                        return (
                                                                                            <Fragment key={k1}>
                                                                                                <Col lg={3 } className='d-flex flex-row'>
                                                                                                    <AppShowmore text={e1.title} />
                                                                                                    {
                                                                                                        e1.url_attachment ?
                                                                                                            <AppTooltip tooltip="Download Evaluation Attachment">
                                                                                                                <a className='badge badge-soft-danger p-2 ms-2' rel="noreferrer" target='_blank' href={e1.url_attachment} download>
                                                                                                                    <i className='fas fa-paperclip' />
                                                                                                                </a>
                                                                                                            </AppTooltip>
                                                                                                            :
                                                                                                            <Fragment></Fragment>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Fragment>
                                                                                        )
                                                                                    })}

                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div >
                                            <hr />
                                        </Fragment>
                                    )
                                }) :
                                    <p className='border border-danger mt-3 p-2 rounded'>
                                        No Evaluation Action Found...
                                    </p>
                                }
                            </Fragment>
                        </CardBody>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
