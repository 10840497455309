import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../Appservice';
import { Col, Form, Row } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { signalApi } from '../ApiConstant';
import { exportExcel } from '../../../../helpers/exportExcel';
export default class AddSignal extends Component {
    static get propTypes() {
        return {
            isEdit: PropTypes.bool,
            updateData: PropTypes.any,
            onClose: PropTypes.func,
            drugList: PropTypes.any,
            modelList: PropTypes.any,
            durationList: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            isBulk: false,
            isManual: false,
            postData: {
                signal_parent_model: '',
                duration: '',
                drug: '',
                ae: '',
                observed: '',
                expected: '',
                pval: '',
                src_identifier: '',
                hlt_name: '',
                hlgt_name: '',
                soc_name: '',
                hlt_observed: '',
                hlt_expected: '',
                hlgt_observed: '',
                soc_observed: '',
                soc_expected: '',
                selectedFile: ''
            },
            postErr: {
                signal_parent_model: false,
                duration: false,
                drug: false,
                ae: false,
                observed: false,
                expected: false,
                pval: false,
                src_identifier: false,
                hlt_name: false,
                hlgt_name: false,
                soc_name: false,
                hlt_observed: false,
                hlt_expected: false,
                hlgt_observed: false,
                soc_observed: false,
                soc_expected: false,
                selectedFile: false
            },
            isSubmit: false
        }
    }
    downloadSampleFile = () => {
        let headers = [{"AE": "", "signal_data": ""}];

        exportExcel(headers, "Bulk upload sample file", "Signals")
    }
    setValue = async (i, e, isFile) => {
        const value = isFile ? i.target.files[0] : i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onBulkupload = async () => {
        const { postData } = this.state
        if (!postData.selectedFile) return this.setError('selectedFile');
        const formData = new FormData();
        formData.append("file", postData.selectedFile);
        const postAction = await Service.appservice('post', `${signalApi.signals}/bulk_upload`, formData, true)
        if (postAction) {
            toast.success(`Bulk signal data uploaded Successfully !...`);
        }
        this.setState({ isSubmit: false });
    }
    onManual = async (isAutomate) => {
        const { postData } = this.state
        if (!postData.signal_parent_model) return this.setError('signal_parent_model');
        if (!postData.duration) return this.setError('duration');
        if (!postData.drug) return this.setError('drug');
        if (!postData.ae) return this.setError('ae');
        const manualData = {
            signal_parent_model: postData.signal_parent_model,
            duration: postData.duration,
            signal_data: {
                drug: postData.drug,
                ae: postData.ae,
                observed: postData.observed,
                expected: postData.expected,
                pval: postData.pval,
                src_identifier: postData.src_identifier,
                hlt_name: postData.hlt_name,
                hlgt_name: postData.hlgt_name,
                soc_name: postData.soc_name,
                hlt_observed: postData.hlt_observed,
                hlt_expected: postData.hlt_expected,
                hlgt_observed: postData.hlgt_observed,
                soc_observed: postData.soc_observed,
                soc_expected: postData.soc_expected
            },
        }
        this.setState({ isSubmit: true });
        const postAction = await Service.appservice('post', signalApi.signals, manualData);
        if (postAction) {
            toast.success(`Signal created Successfully !...`);
            if (isAutomate) {
                const automate = await Service.appservice('post', `${signalApi.signalAutomate}?signal_ids=` + postAction.id);
                if (automate)
                    toast.success('Workflow created successfully...');
            }
            this.props.onClose()
        }
        this.setState({ isSubmit: false });
    }
    render() {
        const { isBulk, isManual, postData, postErr, isSubmit } = this.state;
        const { drugList, modelList, durationList, } = this.props
        return (
            <div className='row ps-3'>
                {isBulk ?
                    <Fragment>
                        <Form className='d-flex justify-content-center align-items-center'>
                            <a href='#' className='btn btn-danger btn-sm me-2 me-sm-5' onClick={this.downloadSampleFile}>
                                <i className='fas fa-download me-sm-2' />
                                <span className='d-none d-sm-inline'>Sample file</span>
                            </a>
                            <AppInput type='file' label="Upload *" error="File should not be empty" isInvalid={postErr.selectedFile} onChange={e => { this.setValue(e, 'selectedFile', true) }} infoText="You can upload .xls, .xlsx, .csv type files" />
                            <AppButton className="me-2" tooltip="Upload" icon='fas fa-upload' onClick={this.onBulkupload} disabled={isSubmit} />
                            <AppButton className="me-2" tooltip="Close" icon='fas fa-times' onClick={() => this.setState({ isBulk: false, isManual: false })} disabled={isSubmit} />
                        </Form>
                    </Fragment> :
                    isManual ?
                        <Fragment>
                            <Form>
                                <Row>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="Drug *" type="select" value={postData.drug} isInvalid={postErr.drug}
                                            error="Drug  is required" onChange={e => { this.setValue(e, 'drug') }} iArray={drugList} selectType='namepair' />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="Model *" type="select" value={postData.signal_parent_model} isInvalid={postErr.signal_parent_model}
                                            error="Model is required" onChange={e => { this.setValue(e, 'signal_parent_model') }} iArray={modelList} selectType='idpair' />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="Duration *" type="select" value={postData.duration} isInvalid={postErr.duration}
                                            error="Signal Model Duration is required" onChange={e => { this.setValue(e, 'duration') }} iArray={durationList} selectType='idpair' />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="AE *" error="AE is required" value={postData.ae} isInvalid={postErr.ae} onChange={e => { this.setValue(e, 'ae') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Observed" error="Observed is required" value={postData.observed} isInvalid={postErr.observed} onChange={e => { this.setValue(e, 'observed') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Expected" error="Expected is required" value={postData.expected} isInvalid={postErr.expected} onChange={e => { this.setValue(e, 'expected') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Pval" error="Pval is required" value={postData.pval} isInvalid={postErr.pval} onChange={e => { this.setValue(e, 'pval') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="Hlgt Name" error="Hlgt Name is required" value={postData.hlgt_name} isInvalid={postErr.hlgt_name} onChange={e => { this.setValue(e, 'hlgt_name') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Hlt Observed" error="Hlt Observed is required" value={postData.hlt_observed} isInvalid={postErr.hlt_observed} onChange={e => { this.setValue(e, 'hlt_observed') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Hlt Expected" error="Hlt Expected is required" value={postData.hlt_expected} isInvalid={postErr.hlt_expected} onChange={e => { this.setValue(e, 'hlt_expected') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Hlgt Observed" error="Hlgt Observed is required" value={postData.hlgt_observed} isInvalid={postErr.hlgt_observed} onChange={e => { this.setValue(e, 'hlgt_observed') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="Soc Name" error="Soc Name is required" value={postData.soc_name} isInvalid={postErr.soc_name} onChange={e => { this.setValue(e, 'soc_name') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Soc Observed" error="Soc Observed is required" value={postData.soc_observed} isInvalid={postErr.soc_observed} onChange={e => { this.setValue(e, 'soc_observed') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput type='number' label="Soc Expected" error="Soc Expected is required" value={postData.soc_expected} isInvalid={postErr.soc_expected} onChange={e => { this.setValue(e, 'soc_expected') }} />
                                    </Col>
                                    <Col lg={2} md={3} sm={4} xs={12}>
                                        <AppInput label="Hlt Name" error="Hlt Name is required" value={postData.hlt_name} isInvalid={postErr.hlt_name} onChange={e => { this.setValue(e, 'hlt_name') }} />
                                    </Col>
                                    <Col lg={12} className='text-center mb-3'>
                                        <AppButton className="me-2" icon='fas fa-save' onClick={() => this.onManual(false)} iName={'Submit'} disabled={isSubmit} name='Save' />
                                        <AppButton className="me-2" icon='fas fa-paper-plane' onClick={this.onManual} iName={'Submit'} disabled={isSubmit} name='Save & Automate' />
                                        <AppButton tooltip="Close" icon='fas fa-times' onClick={() => this.setState({ isBulk: false, isManual: false })} disabled={isSubmit} />
                                    </Col>
                                </Row>
                            </Form>
                        </Fragment> :
                        <div className='d-flex justify-content-center justify-content-sm-end mb-2' >
                            <AppButton className="me-2" name="Add Manual" icon='fas fa-plus' onClick={() => { this.setState({ isBulk: false, isManual: true }) }} />
                            <AppButton className="me-2" name="Add Bulk" icon='fas fa-upload' onClick={() => { this.setState({ isBulk: true, isManual: false }) }} />
                        </div>
                }
            </div>
        )
    }
}
