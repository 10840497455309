import React from "react"
import { Redirect } from "react-router-dom"
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import MenuList from "../pages/SM_Document/MenuList/MenuList"
import MenuDetails from "../pages/SM_Document/MenuDetails/MenuDetails"
import { Dashboard, Signals, RegIntel, WorkFlowConfiguration, Projects, Products, Users, Calendar, Auditlog, Imdrf ,Epidomilogy} from "pages/SignalManagement/Scenes"
import Powerbi from "pages/SignalManagement/Scenes/Powerbi"
const authProtectedRoutes = [
  //new routes
  { path: "/user-dashboard", component: Dashboard },
  { path: "/signals", component: Signals },
  { path: "/regulatory-intel", component: RegIntel },
  { path: "/workflow-configurations", component: WorkFlowConfiguration },
  { path: "/projects", component: Projects },
  { path: "/products", component: Products },
  { path: "/users", component: Users },
  { path: "/users/chat", component: Users },
  { path: "/calendar", component: Calendar },
  { path: "/auditlog", component: Auditlog },
  { path: "/imdrf", component: Imdrf },
  //power bi routes
  { path: "/pages", component: Powerbi },
  { path: "/page-:id", component: Powerbi },
  //user manual
  { path: "/documentation/module", component: MenuList },
  { path: "/documentation/menu-details/:name/:id", component: MenuDetails },
  //empty root
  { path: "/dashboard", component: Dashboard }, // root page
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: '/epidemiology', component: Epidomilogy },
];
export { authProtectedRoutes, publicRoutes }
