import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CardBody, Table } from 'reactstrap';
import { appDate } from '../../AppConstants';
export default class WorkflowActionComments extends Component {
    static get propTypes() {
        return {
            workFlowActionDetails: PropTypes.any,
            actionId: PropTypes.number
        };
    }
    getTopicComments(flow, id) {
        const isComment = flow.find(x => x.id == id);
        if (isComment) {
            let getComment = isComment.commentData;
            return getComment && getComment.length ? getComment : []
        }
        return []
    }
    render() {
        const { workFlowActionDetails, actionId } = this.props;
        return (
            <div className=' mt-2'>
                {
                    this.getTopicComments(workFlowActionDetails, actionId) && this.getTopicComments(workFlowActionDetails, actionId).length ?
                        <div className='table-responsive border rounded'>
                            <Table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Comment</th>
                                        <th>Commented By</th>
                                        <th>Commented Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.getTopicComments(workFlowActionDetails, actionId).map((e, i) => {
                                            console.log('e', e);
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr >
                                                        <td>{e?.comment}</td>
                                                        <td>{e?.commented_by_data?.first_name}</td>
                                                        <td className='text-right'>
                                                            {appDate(e?.created_at, '')}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        :
                        <CardBody className='text-center'>
                            <span className='text-danger shadow p-2 rounded'>No Action Attachments Found..</span>
                        </CardBody>
                }
            </div>
        )
    }
}
