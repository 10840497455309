import React, { Component, Fragment } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppDropDown, AppLoader, AppShowmore } from 'components/Reusable';
import { Service } from '../../Appservice';
import toast from 'react-hot-toast';
import { signalApi } from '../../ApiConstant';
import { appDate } from '../../AppConstants';
import DeleteAlert from '../../components/DeleteAlert';
export default class ActionTable extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            isLoading: PropTypes.bool,
            filterStaticHeaders: PropTypes.func,
            showMenu: PropTypes.any,
            actionList: PropTypes.any,
            onDetails: PropTypes.func,
            onState: PropTypes.func,
            onMenu: PropTypes.func,
            onDelete: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            isMenu: false,
            updateData: '',
            isDelete: false,
            loading: false
        }
    }
    deleteAction = async () => {
        const { updateData } = this.state;
        this.setState({ loading: true })
        const deleteAction = await Service.appservice('delete', `${signalApi.topicActions}/${updateData.id}`);
        if (deleteAction) toast.success('Action deleted successfully!...')
        this.setState({ isDelete: false, updateData: '', loading: false });
        this.props.onDelete();
    }
    render() {
        const { tabHeader, isLoading, filterStaticHeaders, showMenu, actionList, onDetails, onState, onMenu, } = this.props;
        const { isMenu, updateData, isDelete, loading } = this.state;
        return (
            <div className='shadow table-responsive rounded-3 mt-3'>
                <Table className="table-hover table-borderless mb-0">
                    <thead className='shadow'>
                        <tr>
                            <th className='text-center'>
                                <i className='fas fa-bars text-info'></i>
                            </th>
                            {
                                tabHeader.map((e, i) => {
                                    return (
                                        e.isChecked && <th key={i}>{e.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ?
                            <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                            :
                            actionList.map((e, i) => {
                                return (
                                    isDelete && updateData.id == e.id ?
                                        <td colSpan={15} key={i} className='shadow border border-danger'>
                                            <DeleteAlert loading={loading} title="Action" onYes={this.deleteAction}
                                                onNo={() => { this.setState({ isDelete: false, updateData: '' }) }} />
                                        </td>
                                        :
                                        <tr key={i} >
                                            <td className='text-center'>
                                                <AppDropDown
                                                    menuArray={showMenu}
                                                    isMenu={isMenu}
                                                    onToggle={() => { if (e.id == updateData.id) this.setState({ isMenu: !isMenu, updateData: e }); }}
                                                    onClick={() => { this.setState({ isMenu: true, updateData: e }); }}
                                                    rowId={e.id}
                                                    selectedId={updateData.id}
                                                    onClose={(id) => {
                                                        this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                                                        onMenu(updateData, id);
                                                    }}
                                                />
                                            </td>
                                            <td className={filterStaticHeaders(0) ? '' : 'd-none'}><AppShowmore text={e?.measure_data?.name} /></td>
                                            <td className={filterStaticHeaders(1) ? '' : 'd-none'}><AppShowmore text={e?.theme_data?.name} /></td>
                                            <td className={filterStaticHeaders(2) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.signal_data?.DRUG} /></td>
                                            <td className={filterStaticHeaders(3) ? '' : 'd-none'}><AppShowmore text={e?.signal_data?.signal_data?.AE} /></td>
                                            <td className={filterStaticHeaders(4) ? 'text-end' : 'd-none'}><span className='badge badge-soft-danger p-2'>{e?.measure_attachment_count}</span></td>
                                            <td className={filterStaticHeaders(5) ? 'text-end' : 'd-none'}><span className='badge badge-soft-danger p-2'>{e?.measure_comment_count}</span></td>
                                            <td className={'text-center ' + `${filterStaticHeaders(8) ? '' : 'd-none'}`}>
                                                {!e.status ?
                                                    <span className='badge badge-soft-info p-2' role="button" >
                                                        <i className='fas fa-plus text-info' onClick={async () => { onState(e) }} />
                                                    </span>
                                                    :
                                                    e?.status?.toLowerCase() != 'done' ?
                                                        <Fragment>
                                                            {e.status}
                                                            <span className='badge badge-soft-primary p-2 ms-2' role="button" >
                                                                <i className='fas fa-pen text-primary' onClick={async () => { onState(e) }} />
                                                            </span>
                                                        </Fragment>
                                                        :
                                                        <span className='badge badge-soft-success p-2 ms-2' > {e.status}</span>
                                                }
                                            </td>
                                            <td className={filterStaticHeaders(6) ? '' : 'd-none'}>{e?.assigned_to_data?.first_name + ' ' + e?.assigned_to_data?.last_name}</td>
                                            <td className={filterStaticHeaders(9) ? 'text-end' : 'd-none'}>{appDate(e?.planned_completion_date)}</td>
                                            <td className={filterStaticHeaders(7) ? 'text-center' : 'd-none'}>
                                                <span role="button" onClick={() => { onDetails(e) }} className='badge badge-soft-warning p-2'><i className='fa fa-info text-warning' /></span>
                                            </td>
                                        </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
