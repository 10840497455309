
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppNodata, AppShowmore } from 'components/Reusable';
import Logo from "assets/illusion/add.png";
import { Row, Col, Card, NavLink, TabContent, TabPane, CardImg, Nav, NavItem } from 'reactstrap';
import { appDate } from '../AppConstants';
export default class WorkflowConfigurationView extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            workflowTab: [
                { id: 1, name: 'Action State', icon: 'fas fa-check' },
                { id: 2, name: 'Topics', icon: 'fas fa-file-signature' },
                { id: 3, name: 'Actions', icon: 'fas fa-thumbs-up' },
            ],
            activeIndex: 1
        }
    }

    render() {
        const { onClose, selectedData } = this.props;
        const { workflowTab, activeIndex } = this.state;
        return (
            <div>
                <AppButton tooltip="Back to workflow list" name={`View details of ${selectedData?.name}`} icon='fas fa-arrow-left' onClick={async () => { onClose() }} />
                <Row className="no-gutters align-items-center mt-3 mt-sm-0">
                    <Col md={3} className='d-none d-sm-block'>
                        <CardImg className="img-fluid" src={Logo} alt="Skote" />
                    </Col>
                    <Col md={3}>
                        <ul className='list-group '>
                            <li className='list-group-item text-center shadow rounded'>
                                Workflow Details
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Description" text={selectedData?.description} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Name" text={selectedData?.name} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Created By" text={selectedData?.owner_data?.first_name + ' ' + selectedData?.owner_data?.last_name} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Created Date" text={appDate(selectedData?.created_at)} />
                            </li>
                        </ul>
                    </Col>
                    <Col md={6} className='mt-3 mt-sm-0'>
                        <Nav className="nav-tabs-custom nav-justified nav nav-tabs shadow">
                            {workflowTab.map((e, i) => {
                                return (
                                    <NavItem key={i}>
                                        <NavLink style={{ cursor: "pointer" }} className={activeIndex == e.id ? 'active' : ''}
                                            onClick={() => this.setState({ activeIndex: e.id })} >
                                            <span className="d-none d-sm-block"><i className={e.icon + ' me-2'}></i> {e.name}</span>
                                            <span className="d-block d-sm-none"><i className={e.icon}></i></span>
                                        </NavLink>
                                    </NavItem>
                                )
                            })}
                        </Nav>
                        <TabContent activeTab={activeIndex} className="p-3 text-muted shadow">
                            <TabPane tabId={1}>
                                <Row>
                                    {selectedData?.measure_type?.length ? selectedData?.measure_type.map((e, i) => {
                                        return (
                                            <Col lg={6} key={i}>
                                                <span className='mb-2 text-center'>
                                                    <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                </span>
                                                <div className='card shadow-sm p-2' >
                                                    <span className='mt-3'>
                                                        <h5>{e?.name}</h5>
                                                        <AppShowmore text={e?.description} id={'toolsattachdesc' + i} />
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                        <div className='text-center'>
                                            <span className='shadow rounded p-2 text-danger'>No  action state found..</span>
                                        </div>
                                    }
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                <Row>
                                    {selectedData?.theme_data?.length ? selectedData?.theme_data.map((e, i) => {
                                        return (
                                            <Col lg={4} key={i}>
                                                <span className='mb-2 text-center'>
                                                    <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                </span>
                                                <div className='card shadow-sm p-2' >
                                                    <span className='mt-3'>
                                                        <h5>{e?.name}</h5>
                                                        <AppShowmore text={e?.description} id={'description' + i} />
                                                        <small className='blockquote-footer'>Stage is {e?.stage}</small>
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                        <div className='text-center'>
                                            <span className='shadow rounded p-2 text-danger'>No  topics found..</span>
                                        </div>
                                    }
                                </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                                <Row>
                                    {selectedData?.measure_data?.length ? selectedData?.measure_data.map((e, i) => {
                                        return (
                                            <Col lg={4} key={i}>
                                                <span className='mb-2 text-center'>
                                                    <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                </span>
                                                <div className='card shadow-sm p-2' >
                                                    <span className='mt-3'>
                                                        <h5>{e?.name}</h5>
                                                        <AppShowmore text={e?.description} id={'description' + i} />
                                                        <small className='blockquote-footer'>Stage is {e?.stage}</small>
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                        <AppNodata />
                                    }
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        )
    }
}
