import React, { Component, Fragment } from 'react';
import { AppNodata, AppPagination } from 'components/Reusable';
import { Service } from '../../Appservice';
import { Card } from "reactstrap";
import TabHeader from '../../components/tabHeader';
import Filter from './Filter';
import InlineSearch from './InlineSearch';
import PropTypes from 'prop-types';
import { signalApi } from '../../ApiConstant';
import { getKeyItem, parsedKeyItem, superuser, user } from '../../AppPermission';
import AddEditTopics from './AddEditTopics';
import TopicComment from './TopicComment';
import TopicAttachment from './TopicAttachment';
import TopicsTable from './TopicsTable';
export default class Topics extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            header: ["NAME", "TEAM", "PRODUCT", "SIGNAL", "CONFIGURATION", "PROJECT", "ATTACHMENT", "COMMENTS", "ACTION", "STATUS"],
            menuArray: [
                { name: 'Edit', icon: 'fas fa-pen', id: 1 },
                { name: 'Delete', icon: 'fas fa-trash', id: 2 },
                { name: 'Comments', icon: 'fas fa-comment', id: 3 },
                { name: 'Attachments', icon: 'fas fa-paperclip', id: 4 },
            ],
            page: 1,
            tabHeader: [],
            topicList: [],
            masterTopicList: [],
            workFlowTeamList: [], workFlowConfList: [], projectList: [],
            loading: false,
            postData: { assigned_to: getKeyItem(superuser) == "true" ? '' : parsedKeyItem(user).uid, workflow_theme: '', project: '' },
            csvFile: '',
            isTopic: false,
            isComment: false,
            isAttach: false,
            isFilter: false,
            selectedData: ''
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        await this.getAll();
        await this.getCsv();
        await this.getTopics();
    }
    getAll = async () => {
        const workFlowTeam = await Service.appservice('get', signalApi.workflowTeam);
        const workFlowConfig = await Service.appservice('get', signalApi.workflowConfiguration);
        const Project = await Service.appservice('get', signalApi.project);
        this.setState({ workFlowTeamList: workFlowTeam?.data, workFlowConfList: workFlowConfig?.data, projectList: Project?.data });
    }
    getCsv = async () => {
        const { postData } = this.state;
        let queryParam = "";
        if (postData.assigned_to) queryParam = "?assigned_to__id=" + postData.assigned_to;
        const csv = await Service.appservice('get', signalApi.topicsSignalXl + queryParam);
        if (csv?.file_url) this.setState({ csvFile: csv.file_url })
    }
    paginate = async (page) => {
        this.setState({ page, loading: true });
        this.getTopics();
    }
    getTopics = async () => {
        const { page, search, postData } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        if (postData.assigned_to) queryParam += "&assigned_to__id=" + postData.assigned_to;
        if (postData.workflow_theme) queryParam += "&theme__workflow_configuration=" + postData.workflow_theme;
        if (postData.project) queryParam += "&project=" + postData.project;
        const topic = await Service.appservice('get', signalApi.topicSignals + queryParam);
        if (topic?.data) {
            this.setState({
                topicList: topic.data, masterTopicList: topic.data, loading: false,
                pageNumbers: topic.metadata ? Array.from({ length: topic.metadata.total_pages }, (v, k) => k + 1) : [],
            });
        }
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    render() {
        const { page, pageNumbers, topicList, masterTopicList, loading, csvFile, workFlowTeamList, workFlowConfList, projectList,
            tabHeader, isFilter, isTopic, isComment, isAttach, menuArray, selectedData } = this.state;
        return (
            <React.Fragment>
                <Fragment>
                    {
                        isTopic ?
                            <AddEditTopics selectedData={selectedData} onClose={(e) => { if (e) { this.getTopics(); }; this.setState({ isTopic: false }); }}
                                workFlowTeamList={workFlowTeamList} workFlowConfList={workFlowConfList} projectList={projectList} /> :
                            isComment ?
                                <TopicComment selectedData={selectedData} onClose={(e) => { if (e) { this.getTopics(); }; this.setState({ isComment: false }); }} /> :
                                isAttach ?
                                    <TopicAttachment selectedData={selectedData} onClose={(e) => { if (e) { this.getTopics(); }; this.setState({ isAttach: false }); }} /> :
                                    <Fragment>
                                        <Card className='shadow rounded-3'>
                                            <TabHeader
                                                key={'Topics'}
                                                Title={'Topics'}
                                                isReport
                                                isFilter
                                                headers={tabHeader}
                                                onSearch={async (isSearch) => {
                                                    await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                                    await this.getTopics();
                                                }}
                                                onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                                onFilter={(isFilter) => this.setState({ isFilter })}
                                                isLoading={loading} csvFile={csvFile}
                                            />
                                        </Card >
                                        {isFilter && <Filter loading={loading} workFlowTeamList={workFlowTeamList} workFlowConfList={workFlowConfList} projectList={projectList}
                                            onFilter={async (e) => {
                                                if (!e) this.setState({ isFilter: false });
                                                if (e) {
                                                    await this.setState({ loading: true, page: 1, postData: { ...this.state.postData, ...e } });
                                                    await this.getTopics();
                                                };
                                            }} />}
                                        <Fragment>
                                            <InlineSearch masterTopicList={masterTopicList} onSearch={topicList => this.setState({ topicList })} onClose={this.props.onClose} />
                                            <TopicsTable
                                                tabHeader={tabHeader}
                                                isLoading={loading}
                                                filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                                showMenu={menuArray}
                                                topicList={topicList}
                                                onDelete={() => { this.getTopics() }}
                                                onMenu={(a, b) => {
                                                    this.setState({
                                                        selectedData: a,
                                                        isTopic: b == 1 ? true : false,
                                                        isComment: b == 3 ? true : false,
                                                        isAttach: b == 4 ? true : false,
                                                    })
                                                }} />
                                        </Fragment>
                                        {loading ? <Fragment></Fragment> :
                                            topicList && topicList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                                nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                                <AppNodata />
                                        }
                                    </Fragment>
                    }
                </Fragment>
            </React.Fragment >
        )
    }

}
