import { AppButton, AppLoader } from 'components/Reusable';
import React, { Component, Fragment } from 'react';
import {
    Row,
    Col,
} from "reactstrap";
import ReactEcharts from "echarts-for-react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types';
import { chart, lineChart } from '../Appchart';
export default class Graph extends Component {
    static get propTypes() {
        return {
            signalStatusGraph: PropTypes.any,
            signalWorkFlowGraph: PropTypes.any,
            signalActionGraph: PropTypes.any,
            drugName: PropTypes.any,
            signalTimeGraph: PropTypes.any,
            loading: PropTypes.bool,
            onExpand: PropTypes.func,
        };
    }
    constructor() {
        super();
    }
    render() {
        const { drugName, signalActionGraph, signalStatusGraph, signalTimeGraph, signalWorkFlowGraph, loading, onExpand } = this.props;
        return (
            <Fragment>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='p-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-danger shadow p-2 rounded'>Signals by status</p>
                                <AppButton className="shadow btn-danger" iconbutton tooltip='Expand Graph' icon='fas fa-expand' onClick={() => onExpand(1)} />
                            </div>
                            {loading ? (
                                <AppLoader />
                            ) : (
                                <ReactEcharts
                                    style={{ height: "250px", textAlign: 'center' }}
                                    option={chart(signalStatusGraph)}
                                />
                            )}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='p-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-danger shadow p-2 rounded'>Signals by workflow</p>
                                <AppButton className="shadow btn-danger" iconbutton tooltip='Expand Graph' icon='fas fa-expand' onClick={() => onExpand(2)} />
                            </div>
                            {loading ? <AppLoader /> :
                                <ReactEcharts
                                    style={{ height: "250px" }}
                                    option={chart(signalWorkFlowGraph)}
                                />
                            }
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='p-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-danger shadow p-2 rounded'>Signals by time</p>
                                <AppButton className="shadow btn-danger" iconbutton tooltip='Expand Graph' icon='fas fa-expand' onClick={() => onExpand(3)} />
                            </div>
                            {loading ? (
                                <AppLoader />
                            ) : (
                                <ReactEcharts
                                    style={{ height: "250px" }}
                                    option={chart(signalTimeGraph)}
                                />
                            )}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='p-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-danger shadow p-2 rounded'>Actions Data</p>
                                <AppButton className="shadow btn-danger" iconbutton tooltip='Expand Graph' icon='fas fa-expand' onClick={() => onExpand(4)} />
                            </div>
                            {loading ? (
                                <AppLoader />
                            ) : (
                                <ReactApexChart
                                    options={lineChart(drugName)}
                                    series={signalActionGraph}
                                    type="bar"
                                    height={230}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
