import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';

export default class DeleteAlert extends Component {
    static get propTypes() {
        return {
            title: PropTypes.any,
            onYes: PropTypes.func,
            onNo: PropTypes.func,
            loading: PropTypes.bool
        };
    }
    render() {
        const { title, onYes, onNo, loading } = this.props;
        return (
            <div className='d-flex justify-content-between align-items-center text-primary shadow rounded-2 p-2'>
                Are you sure do you want delete this {title}?
                <span className='d-flex'>
                    <AppButton icon="fas fa-trash-alt" name="Yes" className="me-2" onClick={onYes} disabled={loading} showloader />
                    <AppButton icon="fas fa-times" name="No" onClick={onNo} disabled={loading} showloader />
                </span>
            </div>
        )
    }
}
