import React, { Component, Fragment } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppNodata, AppPagination } from 'components/Reusable';
import { Service } from '../Appservice';
import { Card, Container } from "reactstrap";
import TabHeader from '../components/tabHeader';
import Filter from './Filter';
import InlineSearch from './InlineSearch';
import WorkflowConfigurationTable from './WorkflowConfigurationTable';
import MetaTags from "react-meta-tags";
import WorkflowConfigurationAdd from './WorkflowConfigurationAdd';
import WorkflowConfigurationView from './WorkflowConfigurationView';
import ActionState from './ActionState';
import { signalApi } from '../ApiConstant';
export default class WorkFlowConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: ["NAME", "DESCRIPTION", "OWNER", "STAGE"],
      menuArray: [
        { name: 'Edit', icon: 'fas fa-pen', id: 1 },
        { name: 'Delete', icon: 'fas fa-trash', id: 2 },
        { name: 'View', icon: 'fas fa-eye', id: 3 }
      ],
      tabHeader: [],
      page: 1,
      loading: false,
      search: '',
      workFlowList: [],
      workFlowCopyList: [],
      isFilter: false,
      isAdd: false,
      isView: false,
      selectedData: '',
      stagList: [{ id: 'nor', name: 'Normal' }, { id: 'ev', name: 'Evaluation' }, { id: 'val', name: 'Validation' }],
      showActionState: false,
      stage: ''
    }
  }
  componentDidMount = async () => {
    let { header } = this.state;
    let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
    this.setState({ header, tabHeader, loading: true });
    this.getWorkflowConfiguration();
  }
  filterStaticHeaders = (header) => {
    const { tabHeader } = this.state
    const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
    return isActiveHeader ? true : false;
  }
  paginate = async (page) => {
    await this.setState({ page, isUpdating: true });
    this.getWorkflowConfiguration();
  }
  getWorkflowConfiguration = async () => {
    const { page, search, stage } = this.state;
    let queryParam = `?page=${page}&page_size=10`;
    if (search) queryParam += "&search=" + search;
    if (stage) queryParam += "&stage=" + stage;
    const getWorkflowConfiguration = await Service.appservice('get', signalApi.workflowConfiguration + queryParam);
    if (getWorkflowConfiguration?.data) {
      this.setState({
        workFlowList: getWorkflowConfiguration.data, workFlowCopyList: getWorkflowConfiguration.data,
        pageNumbers: getWorkflowConfiguration.metadata ? Array.from({ length: getWorkflowConfiguration.metadata.total_pages }, (v, k) => k + 1) : [],
        loading: false
      });
    }
  }
  render() {
    const { page, pageNumbers, workFlowList, workFlowCopyList, loading, tabHeader, isFilter, stagList, menuArray, isAdd,
      selectedData, isView, showActionState } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Workflow Configuration | 3 Analytics</title>
          </MetaTags>
          <Container fluid>
            {
              showActionState ?
                <ActionState
                  onViewWorkflow={() => { this.setState({ showActionState: false }); }}
                  workFlowList={workFlowList} />
                :
                <Fragment>
                  <Card className='shadow rounded-3'>
                    <TabHeader
                      key={'Workflow configuration'}
                      Title={'Workflow configurations'}
                      headers={tabHeader}
                      isAdd
                      isFilter
                      isLoading={loading}
                      onSearch={async (isSearch) => {
                        await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                        await this.getWorkflowConfiguration();
                      }}
                      onHeader={(items) => { this.setState({ tabHeader: items }) }}
                      onFilter={(isFilter) => this.setState({ isFilter })}
                      onAdd={(isAdd) => this.setState({ isAdd })}
                    />
                  </Card>
                  {isFilter ?
                    <Filter
                      stagList={stagList}
                      onFilter={(e) => {
                        if (!e || e == 'reset') this.setState({ isFilter: false });
                        if (e) { this.setState({ loading: true, page: 1 }); this.getWorkflowConfiguration(e); };
                      }} /> :
                    <Fragment></Fragment>
                  }
                  {isAdd ?
                    <WorkflowConfigurationAdd selectedData={selectedData}
                      onClose={(e) => { this.setState({ selectedData: '', isAdd: false }); if (e) this.getWorkflowConfiguration() }} /> :
                    <Fragment></Fragment>}
                  <Fragment>
                    {isView ?
                      <WorkflowConfigurationView selectedData={selectedData}
                        onClose={() => { this.setState({ selectedData: '', isView: false }); }} /> :
                      <Fragment>
                        <div>
                          <InlineSearch
                            workFlowCopyList={workFlowCopyList}
                            onSearch={workFlowList => this.setState({ workFlowList })}
                            onActionState={() => this.setState({ showActionState: true })} />
                          <WorkflowConfigurationTable
                            stagList={stagList}
                            tabHeader={tabHeader}
                            loading={loading}
                            filterStaticHeaders={e => this.filterStaticHeaders(e)}
                            menuArray={menuArray}
                            workFlowList={workFlowList}
                            onDelete={this.getWorkflowConfiguration}
                            onMenu={(a, b) => {
                              this.setState({
                                selectedData: a,
                                isAdd: b == 1 ? true : false,
                                isView: b == 3 ? true : false
                              })
                            }} />
                          {loading ? <Fragment></Fragment> :
                            workFlowList && workFlowList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                              nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                              <AppNodata />
                          }
                        </div>
                      </Fragment>
                    }
                  </Fragment>
                </Fragment>
            }
          </Container>
        </div>
      </React.Fragment >
    )
  }

}
