import React, { Component } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import empty from 'assets/illusion/listempty.png';
export default class AppNodata extends Component {
    static get propTypes() {
        return {
            isTable: PropTypes.bool,
        };
    }
    render() {
        // const { isTable } = this.props;
        return (
            <React.Fragment>
                <div className='mt-3 text-center '>
                    <img src={empty} alt="" className="rounded mx-auto d-block" />
                    <span className='badge badge-soft-danger p-2 mt-5 shadow rounded font-size-13'>No Data Available</span>
                </div>
            </React.Fragment>
        )
    }
}
