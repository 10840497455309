import React, { Component, Fragment } from 'react';
import { Row, Col, CardBody, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppButton, AppInput, AppShowmore } from 'components/Reusable';
import empty from 'assets/illusion/listempty.png';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';
import { signalApi } from '../ApiConstant';

export default class SignalRecommentationInfo extends Component {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      isLoading: PropTypes.bool,
      disableRecommentation: PropTypes.bool,
      onAddRecommndation: PropTypes.func,
    };
  }
  constructor(props) {
    super(props);
    const { selectedData } = props;
    this.state = {
      postData: {
        recommendation_status: selectedData.recommendation_status ?? '',
        recommendation_comment: selectedData.recommendation_comment ?? '',
      },
      postErr: {
        recommendation_status: false,
        recommendation_comment: false,
      },
      recommendList: [{ id: 'PU', name: 'PI update' }, { id: 'RU', name: 'RMP Update' }, { id: 'RPP', name: 'Routine PV-monitor within PSUR' },
      { id: 'AR', name: 'aRMM' }],
      loading: false,
      isAdd: false
    }
  }
  setValue = async (i, e) => {
    const value = i.target.value;
    await this.setState({ postData: { ...this.state.postData, [e]: value } });
    if (!value) this.setError(e);
    else this.removeErr(e);
  };
  setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  onMarkValidation = async () => {
    const { selectedData, onAddRecommndation } = this.props;
    const { postData } = this.state;
    if (!postData.recommendation_status) return this.setError('recommendation_status');
    if (!postData.recommendation_comment) return this.setError('recommendation_comment');
    this.setState({ loading: true });
    let recData = { recommendation_status: postData.recommendation_status, comment: postData.recommendation_comment };
    await Service.appservice('patch', `${signalApi.signals}/${selectedData.id}/update_status`, recData);
    toast.success('Validation Status Updated Successfully !...');
    this.setState({ loading: false });
    onAddRecommndation();
  }
  recommendList = [{ id: 'PU', name: 'PI update' }, { id: 'RU', name: 'RMP Update' }, { id: 'RPP', name: 'Routine PV-monitor within PSUR' },
  { id: 'AR', name: 'aRMM' }];
  render() {
    const { selectedData, isLoading, disableRecommentation } = this.props;
    const { postData, postErr, recommendList, loading, isAdd } = this.state;
    return (
      <Fragment>
        <div className='card'>
          <div className='border p-2 shadow rounded d-flex align-items-center justify-content-between'>
            <h5 className="font-size-14 m-0  p-2  badge badge-soft-danger rounded">
              <i className="fas fa-check  me-2" /> Recommendation
            </h5>
            {isAdd ? null :
              disableRecommentation ? null : <AppButton icon='fas fa-plus me-2' name="Add" showloader onClick={() => this.setState({ isAdd: true })} disabled={selectedData?.recommendation_status || disableRecommentation} />}
          </div>
          <div style={{ height: 250 }}>
            {(loading || isLoading) ?
              <div className='m-3'>
                <p className="card-text placeholder-glow">
                  <span className="placeholder rounded col-7"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-6"></span>
                  <span className="placeholder rounded col-8"></span>
                </p>
                <p className="card-text placeholder-glow">
                  <span className="placeholder rounded col-7"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-6"></span>
                  <span className="placeholder rounded col-8"></span>
                </p>
                <p className="card-text placeholder-glow">
                  <span className="placeholder rounded col-7"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-6"></span>
                  <span className="placeholder rounded col-8"></span>
                </p>
              </div>
              : selectedData?.recommendation_status ?
                <Fragment>
                  <div className="shadow-none border mb-2 rounded-3 card m-3 mt-5" >
                    <div className='border-bottom p-2 rounded-3  d-flex align-items-center justify-content-end'>
                      <span className={`badge p-2 badge-soft-danger rounded-2 font-size-12`}>{selectedData.recommendation_status}</span>
                    </div>
                    <div className="card-body">
                      <div >
                        <p className="text-muted mb-1">Status</p>
                        <h5 className="font-size-14 text-info" role='button' onClick={() => { onActionView(e) }}>
                          <AppShowmore text={this.recommendList?.find(e => e.id == selectedData.recommendation_status)?.name} />
                        </h5>
                      </div>
                      <div >
                        <p className="text-muted mb-1">Comment</p>
                        <h5 className="font-size-14"><AppShowmore text={selectedData.recommendation_comment} /></h5>
                      </div>
                    </div>
                  </div>
                </Fragment> :
                (disableRecommentation || !isAdd) ?
                  <Fragment>
                    <div className='text-danger text-center p-3'>
                      <span className='p-2 shadow rounded'>No Recommendation Added..</span>
                      <img src={empty} alt="" className="bx-fade-left-hover rounded mx-auto d-block w-50 h-50 mt-3" />
                    </div>
                  </Fragment> :
                  <Form className='m-5 mt-5  p-2'>
                    <Col >
                      <AppInput label="Recommendation status *" type="select" value={postData.recommendation_status} isInvalid={postErr.recommendation_status}
                        error="Recommendation status is required" onChange={e => { this.setValue(e, 'recommendation_status') }} iArray={recommendList} selectType='idpair' />
                    </Col>
                    <Col >
                      <AppInput label="Comment *" type="text" value={postData.recommendation_comment} isInvalid={postErr.recommendation_comment}
                        error="Recommendation Comment is required" onChange={e => { this.setValue(e, 'recommendation_comment') }} />
                    </Col>
                  </Form>
            }
          </div>
          <div className='p-2 rounded text-center'>
            <Fragment>
              {selectedData?.recommendation_status ?
                <AppButton icon='fas fa-check' name={'Action Recommendation Completed'} disabled /> :
                <AppButton icon='fas fa-check me-2' name="Recommendation" disabled={loading || disableRecommentation || !isAdd} showloader onClick={this.onMarkValidation} />}
            </Fragment>
          </div>
        </div>
      </Fragment >
    )
  }
}
