import { collection, setDoc, doc, updateDoc, deleteDoc, getDoc, addDoc, query, where, getDocs, arrayUnion, onSnapshot, serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from './Appfirebase';
import { AppCollections } from './Appenum';
import { runTransaction } from "firebase/firestore";
const _preFix = "3auser" + process.env.REACT_APP_CHAT_PREFIX;
const appId = process.env.REACT_APP_CHAT_APP_ID;
const clientId = process.env.REACT_APP_CHAT_CLIENT_ID;
const authId = process.env.REACT_APP_CHAT_AUTH_KEY;
class appApi {
    setBg = () => {
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        return randomColor;
    }
    chatDocId = (currentUser, selectedUser) => {
        return currentUser > selectedUser ? currentUser + selectedUser : selectedUser + currentUser;
    }
    createSingleUser = async (user) => {
        const userId = _preFix + user.uid;
        const q = collection(db, AppCollections.CHATUSERS);
        const qdoc = query(q, where("userId", "==", userId), where("clientId", "==", clientId), where("appId", "==", appId));
        const allUsers = await getDocs(qdoc);
        let isNew = true;
        allUsers.forEach(doc => {
            if (doc.id) isNew = false;
            this.getLastCount(doc.id)
        });
        if (isNew) {
            const post = {
                userId: userId,
                userName: user.username,
                email: user.email,
                mobile: '',
                role: '',
                uImage: '',
                createdDate: serverTimestamp(),
                createdBy: clientId,
                isOnline: false,
                isActive: true,
                updatedBy: '',
                updatedDate: '',
                clientId: clientId,
                appId: appId,
                bgColor: this.setBg(),
                last_changed: '',
                authId: authId
            }
            await setDoc(doc(db, AppCollections.CHATUSERS, userId), post);
            await setDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, userId), {});
        }
    }
    unsub;
    getLastCount = (userId) => {
        if (this.unsub) this.unsub()
        this.unsub = onSnapshot(doc(db, AppCollections.CHATUSERS, userId), (doc) => {
            const lastCount = doc.data().lastCount;
            localStorage.setItem('lastCount', lastCount ? lastCount : 0)
            window.dispatchEvent(new Event('storage'));
        });
    }
    FindAndCreate = async (user) => {
        const userId = _preFix + user.id;
        const sfDocRef = doc(db, AppCollections.CHATUSERS, userId);
        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    const post = {
                        userId: userId,
                        userName: user.first_name + ' ' + user.last_name,
                        email: user.email,
                        mobile: '',
                        role: '',
                        uImage: '',
                        createdDate: serverTimestamp(),
                        createdBy: clientId,
                        isOnline: false,
                        isActive: true,
                        updatedBy: '',
                        updatedDate: '',
                        clientId: clientId,
                        appId: appId,
                        bgColor: this.setBg(),
                        last_changed: '',
                        authId: authId,
                        lastCount: 0
                    }
                    transaction.set(sfDocRef, post);
                    await setDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, userId), {});
                }
                else {
                    const post = {
                        userId: userId,
                        userName: user.first_name + ' ' + user.last_name,
                        email: user.email,
                        updatedDate: serverTimestamp()
                    }
                    transaction.update(sfDocRef, post);
                }
            });
        } catch (e) {
            // This will be a "population is too big" error.
            console.error(e);
        }
    }
    CreateRoom = async (userId, selectedUserId) => {
        const combinedId = this.chatDocId(_preFix + userId, _preFix + selectedUserId);
        const checkConnected = await getDoc(doc(db, AppCollections.CHATROOM, combinedId));
        if (!checkConnected.exists()) {
            const currentUser = await getDoc(doc(db, AppCollections.CHATUSERS, _preFix + userId));
            const selectedUser = await getDoc(doc(db, AppCollections.CHATUSERS, _preFix + selectedUserId));
            if (selectedUser.exists()) await this.connectChatToUser(_preFix + userId, selectedUser.data(), combinedId)
            if (currentUser.exists()) await this.connectChatToUser(_preFix + selectedUserId, currentUser.data(), combinedId)
        }
    }
    connectChatToUser = async (userId, e, combinedId) => {
        await updateDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, userId), {
            [combinedId + ".userInfo"]: e,
            [combinedId + ".date"]: serverTimestamp(),
        });
    }
    appunReadMessage = async (userId) => {
        const users = await getDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, userId));
        return users.data();
    }
    appLastMessage = async (uid, data) => {
        await updateDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, uid), data);
        return true;
    }
    appSaveMessage = async (uid, data) => {
        try {
            await addDoc(collection(db, AppCollections.CHATROOM, uid, 'messages'), data);
            return true;
        }
        catch (e) {
            await setDoc(doc(db, AppCollections.CHATROOM, uid), { messages: arrayUnion(data) });
            return true;
        }
    }
    sendMessage = async (user, selectedUser, message) => {
        const cUser = _preFix + user;
        const sUser = _preFix + selectedUser;
        const connectedId = this.chatDocId(cUser, sUser);
        const getUnread = await this.appunReadMessage(sUser);
        let unReadcount = 1;
        if (getUnread) {
            Object.entries(getUnread).map((e) => {
                if (e[0] === connectedId) {
                    unReadcount = e[1].lastMessage?.unread ? e[1].lastMessage.unread + unReadcount : unReadcount;
                }
            })
        }
        const lastMessage = {
            sendBy: cUser,
            message: message,
            sendAt: Timestamp.now(),
            isViewed: false,
            unread: unReadcount
        }
        const dataLastMessage = {
            [connectedId + ".date"]: serverTimestamp(),
            [connectedId + ".lastMessage"]: lastMessage,
        }
        await AppApi.appLastMessage(sUser, dataLastMessage);
        await AppApi.appLastMessage(cUser, dataLastMessage);
        let umessage = {
            createdDate: Timestamp.now(),
            files: '',
            fromId: cUser,
            toId: sUser,
            message: message,
            isSend: true,
            isViewed: false,
            isSticker: false,
            isEdited: false,
            updatedDate: Timestamp.now()
        };
        await AppApi.appSaveMessage(connectedId, umessage);
        // await AppApi.appSendNotfication(this.props.userProfile, { message: post.message, from: this.props.userId, sender: this.props.userProfile, to: this.props.selectedUser.userId }, this.props.selectedUser)
    }
    deleteAllData = async (selectedUserId) => {
        const user = JSON.parse(localStorage.getItem('authUser'));
        const combinedId = this.chatDocId(_preFix + user.uid, _preFix + selectedUserId);
        await deleteDoc(doc(db, AppCollections.CHATUSERS, _preFix + selectedUserId));
        await deleteDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, combinedId));
        await deleteDoc(doc(db, AppCollections.CHATCONNECTEDUSERS, combinedId));
        return;
    }
}

export const AppApi = new appApi();