import PropTypes from "prop-types";
import axios from "axios";
import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { userApi } from "pages/SignalManagement/Scenes/ApiConstant";

const Logout = ({ history }) => {

  useEffect(() => {
    const doLogout = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(userApi.logout, { headers: { Authorization: `Token ${token}` } });
        if (response.status === 200) {
          localStorage.clear();
          window.location.href = '/login';
        }
        else history.goBack()
      } catch (error) {
        localStorage.clear();
        history.goBack();
      }
    };
    doLogout();
  }, [history]);

  return null;
};

Logout.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Logout);
