import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { CardBody, Table} from 'reactstrap';
import { appDate } from '../../AppConstants';
export default class WorkflowTopicComments extends Component {
  static get propTypes() {
    return {
      workFlowDetails: PropTypes.any,
      topicId: PropTypes.number
    };
  }
  getTopicComments(flow, id) {
    const isComment = flow.find(x => x.id == id);
    if (isComment) {
      let getComment = isComment.commentData;
      return getComment && getComment.length ? getComment : []
    }
    return []
  }
  render() {
    const { workFlowDetails, topicId } = this.props;
    return (
      <Fragment>
        {
          this.getTopicComments(workFlowDetails, topicId) && this.getTopicComments(workFlowDetails, topicId).length ?
            <div className='table-responsive border rounded'>
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Comment</th>
                    <th>Commented By</th>
                    <th>Commented Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.getTopicComments(workFlowDetails, topicId).map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tr >
                            <td>{e?.comment}</td>
                            <td>{e?.commented_by_data?.first_name}</td>
                            <td className='text-right'>
                              {appDate(e?.created_at, '')}
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
            :
            <CardBody className='text-center'>
              <span className='text-danger shadow p-2 rounded'>No Topic Comments Found..</span>
            </CardBody>
        }
      </Fragment>
    )
  }
}
