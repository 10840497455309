import React, { Component } from 'react'
import {
  Card,
  CardBody,
  Row,
  Spinner,

} from "reactstrap";
import PropTypes from 'prop-types';
export default class AppLoader extends Component {
  static get propTypes() {
    return {
      isGlow: PropTypes.bool,
      count: PropTypes.number
    };
  }

  render() {
    const {  isGlow, count } = this.props;
    return (
      <Row>
        {
          isGlow ?
            <p className="placeholder-glow mb-0">
              <span className="placeholder loader col-12"></span>
            </p>
            :
            <span className="text-center text-danger ">
              <i className="fas fa-spinner bx-spin align-middle me-2 shadow-lg font-size-16 rounded p-2 " />
            </span>
        }
      </Row>
    )
  }
}
