import React, { Component } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppShowmore, AppDropDown } from 'components/Reusable';
import { Service } from '../../Appservice';
import toast from 'react-hot-toast';
import { signalApi } from '../../ApiConstant';
import DeleteAlert from '../../components/DeleteAlert';
export default class ActionStateTable extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            loading: PropTypes.bool,
            filterStaticHeaders: PropTypes.func,
            menuArray: PropTypes.any,
            actionStateList: PropTypes.any,
            onMenu: PropTypes.func,
            onDelete: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            isMenu: false,
            updateData: '',
            isDelete: false,
            isLoading: false,
        }
    }
    deleteActionState = async () => {
        const { updateData } = this.state;
        this.setState({ isLoading: true });
        const deleteAction = await Service.appservice('delete', `${signalApi.actionType}/${updateData.id}`);
        if (deleteAction) toast.success('ActionState deleted successfully!...')
        this.setState({ isDelete: false, updateData: '', isLoading: false });
        this.props.onDelete();
    }
    render() {
        const { tabHeader, filterStaticHeaders, menuArray, actionStateList, onMenu, loading } = this.props;
        const { isMenu, updateData, isDelete, isLoading, } = this.state;
        return (
            <div className='shadow text-center rounded-3 mt-3'>
                <Table className="table-hover table-borderless mb-0">
                    <thead className='shadow'>
                        <tr>
                            <th className='text-center'>
                                <i className='fas fa-bars text-info'></i>
                            </th>
                            {
                                tabHeader.map((e, i) => {
                                    return (
                                        e.isChecked && <th key={i}>{e.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {(isLoading || loading) ?
                            <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                            :
                            actionStateList.map((e, i) => {
                                return (
                                    isDelete && updateData.id == e.id ?
                                        <td colSpan={15} key={i} className='shadow border border-danger'>
                                            <DeleteAlert loading={loading || isLoading} title="Action State" onYes={this.deleteActionState}
                                                onNo={() => { this.setState({ isDelete: false, updateData: '' }) }} />
                                        </td>
                                        :
                                        <tr key={i} ><td className='text-center'>
                                            <AppDropDown
                                                menuArray={menuArray}
                                                isMenu={isMenu}
                                                onToggle={() => { if (e.id == updateData.id) this.setState({ isMenu: !isMenu, updateData: e }); }}
                                                onClick={() => { this.setState({ isMenu: true, updateData: e }); }}
                                                rowId={e.id}
                                                selectedId={updateData.id}
                                                onClose={(id) => {
                                                    this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                                                    onMenu(updateData, id);
                                                }}
                                            />
                                        </td>
                                            <td className={filterStaticHeaders(0) ? '' : 'd-none'}><AppShowmore text={e?.name} /> </td>
                                            <td className={filterStaticHeaders(1) ? '' : 'd-none'}><AppShowmore text={e?.description} /></td>
                                            <td className={filterStaticHeaders(2) ? '' : 'd-none'}><AppShowmore text={e?.workflow_configuration_data?.name} /></td>
                                        </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
