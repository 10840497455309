import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { Row, Col, CardBody, Form, FormGroup, Label, Input, CardImg } from 'reactstrap';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';
import { signalApi, userApi } from '../ApiConstant';
export default class SignalValidationActionChangeStatus extends Component {
    static get propTypes() {
        return {
            isEdit: PropTypes.bool,
            updateData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super();
        const { updateData, isEdit } = props;
        this.state = {
            loading: false,
            postData: {
                name: isEdit ? updateData?.measure_data?.name : '',
                description: isEdit ? updateData?.measure_data?.description : '',
                assigned_to: isEdit ? updateData?.assigned_to : '',
                measure_order: isEdit ? updateData?.measure_order : '',
                start_date: isEdit ? updateData?.start_date : '',
                status: isEdit ? updateData?.status : [],
                workflow_configuration: updateData ? updateData?.workflow_config?.id : '',
                topic: updateData ? updateData?.theme : '',
                measure: ''
            },
            postErr: {
                name: false,
                description: false,
                assigned_to: false,
                measure_order: false,
                start_date: false,
                status: false,
                measure: false
            },
            actionStatusList: [],
            workFlowList: [],
            userList: [],
            workFlowConfigList: [],
            themeList: [],
            actionList: [],
            isNew: true
        }
    }
    componentDidMount = async () => {
        await this.getAll();
    }
    getAll = async () => {
        const { updateData, isEdit } = this.props;
        const actionStatus = isEdit ? updateData.measure_type_data
            : await Service.appservice('get', `${signalApi.actionType}?workflow_configuration=${updateData?.workflow_config?.id}`);
        if (actionStatus && actionStatus.data) await this.setState({ actionStatusList: actionStatus.data });
        if (isEdit && actionStatus) await this.setState({ actionStatusList: actionStatus });
        const workFlow = await Service.appservice('get', `${signalApi.topicWorkflowTeam}/${isEdit ? updateData.theme_signal : 80}`);
        if (workFlow) await this.setState({ workFlowList: workFlow });
        const user = await Service.appservice('get', userApi.users);
        if (user && user.data) await this.setState({ userList: user.data });
        if (!isEdit) {
            const action = await Service.appservice('get', `${signalApi.actions}?workflow_configuration=${updateData?.workflow_config?.id}`);
            if (action && action.data) await this.setState({ actionList: action.data });
            const workFlowConfig = await Service.appservice('get', signalApi.workflowConfiguration);
            if (workFlowConfig && workFlowConfig.data) await this.setState({ workFlowConfigList: workFlowConfig.data });
            const theme = await Service.appservice('get', signalApi.topics);
            if (theme) await this.setState({ themeList: theme });
        }
    }
    setValue = async (i, e, isArray) => {
        const value = isArray ? Array.from(i.target.selectedOptions, option => option.value) : i.target.value === "--Select--" ? "" : i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onSubmit = async () => {
        const { postData, isNew } = this.state;
        const { updateData, isEdit, onClose } = this.props;
        if (isNew) {
            if (!postData.name || !postData.name.replace(/[^\p{L}]/gu, '')) return this.setError('name');
            if (!postData.description || !postData.description.replace(/[^\p{L}]/gu, '')) return this.setError('description');
            if (!postData.status || postData.status === "--Select--") return this.setError('status');
        }
        else if (!postData.measure) return this.setError('measure');
        if (!postData.assigned_to) return this.setError('assigned_to');
        if (!postData.measure_order) return this.setError('measure_order');
        if (!postData.start_date) return this.setError('start_date');
        let data = {
            status: postData.status,
            assigned_to: postData.assigned_to,
            measure_order: postData.measure_order,
            start_date: postData.start_date,
            measure: {
                name: postData.name,
                description: postData.description,
            }
        };
        await this.setState({ loading: true })
        const postAction = await Service.appservice('patch', `${signalApi.topicActions}/${updateData.id}`, data)
        if (postAction) {
            toast.success(`Action Updated Successfully !...`);
            await onClose(true);
        }
        await this.setState({ loading: false });
    }
    render() {
        const { onClose, updateData, isEdit } = this.props;
        const { postData, postErr, loading, actionStatusList, userList, workFlowList, themeList, workFlowConfigList, isNew, actionList } = this.state;
        const isDisabled = false;
        return (
            <div >
                <AppButton name={`Update Actions Status of ${updateData?.measure_data?.name}`} tooltip="Back to View" icon='fas fa-arrow-left' onClick={()=>onClose()} />
                <Row >
                    <Col md={2}/>
                    <Col md={8}>
                        <CardBody>
                            <Form className="align-content-center" >
                                <Row>
                                    {
                                        isNew ?
                                            <Fragment>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <AppInput disabled={isDisabled} label="Action Name *" error="Name is required" onChange={e => { this.setValue(e, 'name') }} value={postData.name} isInvalid={postErr.name} />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <AppInput disabled={isDisabled} type='text' label="Action Description *" error="Description is required" onChange={e => { this.setValue(e, 'description') }} value={postData.description} isInvalid={postErr.description} />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <AppInput disabled={isDisabled} isMultiple={!isEdit ? true : false} label="Action State  *" type="select" value={postData.status} isInvalid={postErr.status}
                                                        error="Action State is required" onChange={e => { this.setValue(e, 'status', !isEdit ? true : false) }} iArray={actionStatusList} selectType={!isEdit ? 'idpair' : 'valuepair'} />
                                                </Col>
                                            </Fragment>
                                            :
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <AppInput disabled={isDisabled} label="Action   *" type="select" value={postData.measure} isInvalid={postErr.measure}
                                                    error="Action  is required" onChange={e => { this.setValue(e, 'measure') }} iArray={actionList} selectType='idpair' />
                                            </Col>
                                    }
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="Assignee">Assignee *</Label>
                                            <Input disabled={isDisabled} onChange={e => { this.setValue(e, 'assigned_to') }} type="select" name="select" invalid={postErr.assigned_to} value={postData.assigned_to}>
                                                {(workFlowList && workFlowList.length || userList && userList.length) ?
                                                    <>
                                                        <option value=''>--Select--</option>
                                                        <option disabled className='text-primary'>--Select From Workflow Team--</option>
                                                        {
                                                            workFlowList && workFlowList.map((e, i) => {
                                                                return <option key={i} value={e.id}>{e.username}</option>
                                                            })
                                                        }
                                                        <option disabled className='text-primary'>--Select From Users--</option>
                                                        {
                                                            userList && userList.map((e, i) => {
                                                                return <option key={i} value={e.id}>{e.username}</option>
                                                            })
                                                        }
                                                    </> :
                                                    <option style={{ color: 'red' }}>Loading...</option>
                                                }
                                            </Input>
                                            {/* <FormFeedback>Assignee is required</FormFeedback> */}
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <AppInput disabled={isDisabled} label="Action Order *" error="Order is required" type="number" onChange={e => { this.setValue(e, 'measure_order') }} value={postData.measure_order} isInvalid={postErr.measure_order} />
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <AppInput disabled={isDisabled} label="Start Date  *" error="Start Date is required" type="date" onChange={e => { this.setValue(e, 'start_date') }} value={postData.start_date} isInvalid={postErr.start_date} />
                                    </Col>
                                    <Col lg={12} className='text-center'>
                                        <AppButton name='Update Status' icon='fas fa-check' onClick={this.onSubmit} disabled={loading || isDisabled} />
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Col>
                </Row>
            </div>
        )
    }
}
