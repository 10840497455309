import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { Row, CardImg, CardBody, Col, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Logo from "assets/illusion/add.png";
import { Service } from '../Appservice';
import SignalEvaluationTopic from './SignalEvaluationTopic';
import toast from 'react-hot-toast';
import SignalMarkEvaluation from './SignalMarkEvaluation';
import { signalApi, userApi } from '../ApiConstant';
export default class SignalEvaluation extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            workFlowTeamList: PropTypes.any,
            workFlowEvaluationList: PropTypes.any,
            evaluationTopicList: PropTypes.any,
            projectsList: PropTypes.any,
            onAddEvaluationTopic: PropTypes.func,
            onMarkEvaluation: PropTypes.func,
            selectedStatus: PropTypes.number,
        };
    }
    constructor(props) {
        super(props);
        const { workFlowEvaluationList, selectedData, selectedStatus } = props;
        this.state = {
            isSkip: selectedStatus == 5 || selectedStatus == 6 ? true : false,
            postData: {
                workflow_configuration: workFlowEvaluationList.length > 0 ? workFlowEvaluationList[0].id : [],
                topic: '',
                project: '',
                workflow_team: '',
                name: '',
                description: '',
                status: '',
                measure: '',
                measure_order: '',
                start_date: '',
                theme_signal: '',
                signalid: selectedData.id
            },
            postErr: {
                topic: false,
                project: false,
                workflow_team: false,
                name: false,
                description: false,
                status: false,
                measure: false,
                measure_order: false,
                start_date: false
            },
            newAction: true,
            actionStateList: [],
            actionList: [],
            userList: [],
            workFlowList: [],
            newTopic: false,
            loading: false,
            isTopicAdded: false,
        };
    }
    componentDidMount = () => {
        this.getAction();
        this.getActionState();
        this.getUsers()
    }
    getAction = async () => {
        const action = await Service.appservice('get', `${signalApi.actions}?workflow_configuration=${this.props.workFlowEvaluationList[0].id}`);
        if (action?.data) await this.setState({ actionList: action.data });
    }
    getActionState = async () => {
        const actionState = await Service.appservice('get', `${signalApi.actionType}?workflow_configuration=${this.props.workFlowEvaluationList[0].id}`);
        if (actionState?.data) await this.setState({ actionStateList: actionState.data });
    }
    getUsers = async () => {
        const user = await Service.appservice('get', userApi.users);
        if (user?.data) await this.setState({ userList: user.data });
    }
    getWorkFlowTeam = async (id) => {
        const workFlowTeam = await Service.appservice('get', `${signalApi.workflowTeam}/${id}`);
        if (workFlowTeam?.user_data) await this.setState({ workFlowList: workFlowTeam.user_data });
    }
    getThemesignalId = async (topicId) => {
        const theme = await Service.appservice('get', `${signalApi.topicSignals}?theme=${topicId}`);
        if (theme?.data) await this.setState({ postData: { ...this.state.postData, theme_signal: theme?.data[0]?.id } });
    }
    setValue = (i, e, isArray = false) => {
        let value = isArray ? Array.from(i.target.selectedOptions, option => option.value) : i.target.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onCreateNewTopicWithAction = async () => {
        const { postData, newAction } = this.state;
        const { onClose } = this.props;
        if (!postData.topic) return this.setError('topic');
        if (!postData.project) return this.setError('project');
        if (!postData.workflow_team) return this.setError('workflow_team');
        if (newAction) {
            if (!postData.name) return this.setError('name');
            if (!postData.description) return this.setError('description');
            if (!postData.status || postData.status?.length === 0) return this.setError('status');
        }
        else {
            if (!postData.measure) return this.setError('measure');
        }
        if (!postData.assigned_to) return this.setError('assigned_to');
        if (!postData.measure_order) return this.setError('measure_order');
        if (!postData.start_date) return this.setError('start_date');

        //create first action validation
        let actionValidationData;
        //topic with new action
        if (newAction) {
            actionValidationData = {
                name: postData.name,
                description: postData.description,
                measure_type: postData.status,
                theme_measure: [
                    {
                        assigned_to: postData.assigned_to,
                        measure_order: postData.measure_order,
                        status: "",
                        start_date: postData.start_date,
                        theme_signal: postData.theme_signal,
                    },
                ],
                workflow_configuration: postData.workflow_configuration
            }
        }
        //topic with exsisting action
        else {
            actionValidationData = {
                measure: Number(postData.measure),
                assigned_to: Number(postData.assigned_to),
                measure_order: Number(postData.measure_order),
                start_date: postData.start_date,
                theme_signal: postData.theme_signal,
            }
        }
        this.setState({ loading: true });
        const postAction = newAction ?
            await Service.appservice('post', `${signalApi.actions}`, actionValidationData) :
            await Service.appservice('post', `${signalApi.topicActions}/add_existing`, actionValidationData);
        if (postAction) {
            toast.success(`Evaluation action  created successfully !...`);
            this.setState({ postData: { ...this.state.postData } });
            await onClose(true);
        }
        this.setState({ loading: false });
    }
    onCreateExsistingTopicWithAction = async () => {
        const { postData, newAction } = this.state;
        const { onClose } = this.props;
        if (!postData.topic) return this.setError('topic');
        if (!postData.project) return this.setError('project');
        if (!postData.workflow_team) return this.setError('workflow_team');
        if (newAction) {
            if (!postData.name) return this.setError('name');
            if (!postData.description) return this.setError('description');
            if (!postData.status || postData.status?.length === 0) return this.setError('status');
        }
        else {
            if (!postData.measure) return this.setError('measure');
        }
        if (!postData.assigned_to) return this.setError('assigned_to');
        if (!postData.measure_order) return this.setError('measure_order');
        if (!postData.start_date) return this.setError('start_date');

        //exsisting topic
        const userData = JSON.parse(localStorage.getItem("authUser"))
        let validationtopicData = {
            assigned_to: postData.workflow_team,
            workflow_configuration: postData.workflow_configuration,
            project: postData.project,
            theme: postData.topic,
            signal: postData.signalid,
            created_by: Number(userData.uid)
        };
        this.setState({ loading: true });
        const addExsitingTopic = await Service.appservice('post', `${signalApi.topicSignals}/add_existing`, validationtopicData);
        if (addExsitingTopic) {
            //create first action validation
            let actionValidationData;
            //exsisting topic with new action
            if (newAction) {
                actionValidationData = {
                    name: postData.name,
                    description: postData.description,
                    measure_type: postData.status,
                    theme_measure: [
                        {
                            assigned_to: postData.assigned_to,
                            measure_order: postData.measure_order,
                            status: "",
                            start_date: postData.start_date,
                            theme_signal: addExsitingTopic.id,
                        },
                    ],
                    workflow_configuration: postData.workflow_configuration
                }
            }
            //exsisting topic with exsisting action
            else {
                actionValidationData = {
                    measure: Number(postData.measure),
                    assigned_to: Number(postData.assigned_to),
                    measure_order: Number(postData.measure_order),
                    start_date: postData.start_date,
                    theme_signal: addExsitingTopic.id,
                }
            }
            const postAction = newAction ?
                await Service.appservice('post', `${signalApi.actions}`, actionValidationData) :
                await Service.appservice('post', `${signalApi.topicActions}/add_existing`, actionValidationData);
            if (postAction) {
                toast.success(`Evaluation action  created successfully !...`);
                this.setState({ postData: { ...this.state.postData } });
                await onClose(true);
            }
            this.setState({ loading: false });
        }
        this.setState({ loading: false });
    }
    render() {
        const { selectedData, onClose, workFlowEvaluationList, evaluationTopicList, projectsList, workFlowTeamList, onAddEvaluationTopic,
            onMarkEvaluation, selectedStatus } = this.props;
        const { isSkip, postData, postErr, newAction, actionStateList, actionList, userList, workFlowList, newTopic, loading,
            isTopicAdded } = this.state;
        return (
            <Fragment>
                <div className='d-flex justify-content-md-between text-center'>
                    {
                        isSkip ?
                            <div>
                                {selectedStatus ?
                                    <AppButton name="Back to Signals" icon='fas fa-arrow-left' tooltip="Close Evaluation" onClick={onClose} /> :
                                    <AppButton name="Back to Add Evaluation" className="me-3" icon='fas fa-arrow-left' onClick={async () => { this.setState({ isSkip: false }) }} />
                                }
                            </div> :
                            <Fragment>
                                <span>
                                    <AppButton name={` ${isSkip ? 'Mark' : 'Add'}  Evaluation for ${selectedData?.id}`} icon='fas fa-arrow-left' tooltip="Close Evaluation" onClick={onClose} />
                                </span>
                                <AppButton icon='fas fa-long-arrow-alt-right me-2' className="ms-2 ms-sm-0" iName={'Skip'} name={'Skip Actions'}
                                    onClick={async () => { this.setState({ isSkip: true }) }} />
                            </Fragment>
                    }
                </div>
                <Row className="no-gutters align-items-center">
                    <Col md={4} className='d-none d-sm-block'>
                        <CardImg className="img-fluid" src={Logo} alt="Skote" />
                    </Col>
                    <Col md={7}>
                        {
                            isSkip ?
                                <CardBody>
                                    <SignalMarkEvaluation selectedData={selectedData} onClose={(e) => {
                                        this.setState({ isSkip: false });
                                        if (e) { onMarkEvaluation(); onClose(true) }
                                    }} />
                                </CardBody>
                                :
                                <CardBody>
                                    {
                                        newTopic ?
                                            <SignalEvaluationTopic
                                                selectedData={selectedData}
                                                workFlowTeamList={workFlowTeamList}
                                                workFlowEvaluationList={workFlowEvaluationList}
                                                projectsList={projectsList}
                                                onClose={(e) => {
                                                    this.setState({ newTopic: false });
                                                    if (e) {
                                                        this.setState({ isTopicAdded: true, postData: { ...this.state.postData, topic: e.id, project: e.project, workflow_team: e.assigned_to, theme_signal: e.theme_signal.id } })
                                                        this.getWorkFlowTeam(e.assigned_to)
                                                    }
                                                }}
                                                onAddEvaluationTopic={onAddEvaluationTopic} /> :
                                            <Form>
                                                <span className='shadow p-2 text-center mt-4 rounded text-danger'>Topics</span>
                                                <Row className='mt-4 mb-3'>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <AppInput disabled label="Workflow Configuration *" type="select" value={postData.workflow_configuration} iArray={workFlowEvaluationList} selectType='idpair' />
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <AppInput onChange={(e) => { this.setValue(e, 'topic'); this.getThemesignalId(e.target.value) }} label="Topic  *" type="select" iArray={evaluationTopicList} value={postData.topic}
                                                            error="Topic is required" isInvalid={postErr.topic} selectType='idpair' onAddClick={() => { this.setState({ newTopic: true }) }} addTitle={'New Topic'} showAdd />
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <AppInput label="Project *" type="select" value={postData.project} isInvalid={postErr.project}
                                                            error="Project is required" onChange={e => { this.setValue(e, 'project') }} iArray={projectsList} selectType='idpair' />
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <AppInput label="Workflow Team *" type="select" value={postData.workflow_team} isInvalid={postErr.workflow_team}
                                                            error="Workflow Team  is required" onChange={e => { this.setValue(e, 'workflow_team'); this.getWorkFlowTeam(e.target.value) }} iArray={workFlowTeamList} selectType='idpair' />
                                                    </Col>
                                                </Row>
                                                <span className='shadow p-2 text-center mt-4 rounded text-danger'>Actions</span>
                                                <Row className='mt-4'>
                                                    {
                                                        !newAction ?
                                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                                <AppInput label="Action *" onAddClick={() => { this.setState({ newAction: true }) }} addTitle={'New Action'} showAdd type="select" value={postData.measure} isInvalid={postErr.measure}
                                                                    error="Action  is required" onChange={e => { this.setValue(e, 'measure') }} iArray={actionList} selectType='idpair' />
                                                            </Col>
                                                            :
                                                            <Fragment>
                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                    <AppInput onAddClick={() => { this.setState({ newAction: false }) }} addTitle={'Choose Existing Action'} showAdd label="Action Name *" error="Name is required" onChange={e => { this.setValue(e, 'name') }} value={postData.name} isInvalid={postErr.name} />
                                                                </Col>
                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                    <AppInput type='text' label="Action Description *" error="Description is required" onChange={e => { this.setValue(e, 'description') }} value={postData.description} isInvalid={postErr.description} />
                                                                </Col>
                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                    <AppInput isMultiple={true} label="Action State  *" type="select" value={postData.status} isInvalid={postErr.status}
                                                                        error="Action State is required" onChange={e => { this.setValue(e, 'status', true) }} iArray={actionStateList} selectType={'idpair'} />
                                                                </Col>
                                                            </Fragment>
                                                    }
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <FormGroup>
                                                            <Label for="Assignee">Assignee *</Label>
                                                            <Input onChange={e => { this.setValue(e, 'assigned_to') }} type="select" name="select" invalid={postErr.assigned_to} value={postData.assigned_to}>
                                                                {(workFlowList && workFlowList.length || userList && userList.length) ?
                                                                    <>
                                                                        <option value=''>--Select--</option>
                                                                        <option disabled className='text-primary'>--Select From Workflow Team--</option>
                                                                        {
                                                                            workFlowList && workFlowList.map((e, i) => {
                                                                                return <option key={i} value={e.id}>{e.username}</option>
                                                                            })
                                                                        }
                                                                        <option disabled className='text-primary'>--Select From Users--</option>
                                                                        {
                                                                            userList && userList.map((e, i) => {
                                                                                return <option key={i} value={e.id}>{e.username}</option>
                                                                            })
                                                                        }
                                                                    </> :
                                                                    <option style={{ color: 'red' }}>Loading...</option>
                                                                }
                                                            </Input>
                                                            <FormFeedback valid={!postErr.assigned_to}>Assignee is required</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <AppInput label="Action Order *" error="Order is required" type="number" onChange={e => { this.setValue(e, 'measure_order') }} value={postData.measure_order} isInvalid={postErr.measure_order} />
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <AppInput label="Start Date  *" error="Start Date is required" type="date" onChange={e => { this.setValue(e, 'start_date') }} value={postData.start_date} isInvalid={postErr.start_date} />
                                                    </Col>
                                                    <Col className='text-center'>
                                                        <AppButton icon='fas fa-save' onClick={isTopicAdded ? this.onCreateNewTopicWithAction : this.onCreateExsistingTopicWithAction} showloader disabled={loading} name='Create Evaluation' />
                                                    </Col>
                                                </Row>
                                            </Form>
                                    }
                                </CardBody>
                        }
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
