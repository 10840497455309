import axios from "axios";
import toast from "react-hot-toast";
import { signalApi } from "./ApiConstant";
class service {
  createLog = async (pname, message, action) => {
    const data = { changes: message, action: Number(action), content_type: pname }
    await this.appservice('post', signalApi.auditLog, data)
  }
  appservice = async (method, url, postdata, isHeader) => {
    const header = isHeader ? { headers: { "Content-Type": "multipart/form-data" } } : {};
    let response;
    return axios({
      method: method,
      url: url,
      data: postdata,
      header
    }).then((res) => {
      response = res.data;
      if (!response) response = true;
      return response;
    })
      .catch((e) => {
        let errText = 'Something went wrong...';
        if (e?.response?.status == 401) {
          toast.error("Expired token..");
          window.location.href = '/logout';
          return false;
        }
        if (e?.response?.status == 500) {
          toast.error('Server error');
          return false;
        }
        if (e?.response?.status == 400) {
          if (Object.values(e.response?.data)?.length > 0 && Object.values(e.response?.data)[0]?.length > 0) {
            toast.error(Object.values(e.response?.data)[0][0])
          } else {
            toast.error(e.response?.data);
          }
          return false;
        }
        if (!e?.response?.headers) {
          toast.error(errText);
          return false;
        }
        if (typeof e.response.data === 'object') {
          errText = Object.entries(e.response.data).map(([k, v]) => `${k} : ${v.toString()}`)
        }
        if (e.response && e.response.data && e.response.data.message) errText = e.response.data.message;
        if (e.response && e.response.data && e.response.data.msg) errText = e.response.data.msg;

        toast.error(errText);
        return false;
      })
      .finally(() => {
        return response;
      });
  }
}
export const Service = new service;