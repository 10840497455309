import React, { Component, Fragment } from 'react';
import { AppPagination, AppNodata } from 'components/Reusable';
import { Service } from '../../Appservice';
import { Card } from "reactstrap";
import TabHeader from '../../components/tabHeader';
import Filter from './Filter';
import InlineSearch from './InlineSearch';
import ActionTable from './ActionTable';
import ActionDetails from './ActionDetails';
import ActionStatus from './ActionStatus';
import AddEditActions from './AddEditActions';
import ActionComments from './ActionComments';
import PropTypes from 'prop-types';
import ActionAttachments from './ActionAttachment';
import { signalApi } from '../../ApiConstant';
import { getKeyItem, parsedKeyItem, superuser, user } from '../../AppPermission';
export default class Action extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            header: ["ACTION NAME", "TOPIC NAME", "PRODUCT", "SIGNAL", "ATTACHMENTS", "COMMENTS", "ACTION STATUS", "ASSIGNED TO", "PLANNED COMPLETION DATE", "MANAGE",],
            menuArray: [
                { name: 'Edit', icon: 'fas fa-pen', id: 1 },
                { name: 'Delete', icon: 'fas fa-trash', id: 2 },
                { name: 'Comments', icon: 'fas fa-comment', id: 3 },
                { name: 'Attachments', icon: 'fas fa-paperclip', id: 4 },
            ],
            loading: false,
            isFilter: false,
            userList: [],
            csvFile: "",
            tabHeader: [],
            actionList: [],
            masterActionList: [],
            updateData: null, page: 1, pageNumbers: [], search: '', filteredData: [],
            isAction: false,
            isActionState: false,
            isDetails: false,
            isComment: false,
            isAttach: false,
            assigned_to: getKeyItem(superuser) == "true" ? '' : parsedKeyItem(user).uid
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        await this.getUsers();
        await this.getActions();
        await this.getCsv();
        this.setState({ loading: false });
    }
    getUsers = async () => {
        const user = await Service.appservice('get', `${process.env.REACT_APP_API_ENDPOINT_VC19}users/list_basics`);
        if (user) this.setState({ userList: user })
    }
    getCsv = async () => {
        const { assigned_to } = this.state;
        let queryParam = "";
        if (assigned_to) queryParam = "?assigned_to__id=" + assigned_to;
        const csv = await Service.appservice('get', signalApi.actionsSignalXl + queryParam);
        if (csv?.file_url) this.setState({ csvFile: csv.file_url })
    }
    paginate = async (page) => {
        this.setState({ page });
        this.getActions();
    }
    getActions = async () => {
        const { page, search, assigned_to } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        if (assigned_to) queryParam += "&assigned_to__id=" + assigned_to;
        this.setState({ loading: true })
        const action = await Service.appservice('get', signalApi.topicActions + queryParam);
        if (action?.data) {
            this.setState({
                actionList: action.data, masterActionList: action.data,
                pageNumbers: action.metadata ? Array.from({ length: action.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    render() {
        const { userList, tabHeader, actionList, masterActionList, page, pageNumbers, isFilter,
            menuArray, updateData, isAction, isActionState, csvFile, isDetails, isComment, isAttach, loading } = this.state;
        return (
            <Fragment>
                {isDetails ?
                    <ActionDetails updateData={updateData} onClose={() => { this.setState({ isDetails: false }) }} /> :
                    isActionState ?
                        <ActionStatus updateData={updateData} onClose={(e) => { if (e) { this.getActions(); }; this.setState({ isActionState: false }); }} /> :
                        isAction ?
                            <AddEditActions isEdit updateData={updateData} onClose={(e) => { if (e) { this.getActions(); }; this.setState({ isAction: false }); }} /> :
                            isComment ?
                                <ActionComments updateData={updateData} onClose={(e) => { if (e) { this.getActions(); }; this.setState({ isComment: false }); }} /> :
                                isAttach ?
                                    <ActionAttachments updateData={updateData} onClose={(e) => { if (e) { this.getActions(); }; this.setState({ isAttach: false }); }} /> :
                                    <Fragment>
                                        <Card className='shadow rounded-3'>
                                            <TabHeader
                                                key={'Action'}
                                                Title={'Actions'}
                                                isReport={true}
                                                headers={tabHeader}
                                                onSearch={async (isSearch) => {
                                                    await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                                    await this.getActions();
                                                }}
                                                onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                                onFilter={(isFilter) => this.setState({ isFilter })}
                                                isLoading={loading} csvFile={csvFile} isFilter
                                            />
                                        </Card >
                                        {isFilter && <Filter userList={userList} loading={loading}
                                            onFilter={async (e) => {
                                                console.log('e', e);
                                                await this.setState({ loading: true, page: 1, assigned_to: e, isFilter: e ? true : false });
                                                this.getActions();
                                            }} />}
                                        <Fragment>
                                            <InlineSearch masterActionList={masterActionList} onSearch={actionList => this.setState({ actionList })} onClose={this.props.onClose} />
                                            <ActionTable
                                                tabHeader={tabHeader}
                                                isLoading={loading}
                                                filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                                showMenu={menuArray}
                                                actionList={actionList}
                                                onDetails={(e) => this.setState({ isDetails: true, updateData: e })}
                                                onState={(e) => this.setState({ isActionState: true, updateData: e })}
                                                onDelete={() => { this.getActions() }}
                                                onMenu={(a, b) => {
                                                    this.setState({
                                                        updateData: a,
                                                        isAction: b == 1 ? true : false,
                                                        isComment: b == 3 ? true : false,
                                                        isAttach: b == 4 ? true : false,
                                                    })
                                                }} />
                                        </Fragment>
                                        {loading ? <Fragment></Fragment> :
                                            actionList && actionList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                                nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                                <AppNodata />
                                        }
                                    </Fragment>
                }
            </Fragment>
        )
    }

}
