import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { AppLoader } from "components/Reusable";
import { Service } from "pages/SignalManagement/Scenes/Appservice";
const SidebarContent = props => {
  const ref = useRef();
  //sub-menu paths to check
  const dashboardSubPath = ["action-comments-i"]
  const signalSubPath = ["signals", "edit-signal", "signalsview"]
  const signalGridPath = ["signal-grid"]
  const workFlowSubPath = ["workflow-config"]
  const topicSubPath = ["topic-attachments", "topic-comments", "topics"]
  const actionSubPath = ["action-attachments", "action-comments-view", "actions", "action-comments",]
  const workTeamSubPath = ["team-memebers", "user-action-list",];
  const usersSubPath = ["users"];
  const socialSubPath = ["social-media"];
  const pathName = props.location.pathname;
  const permissions = JSON.parse(localStorage.getItem("permission"))
  const view_signal = permissions["signalapp.view_signal"]
  const view_regulatory = permissions["signalapp.change_reg_intel"]
  const view_measure = permissions["signalapp.view_measure"]
  const view_theme = permissions["signalapp.view_theme"]
  const view_project = permissions["signalapp.view_project"]
  const view_product = permissions["signalapp.view_drug"]
  const view_wconfig = permissions["signalapp.view_workflowconfiguration"]
  const view_user = permissions["auth.view_user"]
  const view_workteam = permissions["signalapp.view_workflowteam"]
  const view_measuretype = permissions["signalapp.view_measuretype"];
  const [isPMIServer, setIsPMIServer] = useState(false);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let isMounted = true;
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        } else if (checkSubPathExist(pathName, items[i].pathname)) {
          let arrayToSearch = []
          if (items[i].pathname === "/user-dashboard") {
            arrayToSearch = dashboardSubPath
          } else if (items[i].pathname === "/signals") {
            arrayToSearch = signalSubPath
          } else if (items[i].pathname === "/signal-grid") {
            arrayToSearch = signalGridPath
          } else if (items[i].pathname === "/workflow-configurations") {
            arrayToSearch = workFlowSubPath
          } else if (items[i].pathname === "/topics") {
            arrayToSearch = topicSubPath
          } else if (items[i].pathname === "/actions") {
            arrayToSearch = actionSubPath
          } else if (items[i].pathname === "/work-team") {
            arrayToSearch = workTeamSubPath
          } else if (items[i].pathname === "/users") {
            arrayToSearch = usersSubPath
          } else if (items[i].pathname === "/chat") {
            arrayToSearch = usersSubPath
          } else if (items[i].pathname === "/social-media") {
            arrayToSearch = socialSubPath
          }
          const isConfirmPath = arrayToSearch.find(
            e => e === pathName.split("/")[1]
          )
          if (isConfirmPath) {
            matchingMenuItem = items[i]
            break
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu();
    // if (pathName != '/chat') localStorage.removeItem('selectedUid')
    // if (process.env.REACT_APP_API_ENDPOINT_VC19 && process.env.REACT_APP_API_ENDPOINT_VC19 === "https://pmiadmin.vc-19.ai/api/v1/vc19/") {
    //   setIsPMIServer(true)
    // } else {
    //   setIsPMIServer(false)
    // }
    return () => { isMounted = false };
  }, [])
  useEffect(() => {
    if (ref)
      ref.current.recalculate()
  })
  function checkSubPathExist(currentPath, pathList) {
    let arrayToSearch = []
    if (pathList === "/user-dashboard") {
      arrayToSearch = dashboardSubPath
    } else if (pathList === "/signals") {
      arrayToSearch = signalSubPath
    } else if (pathList === "/signal-grid") {
      arrayToSearch = signalGridPath
    } else if (pathList === "/workflow-configurations") {
      arrayToSearch = workFlowSubPath
    } else if (pathList === "/topics") {
      arrayToSearch = topicSubPath
    } else if (pathList === "/actions") {
      arrayToSearch = actionSubPath
    } else if (pathList === "/work-team") {
      arrayToSearch = workTeamSubPath
    } else if (pathList === "/users") {
      arrayToSearch = usersSubPath
    } else if (pathList === "/chat") {
      arrayToSearch = usersSubPath
    } else if (pathList === "/social-media") {
      arrayToSearch = socialSubPath
    }
    const result = arrayToSearch.find(
      name => name === currentPath.split("/")[1]
    )
    return result ? true : false
  }
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  //imdrf codings menu
  const isLabCorp = process.env.REACT_APP_API_ENDPOINT_VC19.includes("labcorp");
  //ananlytical menu api
  const [isLoading, setLoading] = useState(false);
  let submenu = JSON.parse(localStorage.getItem("submenu") || "[]");
  const loadData = async () => {
    return await axios.get(`${process.env.REACT_APP_API_ENDPOINT_VC19}menu`).then(response => {
      localStorage.setItem("submenu", JSON.stringify(response.data.source[0]));
      setLoading(false);
      document.body.classList.add('right-bar-enabled');
    }).catch(err => console.log(err));
  }
  useEffect(() => {
    let isMounted = true;
    if (submenu && submenu.length) return;
    setLoading(true)
    loadData();
    return () => { isMounted = false };
  }, [])
  const recursive = (array, index) => {
    return (
      <ul className={"sub-menu  mm-collapse"} aria-expanded='true'>
        {array.map((e1, i1) => {
          const hasActive = e1?.submenu?.length ? "has-arrow" : ""
          const isActive = pathName == `/page-${e1.id}/` ? "active" : ""
          const ismmActive = pathName == `/page-${e1.id}/` ? "mm-active" : ""
          const linkPath = e1?.submenu?.length
            ? "/#"
            : e1?.chart?.url == "/medical-report"
              ? `${e1.chart.url}`
              : `/page-${e1.id}/?url=${e1.chart.url}&type=${e1.chart.type}&title=${e1.name}`

          return (
            <li key={i1} className={`${ismmActive}`} onClick={() => e1.submenus?.length ? '' : createLog(e1.name)}>
              <Link to={linkPath} className={`${hasActive}${isActive}`} style={{ padding: '0px !important' }}>
                <span>{e1.name}</span>
              </Link>
              {e1.submenu && e1.submenu.length ? recursive(e1.submenu, i1 + index) : null}
            </li>
          )
        })}
      </ul>
    )
  }
  const createLog = async (link) => {
    if (link)
      await Service.createLog(link, "Clicked Link Is " + link, 4);
  }
  return (
    <SimpleBar ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {isLoading ? <div style={{ margin: 'auto', width: '80%' }}>
            <div className='m-3'>
              <p className="card-text placeholder-glow">
                <span className="placeholder rounded col-7"></span>
                <span className="placeholder rounded col-4"></span>
                <span className="placeholder rounded col-4"></span>
                <span className="placeholder rounded col-6"></span>
                <span className="placeholder rounded col-8"></span>
              </p>
            </div>
          </div> :
            submenu && submenu.length ? (
              <Fragment>
                {submenu.filter(v => (isLabCorp && v.name !== "Epidemiology") || !isLabCorp).map((e, i) => {
                  const hasActive = e?.submenu?.length ? "has-arrow" : "";
                  const isActive = pathName == `/page-${e.id}/` ? "active" : "";
                  const ismmActive = pathName == `/page-${e.id}/` ? "mm-active" : "";
                  return (
                    <li key={e.id} className={`${ismmActive}`}>
                      <Link
                        to={
                          e?.submenu?.length
                            ? "/#"
                            : `/page-${e.id}/?url=${e.chart.url}&type=${e.chart.type}&title=${e.name}`
                        }
                        onClick={() => createLog(e.name)}
                        aria-expanded="false"
                        className={`${hasActive}${isActive}`}
                      >
                        <i className="fas fa-link font-size-12 bounce-in-right"></i>
                        <span >{e.name}</span>
                      </Link>
                      {e.submenu.length ? recursive(e.submenu, i) : null}
                    </li>
                  )
                })}
              </Fragment>
            ) : null}
          <li>
            <Link to="/#" className="has-arrow">
              <i className="fas fa-broadcast-tower"></i>
              <span>  {props.t("Signal Management")} </span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              {view_wconfig &&
                <li onClick={() => createLog('Dashboard')}>
                  <Link to="/user-dashboard"><i className="fab fa-delicious font-size-12" />{props.t("Dashboard")}</Link>
                </li>
              }
              {view_signal &&
                <li onClick={() => createLog('Signals')}>
                  <Link to="/signals"><i className="fas fa-signal font-size-12" />{props.t("Signals")}</Link>
                </li>
              }
              {view_regulatory && !isPMIServer &&
                <li onClick={() => createLog('Regulatory Intel')}>
                  <Link to="/regulatory-intel"><i className="fas fa-sign-language font-size-12" />{props.t("Regulatory Intel")}</Link>
                </li>
              }
              {view_wconfig &&
                <li onClick={() => createLog('Workflow Configurations')}>
                  <Link to="/workflow-configurations">
                    <i className="fas fa-network-wired font-size-12 " />
                    {props.t("Workflow Configurations")}
                  </Link>
                </li>
              }
              {view_project &&
                <li onClick={() => createLog('Projects')}>
                  <Link to="/projects"><i className="fas fa-project-diagram font-size-12" />{props.t("Projects")}</Link>
                </li>
              }
              {view_product && (
                <li onClick={() => createLog('Products')}>
                  <Link to="/products"><i className="fas fa-vote-yea font-size-12" />{props.t("Products")}</Link>
                </li>
              )}
              {view_user &&
                <li onClick={() => createLog('Dashboard')}>
                  <Link to="/users">
                    <i className="fas fa-users-cog font-size-12" />
                    {props.t("Users")}
                  </Link>
                </li>
              }
              <li onClick={() => createLog('Calendar')}>
                <Link to="/calendar" >
                  <i className="bx bx-notepad"></i>
                  <span> {props.t("Calendar")}</span>
                </Link>
              </li>
              <li>
                <Link to="/imdrf" onClick={() => createLog('IMDRF codings')}>
                  <i className="fas fa-link font-size-12"></i>
                  <span> {props.t("IMDRF codings")}</span>
                </Link>
              </li>
              {view_signal && view_measure && view_theme && view_user &&
                <li onClick={() => createLog('Audit log')}>
                  <Link to="/auditlog">
                    <i className="bx bxl-audible"></i>
                    <span> {props.t("Audit Log")}</span>
                  </Link>
                </li>
              }
            </ul>
          </li>
          {/* show epidemiology */}
          {isLabCorp ?
            isLoading ? <div style={{ margin: 'auto', width: '80%' }}>
              <div className='m-3'>
                <p className="card-text placeholder-glow">
                  <span className="placeholder rounded col-7"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-4"></span>
                  <span className="placeholder rounded col-6"></span>
                  <span className="placeholder rounded col-8"></span>
                </p>
              </div>
            </div> :
              submenu && submenu.length &&
              <Fragment>
                {submenu.filter(v => v.name?.toLowerCase() === "epidemiology").map((e, i) => {
                  const hasActive = e?.submenu?.length ? "has-arrow" : "";
                  const isActive = pathName == `/page-${e.id}/` ? "active" : "";
                  const ismmActive = pathName == `/page-${e.id}/` ? "mm-active" : "";
                  return (
                    <li key={e.id} className={`${ismmActive}`}>
                      <Link
                        to={
                          e?.submenu?.length
                            ? "/#"
                            : `/page-${e.id}/?url=${e.chart.url}&type=${e.chart.type}&title=${e.name}`
                        }
                        onClick={() => createLog(e.name)}
                        aria-expanded="false"
                        className={`${hasActive}${isActive}`}
                      >
                        <i className="fas fa-link font-size-12 bounce-in-right"></i>
                        <span >{e.name}</span>
                      </Link>
                      {e.submenu.length ? recursive(e.submenu, i) : null}
                    </li>
                  )
                })}
              </Fragment> :
            <Fragment></Fragment>}
        </ul>
      </div>
    </SimpleBar>
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(SidebarContent))
