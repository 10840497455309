import React, { Component } from 'react';
import { InputGroup, Form, Row, Input } from "reactstrap";
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
export default class Filter extends Component {
    static get propTypes() {
        return {
            stagList: PropTypes.any,
            onFilter: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            stage: ''
        }
    }
    render() {
        const { stagList, onFilter } = this.props;
        const { stage } = this.state;
        return (
            <div className="d-flex justify-content-center card-body">
                <Form >
                    <Row>
                        <InputGroup>
                            <div className="input-group-text p-1 shadow">Stage</div>
                            <Input className='form-control' onChange={e => this.setState({ stage: e.target.value })} type="select" name="select" value={stage}>
                                <option value="">All</option>
                                {
                                    stagList.map((e, i) => {
                                        return <option value={e.id} key={i}>{e.name}</option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                        <div className='mt-4 text-center'>
                            <AppButton   name="Filter" icon='fas fa-filter ' className="ms-2 me-2" tooltip="Workflow Configuration Filter" onClick={() => { onFilter(stage) }} />
                            <AppButton  icon='fas fa-redo ' className="ms-2 me-2" tooltip="Reset Filter" onClick={() => { onFilter('reset') }} />
                            <AppButton  icon='fas fa-times' className="ms-2 me-2" tooltip="Close Filter" onClick={() => onFilter()} />
                        </div>
                    </Row>
                </Form>
            </div>
        )
    }
}
