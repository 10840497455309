import React, { Component, Fragment } from 'react';
import { Form, InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import WorkflowTeamLead from './WorkflowTeamLead';
export default class InlineSearch extends Component {
    static get propTypes() {
        return {
            workflowteamCopyList: PropTypes.any,
            onSearch: PropTypes.func,
            onClose: PropTypes.func,
            selectedData: PropTypes.any,
            onWorkflowteam: PropTypes.func,
            userList: PropTypes.any,
            isTeamLead: PropTypes.bool,
        };
    }
    constructor() {
        super();
        this.state = {
            inlineSearch: ''
        }
    }
    onInlineSearch = (e) => {
        const searchText = e;
        function matchValue(input) {
            let result = input?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            return result;
        }
        const filteredData = this.props.workflowteamCopyList.filter((row) => {
            return (matchValue(row?.name));
        });
        this.props.onSearch(filteredData);
        this.setState({ inlineSearch: searchText });
    }
    render() {
        const { inlineSearch } = this.state;
        const { isTeamLead, selectedData, userList, onWorkflowteam } = this.props;
        return (
            <Fragment>
                {isTeamLead ?
                    <div className='d-flex justify-content-center align-items-center'>
                        <WorkflowTeamLead
                            selectedData={selectedData}
                            onClose={(e) => { onWorkflowteam(e) }}
                            userList={userList} />
                    </div>
                    :
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form className="row col-lg-4 col-md-4 col-sm-12 col-xs-12" key={'Search'}>
                            <InputGroup>
                                <div className="input-group-text bg-white" onClick={() => { this.setState({ inlineSearch: '' }); this.props.onSearch(this.props.workflowteamCopyList); }}>
                                    {inlineSearch ? <i className='fas fa-times text-danger' /> : <i className='bx bx-search-alt-2 text-danger' />}
                                </div>
                                <input onKeyPress={(e) => { e.key === 'Enter' ? e.preventDefault() : ''; }}
                                    onChange={async (e) => this.onInlineSearch(e.target.value)}
                                    value={inlineSearch} type="text" className="form-control" id="autoSizingInputGroup" placeholder="Search in table..." />
                            </InputGroup>
                        </Form>
                        <AppButton icon='fa fa-eye me-2' classList="btn btn-sm btn-danger " iName={'Close'} name="Users"
                            onClick={this.props.onClose} />
                    </div>
                }
            </Fragment>
        )
    }
}
