import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import axios from "axios"
import { Col, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import logo from "../../../assets/images/logo.svg"
import "./MenuDetails.css"

const Details = () => {
  const { id, name } = useParams()
  const [menuDetails, setMenuDetails] = useState([])
  const pathHistory = useHistory()

  useEffect(() => {
    async function getMenuDetails() {
      return await axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT_DOCUMENT}category/${id}/documents/`
        )
        .then(response => {
          setMenuDetails(response.data)
        })
        .catch(err => console.log(err))
    }
    getMenuDetails()
  }, [])

  //Sub-menu detail modal
  const [subMenuModal, setSubMenuModal] = useState(false)
  const [subMenuData, setSubMenuData] = useState([])
  const [subMenuName, setSubMenuName] = useState("")

  const onClickSubMenu = item => {
    const details = item;
    setSubMenuName(details.title)
    setSubMenuModal(true)
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT_DOCUMENT}document/${details.id}/`
        )
        .then(response => {
          setSubMenuData(response.data)
        })
    } catch (error) {
      alert(error)
    }
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ padding: "2% 4%", rowGap: "100px" }}
      >
        <div>
          <img src={logo} alt="" height="60" />
        </div>
        <div className="icon-container">
          <i
            className="fas fa-arrow-left"
            onClick={() => pathHistory.goBack()}
            style={{
              fontSize: "40px",
              color: "#295050",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="container-header">
          <h2 style={{ fontWeight: "800" }}>{name}</h2>
        </div>
        <div className="container-detail">
          {menuDetails.map((val, index) => (
            <div key={index}>
              <p style={{ fontSize: "16px" }}>{val.description}</p>
              <div className="image-container">
                <img
                  src={val.attachment}
                  style={{
                    // width:"100%",
                    // height:"100%",
                    minWidth: "320px",
                    minHeight: "300px",
                    marginBottom: "1%",
                    objectFit: "contain",
                  }}
                />
              </div>
              {val.documents !== undefined ? (
                <div className="sub-menu-container">
                  {val.documents.map((item, index) => (
                    <div
                      key={index}
                      className="sub-menu-wrapper"
                      onClick={() => onClickSubMenu(item)}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>

      <Modal size="lg" isOpen={subMenuModal} toggle={() => setSubMenuModal(false)} centered={true} className="user_manual_modal">
      <ModalHeader
        toggle={() => setSubMenuModal(false)}
        tag="h4"
        style={{ padding: "16px 40px" }}
      >
        {subMenuName}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12} style={{ padding: "0" }}>
            <div>
              {subMenuData.map((val, index) => (
                <div key={index}>
                  <p style={{ fontSize: "16px" }}>{val.description}</p>
                  <div className="image-container">
                    <img
                      src={val.attachment}
                      style={{
                        width: "100%",
                        height: "100%",
                        minWidth: "320px",
                        minHeight: "300px",
                        marginBottom: "1%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
    </React.Fragment>
  )
}
export default Details
