import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { caseSummery } from '../AppConstants';
export default class SignalCaseSummery extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    render() {
        const { selectedData, onClose } = this.props;
        let iframeURl = caseSummery.default;
        if (selectedData.id === 10905152 || selectedData?.signal_data?.AE.includes("Acute kidney injury"))
            iframeURl = caseSummery.acuteKidney;
        if (selectedData.id === 10898869 || selectedData?.signal_data?.AE.includes("Myocardial ischaemia"))
            iframeURl = caseSummery.myocardial;
        return (
            <Fragment>
                <AppButton tooltip="back" icon='fas fa-arrow-left' name={`Case Summery of ${selectedData.id}`} onClick={onClose} />
                <Row className='mt-3'>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="pt-3">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-12">
                                            <iframe style={{ width: "100%", height: "850px" }} src={iframeURl}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
