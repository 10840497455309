import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CardBody, Table } from 'reactstrap';
export default class WorkFlowActionAttachment extends Component {
  static get propTypes() {
    return {
      workFlowActionDetails: PropTypes.any,
      actionId: PropTypes.number
    };
  }
  getTopicAttach(flow, id) {
    const isAttach = flow.find(x => x.id == id);
    if (isAttach) {
      let getAttach = isAttach.attachData;
      return getAttach && getAttach.length ? getAttach : []
    }
    return []
  }
  render() {
    const { workFlowActionDetails, actionId } = this.props;
    return (
      <div className='mt-2'>
        {
          this.getTopicAttach(workFlowActionDetails, actionId) && this.getTopicAttach(workFlowActionDetails, actionId).length ?
            <div className='table-responsive border rounded'>
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Attachments</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.getTopicAttach(workFlowActionDetails, actionId).map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tr >
                            <td>{e?.title}</td>
                            <td>{e?.description}</td>
                            <td>
                              <a className='badge p-2 bg-warning text-center' href={e?.url_attachment} download={e?.url_attachment} target="_blank" rel="noreferrer"  >
                                <i className='fas fa-download me-2' />
                                {e?.url_attachment ? e?.url_attachment?.split('/').pop() : ''}
                              </a>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
            :
            <CardBody className='text-center'>
              <span className='text-danger shadow p-2 rounded'>No Action Attachments Found..</span>
            </CardBody>
        }
      </div>
    )
  }
}
