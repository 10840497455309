import React, { Component, Fragment } from 'react';
import { Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppShowmore, AppInput, AppButton, AppDropDown } from 'components/Reusable';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';
import { signalApi } from '../ApiConstant';
import DeleteAlert from '../components/DeleteAlert';
import { riskTrackingList, frequencyList } from '../AppConstants';
export default class ProductsTable extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            loading: PropTypes.bool,
            filterStaticHeaders: PropTypes.func,
            menuArray: PropTypes.any,
            productList: PropTypes.any,
            onMenu: PropTypes.func,
            onDelete: PropTypes.func,
            onShowRisk: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            isMenu: false,
            updateData: '',
            isDelete: false,
            isLoading: false,
            showFrequencyEdit: null,
            frequencyValue: "",
        }
    }
    deleteProduct = async () => {
        const { updateData } = this.state;
        this.setState({ isLoading: true })
        const deleteProduct = await Service.appservice('delete', `${signalApi.drugs}/${updateData.id}`);
        if (deleteProduct) toast.success('Project deleted successfully!...')
        this.setState({ isDelete: false, updateData: '', isLoading: false });
        this.props.onDelete();
    }
    showRiskTrackingModal = async (e) => {
        let value = e.risk_categorisation ? e.risk_categorisation : "";
        let newList = riskTrackingList.map(v => ({ ...v, value: false }));
        this.props.onShowRisk({ riskTrackingValue: value, riskCheckList: newList, selectedData: e });
    }
    showFrequency = async (e) => {
        let value = e.review_frequency ? e.review_frequency : "";
        this.setState({ frequencyValue: value, showFrequencyEdit: e });
    }
    hideFrequency = async () => {
        this.setState({ frequencyValue: "", showFrequencyEdit: null });
    }
    handleOnChangeFrequency = async (e, row) => {
        const selected = e.target.value ? e.target.value : null;
        const json = {
            review_frequency: selected
        }
        const updateProducts = await Service.appservice('patch', `${signalApi.drugs}/${row.id}`, json);
        if (updateProducts) {
            this.setState({ frequencyValue: "", showFrequencyEdit: null })
            toast.success('Project Review Frequency updated successfully!...');
            this.props.onDelete();
        }
    }
    render() {
        const { tabHeader, filterStaticHeaders, menuArray, productList, onMenu, loading } = this.props;
        const { isMenu, updateData, isDelete, isLoading, frequencyValue, showFrequencyEdit } = this.state;
        return (
            <div className='table-responsive shadow rounded-3 mt-3'>

                <Table className="table-hover table-borderless mb-0">
                    <thead className='shadow'>
                        <tr>
                            <th className="text-center">
                                <i className='fas fa-bars text-info'></i>
                            </th>
                            {
                                tabHeader.map((e, i) => {
                                    return (
                                        e.isChecked && <th key={i}>{e.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody >
                        {(isLoading || loading) ?
                            <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                            :
                            productList.map((e, i) => {
                                return (
                                    isDelete && updateData.id == e.id ?
                                        <td colSpan={15} key={i} className='shadow border border-danger'>
                                            <DeleteAlert loading={loading || isLoading} title="Project" onYes={this.deleteProduct}
                                                onNo={() => { this.setState({ isDelete: false, updateData: '' }) }} />
                                        </td>
                                        :
                                        <tr key={i} >
                                            <td className="text-center">
                                                <AppDropDown
                                                    menuArray={menuArray}
                                                    isMenu={isMenu}
                                                    onToggle={() => { if (e.id == updateData.id) this.setState({ isMenu: !isMenu, updateData: e }); }}
                                                    onClick={() => { this.setState({ isMenu: true, updateData: e }); }}
                                                    rowId={e.id}
                                                    selectedId={updateData.id}
                                                    onClose={(id) => {
                                                        this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                                                        onMenu(updateData, id);
                                                    }}
                                                />
                                            </td>
                                            <td className={filterStaticHeaders(0) ? null : 'd-none'}>
                                                <AppShowmore text={e?.name} />
                                            </td>
                                            <td className={filterStaticHeaders(1) ? null : 'd-none'}><AppShowmore text={e?.manufacturer} /></td>
                                            <td className={filterStaticHeaders(2) ? null : 'd-none'}><AppShowmore text={e?.trade_name} /></td>
                                            <td className={filterStaticHeaders(3) ? null : 'd-none'}><AppShowmore text={e?.atc_code} /></td>
                                            <td className={filterStaticHeaders(4) ? null : 'd-none'}><AppShowmore text={e?.description} /></td>
                                            <td className={filterStaticHeaders(5) ? null : 'd-none'}><span className='badge badge-soft-danger p-2'>{e?.is_class_drug === true ? "Yes" : "No"}</span></td>
                                            <td className={filterStaticHeaders(6) ? null : 'd-none'}>
                                                <div>
                                                    {e.risk_categorisation ? e.risk_categorisation : ""}
                                                    <span className='badge badge-soft-danger shadow p-2  mx-2 rounded' role={'button'} onClick={() => this.showRiskTrackingModal(e)}>
                                                        <i className='fas fa-pencil-alt text-danger'></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className={filterStaticHeaders(7) ? null : 'd-none'}>
                                                {showFrequencyEdit && showFrequencyEdit?.id === e.id ?
                                                    <Col lg={10} md={3} sm={6} xs={12} className='d-inline-flex flex-row align-items-baseline'>
                                                        <AppInput type="select" value={frequencyValue}
                                                            error="Risk is required" onChange={v => { this.handleOnChangeFrequency(v, e) }} iArray={frequencyList} selectType='valuepair' />
                                                        <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={this.hideFrequency} />
                                                    </Col> :
                                                    <div>{e.review_frequency ? e.review_frequency : ""}
                                                        <span className='badge badge-soft-danger shadow p-2 mx-2 rounded' role={'button'} onClick={() => this.showFrequency(e)}>
                                                            <i className='fas fa-pencil-alt text-danger'></i>
                                                        </span>
                                                    </div>}
                                            </td>
                                        </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
