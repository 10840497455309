import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
const UserInactivityDetector = props => {
    const { onActive, onIdle, timeOutInterval } = props
    let timer = undefined;
    const events = ['click', 'scroll', 'load', 'keydown', 'mousemove', 'wheel']
    const eventHandler = (eventType) => {
        // console.log(eventType)
        localStorage.setItem('lastInteractionTime', moment())
        if (timer) {
            onActive && onActive();
            startTimer();
        }
    };
    useEffect(() => {
        addEvents();
        return (() => {
            removeEvents();
        })
    }, [])
    const startTimer = () => {
        timer = setTimeout(() => {

            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let tout = timeOutInterval ? timeOutInterval : 60 * 10000;

            if (diff._milliseconds < tout) {
                startTimer();
                onActive();
            } else {
                onIdle && onIdle();
            }
        }, timeOutInterval ? timeOutInterval : 60 * 10000)


    }
    const addEvents = () => {
        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler)
        })
        startTimer();
    }
    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler)
        })
    };

    return (
        <div></div>
    )
};

UserInactivityDetector.propTypes = {
    onActive: PropTypes.any,
    onIdle: PropTypes.any,
    timeOutInterval: PropTypes.any
}

export default UserInactivityDetector;
