import React, { Component, Fragment } from 'react';
import { Table, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppShowmore, AppPagination } from 'components/Reusable';
import { setStatus } from '../AppConstants';
export default class SignalTable extends Component {
  static get propTypes() {
    return {
      loading: PropTypes.bool,
      filterStaticHeaders: PropTypes.func,
      signalList: PropTypes.any,
      pageNumbers: PropTypes.any,
      page: PropTypes.number,
      paginate: PropTypes.func
    };
  }
  constructor() {
    super();
    this.state = {
      header: ["SRC IDENTIFIER", "DRUG", "AE", "MODEL", "IS IME", "IS DME",
        "VALIDATION", "PRIORITY", "EVALUATION", "RISK CATEGORISATION", "RECOMMENDATION"],
    }
  }
  render() {
    const { loading, signalList, page, pageNumbers, paginate } = this.props;
    const { header } = this.state;
    return (
      <div >
        <div className='shadow table-responsive rounded-3'>
          <Table className="table-hover table-borderless mb-0">
            <thead className='shadow'>
              <tr> {header.map((e, i) => <th key={i}>{e}</th>)}</tr>
            </thead>
            <tbody>
              {loading ?
                <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                :
                signalList.map((e, i) => {
                  const duplicateClass = e.is_duplicated ? 'shadow-sm bg-danger  text-white' : '';
                  const removeBorder = e.is_duplicated ? 'rounded-0' : '';
                  return (
                    <tr key={i} className={duplicateClass}>
                      <td><AppShowmore text={e?.signal_data?.SRC_IDENTIFIER}  /></td>
                      <td ><AppShowmore text={e?.signal_data?.DRUG}/></td>
                      <td ><AppShowmore text={e?.signal_data?.AE} width={100} /></td>
                      <td ><AppShowmore text={e?.parent_model} width={100} /></td>
                      <td className={'text-center'}><AppShowmore text={e?.signal_data?.PRESENT_IN_IME_LIST} width={100} /></td>
                      <td className={'text-center'}><AppShowmore text={e?.signal_data?.PRESENT_IN_DME_LIST}  width={100} /></td>
                      <td className={'text-center'}>
                        <span className='fw-bold ms-2'>
                          {e?.validation_status ?
                            <span className='text-success'>
                              {setStatus(e?.validation_status, 'Validation')}
                            </span> :
                            <span className="badge badge-soft-danger me-1 p-2">
                              <i className="fas fa-ban me-2 " /> Not Added
                            </span>}
                        </span>
                      </td>
                      <td className={'text-center'}>
                        <span className='fw-bold ms-2'>
                          {e.priority
                            ? <span className={e?.priority?.name?.includes('High') ? 'text-danger' : e?.priority?.name?.includes('Low') ? 'text-dark' : 'text-warning'}>
                              {e?.priority?.name}
                            </span>
                            :
                            <span className="badge badge-soft-danger me-1 p-2">
                              <i className="fas fa-ban me-2 " /> Not Added
                            </span>
                          }
                        </span>
                      </td>
                      <td className={'text-center'}>
                        <span className='fw-bold ms-2'>
                          {
                            e.evaluation_confirmation_status ?
                              <span className='text-success'>
                                {setStatus(e?.evaluation_confirmation_status, 'Evaluvate')}
                              </span>
                              :
                              <span className="badge badge-soft-danger me-1 p-2">
                                <i className="fas fa-ban me-2 " /> Not Added
                              </span>
                          }
                        </span>
                      </td>
                      <td className={'text-center'}>
                        <span className='fw-bold ms-2'>
                          {e.risk_categorisation ?
                            <span className={e?.risk_categorisation == 'NIIR' ? 'text-danger' : e?.risk_categorisation == 'NIR' ? 'text-warning' : 'text-info'}>
                              {setStatus(e?.risk_categorisation, 'Risk')}
                            </span>
                            :
                            <span className="badge badge-soft-danger me-1 p-2">
                              <i className="fas fa-ban me-2 " /> Not Added
                            </span>}
                        </span>

                      </td>
                      <td className={`text-center ${removeBorder}`}>
                        <span className='fw-bold ms-2'>
                          {e.recommendation_status ?
                            <span className={e?.recommendation_status == 'PU' ? 'text-danger' : e?.recommendation_status == 'RU' ? 'text-warning' : 'text-info'}>
                              {setStatus(e?.recommendation_status, 'Recomment')}
                            </span> :
                            <span className="badge badge-soft-danger me-1 p-2">
                              <i className="fas fa-ban me-2 " /> Not Added
                            </span>
                          }
                        </span>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          {
            !loading ? signalList && signalList.length ?
              <AppPagination
                previousPage={e => { paginate(e) }}
                nextPage={e => { paginate(e) }}
                selectedPage={e => { paginate(e) }}
                page={page}
                pageNumbers={pageNumbers} /> :
              <CardBody className='text-center'>
                <span className='text-warning shadow p-2 rounded'>No records found..</span>
              </CardBody>
              :
              <Fragment></Fragment>
          }
        </div >
      </div>
    )
  }
}
