import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { Component } from "react";
import { Row, Col,  Card,  Form,  } from "reactstrap";
import { withRouter } from "react-router-dom";
import Bg from "assets/images/login2.jpg";
import { AppButton, AppInput } from "components/Reusable";
import { Service } from "pages/SignalManagement/Scenes/Appservice";
import axios from "axios";
import { AppApi } from "Appfirebaseapi";
import SimpleBar from "simplebar-react";
import { saveUser } from 'store/actions';
import { connect } from "react-redux";
import { compose } from "redux";
import toast from "react-hot-toast";
import { userApi } from "pages/SignalManagement/Scenes/ApiConstant";

import fortreaLogo from "assets/images/fortreaLogo.png";
import Logo from "assets/logo3a.png";
const server = process.env.REACT_APP_SERVER;
let image = Logo;
// if (server == "labcorp") { image = fortreaLogo; }

class Login extends Component {
  static get propTypes() {
    return {
      history: PropTypes.any,
      saveUser: PropTypes.func,
    };
  }
  constructor() {
    super();
    this.state = {
      isLoading: false,
      postData: {
        username: "", password: ""
      },
      postErr: {
        username: false, password: false, login: false
      },
      settings: null,
      attempt: 0,
      timer: 0
    }
  }
  componentDidMount = async () => {
    const isLoggedin = localStorage.getItem("authToken");
    if (isLoggedin) {
      this.props.history.push({
        pathname: '/user-dashboard',
        state: null
      });
    }
    await this.getSettings();
  }
  getSettings = async () => {
    const settings = await Service.appservice('get', userApi.settings);
    if (settings) this.setState({ settings })
  }
  setValue = async (i, e) => {
    const value = i.target.value;
    await this.setState({ postData: { ...this.state.postData, [e]: value } });
    if (!value) this.setError(e);
    else this.removeErr(e);
  };
  setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  interval;
  startTimer = () => {
    this.interval = setInterval(
      () => {
        this.setState((prevState) => ({ timer: prevState.timer == 0 ? 30 : prevState.timer - 1 }));
      },
      1000
    );
  }
  clearTimer = () => {
    clearInterval(this.interval);
    this.setState({ attempt: 0, timer: 0, isLoading: false })
  }
  onSubmit = async () => {
    const { postData, attempt } = this.state;
    if (!postData.username) return this.setError('username');
    if (!postData.password) return this.setError('password');
    this.setState({ isLoading: true });
    axios.post(userApi.login, postData).then(async (res) => {
      this.props.saveUser(postData)
      const result = res.data.data;
      // await axios.post(`${process.env.REACT_APP_API_ENDPOINT_VC19}user/update_single_session_login`,
      //   { single_session_login: true }, { headers: { Authorization: `Token ${result.token}` } });
      const authUser = { "uid": result.user_id, "username": result.username, "email": result.email }
      AppApi.createSingleUser(authUser);
      localStorage.setItem("superUser", result.is_super_user);
      localStorage.setItem("authUser", JSON.stringify(authUser));
      localStorage.setItem('authToken', result.token);
      localStorage.setItem('powerBIUrl', result.url);
      localStorage.setItem('sourceIdentifier', result.default_SRC);
      const permissions = result.permissions
      localStorage.setItem("permission", JSON.stringify(permissions));
      localStorage.setItem("oneDriveClientId", result.onedrive_data.client_id);
      const view_signal = permissions["signalapp.view_signal"];
      this.props.history.push({
        pathname: view_signal ? '/user-dashboard' : '/actions',
        state: null
      });
      this.setState({ isLoading: false });
      Service.createLog('Login Event', "Logged in user is " + result.username, 5);
    }).catch(error => {
      if (attempt >= 2) return this.startTimer()
      if (error.response?.data?.status == 'error') {
        this.setState({ isLoading: false });
        toast.error(error.response?.data?.message)
        return;
      }
      toast.error(error?.response?.data?.detail)
      this.setState({ isLoading: false, attempt: attempt + 1 });
    })
  }
  handleKeyPress = (event) => {
    const { postData } = this.state;
    if (event.key === 'Enter' && (postData.username && postData.password)) this.onSubmit()
  }
  render() {
    const { postData, postErr, isLoading, settings, attempt, timer } = this.state;
    if (timer == 1) this.clearTimer()
    return (
      <React.Fragment>
        <MetaTags>
          <title>Login | 3 Analytics </title>
        </MetaTags>
        <div style={{ background: '#eeeee' }}>
          <SimpleBar>
            <Row style={{ height: '100vh' }}>
              <Col lg={4} >
                <div className="d-flex flex-column  align-items-center justify-content-center h-100 ">
                  <img src={image} className="w-10 h-10 position-absolute top-0 start-0 m-5  z-index-2" />
                  {/* <img src={LoginImage} className="w-10 h-10 d-block d-sm-block d-md-block d-lg-none" style={{ width: '50%' }} /> */}
                  <Card className="p-5  rounded">
                    <span className="shadow p-2 rounded mb-3 text-center text-danger">Login..</span>
                    <Form >
                      <AppInput onKeyPress={this.handleKeyPress} label="Username *" error="Username is required" onChange={e => { this.setValue(e, 'username') }} value={postData.username} isInvalid={postErr.username} />
                      <AppInput onKeyPress={this.handleKeyPress} type="password" label="Password *" error="Password is required" onChange={e => { this.setValue(e, 'password') }} value={postData.password} isInvalid={postErr.password} isPasswordShow={true} />
                      <div className="row"><AppInput type="checkbox" label="Remember me  " value={false} /></div>
                      <div className="mt-2 text-center">
                        {timer > 0 ?
                          <span className="shadow p-2 rounded text-primary">
                            You have tried maximum login attempts. Hold on for
                            <span className="text-dark shadow me-2 ms-2 badge badge-soft-dark p-2">{timer}</span>
                            secs..
                          </span>
                          :
                          <AppButton name='Log In' icon='fas fa-check' onClick={this.onSubmit} disabled={timer > 0 || isLoading} loader />
                        }
                      </div>
                    </Form>
                  </Card>
                </div>
              </Col>
              <Col lg={8} className="d-none d-lg-block" >
                <img src={Bg} className="w-100 h-100 rounded shadow" />
              </Col>
            </Row>
          </SimpleBar>
        </div>
      </React.Fragment>
    )
  }
}
export default compose(connect(null, { saveUser }), withRouter)(Login)
