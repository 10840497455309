import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import { Row, Col, CardBody, CardText, Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import { Service } from '../Appservice';
import ViewSignalStatus from './ViewSignalStatus';
import SignalValidation from './SignalValidation';
import SignalValidationGrid from './SignalValidationGrid';
import SignalEvaluationGrid from './SignalEvaluationGrid';
import SignalRecommentationInfo from './SignalRecommentationInfo';
import SignalHistoryGrid from './SignalHistoryGrid';
import SignalEvaluation from './SignalEvaluation';
import SignalValidationActionChangeStatus from './SignalValidationActionChangeStatus';
import SignalValidationActionComments from './SignalValidationActionComments';
import SignalValidationActionAttachment from './SignalValidationActionAttachment';
import SignalEvaluationActionChangeStatus from './SignalEvaluationActionChangeStatus';
import SignalEvaluationActionComments from './SignalEvaluationActionComments';
import SignalEvaluationActionAttachment from './SignalEvaluationActionAttachment';
import SignalRecommentation from './SignalRecommentation';
import SignalValidationActionView from './SignalValidationActionView';
import SignalEvaluationActionView from './SignalEvaluationActionView';
import FullViewSignal from './FullviewSignal';
import { signalApi } from '../ApiConstant';

export default class ViewSignals extends PureComponent {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            workFlowTeamList: PropTypes.any,
            workFlowEvaluationList: PropTypes.any,
            workFlowValidationList: PropTypes.any,
            evaluationTopicList: PropTypes.any,
            validationTopicList: PropTypes.any,
            projectsList: PropTypes.any,
            onAddValidationTopic: PropTypes.func,
            onMarkValidation: PropTypes.func,
            onMarkEvaluation: PropTypes.func,
            onAddEvaluationTopic: PropTypes.func,
            onRecommendation: PropTypes.func,
            selectedStatus: PropTypes.number
        };
    }
    constructor(props) {
        super(props);
        const { selectedStatus } = this.props;
        this.state = {
            signalHistory: [],
            showValidation: selectedStatus == 1 || selectedStatus == 2 || selectedStatus == 3 ? true : false,
            showEvaluation: selectedStatus == 4 || selectedStatus == 5 || selectedStatus == 6 ? true : false,
            showHistory: false,
            activeIndex: 1,
            signalTab: [
                { id: 1, name: 'Validation', icon: 'fas fa-check' },
                { id: 2, name: 'Evaluation', icon: 'fas fa-file-signature' },
                { id: 3, name: 'Recommentation', icon: 'fas fa-thumbs-up' },
                { id: 4, name: 'History', icon: 'fas fa-history' },
            ],
            page: 1,
            signalInfoList: [],
            signalInfoTopicList: [],
            signalInfoActionList: [],
            signalValidationActionList: [],
            signalEvaluationActionList: [],
            issignalValidationActionChangeStatus: false,
            issignalValidationActionComment: false,
            issignalValidationActionAttachment: false,
            updateActionData: '',
            issignalEvaluationActionChangeStatus: false,
            issignalEvaluationActionComment: false,
            issignalEvaluationActionAttachment: false,
            isSignalValidationActionView: false,
            isSignalEvaluationActionView: false,
            ViewActionData: '',
            showFullView: false
        }
    }
    componentDidMount = async () => {
        this.getSignalHistory();
        this.getSelectedSignalInfo();
    }
    getSignalHistory = async () => {
        const signalHistory = await Service.appservice('get', `${signalApi.signalStatusHistory}?signal=${this.props.selectedData.id}`);
        if (signalHistory) this.setState({ signalHistory })
    }
    getSelectedSignalInfo = async () => {
        const { selectedData } = this.props;
        const { page } = this.state;
        let queryParam = "?page=" + page;
        this.setState({ loading: true });
        //get signal added by selected signal id
        const signalInfo = await Service.appservice('get', `${signalApi.signals}/${selectedData.id}/theme_signals${queryParam}`);
        if (signalInfo?.data) {
            this.setState({
                signalInfoActionList: [],
                signalInfoList: signalInfo,
                pageNumbers: signalInfo.metadata ? Array.from({ length: signalInfo.metadata.total_pages }, (v, k) => k + 1) : [],
            });
            let wid = signalInfo?.data;
            if (wid?.length) {
                await this.getWorkFlowConfig(wid[0].workflow_config.id);
                wid.forEach(async e => { await this.getSignalTopics(e.id) }); //get all topics added under signals
            }
            else this.setState({ loading: false });
        }
        else this.setState({ loading: false });
    }
    getWorkFlowConfig = async (id) => {
        const workFlowConfig = await Service.appservice('get', `${signalApi.project}?workflow_configuration=${id}`);
        await this.setState({ workFlowConfig })
    }
    getSignalTopics = async (id) => {
        let updateData, commentData, attachData, measureData = [];
        updateData = await Service.appservice('get', `${signalApi.topicSignals}/${id}`) || [];
        commentData = await Service.appservice('get', `${signalApi.topics}/${id}/comments`) || [];
        attachData = await Service.appservice('get', `${signalApi.topicAttachmentGet}/${id}`) || [];
        measureData = await Service.appservice('get', `${signalApi.topicSignals}/${id}/theme_measures`) || [];
        if (measureData?.data?.length)
            measureData.data.forEach(async e => { await this.getSignalActions(e.id); }); // get all actions added under signals
        else { this.setState({ loading: false }); }
        this.setState({ signalInfoTopicList: [...this.state.signalInfoTopicList, { id, updateData, commentData, attachData, measureData }] })
    }
    getSignalActions = async (id) => {
        console.log('3');
        let updateData, commentData, attachData, signalActionList = [];
        updateData = await Service.appservice('get', `${signalApi.topicActions}/${id}`) || [];
        commentData = await Service.appservice('get', `${signalApi.topicActions}/${id}/comments`) || [];
        attachData = await Service.appservice('get', `${signalApi.actionAttachmentsGet}/${id}`) || [];
        signalActionList = [...this.state.signalInfoActionList, { id, updateData, commentData, attachData }];
        const signalValidationActionList = signalActionList?.filter(e => e?.updateData?.workflow_config?.stage == "val")?.sort((a, b) => b.id - a.id);
        const signalEvaluationActionList = signalActionList?.filter(e => e?.updateData?.workflow_config?.stage == "ev")?.sort((a, b) => b.id - a.id);
        this.setState({ signalInfoActionList: [...this.state.signalInfoActionList, { id, updateData, commentData, attachData }], signalValidationActionList, signalEvaluationActionList, loading: false });
    }
    render() {
        const { signalHistory, showEvaluation, showHistory, showValidation, signalTab, activeIndex, signalInfoList,
            signalInfoActionList, signalInfoTopicList, loading, signalValidationActionList, signalEvaluationActionList,
            issignalValidationActionChangeStatus, updateActionData, issignalValidationActionComment, issignalValidationActionAttachment,
            issignalEvaluationActionChangeStatus, issignalEvaluationActionComment, issignalEvaluationActionAttachment,
            isSignalValidationActionView, isSignalEvaluationActionView, ViewActionData, showFullView } = this.state;
        const { onClose, selectedData, workFlowTeamList, workFlowValidationList, workFlowEvaluationList, validationTopicList, evaluationTopicList,
            projectsList, onAddValidationTopic, onMarkValidation, onMarkEvaluation, onAddEvaluationTopic, onRecommendation, selectedStatus } = this.props;
        let disableValidation = selectedData?.validation_status ? true : false;
        let disableEvaluation = selectedData.validation_status != 'V' || selectedData?.evaluation_confirmation_status ? true : false;
        let disableRecommentation = !selectedData.validation_status || selectedData?.recommendation_status ? true : false;
        if (!selectedData.validation_topic_added && selectedData?.validation_status) { disableValidation = true; }
        if (!selectedData.evaluation_topic_added && selectedData?.evaluation_confirmation_status) { disableEvaluation = true; }
        return (
            <Fragment>
                {showFullView ?
                    <FullViewSignal
                        selectedData={selectedData}
                        signalHistory={signalHistory}
                        onClose={() => this.setState({ showFullView: false })} /> :
                    showValidation ?
                        <SignalValidation
                            selectedStatus={selectedStatus}
                            selectedData={selectedData}
                            onClose={(e) => {
                                this.setState({ showValidation: false });
                                if (e) { this.getSelectedSignalInfo(); onMarkValidation() }
                                if (selectedStatus) onClose();
                            }}
                            workFlowTeamList={workFlowTeamList}
                            workFlowValidationList={workFlowValidationList}
                            validationTopicList={validationTopicList}
                            projectsList={projectsList}
                            onAddValidationTopic={onAddValidationTopic}
                            onMarkValidation={() => { onMarkValidation(); this.getSignalHistory(); if (selectedStatus) onClose(); }} />
                        :
                        showEvaluation ?
                            <SignalEvaluation
                                selectedStatus={selectedStatus}
                                selectedData={selectedData}
                                onClose={(e) => {
                                    this.setState({ showEvaluation: false });
                                    if (e) { this.getSelectedSignalInfo(); onMarkEvaluation() }
                                    if (selectedStatus) onClose();
                                }}
                                workFlowTeamList={workFlowTeamList}
                                projectsList={projectsList}
                                workFlowEvaluationList={workFlowEvaluationList}
                                evaluationTopicList={evaluationTopicList}
                                onAddEvaluationTopic={onAddEvaluationTopic}
                                onMarkEvaluation={() => { onMarkEvaluation(); this.getSignalHistory(); }}
                            />
                            :
                            <Fragment>
                                <div className='d-sm-flex justify-content-md-between text-center'>
                                    <span>
                                        <AppButton icon='fas fa-arrow-left' name={`View details of ${selectedData?.id}`} tooltip="Back to signal list" onClick={onClose} />
                                    </span>
                                    <div className='d-inline ms-2 ms-sm-0'>
                                        {!showHistory &&
                                            <AppButton icon='fas fa-eye' className="me-2 mt-2 mt-sm-0" name={'History'} disabled={!signalHistory?.data?.length}
                                                onClick={async () => { this.setState({ showHistory: true }) }} />}
                                        <AppButton icon='fas fa-expand' className="me-2 mt-2 mt-sm-0" tooltip={'Fullscreen View'}
                                            onClick={async () => { this.setState({ showFullView: true }) }} />
                                    </div>
                                </div>
                                <Row >
                                    <Col lg={12}>
                                        <ViewSignalStatus selectedData={selectedData} />
                                        {showHistory ?
                                            <SignalHistoryGrid signalHistory={signalHistory} selectedData={selectedData} onClose={async () => { this.setState({ showHistory: false }) }} /> :
                                            isSignalValidationActionView ?
                                                <SignalValidationActionView ViewActionData={ViewActionData} onClose={() => this.setState({ ViewActionData: '', isSignalValidationActionView: false })} /> :
                                                isSignalEvaluationActionView ?
                                                    <SignalEvaluationActionView ViewActionData={ViewActionData} onClose={() => this.setState({ ViewActionData: '', isSignalEvaluationActionView: false })} /> :
                                                    issignalValidationActionChangeStatus ?
                                                        <SignalValidationActionChangeStatus
                                                            isEdit
                                                            updateData={updateActionData?.updateData}
                                                            onClose={(e) => {
                                                                this.setState({ issignalValidationActionChangeStatus: false });
                                                                if (e) { this.getSelectedSignalInfo(); }
                                                            }} /> :
                                                        issignalValidationActionComment ?
                                                            <SignalValidationActionComments
                                                                updateData={updateActionData}
                                                                onClose={(e) => {
                                                                    this.setState({ issignalValidationActionComment: false });
                                                                    if (e) { this.getSelectedSignalInfo(); }
                                                                }} /> :
                                                            issignalValidationActionAttachment ?
                                                                <SignalValidationActionAttachment
                                                                    updateData={updateActionData}
                                                                    onClose={(e) => {
                                                                        this.setState({ issignalValidationActionAttachment: false });
                                                                        if (e) { this.getSelectedSignalInfo(); }
                                                                    }} /> :
                                                                issignalEvaluationActionChangeStatus ?
                                                                    <SignalEvaluationActionChangeStatus
                                                                        isEdit
                                                                        updateData={updateActionData?.updateData}
                                                                        onClose={(e) => {
                                                                            this.setState({ issignalEvaluationActionChangeStatus: false });
                                                                            if (e) { this.getSelectedSignalInfo(); }
                                                                        }} /> :
                                                                    issignalEvaluationActionComment ?
                                                                        <SignalEvaluationActionComments
                                                                            updateData={updateActionData}
                                                                            onClose={(e) => {
                                                                                this.setState({ issignalEvaluationActionComment: false });
                                                                                if (e) { this.getSelectedSignalInfo(); }
                                                                            }} /> :
                                                                        issignalEvaluationActionAttachment ?
                                                                            <SignalEvaluationActionAttachment
                                                                                updateData={updateActionData}
                                                                                onClose={(e) => {
                                                                                    this.setState({ issignalEvaluationActionAttachment: false });
                                                                                    if (e) { this.getSelectedSignalInfo(); }
                                                                                }} /> :
                                                                            <Row>
                                                                                <Col className='overflow-auto'>
                                                                                    <SignalValidationGrid
                                                                                        onShowValidation={async () => { this.setState({ showValidation: true }) }}
                                                                                        disableAddValidation={disableValidation}
                                                                                        loading={loading}
                                                                                        signalValidationActionList={signalValidationActionList}
                                                                                        selectedData={selectedData}
                                                                                        onAddValidation={() => this.setState({ showValidation: true })}
                                                                                        onMarkValidation={() => { onMarkValidation(); this.getSignalHistory(); }}
                                                                                        onMenu={(a, b) => {
                                                                                            this.setState({
                                                                                                updateActionData: a,
                                                                                                issignalValidationActionChangeStatus: b == 1 ? true : false,
                                                                                                issignalValidationActionComment: b == 2 ? true : false,
                                                                                                issignalValidationActionAttachment: b == 3 ? true : false,
                                                                                            })
                                                                                        }}
                                                                                        onActionView={(e) => { this.setState({ isSignalValidationActionView: true, ViewActionData: e }) }} />

                                                                                </Col>
                                                                                <Col className='overflow-auto'>
                                                                                    <SignalEvaluationGrid
                                                                                        onShowEvaluation={async () => { this.setState({ showEvaluation: true }) }}
                                                                                        disableAddEvaluation={disableEvaluation}
                                                                                        loading={loading}
                                                                                        selectedData={selectedData}
                                                                                        signalEvaluationActionList={signalEvaluationActionList}
                                                                                        onMarkEvaluation={() => { onMarkEvaluation(); this.getSignalHistory(); }}
                                                                                        onMenu={(a, b) => {
                                                                                            this.setState({
                                                                                                updateActionData: a,
                                                                                                issignalEvaluationActionChangeStatus: b == 1 ? true : false,
                                                                                                issignalEvaluationActionComment: b == 2 ? true : false,
                                                                                                issignalEvaluationActionAttachment: b == 3 ? true : false,
                                                                                            })
                                                                                        }}
                                                                                        onActionView={(e) => { this.setState({ isSignalEvaluationActionView: true, ViewActionData: e }) }} />
                                                                                </Col>
                                                                                <Col >
                                                                                    <SignalRecommentationInfo
                                                                                        selectedData={selectedData}
                                                                                        isLoading={loading}
                                                                                        disableRecommentation={disableRecommentation}
                                                                                        onAddRecommndation={() => { onRecommendation() }} />
                                                                                </Col>
                                                                            </Row>
                                        }

                                    </Col>
                                </Row>
                            </Fragment>
                }
            </Fragment>
        )
    }
}
