import React, {  Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../../Appservice';
import { Col, Form, Row } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { signalApi } from '../../ApiConstant';
export default class WorkflowteamAdd extends PureComponent {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      onClose: PropTypes.func,
      userList: PropTypes.any,
    };
  }
  constructor(props) {
    super(props);
    const { selectedData } = props;
    this.state = {
      postData: {
        name: selectedData?.name ?? '',
        users: selectedData?.users ?? [],
        team_lead: selectedData?.team_lead ?? null,
        id: selectedData?.id ?? 0,
      },
      postErr: { name: false, users: false },
      loading: false,
    }

  }
  setValue = async (i, e, isArray) => {
    const value = isArray ? Array.from(i.target.selectedOptions, option => option.value) : i.target.value;
    await this.setState({ postData: { ...this.state.postData, [e]: value } });
    if (!value) this.setError(e);
    else this.removeErr(e);
  };
  setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  onWorkflowteam = async () => {
    const { postData } = this.state;
    const { selectedData, onClose } = this.props;
    if (!postData.name) return this.setError('name');
    if (postData.users && !postData.users.length) return this.setError('users');
    this.setState({ loading: true });
    const postAction = selectedData?.id ?
      await Service.appservice('patch', `${signalApi.workflowTeam}/${selectedData.id}`, postData) :
      await Service.appservice('post',signalApi.workflowTeam, postData);
    if (postAction) {
      toast.success(`User ${selectedData?.id ? 'Updated' : 'Created'} Successfully !...`);
      onClose(true);
    }
    this.setState({ loading: false });
  }
  render() {
    const { postData, postErr, loading } = this.state;
    const { onClose, userList, selectedData } = this.props;
    return (
      <div className='d-flex justify-content-sm-center mb-4'>
        <Fragment>
          <Form>
            <Row>
              <Col lg={6} md={6} sm={6} xs={12}>
                <AppInput label="Workflow Team Name *" error="Workflow Team Name is required" onChange={e => { this.setValue(e, 'name') }} value={postData.name} isInvalid={postErr.name} />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12}>
                <AppInput isMultiple label="Select Team Members  *" type="select" value={postData.users} isInvalid={postErr.users}
                  error="Team members is required" onChange={e => { this.setValue(e, 'users', true) }} iArray={userList} selectType={'firstnamepair'} />
              </Col>
              <Col lg={12} className='text-center'>
                <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.onWorkflowteam} showloader active={loading} name={`${selectedData ? 'Update ' : 'Add '} Workflow Team`} />
                <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onClose()} tooltip="Close" />
              </Col>
            </Row>
          </Form>
        </Fragment>
      </div>
    )
  }
}
