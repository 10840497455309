import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CardBody, CardTitle, Row, Col, Card } from 'reactstrap';

import { appDate } from '../../AppConstants';
export default class Histroy extends Component {
    static get propTypes() {
        return {
            signalHistory: PropTypes.any,
        };
    }
    render() {
        const { signalHistory } = this.props;
        return (
            <div>
                <CardBody>
                    <CardTitle className='text-dark ms-2'>History</CardTitle>
                    <Row>
                        <Col lg="12" md="12">
                            <Row>
                                {
                                    signalHistory?.data && signalHistory.data.length ?
                                        signalHistory.data.map((j, index) => {
                                            return (
                                                <Col lg={4} md={4} key={index}>
                                                    <Card className="p-3">
                                                        <div className='row p-2'>
                                                            <span className='badge bg-dark p-2 text-center'>{j.status_type}</span>
                                                            {j.actual_status ? <p className='mb-1 mt-2'> Status changed to: <span className='ms-2 text-danger'>{j.actual_status}</span> </p> : null}
                                                            {j.comment ? <p className='mb-1'> Comment: {j.comment}</p> : null}
                                                        </div>
                                                        <blockquote className="blockquote mb-0 ms-2">
                                                            {j.comment != 'null' &&
                                                                <footer className="blockquote-footer">
                                                                    <small className="text-muted font-size-12">
                                                                        Comments added by
                                                                        <cite title="Source Title">{j.owner.first_name + ' ' + j.owner.last_name}</cite>
                                                                        {" "}on
                                                                        <cite title="Source Title"> {appDate(j.created_at)}</cite>
                                                                    </small>
                                                                </footer>
                                                            }
                                                        </blockquote>
                                                    </Card>
                                                </Col>
                                            )
                                        })

                                        :
                                        <CardBody className='text-center'>
                                            <span className='text-dark shadow p-2 rounded'>No History Found..</span>
                                        </CardBody>
                                }

                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </div>
        )
    }
}
