import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { Card, CardBody, CardText, Col, Container, Input, InputGroup, Label, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import { signalApi } from '../ApiConstant';
import { Service } from '../Appservice';
const riskCategoryList = ["HIGH", "MEDIUM", "LOW"];
export default class ProductRiskCheckList extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            list: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        const { list } = props;
        this.state = {
            showRiskTrackingEdit: null,
            riskTrackingValue: "",
            riskCheckList: list,
            editData: ""
        }
    }

    componentDidMount = async () => {
        const { selectedData, list } = this.props;
        const { riskCheckList } = this.state;
        if (selectedData?.risk_categorisation) {
            let newArray = riskCheckList;
            const getDetails = await Service.appservice('get', `${signalApi.drugs}/${selectedData.id}/product_frequency_from_product`);
            if (getDetails) {
                newArray = newArray.map(v => ({ ...v, value: getDetails[v.id] }))
            }
            this.setState({ riskTrackingValue: selectedData?.risk_categorisation, riskCheckList: newArray, editData: getDetails });
        } else {
            this.setState({ editData: "", riskTrackingValue: "" })
        }
    }

    handlSubmitRiskTracking = async () => {
        const { selectedData, onClose } = this.props;
        const { riskCheckList, riskTrackingValue, editData } = this.state;
        if (!riskTrackingValue) return false;
        let json = riskCheckList.map(v => ({ [v.id]: v.value }));
        json = json.reduce((key, value) => ({ ...key, ...value }), {});
        json.drug = selectedData?.id;
        const jsonForDrugs = {
            risk_categorisation: riskTrackingValue
        }
        const updateProducts = await Service.appservice('patch', `${signalApi.drugs}/${selectedData.id}`, jsonForDrugs);

        if(updateProducts) {

            const assignRiskChecklist = editData ? await Service.appservice('patch', `${signalApi.reviewFrequency}/${editData.id}`, json) : await Service.appservice('post', signalApi.reviewFrequency, json);
            if(assignRiskChecklist) {
                this.setState({ riskTrackingValue: "", showRiskTrackingEdit: null })
                toast.success('Project Risk Tracking updated successfully!...');
                onClose(true);
            }
        }
    }
    onChangeCheckList = (index) => {
        const { riskCheckList } = this.state;
        let newItem = [...riskCheckList];
        newItem[index].value = !newItem[index].value;
        this.setState({ riskCheckList: newItem });
    }
    handleOnChangeRisk = async (e) => {
        const selected = e.target.value ? e.target.value : null;
        this.setState({ riskTrackingValue: selected });
    }
    render() {
        const { selectedData, onClose } = this.props;
        const { riskTrackingValue, riskCheckList } = this.state;
        return (
            <Fragment>
                <div className='d-flex justify-content-sm-between mb-3 flex-column flex-sm-row'>
                    <span>
                        <AppButton tooltip="back" icon='fas fa-arrow-left' name={`Product - ${selectedData.name}`} onClick={()=>onClose()} />
                    </span>
                    <span className='mt-2 mt-sm-0'>
                        <InputGroup>
                            <div className="input-group-text p-1 shadow">Risk Categorisation</div>
                            <Input className='form-control' onChange={v => { this.handleOnChangeRisk(v) }} type="select" name="select" value={riskTrackingValue}>
                                <option value="">Select</option>
                                {
                                    riskCategoryList.map((e, i) => {
                                        return <option value={e} key={i}>{e}</option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                    </span>
                </div>
                <div className='mt-4'>
                    <div >
                        <Row>
                            {riskCheckList && riskCheckList.map((val, i) => (
                                <Col lg={3} key={i} >
                                    <Card className={val.value ? 'shadow-sm risk-checklist-active' : 'shadow-sm'} onClick={() => this.onChangeCheckList(i)}>
                                        <CardBody className='p-2'>
                                            <Input className='me-3' type="checkbox" id={val.id} defaultChecked={val.value} checked={val.value} readOnly />
                                            <span className=''>{val.name}</span>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                            )}
                        </Row>
                    </div>
                    <Col lg={12} className='text-center p-4'>
                        <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.handlSubmitRiskTracking} showloader active={!riskTrackingValue} disabled={!riskTrackingValue} name={"Update"} />
                        <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={onClose} tooltip="close"/>
                    </Col>
                </div>
            </Fragment>
        )
    }
}
