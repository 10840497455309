const signal = process.env.REACT_APP_API_ENDPOINT_SIGNAL;
const user = process.env.REACT_APP_API_ENDPOINT_VC19;
const epidomilogy = process.env.REACT_APP_API_ENDPOINT_EPIDOMILOGY;
const imdrf = "https://imdrf-api-23.vc-19.ai/bausch/api/v1/imdrf";
const literature = "https://mldemo.vc-19.ai/processADR";
const userApi = {
  settings: user + "settings",
  login: user + "auth-with-permissions",
  session: user + "update_single_session_login",
  logout: user + "logout",
  roles: user + "group",
  users: user + "users",
  userPdf: user + "users/download_pdf",
  userXl: user + "users/download_excel",
  users: user + "users",
}
const signalApi = {
  actions: signal + "measure",
  topicActions: signal + "theme-measures",
  actionsCsv: signal + "measurecsv",
  actionsSignalXl: signal + "theme-measures/download_excel",
  actionType: signal + "measure-type",
  actionAttachments: signal + "measure-attachments",
  actionAttachmentsGet: signal + "measure-attachments-get",
  actionComments: signal + "measure-comments",

  topics: signal + "themes",
  topicStructure: signal + "theme-structure",
  topicWorkflowTeam: signal + "workflow-team-ontheme",
  topicSignals: signal + "theme-signals",
  topicAttachmentGet: signal + "theme-attachments-get",
  topicsSignalXl: signal + "theme-signals/download_excel",
  topicsAttachment: signal + "theme-attachments",
  topicCommenents: signal + "theme-comments",

  dashboard: signal + "dashbaordsnew",
  parentModel: signal + "signal-parent-models",
  duration: signal + "signal-model-duration",
  drugs: signal + "drug",
  signals: signal + "signals",
  signalsCsv: signal + "signalcsv",
  signalAutomate: signal + "automate-workflow",
  signalIcsr: signal + "signal-report-data",
  signalIcsrHistory: signal + "signal-report-history",
  signalIcsrHistoryExcel: signal + "signal-report-history-excel",
  signalStatusHistory: signal + "signal-status-history",
  signalsSummery: signal + "signals/summary",
  signalsEaluvationSummery: signal + "signals/evaluation_summary",
  project: signal + "project",
  workflowTeam: signal + "workflow-team",
  workflowConfiguration: signal + "workflow-config",
  regIntel: signal + "reg-intel-list",
  calender: signal + "theme-measures/calender",
  auditLog: signal + "audit-log",
  auditLogGraph: signal + "audit-log/audit_log_graphs",
  auditLogCsv: signal + "audit-log/audit_log_csv",
  auditLogEntry: signal + "log-entry",

  reviewFrequency: signal + "product-frequency",
}
const epidomilogyDrugs = epidomilogy + 'drugs';
export { userApi, signalApi, imdrf, literature, epidomilogyDrugs }