import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { Col, Form, Row,Card } from 'reactstrap';
import toast from 'react-hot-toast';
import { Service } from '../Appservice';
import { signalApi } from '../ApiConstant';
export default class SignalRecommentation extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func
        };
    }
    constructor(props) {
        super(props);
        const { selectedData } = props;
        this.state = {
            postData: {
                recommendation_status: selectedData.recommendation_status ?? '',
                recommendation_comment: selectedData.recommendation_comment ?? '',
            },
            postErr: {
                recommendation_status: false,
                recommendation_comment: false,
            },
            recommendList: [{ id: 'PU', name: 'PI update' }, { id: 'RU', name: 'RMP Update' }, { id: 'RPP', name: 'Routine PV-monitor within PSUR' },
            { id: 'AR', name: 'aRMM' }],
            loading: false
        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onMarkValidation = async () => {
        const { selectedData, onClose } = this.props;
        const { postData } = this.state;
        if (!postData.recommendation_status) return this.setError('recommendation_status');
        if (!postData.recommendation_comment) return this.setError('recommendation_comment');

        this.setState({ loading: true });
        let recData = { recommendation_status: postData.recommendation_status, comment: postData.recommendation_comment };
        await Service.appservice('patch', `${signalApi.signals}/${selectedData.id}/update_status`, recData);
        toast.success('Validation Status Updated Successfully !...');
        this.setState({ loading: true });
        onClose(true);
    }
    render() {
        const { selectedData, onClose } = this.props;
        const { postData, postErr, recommendList, loading } = this.state;
        return (
            <div className='p-3'>
                <Form>
                        <Col >
                            <AppInput label="Recommendation status *" type="select" value={postData.recommendation_status} isInvalid={postErr.recommendation_status}
                                error="Recommendation status is required" onChange={e => { this.setValue(e, 'recommendation_status') }} iArray={recommendList} selectType='idpair' />
                        </Col>
                        <Col >
                            <AppInput label="Comment *" type="text" value={postData.recommendation_comment} isInvalid={postErr.recommendation_comment}
                                error="Recommendation Comment is required" onChange={e => { this.setValue(e, 'recommendation_comment') }} />
                        </Col>
                        <Col className='text-center'>
                            <AppButton icon='fas fa-check me-2' name="Recommendation" disabled={loading} showloader onClick={this.onMarkValidation} />
                            {/* <AppButton className="ms-2" tooltip="Close Recommendation" icon='fas fa-times' onClick={async () => { onClose() }} /> */}
                        </Col>
                </Form>
            </div>
        )
    }
}
