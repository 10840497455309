import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Col, Form, Row, Table } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import SimpleBar from "simplebar-react";
import { Service } from '../Appservice';
import { AppPagination, AppShowmore, AppInput, AppButton } from 'components/Reusable';
import { signalApi } from '../ApiConstant';
import { appDate } from '../AppConstants';
export default class UserPages extends Component {
    static get propTypes() {
        return {
            loading: PropTypes.bool,
            headers: PropTypes.any,
            graphData: PropTypes.any,
            userList: PropTypes.any,
            onLog: PropTypes.func
        };
    }
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            viewloading: false,
            viewList: [],
            assigned_to: '',
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
                offsetY: -10,
            }
        }
    }
    componentDidMount() {
        this.setState({ viewloading: true });
        this.getViewData();
    }
    getViewData = async () => {
        const { page, assigned_to } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (assigned_to) queryParam += "&actor__id=" + assigned_to;
        const getViewData = await Service.appservice('get', signalApi.auditLog + queryParam);
        if (getViewData?.data)
            this.setState({
                viewList: getViewData.data,
                viewloading: false,
                pageNumbers: getViewData.metadata ? Array.from({ length: getViewData.metadata.total_pages }, (v, k) => k + 1) : []
            });
    }
    paginate = async (page) => {
        this.setState({ page, viewloading: true });
        this.getViewData();
    }
    render() {
        const { page, pageNumbers, viewList, viewloading, assigned_to, legend } = this.state;
        const { graphData, loading, headers, userList, onLog } = this.props;
        const labels = graphData?.map(a => a.actor__username) ?? [];
        const series = graphData?.map(a => a.count) ?? undefined;
        const graph = { options: { labels, series, legend } };
        return (
            <Fragment>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    <div className="text-primary fw-bold d-none d-sm-block shadow p-2 rounded">
                        <i className="fas fa-user me-2" />
                        User event logs
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <AppButton icon="fas fa-eye" name="Activity Logs" onClick={onLog} className="me-2" />
                        <Form>
                            <AppInput label="Filter" type="select" value={assigned_to} iArray={userList} selectType='usernamepair'
                                onChange={async e => { console.log('e', isNaN(e.target.value)); await this.setState({ assigned_to: isNaN(e.target.value) ? '' : e.target.value }); this.getViewData() }} />
                        </Form>
                    </div>
                </div>
                <Row>
                    <Col lg={4} >
                        {loading ?
                            <Fragment></Fragment> :
                            series?.length && labels?.length ?
                                <ReactApexChart options={graph.options} series={graph.options.series} type="donut" height={500} /> :
                                <p>No graph data found..</p>}
                    </Col>
                    <Col lg={8}>
                        <SimpleBar className='shadow rounded-3 mt-3'>
                            <Table className="table-hover table-borderless mb-0">
                                <thead className='shadow'>
                                    <tr>{headers.map((e, i) => <th key={i}>{e}</th>)}</tr>
                                </thead>
                                <tbody>
                                    {viewloading ?
                                        <tr>
                                            <td colSpan={5} className='text-center'>
                                                <AppButton name="Loading" disabled showloader />
                                            </td>
                                        </tr> :
                                        viewList?.length ?
                                            viewList.map((e, i) => {
                                                return (
                                                    <tr key={i} >
                                                        <td className='text-right'>{appDate(e?.created_at)}</td>
                                                        <td >{e?.actor?.first_name}{' '}{e?.actor?.last_name}</td>
                                                        <td><AppShowmore text={e?.changes} id={'tool' + i} /></td>
                                                        <td ><span className='me-1 badge badge-soft-danger p-2'>{e?.remote_addr}</span></td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan={5} className='text-center'>
                                                    <p className='  p-2 text-center text-danger'>No view logs found..</p>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                            {
                                viewList?.length ?
                                    <AppPagination previousPage={e => { this.paginate(e) }} nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} />
                                    : <Fragment></Fragment>
                            }
                        </SimpleBar>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
