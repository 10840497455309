import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Badge, CardBody, Row, Col } from 'reactstrap';
import { AppShowmore, AppLoader, AppButton, AppDropDown } from 'components/Reusable';
import SignalMarkEvaluation from './SignalMarkEvaluation';
import empty from 'assets/illusion/listempty.png';

export default class SignalEvaluationGrid extends Component {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      signalEvaluationActionList: PropTypes.any,
      loading: PropTypes.bool,
      onMarkEvaluation: PropTypes.func,
      onMenu: PropTypes.func,
      onActionView: PropTypes.func,
      disableAddEvaluation: PropTypes.bool,
      onShowEvaluation: PropTypes.func,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      markEvaluation: false,
      isMenu: false,
      selectedAction: '',
      actionMenus: [
        { name: 'Change Status', icon: 'fas fa-pen', id: 1 },
        { name: 'Comments', icon: 'fas fa-comment', id: 2 },
        { name: 'Attachments', icon: 'fas fa-paperclip', id: 3 },
      ],
    }
  }
  render() {
    const { loading, signalEvaluationActionList, onMarkEvaluation, selectedData, onMenu, onActionView, disableAddEvaluation, onShowEvaluation } = this.props;
    const { markEvaluation, isMenu, selectedAction, actionMenus } = this.state;
    let completedEvaluvation = selectedData?.evaluation_confirmation_status == 'C' ? true : false;
    let disableEvaluation = selectedData.validation_status != 'V' || selectedData?.evaluation_confirmation_status == 'C' ? true : false;
    const isSkipEvaluation = !selectedData.evaluation_topic_added && selectedData?.evaluation_confirmation_status ? true : false;
    if (!selectedData?.evaluation_topic_added) disableEvaluation = true;
    if (!selectedData.evaluation_topic_added && selectedData?.evaluation_confirmation_status != 'C' && isSkipEvaluation) disableEvaluation = false;
    return (
      <Fragment>
        {markEvaluation ?
          <SignalMarkEvaluation isGrid selectedData={selectedData} onClose={(e) => { this.setState({ markEvaluation: false }); if (e) { onMarkEvaluation() } }} /> :
          <Fragment>
            <div className='card'>
              <div className='p-2 shadow rounded d-flex align-items-center justify-content-between'>
                <h5 className="font-size-14 m-0  p-2  badge badge-soft-danger rounded">
                  <i className="fas fa-check  me-2" /> Evaluation
                </h5>
                {disableAddEvaluation ? null :
                  <AppButton icon='fas fa-plus' className="me-2 " name={'Add'} disabled={disableAddEvaluation} onClick={onShowEvaluation} />
                }
              </div>
              <div className='card-body' style={{ maxHeight: 250, overflowY: signalEvaluationActionList?.length ? 'scroll' : '' }}>
                {loading ?
                  <div className='m-3'>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder rounded col-7"></span>
                      <span className="placeholder rounded col-4"></span>
                      <span className="placeholder rounded col-4"></span>
                      <span className="placeholder rounded col-6"></span>
                      <span className="placeholder rounded col-8"></span>
                    </p>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder rounded col-7"></span>
                      <span className="placeholder rounded col-4"></span>
                      <span className="placeholder rounded col-4"></span>
                      <span className="placeholder rounded col-6"></span>
                      <span className="placeholder rounded col-8"></span>
                    </p>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder rounded col-7"></span>
                      <span className="placeholder rounded col-4"></span>
                      <span className="placeholder rounded col-4"></span>
                      <span className="placeholder rounded col-6"></span>
                      <span className="placeholder rounded col-8"></span>
                    </p>
                  </div>
                  :
                  signalEvaluationActionList && signalEvaluationActionList?.length ?
                    signalEvaluationActionList.map((e, i) => {
                      return (
                        <Fragment key={i}>
                          <div className="shadow-none border mb-2 rounded-3 card m-3" >
                            <div className='border-bottom p-2 rounded-3  d-flex align-items-center justify-content-between'>
                              <AppDropDown
                                menuArray={actionMenus}
                                isMenu={isMenu}
                                onToggle={() => { if (e.id == selectedAction.id) this.setState({ isMenu: !isMenu, selectedAction: e }); }}
                                onClick={() => { this.setState({ isMenu: true, selectedAction: e }); }}
                                rowId={e.id}
                                selectedId={selectedAction.id}
                                onClose={(id) => {
                                  this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                                  onMenu(selectedAction, id);
                                }}
                              />
                              <span className={`badge p-2 badge-soft-danger rounded-2 font-size-12`}>{e?.updateData?.status ? e?.updateData?.status : 'New'}</span>
                            </div>
                            <div className="card-body">
                              <Row>
                                <Col >
                                  <p className="text-muted mb-1">Action Name</p>
                                  <h5 className="font-size-14 text-info" role='button' onClick={() => { onActionView(e) }}>
                                    <AppShowmore text={e?.updateData?.measure_data?.name} />
                                  </h5>
                                </Col>
                                <Col >
                                  <p className="text-muted mb-1">Topic</p>
                                  <h5 className="font-size-14"><AppShowmore text={e?.updateData?.theme_data?.name} /></h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col >
                                  <p className="text-muted mb-1">Assignee</p>
                                  <h5 className="font-size-14"><AppShowmore text={e?.updateData?.assigned_to_data?.username} /></h5>
                                </Col>
                                <Col >
                                  <p className="text-muted mb-1">Attachments
                                  </p>
                                  <Badge className="me-2 p-2 bg-info" onClick={() => { onMenu(e, 3); }}>
                                    {e?.updateData?.measure_attachment_count ?? 0}
                                  </Badge>
                                </Col>
                              </Row>
                              <Row>
                                <Col >
                                  <p className="text-muted mb-1">Comments
                                  </p>
                                  <Badge className="me-2 p-2 bg-info" onClick={() => { onMenu(e, 2); }}>
                                    {e?.updateData?.measure_comment_count ?? 0}
                                  </Badge>
                                </Col>
                                <Col >
                                  <p className="text-muted mb-1">Order
                                  </p>
                                  <Badge className="me-2 p-2 bg-black" >
                                    {e?.updateData?.measure_order ?? 0}
                                  </Badge>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Fragment>
                      )
                    }) :
                    <div className='text-danger text-center m-3'>
                      <span className='p-2 shadow rounded'>{isSkipEvaluation ? 'Action Evaluation Skipped..' : 'No Action Evaluations Added..'}</span>
                      <img src={empty} alt="" className="bx-fade-left-hover rounded mx-auto d-block w-50 h-50 mt-3" />
                    </div>
                }
              </div>
              <div className='p-2 rounded text-center'>
                {completedEvaluvation ?
                  <Fragment>
                    <AppButton icon='fas fa-check' name={'Action Evaluation Completed'} disabled={completedEvaluvation} />
                  </Fragment> :
                  <Fragment>
                    <AppButton icon='fas fa-check' name={isSkipEvaluation ? 'Mark Evaluation Complete' : 'Mark Evaluation'}
                      disabled={disableEvaluation || loading} onClick={() => { this.setState({ markEvaluation: true }); }} />
                  </Fragment>}
              </div>
            </div>
          </Fragment>
        }
      </Fragment>
    )
  }
}