import PropTypes from "prop-types";
import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import { Link } from "react-router-dom";
import axios from "axios";
import { saveUser } from 'store/actions';

//image
import fortreaLogo from "assets/images/fortreaLogo.png"
import fortreaLogosm from "assets/images/fortreaLogo-sm.png";
import Logo from "assets/logo3a.png"
import Logosm from "assets/logo3a-sm.png";

const Sidebar = props => {
  const [settings, setSettings] = useState([]);
  useEffect(async () => {
    await loadData();
  }, []);
  const loadData = async () => {
    return await axios.get(`${process.env.REACT_APP_API_ENDPOINT_VC19}settings`)
      .then((response) => {
        setSettings(response.data);
        const User = localStorage.getItem('User');
        if (User) {
          props.saveUser(JSON.parse(User));
          localStorage.removeItem('User');
        }
      })
      .catch((err) => console.log(err));
  };
  axios.interceptors.request.use((request) => {
    if (request.url.includes('https://sermgmtdevserver.vc-19.ai/')) {
      request.headers.Authorization = `Token 16fb160a4e3b6a0ece928616ad1529ffb5bbabb0`;
    }
    else if (request.url.includes('https://imdrf-prod.vc-19.ai/bausch/api/v1/imdrf')) {
      request.headers.Authorization = `Bearer 2xMnnAa-e5qo6AoH7I89iCrP2sYjKsZgxWnR5yfjgJQB0M-mp_HZsLs5FpIcMT6HsgYY5AmTgFqwGi89MGYP66UPoXhSr_iaD3YdshTSZnw-YgQBXsMoOVrvrdPa6cz0j-6wXQ1c285bec-bace-46e0-af5a-fa14c45daf56`;
    }
    else if (!request.url.includes("settings") && !request.url.includes("documentationapp")) {
      request.headers.Authorization = `Token ${localStorage.getItem('authToken')}`;
    }
    return request;
  });
  const server = process.env.REACT_APP_SERVER;
  let image = Logo, imageSm = Logosm;
  // if (server == "labcorp") { image = fortreaLogo; imageSm = fortreaLogosm }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={imageSm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={image} alt="" height="30" />
            </span>
          </Link>
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={imageSm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={image} alt="" />
            </span>
          </Link>
        </div>
        <div className="h-100">
          <SidebarContent settings={settings} />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.any,
  user: PropTypes.any,
  saveUser: PropTypes.func,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
    depthStep: state.depthStep,
    user: state.Login.user
  };
};
export default connect(mapStatetoProps, { saveUser })(withRouter(withTranslation()(Sidebar)));
