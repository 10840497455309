import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
import Logo from "assets/illusion/add.png";
import { Row, Col, CardBody, CardText, CardImg } from 'reactstrap';
import { Service } from '../../Appservice';
import { signalApi } from '../../ApiConstant';
import { appDate } from '../../AppConstants';

export default class Workflowteamdetails extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            workflowTeamList: []
        }
    }
    componentDidMount = () => {
        this.getWorkflowTeam()
    }
    getWorkflowTeam = async () => {
        const workflowTeamList = await Service.appservice('get', `${signalApi.workflowTeam}/${this.props.selectedData.id}`);
        if (workflowTeamList) this.setState({ workflowTeamList });
    }
    render() {
        const { onClose, selectedData } = this.props;
        const { workflowTeamList } = this.state;
        const Lead = selectedData?.user_data.find(e => e.id == selectedData?.team_lead)?.first_name ?? '';
        return (
            <Fragment>
                <div className='d-flex justify-content-between'>
                    <AppButton name={`View details of ${selectedData?.name}`} tooltip="Back to Workflow Team List" icon='fas fa-arrow-left' onClick={onClose} />
                </div>

                <Row className="no-gutters align-items-center">
                    <Col md={4} className='d-none d-sm-block'>
                        <CardImg className="img-fluid p-2" src={Logo} alt="Skote" />
                    </Col>
                    <Col md={5}>
                        <CardBody>
                            {Lead ?
                                <CardText className='shadow p-2 rounded text-danger text-center'>Team lead is : {Lead}</CardText> :
                                <CardText className='shadow p-2 rounded text-danger text-center'>No team lead assigned</CardText>}
                            <div className="accordion-body">
                                {workflowTeamList?.user_data?.length ? workflowTeamList?.user_data.map((e, i) => {
                                    console.log('e?.created_at', e);
                                    return (
                                        <Fragment key={i}>
                                            <div className='card shadow-sm p-2' >
                                                <span className='mt-3 text-center'>
                                                    <h5>{e?.first_name + ' ' + e?.last_name}</h5>
                                                    <span className='d-flex justify-content-between mt-4'>
                                                        <small className='blockquote-footer'>Total Actions : <span className='badge bg-danger  p-2'> {e?.total_actions} </span></small>
                                                        <small className='blockquote-footer ms-3'>Total Topics : <span className='badge bg-danger  p-2'>  {e?.total_topics} </span></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </Fragment>
                                    )
                                }) :
                                    <p className='text-danger text-center'>No members found..</p>}
                            </div>
                        </CardBody>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
