import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppInput, AppButton } from 'components/Reusable';
import moment from 'moment';
import { connect } from 'react-redux';
import { signalMainFilter } from 'store/actions';
import { getKeyItem, parsedKeyItem, superuser, user } from '../AppPermission';
import { appMonths, appQuaters, labelExpectedList, seriousnessList, yearList } from '../AppConstants';
class Filter extends Component {
    static get propTypes() {
        return {
            drugList: PropTypes.any,
            modelList: PropTypes.any,
            durationList: PropTypes.any,
            filterData: PropTypes.any,
            onClose: PropTypes.func,
            onFilter: PropTypes.func,
            signalMainFilter: PropTypes.func,
            signalHeaderFilter: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        const { filterData, signalHeaderFilter } = props;
        this.state = {
            postData: {
                drug: signalHeaderFilter ? signalHeaderFilter?.drug : filterData?.drug,
                signal_parent_model_id: signalHeaderFilter ? signalHeaderFilter?.signal_parent_model_id : filterData?.signal_parent_model_id,
                duration_id: signalHeaderFilter ? signalHeaderFilter?.duration_id : filterData?.duration_id,
                src_identifier: signalHeaderFilter ? signalHeaderFilter?.src_identifier : filterData?.src_identifier,
                month_id: signalHeaderFilter ? signalHeaderFilter?.month_id : '',
                quarter_id: signalHeaderFilter ? signalHeaderFilter?.quarter_id : '',
                year_id: signalHeaderFilter ? signalHeaderFilter?.year_id : 2021, //moment().year()
                seriousness: signalHeaderFilter ? signalHeaderFilter?.seriousness : '',
                label_expected: signalHeaderFilter ? signalHeaderFilter?.label_expected : '',
                assigned_to: getKeyItem(superuser) == "true" ? '' : parsedKeyItem(user)?.uid,
            },
            periodList: [],
            seriousnessList: seriousnessList,
            labelExpectedList: labelExpectedList
        }
    }
    setValue = async (i, e) => {
        const value = i.target?.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
    };
    ondurationchange = (e) => {
        this.setState({ postData: { ...this.state.postData, quarter_id: '', month_id: '' } });
        if (!e) return;
        if (e == 2) return this.setState({
            periodList: appMonths,
            postData: { ...this.state.postData, month_id: moment().month() + 1 }
        })
        if (e == 3) return this.setState({
            periodList: appQuaters,
            postData: { ...this.state.postData, quarter_id: appQuaters[0].id }
        })
    }
    render() {
        const { postData, periodList, seriousnessList, labelExpectedList } = this.state;
        const { drugList, modelList, durationList, onClose, onFilter, signalMainFilter, signalHeaderFilter } = this.props;
        const periodValue = postData.duration_id == 1 ? postData.year_id : postData.duration_id == 2 ? postData.month_id : postData.quarter_id;
        const periodSetValue = postData.duration_id == 1 ? 'year_id' : postData.duration_id == 2 ? 'month_id' : 'quarter_id';
        return (
            <Form className=' mb-4' >
                <Row className='m-3'>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <AppInput label="Source" type="text" value={postData.src_identifier} disabled />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <AppInput label="Drug *" type="select" value={postData.drug}
                            onChange={e => { this.setValue(e, 'drug') }} iArray={drugList} selectType='namepair' />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <AppInput label="Model *" type="select" value={postData.signal_parent_model_id}
                            onChange={e => { this.setValue(e, 'signal_parent_model_id'); }} iArray={modelList} selectType='idpair' />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <AppInput label="Duration *" type="select" value={postData.duration_id}
                            onChange={async e => { await this.setValue(e, 'duration_id'); this.ondurationchange(e.target.value) }} iArray={durationList} selectType='idpair' />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12} className={postData.duration_id ? 'd-block' : 'd-none'}>
                        <AppInput label="Year *" type="select" value={postData.year_id}
                            onChange={e => { this.setValue(e, 'year_id'); }} iArray={yearList().reverse()} selectType='idpair' />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12} className={postData.duration_id && postData.duration_id != 1 ? 'd-block' : 'd-none'}>
                        <AppInput label="Period *" type="select" value={periodValue}
                            onChange={e => { this.setValue(e, periodSetValue); }} iArray={periodList} selectType='idpair' />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <AppInput label="Seriousness" type="select" value={postData.seriousness}
                            onChange={e => { this.setValue(e, 'seriousness') }} iArray={seriousnessList} selectType='idpair' />
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <AppInput label="Label Expected" type="select" value={postData.label_expected}
                            onChange={e => { this.setValue(e, 'label_expected'); }} iArray={labelExpectedList} selectType='idpair' />
                    </Col>
                    <Col lg="12" className='text-center'>
                        <AppButton className="me-3" name="Signal Filter" icon="fas fa-filter" onClick={() => { onFilter(postData); signalMainFilter(postData) }} />
                        <AppButton  tooltip="Close Filter" icon="fas fa-times" onClick={onClose} />
                    </Col>
                </Row>
            </Form>
        )
    }
}
const mapStateToProps = state => {
    return {
        signalHeaderFilter: state.Signal.signalHeaderFilter,
    }
}

export default connect(mapStateToProps, { signalMainFilter })(Filter)