import React, { Component, Fragment } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppShowmore, AppDropDown } from 'components/Reusable';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';
import { userApi } from '../ApiConstant';
import DeleteAlert from '../components/DeleteAlert';
import { getKeyItem, parsedKeyItem, user } from '../AppPermission';
export default class UsersTable extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            loading: PropTypes.bool,
            filterStaticHeaders: PropTypes.func,
            menuArray: PropTypes.any,
            userList: PropTypes.any,
            onMenu: PropTypes.func,
            onDelete: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            isMenu: false,
            updateData: '',
            isDelete: false,
            isLoading: false,
        }
    }
    deleteUser = async () => {
        const { updateData } = this.state;
        this.setState({ isLoading: true })
        const deleteUser = await Service.appservice('delete', `${userApi.users}/${updateData.id}`);
        if (deleteUser) toast.success('User deleted successfully!...')
        this.setState({ isDelete: false, updateData: '', isLoading: false });
        this.props.onDelete();
    }
    render() {
        const { tabHeader, filterStaticHeaders, menuArray, userList, onMenu, loading } = this.props;
        const { isMenu, updateData, isDelete, isLoading, } = this.state;
        const uid = parsedKeyItem(user)?.uid;
        return (
            <div className='shadow table-responsive rounded-3 mt-3'>
                <Table className="table-hover table-borderless mb-0">
                    <thead className='shadow'>
                        <tr>
                            <th className='text-center'>
                                <i className='fas fa-bars text-info'></i>
                            </th>
                            {
                                tabHeader.map((e, i) => {
                                    return (
                                        e.isChecked && <th key={i}>{e.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {(isLoading || loading) ?
                            <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                            :
                            userList.map((e, i) => {
                                return (
                                    isDelete && updateData.id == e.id ?
                                        <td colSpan={15} key={i} className='shadow border border-danger'>
                                            <DeleteAlert loading={loading || isLoading} title="User" onYes={this.deleteUser}
                                                onNo={() => { this.setState({ isDelete: false, updateData: '' }) }} />
                                        </td>
                                        :
                                        <tr key={i} >
                                            <td className='text-center'>
                                                {uid == e.id ? <Fragment></Fragment> :
                                                    <AppDropDown
                                                        menuArray={menuArray}
                                                        isMenu={isMenu}
                                                        onToggle={() => { if (e.id == updateData.id) this.setState({ isMenu: !isMenu, updateData: e }); }}
                                                        onClick={() => { this.setState({ isMenu: true, updateData: e }); }}
                                                        rowId={e.id}
                                                        selectedId={updateData.id}
                                                        onClose={(id) => {
                                                            this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                                                            onMenu(updateData, id);
                                                        }}
                                                    />}
                                            </td>
                                            <td className={filterStaticHeaders(0) ? '' : 'd-none'}><AppShowmore text={e?.first_name} id={'tools' + i} /></td>
                                            <td className={filterStaticHeaders(1) ? '' : 'd-none'}><AppShowmore text={e?.last_name} id={'tools' + i} /></td>
                                            <td className={filterStaticHeaders(2) ? 'text-primary' : 'd-none'}><AppShowmore text={e?.username} id={'tools' + i} /></td>
                                            <td className={filterStaticHeaders(3) ? '' : 'd-none'}><AppShowmore text={e?.email} id={'tools' + i} /></td>
                                            <td className={filterStaticHeaders(4) ? '' : 'd-none'}>{e?.role_details?.map((e, i) => (<span key={i}>{e.name}</span>))}</td>
                                            <td className={filterStaticHeaders(5) ? 'text-center' : 'd-none'}><span className='badge bg-dark'>{e?.measure_assigned}</span></td>
                                            <td className={filterStaticHeaders(6) ? 'text-center' : 'd-none'}>
                                                {e?.drug_details && e?.drug_details.length ? e?.drug_details.map((drug, i) => (
                                                    <span key={i}>
                                                        {drug.name}
                                                        {i !== e.drug_details.length - 1 ? ", " : ""}
                                                    </span>
                                                )) : <i className='fas fa-syringe text-info' />}
                                            </td>
                                        </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
