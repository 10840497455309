import React, { Component } from 'react';
import { Row, Col,  CardBody, } from 'reactstrap';
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types';
import { lineChart } from '../Appchart';
export default class SignalActionsGraph extends Component {
    static get propTypes() {
        return {
            signalActionGraph: PropTypes.any,
            drugName: PropTypes.any,
            onFilter: PropTypes.func,
            drugList: PropTypes.any
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            signalTimeGraphFilter: [
                { id: 1, name: 'Topics' },
                { id: 2, name: 'Actions' },
            ],
            Filterby: '',
            drugBy: '',
            drugFilter: this.props.drugName.map(e => { return { id: e, name: e } }),
        }
    }
    onChartClick = (e) => { this.setState({ Filterby: e.data.id }); console.log('e', e); };
    setGraph = () => {
        const { Filterby, drugBy } = this.state;
        const { drugList } = this.props;
        let list = drugList;
        if (drugBy) list = drugList?.filter(e => e.name?.toLocaleLowerCase() == this.state.drugBy?.toLocaleLowerCase());
        let actions = [], topics = [];
        list.forEach(e => { actions.push(e.actions); topics.push(e.topics) });
        let data = [{ name: "Topics", data: topics, id: 1 }, { name: "Actions", data: actions, id: 2 }];
        if (Filterby) data = data.filter(e => e.id == Filterby)
        return data;
    }
    render() {
        const { signalActionGraph, drugName, onFilter, drugList } = this.props;
        const { signalTimeGraphFilter, Filterby, drugFilter, drugBy } = this.state;
        const drugNames = drugBy ? [drugBy] : drugName;
        const drugValues = drugBy || Filterby ? this.setGraph() : signalActionGraph;
        return (
            <Row className="no-gutters align-items-center">
                <Col md={4}>
                    <ReactApexChart options={lineChart(drugNames, (e) => { console.log('e', e); })} series={drugValues} type="bar" />
                </Col>
                <Col md={8}>
                    <CardBody className='text-center'>
                        <span className='text-danger shadow p-2 rounded'>No records found..</span>
                    </CardBody>
                </Col>
            </Row>
        )
    }
}
