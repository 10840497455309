import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import { withTranslation } from "react-i18next";
// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";
import { Card, CardBody, Form } from "reactstrap";
import ChangePassword from "./ChangePassword"
import { AppButton } from 'components/Reusable';
import { motion } from "framer-motion";
const Header = props => {
  const [visiblity, setVisibility] = useState("hidden");
  function toggleFullscreen() {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) { document.documentElement.requestFullscreen(); }
      else if (document.documentElement.mozRequestFullScreen) { document.documentElement.mozRequestFullScreen(); }
      else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
      if (visiblity == "") { setVisibility("hidden") }
      else if (visiblity == "hidden") { setVisibility("") }
    }
  }
  const [count, setCount] = useState(0);
  useEffect(() => {
    const count = localStorage.getItem('lastCount');
    setCount(count);
    window.addEventListener('storage', () => {
      const count = localStorage.getItem('lastCount');
      setCount(count);
    })
    //Remove the event listener when the component unmounts
    return () => {
      // window.removeEventListener("storage", console.log('removed stroafe'));
    }
  }, [])
  const [isPassword, showPassword] = useState(false);
  return (
    <React.Fragment>
      {isPassword &&
          <Card className='position-absolute top-0 end-0 p-2  mt-5 me-3 shadow-lg rounded-3' style={{ zIndex: 999, width: "300px", minHeight: "300px" }}>
            <span className='d-flex align-items-center justify-content-between'>
              <span className='font-size-13'>Change Password</span>
              <AppButton onClick={() => { showPassword(false) }} icon="fas fa-times-circle" tooltip="close" />
            </span>
            <ChangePassword onCancel={() => { showPassword(false) }} />
          </Card>
      }
      <header id="page-topbar" style={{ zIndex: isPassword ? 0 : 999 }}>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>
              <Link to="/" className="logo logo-light">
                <span className="logo-lg">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>
            </div>
            <button type="button" onClick={() => { tToggle(); }} className="btn btn-sm px-3 font-size-16 header-item " id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars text-danger" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ">
              <button type="button" onClick={() => { toggleFullscreen(); }} className="btn btn-sm header-item noti-icon" data-toggle="fullscreen"              >
                <i className="fas fa-expand text-danger font-size-16" />
              </button>
            </div>
            <div className="dropdown ">
              <button className="btn btn-sm header-item noti-icon" >
                <Link to="/users/chat">
                  <i className={`fas fa-bell text-danger font-size-16 ${count > 0 ? 'bx-tada' : ''}`}></i>
                  {count > 0 ? <span className="badge bg-dark rounded-pill">{count}</span> : <Fragment></Fragment>}
                </Link>
              </button>
            </div>
            <div className="dropdown  ">
              <button type="button" className="btn btn-sm header-item noti-icon" >
                <Link to="/documentation/module" target="_blank" >
                  <i className="fas fa-question  text-danger font-size-16 " />
                </Link>
              </button>
            </div>
            <ProfileMenu onChangePassword={() => { showPassword(true) }} />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
