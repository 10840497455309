import React, { Component, Fragment } from 'react';
import { Card } from "reactstrap";
import { ChatClient } from '3auserchat';
import '3auserchat/dist/index.css';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
export default class Chat extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
        };
    }
    render() {
        const { onClose } = this.props;
        const _preFix = "3auser" + process.env.REACT_APP_CHAT_PREFIX;
        const user = JSON.parse(localStorage.getItem('authUser'));
        return (
            <Fragment>
                <Card className='shadow rounded p-2'>
                    <div className='d-flex justify-content-between'>
                        <span className={`text-primary fw-bold align-self-center`}>
                            Chat
                        </span>
                        <AppButton icon='fas fa-times' classList="btn btn-sm btn-danger ms-2" iName={'Close'} disabled={false}
                            onClick={async () => { onClose() }} />
                    </div>
                </Card>
                <Fragment>
                    <ChatClient key={'privateChat-board'} userId={_preFix.concat(user.uid)}
                        appId={process.env.REACT_APP_CHAT_APP_ID ? process.env.REACT_APP_CHAT_APP_ID : ''}
                        clientId={process.env.REACT_APP_CHAT_CLIENT_ID ? process.env.REACT_APP_CHAT_CLIENT_ID : ''}
                        appHeight={80} />
                </Fragment>
            </Fragment>
        )
    }
}
