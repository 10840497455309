import React, { Component } from 'react';
import { Form, InputGroup, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppApi } from 'Appfirebaseapi';
import { AppButton, AppInput } from 'components/Reusable';
import toast from 'react-hot-toast'
export default class InlineSearch extends Component {
    static get propTypes() {
        return {
            userCopyList: PropTypes.any,
            onSearch: PropTypes.func,
            isChat: PropTypes.bool,
            selectedData: PropTypes.any,
            onMessage: PropTypes.any,
            onChat: PropTypes.func,
            onWorkflowteam: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            inlineSearch: '',
            message: '',
            messageError: false
        }
    }
    onInlineSearch = (e) => {
        const searchText = e;
        function matchValue(input) {
            let result = input?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            return result;
        }
        const filteredData = this.props.userCopyList.filter((row) => {
            return (matchValue(row?.first_name) || matchValue(row?.username) || matchValue(row?.email) || matchValue(row?.last_name));
        });
        this.props.onSearch(filteredData);
        this.setState({ inlineSearch: searchText });
    }
    sendMessage = () => {
        const { selectedData, onMessage } = this.props;
        const Appuser = JSON.parse(localStorage.getItem('authUser'));
        const { message } = this.state;
        if (!message) return this.setState({ messageError: true });
        AppApi.sendMessage(Appuser.uid, selectedData.id, message)
        toast.success("Message sent...");
        onMessage(true)
    }
    render() {
        const { inlineSearch, message, messageError } = this.state;
        const { isChat, selectedData, onMessage, onWorkflowteam, onChat } = this.props;
        return (
            <div className='d-flex justify-content-sm-between align-items-center'>
                <Form className="row col-lg-4 col-md-4 col-sm-12 col-xs-12" key={'Search'}>
                    <InputGroup>
                        <div className="input-group-text shadow text-danger rounded" onClick={() => { this.setState({ inlineSearch: '' }); this.props.onSearch(this.props.userCopyList); }}>
                            {inlineSearch ? <i className='fas fa-times text-danger' /> : <i className='bx bx-search-alt-2 text-danger' />}
                        </div>
                        <input
                            onChange={async (e) => this.onInlineSearch(e.target.value)}
                            value={inlineSearch} type="text" className="form-control" id="autoSizingInputGroup" placeholder="Search in table..." />
                    </InputGroup>
                </Form>
                {isChat ?
                    <Form className="row col-lg-4 col-md-4 col-sm-12 col-xs-12 ms-4 ms-sm-0" key={'Chat'}>
                        <Row>
                            <Col lg={8}>
                                <AppInput label={`Message to ${selectedData?.first_name} *`}
                                    value={message} isInvalid={messageError} error="Message should not be empty"
                                    onChange={e => { this.setState({ message: e.target.value }) }} />
                            </Col>
                            <Col lg={4} className='align-self-center'>
                                <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.sendMessage} name={`Send`} />
                                <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onMessage()} tooltip="Close" />
                            </Col>
                        </Row>
                    </Form> :
                    <div className='text-center d-inline-flex'>
                        <AppButton icon="fa fa-eye" name="Workflow Team" onClick={onWorkflowteam} className="ms-2" />
                        <AppButton icon="fa fa-eye" name="Chat" onClick={onChat} className="ms-2" />
                    </div>
                }
            </div>
        )
    }
}
