import React, { Component, Fragment } from 'react';
import ShowMoreText from "react-show-more-text";
import PropTypes from 'prop-types';
import AppTooltip from './Tooltip';
export default class AppShowmore extends Component {
    static get propTypes() {
        return {
            text: PropTypes.any,
            title: PropTypes.any,
            width: PropTypes.number,
            isBox: PropTypes.any,
        }
    }
    render() {
        const { text, title, width, isBox } = this.props;
        let isNumber = false;
        if (text && isNaN(text)) isNumber = false;
        else isNumber = true;
        return (
            <AppTooltip tooltip={text}>
                <div className='d-flex flex-row justify-content-between  align-items-center'>
                    {title ? <span className='bold '>{title} : </span> : null}
                    {isNumber ? <span className='badge badge-soft-danger p-2'>{text}</span> :
                        <ShowMoreText className={isBox ? "shadow-sm rounded p-2 ms-3" : ""} width={width ? width : 200} lines={1}>
                            {text}
                        </ShowMoreText>}
                </div>
            </AppTooltip>
        )
    }
}
