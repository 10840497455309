import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppShowmore } from 'components/Reusable';
import { CardBody, Row, Col, Collapse, Badge } from 'reactstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { appDate } from '../AppConstants';

export default class SignalValidationActionView extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
            ViewActionData: PropTypes.any
        };
    }
    constructor() {
        super();
        this.state = {
            isattachment: true,
            iscomment: false,
        }
    }
    render() {
        const { ViewActionData, onClose } = this.props;
        const { isattachment, iscomment } = this.state;
        return (
            <Fragment>
                <div className='d-flex justify-content-between'>
                    <AppButton name={`Action Details of ${ViewActionData?.updateData?.measure_data?.name}`} tooltip="Back to Action List" icon='fas fa-arrow-left' onClick={onClose} />
                </div>
                <Row >
                    <Col md={4}>
                        <div className=" border mb-2 rounded-3 card m-3" >
                            <div className='shadow border-bottom  border-bottom-2 p-2 rounded-3'>
                                <span className={`badge p-2 badge-soft-danger rounded-2 font-size-12`}>Action Details</span>
                            </div>
                            <PerfectScrollbar style={{ height: 200 }}>
                                <div className="card-body">
                                    <Row>
                                        <Col >
                                            <p className="text-muted mb-1">Action Name</p>
                                            <h5 className="font-size-14" >
                                                <AppShowmore text={ViewActionData?.updateData?.measure_data?.description} />
                                            </h5>
                                        </Col>
                                        <Col >
                                            <p className="text-muted mb-1">Topic</p>
                                            <h5 className="font-size-14"><AppShowmore text={ViewActionData?.updateData?.theme_data?.name} /></h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <p className="text-muted mb-1">Assignee</p>
                                            <h5 className="font-size-14"><AppShowmore text={ViewActionData?.updateData?.assigned_to_data?.first_name + ' ' + ViewActionData?.updateData?.assigned_to_data?.last_name} /></h5>
                                        </Col>
                                        <Col >
                                            <p className="text-muted mb-1">Attachments
                                            </p>
                                            <Badge className="me-2 p-2 bg-info" onClick={() => { onMenu(e, 3); }}>
                                                {ViewActionData?.updateData?.measure_attachment_count ?? 0}
                                            </Badge>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <p className="text-muted mb-1">Comments
                                            </p>
                                            <Badge className="me-2 p-2 bg-info" onClick={() => { onMenu(e, 2); }}>
                                                {ViewActionData?.updateData?.measure_comment_count ?? 0}
                                            </Badge>
                                        </Col>
                                        <Col >
                                            <p className="text-muted mb-1">Order
                                            </p>
                                            <Badge className="me-2 p-2 bg-black" >
                                                {ViewActionData?.updateData?.measure_order ?? 0}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Col>
                    <Col md={4} >
                        <div className="shadow-none border mb-2 rounded-3 card m-3" >
                        <div className='shadow border-bottom  border-bottom-2 p-2 rounded-3'>
                                <span className={`badge p-2 badge-soft-danger rounded-2 font-size-12`}>Action Attachments</span>
                            </div>
                            <PerfectScrollbar style={{ height: 200, overflowY: 'scroll' }}>
                                <div className='card-body'>
                                    {ViewActionData?.attachData?.data?.length ? ViewActionData?.attachData.data?.map((e, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <span className='mb-2 text-center'>
                                                    <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                </span>
                                                <div className='card shadow-sm p-2' >
                                                    <span className='mt-3'>
                                                        <h5>{e?.title}</h5>
                                                        <AppShowmore text={e?.description} id={'toolsattachdesc' + i} />
                                                        <small className='blockquote-footer'>Posted by {e?.created_by_data?.first_name + ' ' + e?.created_by_data?.last_name}</small>
                                                    </span>
                                                </div>
                                            </Fragment>
                                        )
                                    }) :
                                        <CardBody className='text-center'>
                                            <span className='text-danger shadow p-2 rounded'>No Action attachments found..</span>
                                        </CardBody>}
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Col>
                    <Col md={4} >
                        <div className="shadow-none border mb-2 rounded-3 card m-3" >
                        <div className='shadow border-bottom  border-bottom-2 p-2 rounded-3'>
                                <span className={`badge p-2 badge-soft-danger rounded-2 font-size-12`}>Action Comments</span>
                            </div>
                            <PerfectScrollbar style={{ height: 200, overflowY: 'scroll' }}>
                                <div className='card-body'>
                                    {ViewActionData?.commentData?.data?.length ? ViewActionData?.commentData.data?.map((e, i) => {
                                        return (
                                            <>
                                                <Fragment key={i}>
                                                    <span className='mb-2 text-center'>
                                                        <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                    </span>
                                                    <div className='card shadow-sm p-2' >
                                                        <span className='mt-3'>
                                                            <h5>{e?.comment}</h5>
                                                            <small className='blockquote-footer'>Posted by {e?.commented_by_data?.first_name + ' ' + e?.commented_by_data?.last_name}</small>
                                                        </span>
                                                    </div>
                                                </Fragment>
                                            </>)
                                    }) :
                                        <CardBody className='text-center'>
                                            <span className='text-danger shadow p-2 rounded'>No Action comments found..</span>
                                        </CardBody>
                                    }
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
