import React, { Component, Fragment } from 'react';
import { AppNodata, AppPagination } from 'components/Reusable';
import { Service } from '../Appservice';
import { Card, Container } from "reactstrap";
import TabHeader from '../components/tabHeader';
import InlineSearch from './InlineSearch';
import MetaTags from "react-meta-tags";
import ProductsAdd from './ProductsAdd';
import ProductsTable from './ProductsTable';
import { signalApi } from '../ApiConstant';
import ProductRiskCheckList from './ProductRiskCheckList';
export default class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: ["NAME", "MANUFACTURER", "TRADE NAME", "ATC CODE", "DESCRIPTION", "CLASS DRUG ", "RISK TRACKING", "REVIEW FREQUENCY"],
            menuArray: [
                { name: 'Edit', icon: 'fas fa-pen', id: 1 },
                { name: 'Delete', icon: 'fas fa-trash', id: 2 }
            ],
            tabHeader: [],
            page: 1,
            loading: false,
            search: '',
            userList: [],
            productList: [],
            productCopyList: [],
            isAdd: false,
            selectedData: '',
            riskCheckList: [],
            showProductRisk: false
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        this.getUsers();
        this.getProducts();
    }
    getProducts = async () => {
        const { page, search } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        const getProducts = await Service.appservice('get', signalApi.drugs + queryParam);
        if (getProducts?.data) {
            this.setState({
                productList: getProducts.data, productCopyList: getProducts.data,
                pageNumbers: getProducts.metadata ? Array.from({ length: getProducts.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    getUsers = async () => {
        const getUsers = await Service.appservice('get', `${process.env.REACT_APP_API_ENDPOINT_VC19}users`);
        if (getUsers?.data) this.setState({ userList: getUsers.data });
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    paginate = async (page) => {
        await this.setState({ page, loading: true });
        this.getProducts();
    }
    render() {
        const { page, pageNumbers, userList, productCopyList, productList, loading, tabHeader, menuArray, isAdd,
            selectedData, showProductRisk, riskCheckList } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Products | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        <Card className='shadow rounded-3'>
                            <TabHeader
                                key={'Products'}
                                Title={'Products'}
                                headers={tabHeader}
                                isAdd
                                isLoading={loading}
                                onSearch={async (isSearch) => {
                                    await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                    await this.getProducts();
                                }}
                                onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                onAdd={async (isAdd) => await this.setState({ isAdd })}
                            />
                        </Card>
                        {isAdd ?
                            <ProductsAdd selectedData={selectedData}
                                userList={userList}
                                onClose={(e) => { this.setState({ selectedData: '', isAdd: false }); if (e) { this.getProducts() } }} /> :
                            <Fragment></Fragment>}
                        {showProductRisk ?
                            <ProductRiskCheckList selectedData={selectedData} list={riskCheckList} onClose={(e) => { if (e) { this.getProducts() } this.setState({ selectedData: '', riskCheckList: [], showProductRisk: false }) }} /> :
                            <Fragment>

                                <div>
                                    <InlineSearch
                                        productCopyList={productCopyList}
                                        onSearch={productList => this.setState({ productList })} />
                                    <ProductsTable
                                        tabHeader={tabHeader}
                                        loading={loading}
                                        filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                        menuArray={menuArray}
                                        productList={productList}
                                        onDelete={this.getProducts}
                                        onMenu={async (a, b) => {
                                            if (b == 1) await this.setState({ isAdd: false })
                                            this.setState({
                                                selectedData: a,
                                                isAdd: b == 1 ? true : false
                                            })
                                        }}
                                        onShowRisk={(e) => { this.setState({ selectedData: e.selectedData, riskCheckList: e.riskCheckList, showProductRisk: true }) }} />
                                </div>
                                {loading ? <Fragment></Fragment> :
                                    productList && productList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                        nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                        <AppNodata />
                                }
                            </Fragment>
                        }
                    </Container>
                </div>
            </React.Fragment >
        )
    }

}
