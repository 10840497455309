import React, { Component, Fragment } from 'react';
import { AppNodata, AppPagination } from 'components/Reusable';
import { Service } from '../Appservice';
import { Card,  Container } from "reactstrap";
import TabHeader from '../components/tabHeader';
import InlineSearch from './InlineSearch';
import MetaTags from "react-meta-tags";
import UsersTable from './UsersTable';
import UsersAdd from './UsersAdd';
import Chat from './Chat';
import WorkflowTeam from './WorkflowTeam';
import { signalApi, userApi } from '../ApiConstant';
export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: ["FIRST NAME", "LAST NAME", "USER NAME", "EMAIL", "ROLE", "ASSIGNED ACTIONS", "DRUG"],
            menuArray: [
                { name: 'Edit', icon: 'fas fa-pen', id: 1 },
                { name: 'Delete', icon: 'fas fa-trash', id: 2 },
                { name: 'Chat', icon: 'fab fa-rocketchat', id: 3 },
            ],
            tabHeader: [],
            page: 1,
            loading: false,
            search: '',
            userList: [],
            rolesList: [],
            productList: [],
            workflowteamList: [],
            userCopyList: [],
            isAdd: false,
            isChat: false,
            selectedData: '',
            viewChat:window.location.pathname == '/users/chat',
            viewWorkflowteam: false
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        this.getList();
        this.getUsers();
    }
    getUsers = async () => {
        const { page, search } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        const getUsers = await Service.appservice('get', userApi.users + queryParam);
        if (getUsers?.data) {
            this.setState({
                userList: getUsers.data, userCopyList: getUsers.data,
                pageNumbers: getUsers.metadata ? Array.from({ length: getUsers.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    getList = async () => {
        const roles = await Service.appservice('get', userApi.roles);
        const products = await Service.appservice('get', signalApi.drugs);
        const workflowteam = await Service.appservice('get', signalApi.workflowTeam);
        this.setState({ rolesList: roles?.results ?? [], productList: products?.data ?? [], workflowteamList: workflowteam?.data ?? [] });
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    paginate = async (page) => {
        await this.setState({ page, isUpdating: true });
        this.getUsers();
    }
    ondownload = async (isPdf) => {
        const download = isPdf ?
            await Service.appservice('get', userApi.userPdf) :
            await Service.appservice('get', userApi.userXl);
        if (download?.file_url) window.open(download.file_url);
    }
    render() {
        const { page, pageNumbers, loading, tabHeader, menuArray, isAdd, selectedData,
            userCopyList, userList, productList, workflowteamList, rolesList, isChat, viewChat, viewWorkflowteam } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Users | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        {viewChat ?
                            <Chat onClose={() => this.setState({ viewChat: false })} /> :
                            viewWorkflowteam ?
                                <WorkflowTeam userList={userList} onClose={() => this.setState({ viewWorkflowteam: false })} /> :
                                <Fragment>
                                    <Card className='shadow rounded-3'>
                                        <TabHeader
                                            key={'Users'}
                                            Title={'Users'}
                                            headers={tabHeader}
                                            isAdd
                                            isLoading={loading}
                                            onSearch={async (isSearch) => {
                                                await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                                await this.getUsers();
                                            }}
                                            onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                            onAdd={async (isAdd) => await this.setState({ isAdd })}
                                            isDownload
                                            onPdfDownload={() => this.ondownload(true)}
                                            onexcelDownload={() => this.ondownload(false)}
                                        />
                                    </Card>
                                    {isAdd ?
                                        <UsersAdd
                                            selectedData={selectedData}
                                            productList={productList}
                                            rolesList={rolesList}
                                            workflowteamList={workflowteamList}
                                            onClose={(e) => { this.setState({ selectedData: '', isAdd: false }); if (e) { this.getUsers() } }} /> :
                                        <Fragment></Fragment>}
                                    <Fragment>
                                        <div>
                                            <InlineSearch
                                                userCopyList={userCopyList}
                                                onSearch={userList => this.setState({ userList })}
                                                isChat={isChat}
                                                selectedData={selectedData}
                                                onMessage={() => this.setState({ selectedData: '', isChat: false })}
                                                onChat={() => { this.setState({ viewChat: true }) }}
                                                onWorkflowteam={() => { this.setState({ viewWorkflowteam: true }) }} />
                                            <UsersTable
                                                tabHeader={tabHeader}
                                                loading={loading}
                                                filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                                menuArray={menuArray}
                                                userList={userList}
                                                onDelete={this.getUsers}
                                                onMenu={async (a, b) => {
                                                    if (b == 1) await this.setState({ isAdd: false })
                                                    this.setState({
                                                        selectedData: a,
                                                        isAdd: b == 1 ? true : false,
                                                        isChat: b == 3 ? true : false,
                                                    })
                                                }} />
                                        </div>
                                        {loading ? <Fragment></Fragment> :
                                            userList && userList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                                nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                                <AppNodata />
                                        }
                                    </Fragment>
                                </Fragment>
                        }

                    </Container>
                </div>
            </React.Fragment >
        )
    }

}
