import PropTypes from 'prop-types';
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes"
import UserInactivityDetector from 'helpers/userInactivity';
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import PageNotFound from "./components/Common/pageNotFound"
import "./assets/scss/theme.scss"
import { AppApi } from 'Appfirebaseapi';
import toastr from 'toastr';
import toast from 'react-hot-toast';
const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const user = JSON.parse(localStorage.getItem('authUser'));
  window.onload = function () {
    if (user) {
      const _preFix = "3auser" + process.env.REACT_APP_CHAT_PREFIX;
      const userId = _preFix + user.uid;
      AppApi.getLastCount(userId);
    }
  }
  let positionClass = "toast-bottom-right";
  toastr.options = {
    positionClass: positionClass,
  };
  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
      {user &&
        <UserInactivityDetector
          onActive={() => { }}
          onIdle={() => { window.location.href = "/logout"; }} />}
    </React.Fragment>
  )
}
App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
