import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput, AppPagination } from 'components/Reusable';
import { Row, Col, CardBody, Collapse, Form } from 'reactstrap';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { signalApi } from '../ApiConstant';
import { appDate } from '../AppConstants';
import DeleteAlert from '../components/DeleteAlert';
export default class SignalValidationActionComments extends Component {
    static get propTypes() {
        return {
            updateData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        const { updateData } = props;
        this.state = {
            postData: {
                comment: '',
                measure: updateData.id,
            },
            postErr: {
                comment: false,
            },
            page: 1,
            pageNumbers: [],
            loading: true,
            commentList: [],
            isDeleteActionComment: false,
            ischanged: false,
            isAdd: false,
        }
    }
    componentDidMount = async () => {
        await this.getComments()
    }
    paginate = async (page) => {
        await this.setState({ page, loading: true });
        this.getComments();
    }
    getComments = async () => {
        const { page, postData } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        const action = await Service.appservice('get', `${signalApi.topicActions}/${postData.measure}/comments` + queryParam);
        if (action && action.data) {
            await this.setState({
                commentList: action.data, loading: false, isDeleteActionComment: false, pageNumbers: action.metadata
                    ? Array.from(
                        { length: action.metadata.total_pages },
                        (v, k) => k + 1
                    )
                    : [],
            });

        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    onError = async (e, name) => await this.setState({ postErr: { ...this.state.postErr, [name]: e } });
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onSubmit = async (delId) => {
        const { isDeleteActionComment, postData, postErr } = this.state;
        const errorExist = Object.values(postErr).find(v => v);
        if (!postData.comment && !isDeleteActionComment) return this.setError('comment');
        if (errorExist) return false;
        let data = { ...postData };
        this.setState({ loading: true })
        const postAction = isDeleteActionComment ?
            await Service.appservice('delete', `${signalApi.actionComments}/${delId}`) :
            await Service.appservice('post', `${signalApi.actionComments}`, data);
        if (postAction) {
            toast.success(`Comment ${isDeleteActionComment ? 'Deleted' : 'Created'} Successfully !...`);
            this.setState({ postData: { ...this.state.postData, comment: '' }, isDeleteActionComment: 0, ischanged: true });
        }
        await this.getComments();
    }
    render() {
        const { onClose, updateData } = this.props;
        const { postData, postErr, commentList, loading, page, pageNumbers, isAdd, isDeleteActionComment, ischanged } = this.state;
        return (
            <div className="overflow-hidden">
                <AppButton name={`Add/Update Action Comments of ${updateData?.updateData?.measure_data?.name}`} tooltip="Back to List" icon='fas fa-arrow-left' onClick={() => onClose(ischanged)} />
                <Row >
                    <Col md={2}/>
                    <Col md={8} className='card-body'>
                        {isAdd &&
                            <Form className='mt-3'>
                                <Row>
                                    <Col lg={12}>
                                        <AppInput label="Comment *" placeholder="Write your comments *" error="Comment is required" onIValid={e => this.onError(e, 'comment')} onChange={e => { this.setValue(e, 'comment') }} value={postData.comment} isInvalid={postErr.comment} />
                                    </Col>
                                    <Col lg={12} className='text-center mb-2'>
                                        <AppButton className="me-3" icon='fas fa-plus' tooltip="Add Comment" name="Add Validation comment" onClick={this.onSubmit} disabled={loading} />
                                        <AppButton icon='fas fa-times' tooltip="Close" onClick={() => this.setState({ isAdd: false })} />
                                    </Col>
                                </Row>
                            </Form>}
                        <div className='card'>
                            <div className='rounded-2 p-2 shadow  btn-danger d-flex align-items-center justify-content-between'>
                                <h5 className="font-size-14 m-0 p-1 text-white">
                                    <i className="fas fa-check  me-2" /> Comments
                                </h5>
                                {!isAdd &&
                                    <span role='button' className='shadow p-1 rounded bg-white text-danger' onClick={() => this.setState({ isAdd: true })}>
                                        <i className="fas fa-plus me-1" />    Add Comment
                                    </span>}
                            </div>
                            <div className='card-body'>
                                <PerfectScrollbar >
                                    <div className="accordion-body" >
                                        {commentList?.length ?
                                            commentList.map((e, i) => {
                                                return (
                                                    isDeleteActionComment == e.id ?
                                                        <Fragment key={i}>
                                                            <DeleteAlert loading={loading} title="Comments" onYes={() => { this.onSubmit(isDeleteActionComment) }}
                                                                onNo={() => { this.setState({ isDeleteActionComment: 0, updateData: '' }) }} />
                                                        </Fragment> :
                                                        <Fragment key={i}>
                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <span className='mb-2 text-center text-danger'>
                                                                    <i className='fas fa-calendar-day me-2 '></i>{appDate(e?.created_at)}
                                                                </span>
                                                                <span role="button" className='badge badge-soft-danger p-2' onClick={() => { this.setState({ isDeleteActionComment: e.id }); }}>
                                                                    <i className='fas fa-trash text-danger' ></i>
                                                                </span>
                                                            </div>
                                                            <div className='card shadow-sm p-2' >
                                                                <span className='mt-3'>
                                                                    <h5>{e?.comment}</h5>
                                                                    <small className='blockquote-footer'>Posted by {e?.commented_by_data?.first_name + ' ' + e?.commented_by_data?.last_name}</small>
                                                                </span>
                                                            </div>
                                                        </Fragment>)
                                            }) :
                                            loading ?
                                                <div className='m-3'>
                                                    <p className="card-text placeholder-glow">
                                                        <span className="placeholder rounded col-7"></span>
                                                        <span className="placeholder rounded col-4"></span>
                                                        <span className="placeholder rounded col-4"></span>
                                                        <span className="placeholder rounded col-6"></span>
                                                        <span className="placeholder rounded col-8"></span>
                                                    </p>
                                                </div> :
                                                <div className='text-center'>
                                                    <span className='text-danger shadow p-2 rounded'>
                                                        No comments added..
                                                    </span>
                                                </div>
                                        }
                                    </div>
                                </PerfectScrollbar>
                                {
                                    commentList?.length ?
                                        <AppPagination previousPage={e => { this.paginate(e) }}
                                            nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                        <Fragment></Fragment>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={2}/>
                </Row>
            </div>
        )
    }
}
