import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Row, Container, Col, CardBody, CardTitle, Card, CardText, CardHeader } from 'reactstrap';
import Style from './DocView.css';
import { asBlob } from 'html-docx-js-typescript'
// if you want to save the docx file, you need import 'file-saver'
import { saveAs } from 'file-saver';
import { appDate, setStatus } from '../../AppConstants';
import Logo from 'assets/logo3a.png'
export default class DocView extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            signalHistory: PropTypes.any,
        };
    }
    render() {
        const { selectedData } = this.props;
        return (
            <Fragment>
                <div style={{ marginTop: 3,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column' }}>
                    <h3 style={{ color: '#dd3d44' }}>Details</h3>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            {
                                selectedData?.signal_data && Object.keys(selectedData?.signal_data).length ?
                                    Object.entries(selectedData?.signal_data).map((j, index) => {
                                        return (
                                            <tr key={index} style={{ border: '3px solid orange' }} >
                                                <td >{j[0].replace(/_+/g, " ")} </td>
                                                <td>{j[1] ? (j[1].toString().replace(/_+/g, " ")) : "-"}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <p className='text-center'>No Details found</p>
                            }
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}
