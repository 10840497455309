import React from "react";
import { Col, Container, Row } from "reactstrap";
const isLoggedin = localStorage.getItem("authToken");
import error from 'assets/images/error-img.png';
import { AppButton } from "components/Reusable";
import { MetaTags } from "react-meta-tags";
const PageNotFound = () => {
    const goToPage = () => {
        if (isLoggedin) {
            window.location.href = "/user-dashboard";
        } else {
            window.location.href = "/login";
        }
    }
    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-5">
                <MetaTags>
                    <title>404 Error Page | Skote - React Admin & Dashboard Template</title>
                </MetaTags>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h1 className="display-2 font-weight-medium">
                                    4<i className="bx bx-buoy bx-spin text-primary display-3" />4
                                </h1>
                                <h4 className="text-uppercase">Sorry, page not found</h4>
                                <div className="mt-5 text-center">
                                    <AppButton name={`Back to ${isLoggedin ? "Dashboard" : "Login"}`} tooltip="back" onClick={goToPage} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="8" xl="6">
                            <div>
                                <img src={error} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PageNotFound;
