import React, { Component } from 'react';
import { Form, InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
export default class InlineSearch extends Component {
    static get propTypes() {
        return {
            workFlowCopyList: PropTypes.any,
            onSearch: PropTypes.func,
            onActionState: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            inlineSearch: ''
        }
    }
    onInlineSearch = (e) => {
        const searchText = e;
        function matchValue(input) {
            let result = input?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            return result;
        }
        const filteredData = this.props.workFlowCopyList.filter((row) => {
            return (matchValue(row?.name) || matchValue(row?.description) || matchValue(row?.stage));
        });
        this.props.onSearch(filteredData);
        this.setState({ inlineSearch: searchText });
    }
    render() {
        const { inlineSearch } = this.state;
        return (
            <div className='d-flex justify-content-sm-between align-items-center mt-2 mt-sm-0'>
                <Form className="row col-lg-4 col-md-4 col-sm-12 col-xs-12" key={'Search'}>
                    <InputGroup>
                        <div className="input-group-text shadow text-danger" onClick={() => { this.setState({ inlineSearch: '' }); this.props.onSearch(this.props.workFlowCopyList); }}>
                            {inlineSearch ? <i className='fas fa-times text-danger' /> : <i className='fas fa-search text-danger' />}
                        </div>
                        <input onKeyPress={(e) => { e.key === 'Enter' ? e.preventDefault() : ''; }}
                            onChange={async (e) => this.onInlineSearch(e.target.value)}
                            value={inlineSearch} type="text" className="form-control" id="autoSizingInputGroup" placeholder="Search in table..." />
                    </InputGroup>
                </Form>
                <AppButton icon="fa fa-eye" name="Action State" className="ms-2 ms-sm-0" onClick={this.props.onActionState}  />
            </div>
        )
    }
}
