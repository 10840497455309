import React, { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import PropTypes from 'prop-types';
import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import toast from 'react-hot-toast';
import AppButton from './Button';
const appId = process.env.REACT_APP_SPEECHLY_ID;
const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);
const AppSpeechInput = props => {
    const {
        finalTranscript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition();
    if (!browserSupportsSpeechRecognition) return toast.error('Web Speech API is not available in this browser 🤷‍');
    useEffect(() => {
        if (!finalTranscript) return;
        return props.onSpeech(finalTranscript.replace(/\./g, ""));
    }, [finalTranscript]);
    return (
        <AppButton isIcon onClick={() => isMicrophoneAvailable ?
            listening ? SpeechRecognition.stopListening() : SpeechRecognition.startListening() :
            toast.error('Enble the microphone permission')}
            icon={isMicrophoneAvailable && listening ? "fas fa-microphone-alt" : "fas fa-microphone-alt-slash"}
            disabled={props.isDisabled}
            className={isMicrophoneAvailable && listening ? 'pulse p-2' : ' p-2'}
            tooltip="Voice Search" />

    );
}
AppSpeechInput.propTypes = {
    onSpeech: PropTypes.any,
    isDisabled: PropTypes.bool,
}
export default AppSpeechInput;
