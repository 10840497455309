import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput, AppPagination, AppShowmore } from 'components/Reusable';
import { Row, Col, CardBody, Collapse, Form, UncontrolledTooltip } from 'reactstrap';
import { Service } from '../Appservice';
import toast from 'react-hot-toast';
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { signalApi } from '../ApiConstant';
import { appDate } from '../AppConstants';
import DeleteAlert from '../components/DeleteAlert';
export default class SignalEvaluationActionAttachment extends Component {
    static get propTypes() {
        return {
            updateData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        const { updateData } = props;
        this.state = {
            postData: {
                title: "",
                description: "",
                attachment: "",
                measure: updateData.id,
            },
            postErr: {
                title: false,
                description: false,
                attachment: false,
            },
            page: 1,
            pageNumbers: [],
            loading: true,
            attachmentList: [],
            isDeleteAction: false,
            search: "",
            attachName: "",
            showAdd: false,
            editAttachId: null,
            isAttachDelete: false,

            isAdd: false,
            isListOpen: true,
            isModified: false
        }
    }
    componentDidMount = async () => {
        await this.getAttachments()
    }
    paginate = async (page) => {
        await this.setState({ page, loading: true });
        this.getAttachments();
    }
    getAttachments = async () => {
        const { page, postData, search } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        const action = await Service.appservice('get', `${signalApi.actionAttachmentsGet}/${postData.measure}` + queryParam);
        if (action && action.data) {
            this.setState({
                attachmentList: action.data, loading: false, isDeleteAction: false, pageNumbers: action.metadata
                    ? Array.from(
                        { length: action.metadata.total_pages },
                        (v, k) => k + 1
                    )
                    : [],
            });

        }
    }
    setValue = async (i, e, isupload) => {
        const value = isupload ? i.target.files[0] : i.target.value;
        await this.setState({
            postData: { ...this.state.postData, [e]: value },
        });
        if (isupload)
            await this.setState({ attachName: value.name });
        if (!value) this.setError(e);
        // Check if the file size is greater than 10 MB (10,485,760 bytes)
        if (isupload && i.target.files[0].size > 10485760) {
            // Display an error message
            toast.error("Error: File size cannot exceed 10 MB.");
            // Clear the file input value
            i.target.value = "";
            this.setError(e);
        } else {
            this.removeErr(e);
        }
    };
    onError = async (e, name) => await this.setState({ postErr: { ...this.state.postErr, [name]: e } });
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    setEdit = async (e) => {
        await this.setState({ postData: { ...this.state.postData, title: e.title, description: e.description, attachment: e.url_attachment }, isAdd: true, isListOpen: false, attachName: e.url_attachment, editAttachId: e.id })
    }
    getFormData(object) {
        const formData = new FormData()
        Object.keys(object).forEach(key => formData.append(key, object[key]))
        return formData
    }
    onSubmit = async (delId) => {
        const { isDeleteAction, postData, postErr, editAttachId } = this.state;
        const errorExist = Object.values(postErr).find(v => v);
        if (!isDeleteAction) {
            if (!postData.title) return this.setError('title');
            if (!postData.description) return this.setError('description');
            if (!postData.attachment) return this.setError('attachment');
            if (errorExist) return false;
        }
        let data = { ...postData };
        if (editAttachId) {
            if (!data.attachment.name) {
                let isUrl = data.attachment.includes('http')
                if (isUrl) delete data.attachment
            }
        }
        this.setState({ loading: true });
        const postAction = isDeleteAction ?
            await Service.appservice('delete', `${signalApi.actionAttachments}/${delId}`) :
            !editAttachId ? await Service.appservice('post', `${signalApi.actionAttachments}`, this.getFormData(data), true) :
                await Service.appservice('put', `${signalApi.actionAttachments}/${editAttachId}`, this.getFormData(data), true);
        if (postAction) {
            toast.success(`Attachment ${isDeleteAction ? 'Deleted' : editAttachId ? "Updated" : 'Created'} Successfully !...`);
            if (!isDeleteAction) this.resetState();
            else await this.setState({ isDeleteAction: false })
            this.setState({ isAdd: false, isListOpen: true, isModified: true })
        }
        this.setState({ loading: false });
        await this.getAttachments();
    }
    resetState = async () => {
        await this.setState({ postData: { ...this.state.postData, title: '', description: '', attachment: '' }, showAdd: !this.state.showAdd, attachName: '', editAttachId: null, isAdd: false, isListOpen: true });
    }
    render() {
        const { onClose, updateData } = this.props;
        const { loading, isListOpen, postData, postErr, attachmentList, isModified, page, pageNumbers, editAttachId, attachName, isAttachDelete, isDeleteAction, isAdd } = this.state;
        const isSubmitDisabled = Object.values(postErr).some((value) => value);
        return (
            <Fragment>
                <AppButton name="Add/Update Action Attachment" tooltip="Back to View" icon='fas fa-arrow-left' onClick={onClose} />
                <Row >
                    <Col md={2}/>
                    <Col md={8} className='card-body'>
                        {isAdd &&
                            <div >
                                <Form className='mt-4 ms-md-5 me-md-5'>
                                    <Row>
                                        <Col lg={6} md={6}>
                                            <AppInput label="Title *" error="Title is required" onChange={e => { this.setValue(e, 'title') }} value={postData.title} isInvalid={postErr.title} onIValid={e => this.onError(e, 'title')} />
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <AppInput label="Description *" error="Description is required" onChange={e => { this.setValue(e, 'description') }} value={postData.description} isInvalid={postErr.description} type="text" onIValid={e => this.onError(e, 'description')} />
                                        </Col>
                                        <Col lg={12} md={12}>
                                            {
                                                attachName ?
                                                    <>
                                                        {
                                                            isAttachDelete ?
                                                                <Fragment key={'Delete File'}>
                                                                    <DeleteAlert loading={loading} title="File" onYes={() => { this.setState({ postData: { ...this.state.postData, attachment: "" }, attachName: "", isAttachDelete: false }) }}
                                                                        onNo={() => { this.setState({ isAttachDelete: false }) }} />
                                                                </Fragment> :
                                                                attachName?.includes("https") ?
                                                                    <p className='shadow p-2 rounded'>
                                                                        <a rel="noreferrer" target="_blank" href={attachName} download={attachName} >
                                                                            Attached file  <i role='button' id="view" onClick={async () => { attachName }} className='fas ms-2  fa-solid fa-paperclip'></i>
                                                                        </a>
                                                                        <i role={'button'} id="delete" className='fa fa-trash-alt text-danger float-end' onClick={() => { this.setState({ isAttachDelete: true }) }} />
                                                                        <UncontrolledTooltip target={"view"}>View file</UncontrolledTooltip>
                                                                        <UncontrolledTooltip target={"delete"}>Delete</UncontrolledTooltip>
                                                                    </p> :
                                                                    <p className='shadow p-2 rounded'>
                                                                        {attachName.substring(attachName.lastIndexOf('/') + 1)}
                                                                        <i role={'button'} id="delete" className='fa fa-trash-alt text-danger float-end' onClick={() => { this.setState({ isAttachDelete: true }) }} />
                                                                        <UncontrolledTooltip target={"delete"}>Delete</UncontrolledTooltip>
                                                                    </p>
                                                        }
                                                    </>
                                                    :
                                                    <AppInput label="Attachment *" type="file" error="Attachment is required" onChange={e => { this.setValue(e, 'attachment', true) }} isInvalid={postErr.attachment} infoText="Upload any files less then 10 mb" />
                                            }
                                        </Col>
                                        <Col lg={12} md={12} className='text-center'>
                                            <AppButton name={`${editAttachId ? 'Update ' : 'Add '} Attachment`} icon='fas fa-check' onClick={this.onSubmit} disabled={isSubmitDisabled || loading} />
                                            <AppButton className="ms-3" icon='fas fa-times' tooltip="Close" onClick={this.resetState} />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>}
                        <div className='card mt-3'>
                            <div className='rounded-2 p-2 shadow  btn-danger d-flex align-items-center justify-content-between'>
                                <h5 className="font-size-14 m-0 p-1 text-white">
                                    <i className="fas fa-check  me-2" /> Attachment List
                                </h5>
                                {!isAdd &&
                                    <span role='button' className='shadow p-1 rounded bg-white text-danger' onClick={() => this.setState({ isAdd: true })}>
                                        <i className="fas fa-plus me-1" />    {editAttachId ? 'Update ' : 'Add '} Attachment
                                    </span>}
                            </div>
                            <div className='card-body'>
                                <PerfectScrollbar >
                                    <div className="accordion-body" >
                                        {attachmentList?.length ?
                                            attachmentList.map((e, i) => {
                                                return (
                                                    isDeleteAction == e.id ?
                                                        <Fragment key={i}>
                                                            <DeleteAlert loading={loading} title="Attachment" onYes={() => { this.onSubmit(isDeleteAction) }}
                                                                onNo={() => { this.setState({ isDeleteAction: 0, updateData: '' }) }} />
                                                        </Fragment> :
                                                        <Fragment key={i}>
                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <span className='mb-2 text-center text-danger'>
                                                                    <i className='fas fa-calendar-day me-2 '></i>{appDate(e?.created_at)}
                                                                </span>
                                                                <div>
                                                                    <span role='button' className='badge badge-soft-danger p-2  me-3' onClick={() => { this.setEdit(e) }}>
                                                                        <i className='fas fa-pen text-danger'></i>
                                                                    </span>
                                                                    <span role='button' className='badge badge-soft-danger p-2 ' onClick={() => { this.setState({ isDeleteAction: e.id }); }}>
                                                                        <i className='fas fa-trash text-danger' ></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='card shadow-sm p-2' >
                                                                <span className='mt-3'>
                                                                    <h5>{e?.title}</h5>
                                                                    <AppShowmore text={e?.description} id={'toolsattachdesc' + i} />
                                                                    <small className='blockquote-footer'>Posted by {e?.created_by_data?.first_name + ' ' + e?.created_by_data?.last_name}</small>
                                                                </span>
                                                            </div>
                                                        </Fragment>)
                                            }) :
                                            loading ?
                                                <div className='m-3'>
                                                    <p className="card-text placeholder-glow">
                                                        <span className="placeholder rounded col-7"></span>
                                                        <span className="placeholder rounded col-4"></span>
                                                        <span className="placeholder rounded col-4"></span>
                                                        <span className="placeholder rounded col-6"></span>
                                                        <span className="placeholder rounded col-8"></span>
                                                    </p>
                                                </div> :
                                                <div className='text-center'>
                                                    <span className='text-danger shadow p-2 rounded'>
                                                        No attachments added..
                                                    </span>
                                                </div>
                                        }
                                    </div>
                                </PerfectScrollbar>
                                {
                                    attachmentList?.length ?
                                        <AppPagination previousPage={e => { this.paginate(e) }}
                                            nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                        <Fragment></Fragment>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={2}/>
                </Row>
            </Fragment>
        )
    }
}
