import React, { Component } from 'react';
import { AppButton, AppInput } from 'components/Reusable';
import toast from "react-hot-toast";
import { Col, Form, Row } from "reactstrap";
import { Service } from 'pages/SignalManagement/Scenes/Appservice';
import PropTypes from 'prop-types';
export default class ChangePassword extends Component {
    static get propTypes() {
        return {
            onCancel: PropTypes.func
        };
    }
    constructor() {
        super();
        this.state = {
            isUpdating: false,
            postData: {
                password: '',
                confirm_password: ''
            },
            postErr: {
                password: false,
                confirm_password: false,
                samePassword: false,
                passwordErr: "Password is required"
            }
        }
    }
    componentDidMount = async () => {

    }
    setValue = async (i, e, isFile) => {
        const value = isFile ? i.target.files[0] : i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onSubmit = async () => {
        const { postData } = this.state;
        if (!postData.password) return this.setError('password');
        if (postData.password) {
            let passwordErr = "";
            const isNonWhiteSpace = /^\S*$/; const isContainsUppercase = /^(?=.*[A-Z]).*$/; const isContainsLowercase = /^(?=.*[a-z]).*$/;
            const isContainsNumber = /^(?=.*[0-9]).*$/; const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
            const isValidLength = /^.{8,15}$/;
            if (!isNonWhiteSpace.test(postData.password)) passwordErr = "Password must not contain Whitespaces.";
            if (!isValidLength.test(postData.password)) passwordErr += "Password must be 8-15 Characters Long.";
            if (!isContainsUppercase.test(postData.password)) passwordErr += "Password must have at least one Uppercase Character.";
            if (!isContainsLowercase.test(postData.password)) passwordErr += "Password must have at least one Lowercase Character.";
            if (!isContainsNumber.test(postData.password)) passwordErr += "Password must contain at least one Digit.";
            if (!isContainsSymbol.test(postData.password)) passwordErr += "Password must contain at least one Special Symbol.";
            if (passwordErr) {
                this.setState({
                    postErr: {
                        ...this.state.postErr,
                        password: true,
                        passwordErr
                    }
                });
                return;
            }
        }
        if (!postData.confirm_password) return this.setError('confirm_password');
        if (postData.confirm_password != postData.password) return this.setError('samePassword');
        let data = { ...postData };
        await this.setState({ isUpdating: true });
        const postAction = await Service.appservice('post', `${process.env.REACT_APP_API_ENDPOINT_VC19}changepassword`, data);
        if (postAction) {
            toast.success('Password Changed Successfully !...');
            window.location.href = "/login";
        }
        else toast.success('Password not changed. Retry again !...');
        await this.setState({ isUpdating: false, postData: { ...this.state.postData, password: '', confirm_password: '' } });
    }
    render() {
        const { postData, postErr, isUpdating, } = this.state;

        return (
            <React.Fragment>
                <Form className="ms-5 me-5 mt-2" >
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <AppInput type="password" label="New Password *" error={postErr.passwordErr} value={postData.password} isInvalid={postErr.password} onChange={e => { this.setValue(e, 'password') }} isPasswordShow={true} />
                        </Col>
                        <Col lg={12}>
                            <AppInput type="password" label="Confirm Password *" error={`Confirm Password ${postErr.samePassword ? "  is not same" : "  is required"}`} value={postData.confirm_password} isInvalid={(postErr.confirm_password || postErr.samePassword)} onChange={e => { this.setValue(e, 'confirm_password'); this.removeErr('samePassword') }} />
                        </Col>
                        <Col lg={12} className='text-center'>
                            <AppButton name='Change' icon='fas fa-check' classList=" btn btn-sm btn-danger float-end me-3" onClick={this.onSubmit} iName={'Submit'} />
                        </Col>
                    </Row>
                </Form>
                <span className='text-center text-danger p-2 font-size-12 mt-3 mb-2'>
                    <i className='fas fa-info-circle me-2' /> After password change page will automatically logout
                </span>
            </React.Fragment>
        )
    }
}
