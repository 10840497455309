import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import Header from './Header';
import Count from './Count';
import Graph from './Graph';
import Action from './Action';
import { Service } from '../Appservice';
import domToPdf from "dom-to-pdf"
import FullGraph from './FullGraph';
import { signalApi } from '../ApiConstant';
import Topics from './Topics';
import { getKeyItem, sourceId } from '../AppPermission';
export default class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            signalCounts: [
                {
                    icon: "fas fa-signal",
                    title: "Total Signals",
                    value: 0,
                    id: 1,
                },
                {
                    icon: "fas fa-tasks",
                    title: "Total Actions",
                    value: 0,
                    id: 2,
                },
                {
                    icon: "fas fa-check",
                    title: "Signals Validated",
                    value: 0,
                    id: 3,
                },
                {
                    icon: "fas fa-ban",
                    title: "Signals Refuted",
                    value: 0,
                    id: 4,
                },
            ],
            signalStatusGraph: [],
            signalWorkFlowGraph: [],
            signalActionGraph: [],
            drugName: [],
            signalTimeGraph: [],
            download: false,
            isExpand: false,
            graphId: 0,
            showAction: false,
            showTopics: false,
            drugList: [],
            postData: {
                drug: '',
                signal_parent_model_id: '',
                duration: '',
                month: '',
                quater: '',
                year: '',
                exclude_invalid_refuted_and_recommned: false
            }
        }
    }
    componentDidMount = async () => {
        this.getDashboard();
    }
    getDashboard = async () => {
        const { drug, signal_parent_model_id, duration, month, year, quarter, exclude_invalid_refuted_and_recommned } = this.state.postData;
        let params = "?src_identifier=" + getKeyItem(sourceId);
        if (drug) params += "&drug=" + drug;
        if (signal_parent_model_id) params += "&signal_parent_model_id=" + signal_parent_model_id;
        if (duration) params += "&duration=" + duration;
        if (month) params += "&month=" + month;
        if (year) params += "&year=" + year;
        if (quarter) params += "&quarter=" + quarter;
        if (exclude_invalid_refuted_and_recommned) params += "&exclude_invalid_refuted_and_recommned=" + exclude_invalid_refuted_and_recommned;
        this.setState({ loading: true })
        const dash = await Service.appservice("get", signalApi.dashboard + params);
        if (dash?.data) {
            this.setCount(dash.data[0])
            this.setSignalStatus(dash.data[0])
            this.setsignalWorkFlow(dash.data[0])
            this.setsignalTime(dash.data[0])
            this.setsignalAction(dash.data[0]);
            this.setState({ drugList: dash.data[0].drug_list })
        }
        this.setState({ loading: false })
    }
    setCount = (dashCount) => {
        const { signalCounts } = this.state;
        signalCounts.forEach(e => {
            if (e.id == 1) e.value = dashCount.signal_count
            if (e.id == 2) e.value = dashCount.total_measures
            if (e.id == 3) e.value = dashCount.signal_validated
            if (e.id == 4) e.value = dashCount.signal_refuted
        })
        this.setState({ signalCounts })
    }
    setSignalStatus = (dashCount) => {
        const { exclude_invalid_refuted_and_recommned } = this.state.postData;
        const excludedData = [
            {
                value: dashCount.signal_refuted,
                name: "Refuted (" + dashCount.signal_refuted + ")",
                id: 'R'
            },
            {
                value: dashCount.signal_invalid,
                name: "Invalid (" + dashCount.signal_invalid + ")",
                id: 'IN'
            },

        ];
        const signaldata = [
            {
                value: dashCount.signal_confirmed,
                name: "Confirmed (" + dashCount.signal_confirmed + ")",
                id: 'C'
            },
            {
                value: dashCount.signal_ongoing_review,
                name: "Ongoing review (" + dashCount.signal_ongoing_review + ")",
                id: 'OR'
            },
            {
                value: dashCount.signal_monitoring,
                name: "Monitoring (" + dashCount.signal_monitoring + ")",
                id: 'M'
            },
            {
                value: dashCount.signal_validated,
                name: "Valid (" + dashCount.signal_validated + ")",
                id: 'V'
            },
        ];
        const signalStatusGraph = exclude_invalid_refuted_and_recommned ? signaldata : [...signaldata, ...excludedData];
        this.setState({ signalStatusGraph })
    }
    setsignalWorkFlow = (dashCount) => {
        const { exclude_invalid_refuted_and_recommned } = this.state.postData;
        const excludedData = [
            {
                value: dashCount.total_recommendation_status_count,
                name: "Recommendation (" + dashCount.total_recommendation_status_count + ")",
                id: 4
            },]
        const signaldata = [
            {
                value: dashCount.total_validation_count,
                name: "Validation & Prioritisation  (" + dashCount.total_validation_count + ")",
                id: 1
            },
            {
                value: dashCount.total_evaluation_count,
                name: "Evaluation (" + dashCount.total_evaluation_count + ")",
                id: 2
            },
            {
                value: dashCount.total_risk_categorisation_count,
                name: "Risk Categorisation (" + dashCount.total_risk_categorisation_count + ")",
                id: 3
            }
        ];
        const signalWorkFlowGraph = exclude_invalid_refuted_and_recommned ? signaldata : [...signaldata, ...excludedData];
        this.setState({ signalWorkFlowGraph })
    }
    setsignalTime = (dashCount) => {
        const signalTimeGraph = [
            {
                value: dashCount.signal_ontime,
                name: "OnTime (" + dashCount.signal_ontime + ")",
                id: 1
            },
            {
                value: dashCount.signal_delayed,
                name: "Delayed (" + dashCount.signal_delayed + ")",
                id: 2
            },
        ];
        this.setState({ signalTimeGraph })
    }
    setsignalAction = (dashCount) => {
        let drugName = [], actions = [], topics = [];
        dashCount.drug_list.forEach(e => {
            drugName.push(e.name)
            actions.push(e.actions)
            topics.push(e.topics)
        })
        const signalActionGraph = [
            { name: "Topics", data: topics, id: 1 },
            { name: "Actions", data: actions, id: 2 },
        ];
        this.setState({ signalActionGraph, drugName })
    }
    downloadPdf = async () => {
        this.setState({ download: true })
        const element = document.querySelector(".pdfDownload")
        const options = {
            filename: "Signal -" + Date.now() + ".pdf",
        }
        element.style.margin = "5%"
        return domToPdf(element, options, () => {
            // callback function
            element.style.removeProperty("margin")
            this.setState({ download: false })
        })
    }
    render() {
        const { loading, signalCounts, drugName, signalActionGraph, signalStatusGraph, signalTimeGraph, signalWorkFlowGraph,
            download, graphId, isExpand, showAction, drugList, showTopics, postData } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Dashboard | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                            {
                                showAction ?
                                    <Action onClose={() => this.setState({ showAction: false })} /> :
                                    showTopics ?
                                        <Topics onClose={() => this.setState({ showTopics: false })} /> :
                                        isExpand ?
                                            <FullGraph onClose={() => { this.setState({ isExpand: false, graphId: 0 }) }} drugName={drugName} signalActionGraph={signalActionGraph}
                                                signalStatusGraph={signalStatusGraph} signalTimeGraph={signalTimeGraph} signalWorkFlowGraph={signalWorkFlowGraph} graphId={graphId}
                                                drugList={drugList} />
                                            :
                                            <Fragment>
                                                <Header
                                                    onPdf={this.downloadPdf}
                                                    isDownload={download}
                                                    onshowAction={(e) => { this.setState({ showAction: e }) }}
                                                    onshowTopic={(e) => { this.setState({ showTopics: e }) }}
                                                    showAction={showAction}
                                                    onFilter={async (e) => { await this.setState({ postData: { ...this.state.postData, ...e } }); this.getDashboard() }}
                                                    loading={loading}
                                                    onExclude={async (e) => { await this.setState({ postData: { ...this.state.postData, exclude_invalid_refuted_and_recommned: e } }); this.getDashboard() }}
                                                    isExcluded={postData.exclude_invalid_refuted_and_recommned}
                                                />

                                                <div className="pdfDownload">
                                                    <Count loading={loading} counts={signalCounts} />
                                                    <Graph onExpand={(e) => this.setState({ isExpand: true, graphId: e })} loading={loading} drugName={drugName} signalActionGraph={signalActionGraph}
                                                        signalStatusGraph={signalStatusGraph} signalTimeGraph={signalTimeGraph} signalWorkFlowGraph={signalWorkFlowGraph} />
                                                </div>
                                            </Fragment>
                            }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
