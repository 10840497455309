import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput } from 'components/Reusable';
import { Col, Form, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import { Service } from '../Appservice';
import { signalApi } from '../ApiConstant';
import { priorityList, validationList } from '../AppConstants';
export default class SignalMarkValidation extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
            isGrid: PropTypes.bool,
        };
    }
    constructor(props) {
        super(props);
        const { selectedData } = this.props;
        this.state = {
            postData: {
                validation_status: selectedData.validation_status ?? '',
                validation_comment: selectedData.validation_comment ?? '',
                priority: selectedData.priority ?? '',
                priority_comment: selectedData.priority_comment ?? '',
            },
            postErr: {
                validation_status: false,
                validation_comment: false,
                priority: false,
                priority_comment: false
            },
            validationList: validationList,
            priorityList: priorityList,
            loading: false
        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onMarkValidation = async () => {
        const { selectedData, onClose } = this.props;
        const { postData } = this.state;
        if (!postData.validation_status) return this.setError('validation_status');
        if (!postData.validation_comment) return this.setError('validation_comment');
        if (postData.validation_status == 'V' && !postData.priority) return this.setError('priority');
        if (postData.validation_status == 'V' && !postData.priority_comment) return this.setError('priority_comment');
        this.setState({ loading: true });
        let validationData = { validation_status: postData.validation_status, comment: postData.validation_comment };
        await Service.appservice('patch', `${signalApi.signals}/${selectedData.id}/update_status`, validationData);
        if (postData.validation_status == 'V') {
            let priorityData = { priority: postData.priority, comment: postData.priority_comment }
            await Service.appservice('patch', `${signalApi.signals}/${selectedData.id}/update_status`, priorityData);
        }
        toast.success('Validation Status Updated Successfully !...');
        this.setState({ loading: true });
        onClose(true);
    }
    render() {
        const { selectedData, onClose, isGrid } = this.props;
        const { postData, postErr, validationList, priorityList, loading } = this.state;
        return (
            <Fragment>
                {isGrid ? <AppButton name="Back" icon="fas fa-arrow-left"  onClick={() => onClose()} /> :
                    <span className='shadow rounded p-2'>{`Mark Validation of ${selectedData?.id}`}</span>}
                <Form className='mt-4 me-5 ms-5'>
                    <Row >
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AppInput label="Validation Status *" type="select" value={postData.validation_status} isInvalid={postErr.validation_status}
                                error="Validation Status is required" onChange={e => { this.setValue(e, 'validation_status') }} iArray={validationList} selectType='idpair' />
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AppInput label="Comment *" type="text" value={postData.validation_comment} isInvalid={postErr.validation_comment}
                                error="Validation Comment is required" onChange={e => { this.setValue(e, 'validation_comment') }} />
                        </Col>
                        {postData.validation_status == 'V' &&
                            <Fragment>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <AppInput label="Priority Status *" type="select" value={postData.priority} isInvalid={postErr.priority}
                                        error="Priority Status is required" onChange={e => { this.setValue(e, 'priority') }} iArray={priorityList} selectType='idpair' />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <AppInput label="Comment *" type="text" value={postData.priority_comment} isInvalid={postErr.priority_comment}
                                        error="Priority Comment is required" onChange={e => { this.setValue(e, 'priority_comment') }} />
                                </Col>
                            </Fragment>}
                        <Col className='text-center'>
                            <AppButton showloader icon='fas fa-check me-2' name="Mark Validation" classList=" ms-2" disabled={loading} onClick={this.onMarkValidation} />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        )
    }
}
