import {
  SIGNAL_MAIN_FILTER,
  SIGNAL_PAGE
} from "./actionTypes"

export const signalMainFilter = (filter) => ({
  type: SIGNAL_MAIN_FILTER,
  payload: filter,
})
export const signalPage = (page) => ({
  type: SIGNAL_PAGE,
  payload: page,
})
