import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Whisper } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
export default class AppButton extends Component {
  static get propTypes() {
    return {
      icon: PropTypes.string,
      name: PropTypes.string,
      tooltip: PropTypes.string,
      onClick: PropTypes.func,
      className: PropTypes.any,
      disabled: PropTypes.bool,
      loader: PropTypes.bool,
      isIcon: PropTypes.bool,
      hideName: PropTypes.bool,
    };
  }
  render() {
    const { icon, name, onClick, className, disabled, tooltip, loader, isIcon, hideName } = this.props;
    const tooltipText = tooltip ? tooltip : name;
    return (
      <Whisper placement="leftStart" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{tooltipText}</Tooltip>} preventOverflow>
        {isIcon ?
          (loader && disabled) ?
            <i className={`fas fa-spinner bx-spin shadow p-2 rounded`} /> :
            <i className={`${icon} text-danger ${className ? className : ''}`} onClick={onClick} />
          :
          <button type='button' onClick={onClick} disabled={disabled} className={`d-inline btn-wrap-text btn btn-sm btn-danger rounded ${className}`}>
            {(disabled && loader) ?
              <i className={`fas fa-spinner bx-spin me-2`} /> :
              icon ?
                <i className={`${icon} ${name && !hideName ? 'me-2' : null}`} /> : null}
            <span className={`${hideName ? 'd-none d-sm-inline ms-2' : 'd-inline'}`}>{name}</span>
          </button>
        }
      </Whisper>
    )
  }
}
