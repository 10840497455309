import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'reactstrap';
import { AppButton, AppInput } from 'components/Reusable';
import toast from 'react-hot-toast';
import { Service } from '../Appservice';
import { signalApi } from '../ApiConstant';
export default class SignalValidationTopic extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
            workFlowTeamList: PropTypes.any,
            workFlowValidationList: PropTypes.any,
            projectsList: PropTypes.any,
            selectedData: PropTypes.any,
            onAddValidationTopic: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        const { selectedData } = this.props;
        const userData = JSON.parse(localStorage.getItem("authUser"))
        this.state = {
            postData: {
                name: '',
                description: '',
                assigned_to: '',
                workflow_configuration: '',
                project: '',
                signal: selectedData.id,
                created_by: Number(userData.uid),
            },
            postErr: {
                name: false,
                description: false,
                assigned_to: false,
                workflow_configuration: false,
                project: false
            },
            loading: false
        }
    }
    setValue = async (i, e) => {
        const value = i.target.value;
        await this.setState({ postData: { ...this.state.postData, [e]: value } });
        if (!value) this.setError(e);
        else this.removeErr(e);
    };
    setError = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
    removeErr = async (e) => { await this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
    onSubmit = async () => {
        const { onClose, onAddValidationTopic } = this.props;
        const { postData } = this.state;
        if (!postData.workflow_configuration) return this.setError('workflow_configuration');
        if (!postData.project) return this.setError('project');
        if (!postData.name) return this.setError('name');
        if (!postData.description) return this.setError('description');
        if (!postData.assigned_to) return this.setError('assigned_to');
        this.setState({ loading: true });
        const postAction = await Service.appservice('post', signalApi.topics, postData);
        if (postAction) {
            toast.success(`Topic Created Successfully !...`);
            await onAddValidationTopic();
            await onClose({ ...postAction, ...postData });
        }
        this.setState({ loading: false })
    }
    render() {
        const { onClose, workFlowTeamList, workFlowValidationList, projectsList } = this.props;
        const { postData, postErr, loading } = this.state;
        return (
            <Fragment>
                <AppButton name="Create topic" className="mb-3" tooltip="Back to Action Validation" icon='fas fa-arrow-left' onClick={onClose} />
                <Form >
                    <Row className="no-gutters align-items-center">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <AppInput disabled={false} label="Workflow Configuration *" type="select" value={postData.workflow_configuration} isInvalid={postErr.workflow_configuration}
                                error="Workflow Configuration is required" onChange={e => { this.setValue(e, 'workflow_configuration') }} iArray={workFlowValidationList} selectType='idpair' />

                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <AppInput label="Project *" type="select" value={postData.project} isInvalid={postErr.project}
                                error="Project is required" onChange={e => { this.setValue(e, 'project') }} iArray={projectsList} selectType='idpair' />

                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <AppInput label="Name *" value={postData.name} isInvalid={postErr.name} error="Name is required" onChange={e => { this.setValue(e, 'name') }} />

                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <AppInput label="Description *" type="text" value={postData.description} isInvalid={postErr.description}
                                error="Description is required" onChange={e => { this.setValue(e, 'description') }} />

                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <AppInput disabled={false} label="Assigned To *" type="select" value={postData.assigned_to} isInvalid={postErr.assigned_to}
                                error="Assigned To  is required" onChange={e => { this.setValue(e, 'assigned_to') }} iArray={workFlowTeamList} selectType='idpair' />
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
                            <AppButton icon='fas fa-check' onClick={this.onSubmit} disabled={loading} showloader name='Create Topic' />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        )
    }
}
