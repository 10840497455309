import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppNodata, AppShowmore } from 'components/Reusable';
import Logo from "assets/images/background.jpg";
import { Row, Col, Card,  NavLink, TabContent, TabPane,  CardImg, Nav, NavItem } from 'reactstrap';
import { appDate } from '../AppConstants';
export default class DateDetails extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            workflowTab: [
                { id: 1, name: 'Action State', icon: 'fas fa-check' },
                { id: 2, name: 'Topics', icon: 'fas fa-file-signature' },
                { id: 3, name: 'Actions', icon: 'fas fa-thumbs-up' },
            ],
            activeIndex: 1
        }
    }
    render() {
        const { onClose, selectedData } = this.props;
        const { workflowTab, activeIndex } = this.state;
        return (
            <Fragment>
                <div className='d-flex justify-content-between card-body'>
                    <AppButton icon='fas fa-arrow-left' onClick={onClose} name={`View details`} />
                </div>
                <Fragment>
                    {selectedData?.length ? selectedData.map((e, i) => {
                        return (
                            <Card key={i}>
                                <Row className="no-gutters align-items-center" >
                                    <Col md={3} >
                                        <CardImg className=" shadow rounded-3" src={Logo} alt="Skote" />
                                    </Col>
                                    <Col md={3}>
                                        <ul className='list-group '>
                                            <li className='list-group-item text-center shadow rounded'>
                                                Workflow Details
                                            </li>
                                            <li className='list-group-item'>
                                                <AppShowmore text={'Name : ' + e?.measure_data?.name} />
                                            </li>
                                            <li className='list-group-item'>
                                                <AppShowmore text={'Description : ' + e?.measure_data?.description} />
                                            </li>
                                            <li className='list-group-item'>
                                                <AppShowmore text={'Assigned To : ' + e?.assigned_to_data?.first_name} />
                                            </li>
                                            <li className='list-group-item'>
                                                <AppShowmore text={'Created Date : ' + appDate(e?.created_at)} />
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col md={6}>
                                        <Nav className="nav-tabs-custom nav-justified nav nav-tabs">
                                            {workflowTab.map((e, i) => {
                                                return (
                                                    <NavItem key={i}>
                                                        <NavLink style={{ cursor: "pointer" }} className={activeIndex == e.id ? 'active' : ''}
                                                            onClick={() => this.setState({ activeIndex: e.id })} >
                                                            <span className="d-none d-sm-block"><i className={e.icon + ' me-2'}></i> {e.name}</span>
                                                            <span className="d-block d-sm-none"><i className={e.icon}></i></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            })}
                                        </Nav>
                                        <TabContent activeTab={activeIndex} className="p-3 text-muted">
                                            <TabPane tabId={1}>
                                                <Row>
                                                    {e?.measure_type_data?.length ? e?.measure_type_data.map((e, i) => {
                                                        return (
                                                            <Col lg={6} key={i}>
                                                                <div className='card shadow-sm p-2' >
                                                                    <span className='mt-3'>
                                                                        <h5>{e}</h5>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        )
                                                    }) :
                                                        <p className='text-danger text-center'>No action state found..</p>}
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <Row>
                                                    <Col lg={6} key={i}>
                                                        <span className='mb-2 text-center'>
                                                            <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                        </span>
                                                        <div className='card shadow-sm p-2' >
                                                            <span className='mt-3'>
                                                                <h5>{e?.theme_data?.name}</h5>
                                                                <AppShowmore text={e?.theme_data?.description} id={'description' + i} />
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <Row>
                                                    <Col lg={6} key={i}>
                                                        <span className='mb-2 text-center'>
                                                            <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                        </span>
                                                        <div className='card shadow-sm p-2' >
                                                            <span className='mt-3'>
                                                                <h5>{e?.measure_data?.name}</h5>
                                                                <AppShowmore text={e?.measure_data?.description} id={'description' + i} />
                                                                <small className='blockquote-footer'>Stage is {e?.measure_data?.stage}</small>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    }) :
                        <AppNodata />}
                </Fragment>
            </Fragment>
        )
    }
}
