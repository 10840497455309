import { AppLoader } from 'components/Reusable';
import React, { Component, Fragment } from 'react';
import {
    Row,
    Col,
    CardBody,
} from "reactstrap";
import PropTypes from 'prop-types';
export default class Count extends Component {
    static get propTypes() {
        return {
            loading: PropTypes.bool,
            counts: PropTypes.any,
        };
    }
    constructor() {
        super();
    }
    render() {
        const { loading, counts } = this.props;
        return (
            <Fragment>
                <Row>
                    {counts &&
                        counts.map((e, i) => {
                            return (
                                <Col lg={3} md={3} sm={6} xs={12} key={i}>
                                    <div className="shadow mb-3 rounded">
                                        <CardBody>
                                            <Row className="d-flex align-items-center justify-content-between">
                                                <Col lg={2} md={2} sm={2} xs={2}>
                                                    <span className="text-danger p-1 rounded">
                                                        <i className={e.icon + " font-size-24"}></i>
                                                    </span>
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10} className="d-flex align-items-center flex-column">
                                                    <span >{e.title}</span>
                                                    {loading ?
                                                        <AppLoader /> :
                                                        <span className="fw-bold font-size-20">{e.value}</span>}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </div>
                                </Col>
                            )
                        })}
                </Row>
            </Fragment>
        )
    }
}
