import React, { Component, Fragment } from 'react';
import { AppNodata, AppPagination} from 'components/Reusable';
import { Service } from '../Appservice';
import { Card,  Container } from "reactstrap";
import TabHeader from '../components/tabHeader';
import InlineSearch from './InlineSearch';
import RegintelTable from './RegintelTable';
import MetaTags from "react-meta-tags";
import { signalApi } from '../ApiConstant';
export default class RegIntel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            header: ["PRODUCT NAME", "POTENTIAL SAFETY ISSUE", "PUBLICATION DATE", "STATUS", "AGENCY", "SOURCE LINK 1", "SOURCE LINK 2"],
            tabHeader: [],
            page: 1,
            search: '',
            intelList: [],
            intelCopyList: []
        }
    }
    componentDidMount = () => {
        const header = this.state.header;
        const tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ tabHeader, loading: true });
        this.getIntel()
    }
    paginate = async (page) => {
        this.setState({ page, loading: true });
        this.getIntel();
    }
    getIntel = async () => {
        const { page, search } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search_keyword=" + search;
        const getIntel = await Service.appservice('get', signalApi.regIntel + queryParam);
        if (getIntel?.data) {
            this.setState({
                intelList: getIntel.data, intelCopyList: getIntel.data,
                pageNumbers: getIntel.metadata ? Array.from({ length: getIntel.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false,
            });
        }
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    render() {
        const { loading, tabHeader, intelList, page, pageNumbers, intelCopyList } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Dashboard | 3 Analytics</title>
                    </MetaTags>
                    <Container fluid>
                        <Card className='shadow rounded'>
                            <TabHeader
                                key={'Regulatory Intel'}
                                Title={'Regulatory Intel'}
                                headers={tabHeader}
                                onSearch={async (isSearch) => {
                                    await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                                    await this.getIntel();
                                }}
                                onHeader={(items) => { this.setState({ tabHeader: items }) }}
                                isLoading={loading}

                            />
                        </Card>
                        <Fragment>
                            <div>
                                <InlineSearch intelCopyList={intelCopyList} onSearch={intelList => this.setState({ intelList })} />
                                <RegintelTable
                                    tabHeader={tabHeader}
                                    loading={loading}
                                    filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                    intelList={intelList} />
                            </div>
                            {
                                intelList && intelList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                    nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} />
                                    : <AppNodata />
                            }
                        </Fragment>
                    </Container>
                </div>
            </React.Fragment >
        )
    }

}
