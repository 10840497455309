import React, { Component } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppShowmore } from 'components/Reusable';
import { appDate, urlValidation } from '../AppConstants';
export default class RegintelTable extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            loading: PropTypes.bool,
            filterStaticHeaders: PropTypes.func,
            intelList: PropTypes.any,
        };
    }
    render() {
        const { tabHeader, loading, filterStaticHeaders, intelList } = this.props;
        return (
            <div className='shadow table-responsive rounded-3 mt-3'>
                <Table className="table-hover table-borderless mb-0">
                    <thead className='shadow'>
                        <tr>
                            {
                                tabHeader.map((e, i) => {
                                    return (
                                        e.isChecked && <th key={i}>{e.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr><td colSpan={15}><AppLoader isGlow={true} /></td></tr>
                            :
                            intelList.map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td className={filterStaticHeaders(0) ? '' : 'd-none'}>
                                            <AppShowmore
                                                text={e?.PRODUCTNAME_PRODUCTCLASS_ACTIVE_INGREDIENTS.charAt(0).toUpperCase() + e?.PRODUCTNAME_PRODUCTCLASS_ACTIVE_INGREDIENTS.slice(1)}
                                            />
                                        </td>
                                        <td className={filterStaticHeaders(1) ? '' : 'd-none'}><AppShowmore text={e?.POTENTIAL_SAFETY_ISSUE} /></td>
                                        <td className={filterStaticHeaders(2) ? 'text-end' : 'd-none'}>{appDate(e?.DATE_ISSUE_REVIEW_PUBLICATION_DATE)}</td>
                                        <td className={filterStaticHeaders(3) ? '' : 'd-none'}><AppShowmore text={e?.STATUS} /></td>
                                        <td className={filterStaticHeaders(4) ? '' : 'd-none'}>{e?.AGENCY}</td>
                                        <td className={filterStaticHeaders(5) ? '' : 'd-none'}>{e?.SOURCE_LINK1 && urlValidation(e?.SOURCE_LINK1) ?
                                            <span role={'button'} onClick={() => window.open(e?.SOURCE_LINK1, '_blank')}><i className='fas fa-external-link-alt font-size-18 text-info'></i></span>
                                            : <AppShowmore text={e?.SOURCE_LINK1} id={'link1' + i} />}</td>
                                        <td className={filterStaticHeaders(6) ? 'text-center' : 'text-center d-none'}>{e?.SOURCE_LINK2 && urlValidation(e?.SOURCE_LINK2) ?
                                            <span className='badge badge-soft-danger shadow p-2 rounded' role={'button'} onClick={() => window.open(e?.SOURCE_LINK2, '_blank')}>
                                                <i className='fas fa-link text-danger'></i>
                                            </span>
                                            : <AppShowmore text={e?.SOURCE_LINK2}  />}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
