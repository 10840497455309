import React, { Component, Fragment } from 'react';
import { AppPagination } from 'components/Reusable';
import { Service } from '../../Appservice';
import { Card, CardBody, Container } from "reactstrap";
import TabHeader from '../../components/tabHeader';
import InlineSearch from './InlineSearch';
import PropTypes from 'prop-types';
import ActionStateTable from './ActionStateTable';
import ActionStateAdd from './ActionStateAdd';
import { signalApi } from '../../ApiConstant';
export default class ActionState extends Component {
  static get propTypes() {
    return {
      onViewWorkflow: PropTypes.func,
      workFlowList: PropTypes.any,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      header: ["NAME", "DESCRIPTION", "WORKFLOW"],
      menuArray: [
        { name: 'Edit', icon: 'fas fa-pen', id: 1 },
        { name: 'Delete', icon: 'fas fa-trash', id: 2 },
      ],
      tabHeader: [],
      page: 1,
      loading: false,
      search: '',
      actionStateList: [],
      actionStateCopyList: [],
      isFilter: false,
      isAdd: false,
      isView: false,
      selectedData: '',
      showWorkFlowConfig: false
    }
  }
  componentDidMount = async () => {
    let { header } = this.state;
    let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
    this.setState({ header, tabHeader, loading: true });
    this.getActionState();
  }
  filterStaticHeaders = (header) => {
    const { tabHeader } = this.state
    const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
    return isActiveHeader ? true : false;
  }
  paginate = async (page) => {
    await this.setState({ page, isUpdating: true });
    this.getActionState();
  }
  getActionState = async () => {
    const { page, search } = this.state;
    let queryParam = `?page=${page}&page_size=10`;
    if (search) queryParam += "&search=" + search;
    const getActionState = await Service.appservice('get', signalApi.actionType + queryParam);
    if (getActionState?.data) {
      this.setState({
        actionStateList: getActionState.data, actionStateCopyList: getActionState.data,
        pageNumbers: getActionState.metadata ? Array.from({ length: getActionState.metadata.total_pages }, (v, k) => k + 1) : [],
        loading: false
      });
    }
  }
  render() {
    const { page, pageNumbers, actionStateList, actionStateCopyList, loading, tabHeader, stagList, menuArray, isAdd,
      selectedData, isView, showActionState } = this.state;
    return (
      <React.Fragment>
        <Card className='shadow rounded-3'>
          <TabHeader
            key={'Action State'}
            Title={'Action State'}
            headers={tabHeader}
            isLoading={loading}
            onSearch={async (isSearch) => {
              await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
              await this.getActionState();
            }}
            onHeader={(items) => { this.setState({ tabHeader: items }) }}
            onAdd={(isAdd) => this.setState({ isAdd })}
            isAdd
          />
        </Card>
        {isAdd ?
          <ActionStateAdd selectedData={selectedData}
            workFlowList={this.props.workFlowList}
            onClose={(e) => { this.setState({ selectedData: '', isAdd: false }); if (e) { this.getActionState() } }} /> :
          <Fragment></Fragment>}
        <Fragment>
          <InlineSearch actionStateCopyList={actionStateCopyList}
            onSearch={actionStateList => this.setState({ actionStateList })}
            onViewWorkflow={this.props.onViewWorkflow} />
          <ActionStateTable
            tabHeader={tabHeader}
            loading={loading}
            filterStaticHeaders={e => this.filterStaticHeaders(e)}
            menuArray={menuArray}
            actionStateList={actionStateList}
            onDelete={this.getActionState}
            onMenu={(a, b) => {
              this.setState({
                selectedData: a,
                isAdd: b == 1 ? true : false,
                isView: b == 3 ? true : false
              })
            }}
          />
          {
            actionStateList && actionStateList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
              nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
              <Fragment></Fragment>
          }
        </Fragment>
      </React.Fragment >
    )
  }

}
