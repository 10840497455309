import React, { Component, Fragment } from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import TabHeader from '../components/tabHeader';
import Filter from './Filter';
import AddSignal from './AddSignal';
export default class Header extends Component {
    static get propTypes() {
        return {
            tabHeader: PropTypes.any,
            loading: PropTypes.bool,
            onSearch: PropTypes.func,
            onFilter: PropTypes.func,
            onHeader: PropTypes.func,
            onAdd: PropTypes.func,
            onFilterOpen: PropTypes.func,
            filterData: PropTypes.any,

            drugList: PropTypes.any,
            modelList: PropTypes.any,
            durationList: PropTypes.any,

            hideFilter:PropTypes.bool,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            isFilter: false,
            isAdd: false,
        }
    }
    componentDidMount = () => {

    }
    render() {
        const { loading, onFilterOpen, onFilter, onHeader, onSearch, drugList, modelList, durationList, tabHeader, filterData, onAdd } = this.props;
        const { isFilter, isAdd } = this.state;
        return (
            <Fragment>
                <Card className='shadow'>
                    <TabHeader
                        key={'Signals'}
                        Title={'Signals'}
                        isAdd
                        isFilter
                        headers={tabHeader}
                        onSearch={(isSearch) => onSearch(isSearch)}
                        onHeader={(items) => { onHeader(items); }}
                        onFilter={async (isFilter) => { await this.setState({ isFilter }); onFilterOpen(!isFilter) }}
                        onAdd={(isAdd) => this.setState({ isAdd })}
                        isLoading={loading}
                    />
                </Card>
                {isFilter || this.props.hideFilter?
                    <Filter
                        filterData={filterData}
                        drugList={drugList}
                        modelList={modelList}
                        durationList={durationList}
                        onClose={() => { this.setState({ isFilter: false }); onFilterOpen(true) }}
                        onFilter={e => onFilter(e)} /> : <Fragment></Fragment>}
                {isAdd ?
                    <AddSignal
                        drugList={drugList}
                        modelList={modelList}
                        durationList={durationList}
                        onClose={() => { this.setState({ isAdd: false }); onAdd() }} /> : <Fragment></Fragment>}
            </Fragment>
        )
    }
}
