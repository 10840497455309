import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppInput, AppPagination } from 'components/Reusable';
import Logo from "assets/illusion/add.png";
import { Row, Col, Card, CardBody, CardImg, Collapse, Form } from 'reactstrap';
import { Service } from '../../Appservice';
import toast from 'react-hot-toast';
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { signalApi } from '../../ApiConstant';
import { appDate } from '../../AppConstants';
import DeleteAlert from '../../components/DeleteAlert';
export default class TopicComment extends Component {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      onClose: PropTypes.func,
    };
  }
  constructor(props) {
    super(props);
    const { selectedData } = props;
    this.state = {
      postData: {
        comment: '',
        theme: selectedData.id,
      },
      postErr: {
        comment: false,
      },
      page: 1,
      pageNumbers: [],
      isUpdating: true,
      commentList: [],
      isDeleteTopicComment: false,
      iscomment: true,
      isModified: false,
    }
  }
  componentDidMount = async () => {
    await this.getComments()
  }
  paginate = async (page) => {
    await this.setState({ page, isUpdating: true });
    this.getComments();
  }
  getComments = async () => {
    const { page, postData } = this.state;
    let queryParam = `?page=${page}&page_size=10`;
    const action = await Service.appservice('get', `${signalApi.topics}/${postData.theme}/comments` + queryParam);
    if (action && action.data) {
      this.setState({
        commentList: action.data, isUpdating: false, isDeleteTopicComment: false, pageNumbers: action.metadata
          ? Array.from(
            { length: action.metadata.total_pages },
            (v, k) => k + 1
          )
          : [],
      });

    }
  }
  setValue = async (i, e) => {
    const value = i.target.value;
    this.setState({ postData: { ...this.state.postData, [e]: value } });
    if (!value) this.setError(e);
    else this.removeErr(e);
  };
  onError = async (e, name) => this.setState({ postErr: { ...this.state.postErr, [name]: e } });
  setError = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = async (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  onSubmit = async (delId) => {
    const { isDeleteTopicComment, postData, postErr } = this.state;
    const errorExist = Object.values(postErr).find(v => v);
    if (!postData.comment && !isDeleteTopicComment) return this.setError('comment');
    if (errorExist) return false;
    let data = { ...postData };
    await this.setState({ isUpdating: true })
    const postAction = isDeleteTopicComment ?
      await Service.appservice('delete', `${signalApi.topicCommenents}/${delId}`) :
      await Service.appservice('post', signalApi.topicCommenents, data);
    if (postAction) {
      toast.success(`Comment ${isDeleteTopicComment ? 'Deleted' : 'Created'} Successfully !...`);
      this.setState({ postData: { ...this.state.postData, comment: '' }, isDeleteTopicComment: 0, isModified: true });
    }
    await this.getComments();
  }
  render() {
    const { onClose, selectedData } = this.props;
    const { postData, postErr, commentList, isUpdating, page, pageNumbers, isModified, isDeleteTopicComment, iscomment } = this.state;
    return (
      <Fragment>
        <div className='d-flex justify-content-between'>
          <AppButton name={`Topics Comments of ${selectedData?.theme_data?.name}`} tooltip="Back to Topics List" icon='fas fa-arrow-left' onClick={onClose} />
        </div>
        <Row className="no-gutters align-items-center mt-4">
          <Col md={4} className='d-none d-sm-block'>
            <CardImg className="img-fluid" src={Logo} alt="Skote" />
          </Col>
          <Col md={6} >
            <div className=' p-2 rounded'>
              <span className='mb-3'>
                <Form >
                  <AppInput label="Comment *" placeholder="Write your comments *" error="Comment is required" onIValid={e => this.onError(e, 'comment')} onChange={e => { this.setValue(e, 'comment') }} value={postData.comment} isInvalid={postErr.comment} />
                  <div className="text-center">
                    <AppButton icon='fas fa-plus' name='Add Comments' onClick={this.onSubmit} disabled={isUpdating} showloader />
                  </div>
                </Form>
              </span>
            </div>
            <div className="accordion accordion-flush mt-3 mt-sm-0" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header shadow rounded " id="headingFlushTwo">
                  <AppButton name="Comments List" onClick={() => this.setState({ iscomment: true })}
                    className={classnames("accordion-button", "fw-medium", { collapsed: iscomment })} />
                </h2>
                <Collapse isOpen={iscomment} className="accordion-collapse">
                  <PerfectScrollbar >
                    <div className="accordion-body" >
                      {commentList?.length ?
                        commentList.map((e, i) => {
                          return (
                            isDeleteTopicComment == e.id ?
                              <Fragment key={i}>
                                <DeleteAlert loading={isUpdating} title="Project" onYes={() => { this.onSubmit(isDeleteTopicComment) }}
                                  onNo={() => { this.setState({ isDeleteTopicComment: 0, selectedData: '' }) }} />
                              </Fragment> :
                              <Fragment key={i}>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                  <span className='mb-2 text-center text-danger'>
                                    <i className='fas fa-calendar-day me-2 '></i>{appDate(e?.created_at)}
                                  </span>
                                  <span className='badge badge-soft-danger p-2'>
                                    <button>
                                      <i className='fas fa-trash' onClick={() => { this.setState({ isDeleteTopicComment: e.id }); }}></i>
                                    </button>
                                  </span>
                                </div>
                                <div className='card shadow-sm p-2' >
                                  <span className='mt-3'>
                                    <h5>{e?.comment}</h5>
                                    <small className='blockquote-footer'>Posted by {e?.commented_by_data?.first_name + ' ' + e?.commented_by_data?.last_name}</small>
                                  </span>
                                </div>
                              </Fragment>)
                        }) :
                        <div className='text-center'>
                          <span className='shadow rounded p-2 text-danger'>No Topics Comments found..</span>
                        </div>
                      }
                    </div>
                  </PerfectScrollbar>
                </Collapse>
                {
                  commentList?.length ?
                    <AppPagination previousPage={e => { this.paginate(e) }}
                      nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                    <Fragment></Fragment>
                }
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}
