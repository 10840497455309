import React, { Component, Fragment } from 'react';
import {  AppNodata, AppPagination } from 'components/Reusable';
import { Service } from '../../Appservice';
import { Card} from "reactstrap";
import TabHeader from '../../components/tabHeader';
import InlineSearch from './InlineSearch';
import PropTypes from 'prop-types';
import WorkflowteamTable from './WorkflowteamTable';
import WorkflowteamAdd from './WorkflowteamAdd';
import Workflowteamdetails from './Workflowteamdetails';
import { signalApi } from '../../ApiConstant';
export default class WorkflowTeam extends Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func,
            userList: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            header: ["NAME", "TEAM MEMBERS", "TEAM LEAD"],
            menuArray: [
                { name: 'Edit', icon: 'fas fa-pen', id: 1 },
                { name: 'Delete', icon: 'fas fa-trash', id: 2 },
                { name: 'Choose Team Lead', icon: 'fas fa-plus', id: 3 },
                { name: 'Details', icon: 'fas fa-list', id: 4 }
            ],
            tabHeader: [],
            page: 1,
            loading: false,
            search: '',
            workflowteamList: [],
            workflowteamCopyList: [],
            isAdd: false,
            isDetails: false,
            isTeamLead: false,
            selectedData: '',
        }
    }
    componentDidMount = async () => {
        let { header } = this.state;
        let tabHeader = header.map((v, i) => ({ name: v.toUpperCase(), isChecked: true, id: i }));
        this.setState({ header, tabHeader, loading: true });
        this.getWorkflowteam();
    }
    getWorkflowteam = async () => {
        const { page, search } = this.state;
        let queryParam = `?page=${page}&page_size=10`;
        if (search) queryParam += "&search=" + search;
        const getWorkflowteam = await Service.appservice('get', signalApi.workflowTeam + queryParam);
        if (getWorkflowteam?.data) {
            this.setState({
                workflowteamList: getWorkflowteam.data, workflowteamCopyList: getWorkflowteam.data,
                pageNumbers: getWorkflowteam.metadata ? Array.from({ length: getWorkflowteam.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    filterStaticHeaders = (header) => {
        const { tabHeader } = this.state
        const isActiveHeader = tabHeader.find((v, i) => (i === header && v.isChecked))
        return isActiveHeader ? true : false;
    }
    paginate = async (page) => {
        await this.setState({ page, isUpdating: true });
        this.getWorkflowteam();
    }
    render() {
        const { page, pageNumbers, loading, tabHeader, menuArray, isAdd, selectedData, workflowteamList,
            workflowteamCopyList, isDetails, isTeamLead } = this.state;
        const { onClose, userList } = this.props;
        return (
            <React.Fragment>
                <Card className='shadow rounded'>
                    <TabHeader
                        key={'Workflow Team'}
                        Title={'Workflow Team'}
                        headers={tabHeader}
                        isAdd
                        isLoading={loading}
                        onSearch={async (isSearch) => {
                            await this.setState({ loading: true, page: 1, search: isSearch ? isSearch : '' });
                            await this.getWorkflowteam();
                        }}
                        onHeader={(items) => { this.setState({ tabHeader: items }) }}
                        onAdd={async (isAdd) => await this.setState({ isAdd })}
                    />
                </Card>
                {isAdd ?
                    <WorkflowteamAdd
                        selectedData={selectedData}
                        userList={userList}
                        onClose={(e) => { this.setState({ selectedData: '', isAdd: false }); if (e) { this.getWorkflowteam() } }} /> :
                    <Fragment></Fragment>}
                <Fragment>
                    {
                        isDetails ?
                            <Workflowteamdetails selectedData={selectedData}
                                onClose={(e) => { this.setState({ selectedData: '', isDetails: false }); }} /> :
                            <Fragment>
                                <div>
                                    <InlineSearch
                                        onClose={onClose}
                                        workflowteamCopyList={workflowteamCopyList}
                                        onSearch={workflowteamList => this.setState({ workflowteamList })}
                                        selectedData={selectedData}
                                        onWorkflowteam={(e) => { this.setState({ isTeamLead: false }); if (e) { this.getWorkflowteam() } }}
                                        userList={userList}
                                        isTeamLead={isTeamLead} />
                                    <WorkflowteamTable
                                        tabHeader={tabHeader}
                                        loading={loading}
                                        filterStaticHeaders={e => this.filterStaticHeaders(e)}
                                        menuArray={menuArray}
                                        workflowteamList={workflowteamList}
                                        onDelete={this.getWorkflowteam}
                                        onMenu={async (a, b) => {
                                            if (b == 1) await this.setState({ isAdd: false })
                                            this.setState({
                                                selectedData: a,
                                                isAdd: b == 1 ? true : false,
                                                isTeamLead: b == 3 ? true : false,
                                                isDetails: b == 4 ? true : false
                                            })
                                        }} />
                                </div>
                                {loading ? <Fragment></Fragment> :
                                    workflowteamList && workflowteamList.length ? <AppPagination previousPage={e => { this.paginate(e) }}
                                        nextPage={e => { this.paginate(e) }} selectedPage={e => { this.paginate(e) }} page={page} pageNumbers={pageNumbers} /> :
                                        <AppNodata />
                                }
                            </Fragment>
                    }
                </Fragment>
            </React.Fragment >
        )
    }

}
