import React, { Component } from 'react';
import { Form, InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppButton } from 'components/Reusable';
export default class InlineSearch extends Component {
    static get propTypes() {
        return {
            masterTopicList: PropTypes.any,
            onSearch: PropTypes.func,
            onClose: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            inlineSearch: ''
        }
    }
    onInlineSearch = (e) => {
        const searchText = e;
        function matchValue(input) {
            let result = input?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            return result;
        }
        const filteredData = this.props.masterTopicList.filter((row) => {
            return (
                matchValue(row.theme_data?.name) ||
                matchValue(row.assigned_to_data?.name) ||
                matchValue(row.signal_data?.signal_data?.DRUG) ||
                matchValue(row.signal_data?.signal_data?.AE) ||
                matchValue(row.workflow_config?.name) ||
                matchValue(row.project?.name) ||
                matchValue(row.status)
            );
        });
        this.props.onSearch(filteredData);
        this.setState({ inlineSearch: searchText });
    }
    render() {
        const { inlineSearch } = this.state;
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <Form className="row col-lg-3 col-md-4 col-sm-12 col-xs-12" >
                    <InputGroup>
                        <div className="input-group-text bg-white shadow rounded" onClick={() => { this.setState({ inlineSearch: '' }); this.props.onSearch(this.props.masterTopicList); }}>
                            {inlineSearch ? <i className='fas fa-times text-danger' /> : <i className='fas fa-search  text-danger' />}
                        </div>
                        <input onChange={async (e) => this.onInlineSearch(e.target.value)}
                            value={inlineSearch} type="text" className="form-control" placeholder="Search in table..." />
                    </InputGroup>
                </Form>
                <AppButton tooltip="View Dashboard" icon='fa fa-arrow-left' className="ms-2" name="Dashboard" onClick={this.props.onClose} />
            </div>
        )
    }
}
