import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'reactstrap';
import { AppShowmore, AppButton, AppDropDown } from 'components/Reusable';
import empty from 'assets/illusion/listempty.png';
import PerfectScrollbar from "react-perfect-scrollbar";
import SignalMarkValidation from './SignalMarkValidation';
export default class SignalValidationGrid extends Component {
    static get propTypes() {
        return {
            selectedData: PropTypes.any,
            signalValidationActionList: PropTypes.any,
            loading: PropTypes.bool,
            onMarkValidation: PropTypes.func,
            onMenu: PropTypes.func,
            onActionView: PropTypes.func,
            disableAddValidation: PropTypes.bool,
            onShowValidation: PropTypes.func,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            markValidation: false,
            isMenu: false,
            selectedAction: '',
            actionMenus: [
                { name: 'Change Status', icon: 'fas fa-pen', id: 1 },
                { name: 'Comments', icon: 'fas fa-comment', id: 2 },
                { name: 'Attachments', icon: 'fas fa-paperclip', id: 3 },
            ]
        }
    }
    render() {
        const { loading, signalValidationActionList, onMarkValidation, selectedData, onMenu, onActionView, disableAddValidation, onShowValidation } = this.props;
        const { markValidation, isMenu, selectedAction, actionMenus, } = this.state;
        let completedValidation = selectedData?.validation_status == 'V' ? true : false;
        let disableValidation = selectedData.validation_topic_added ? false : true;
        const isSkipValidation = !selectedData.validation_topic_added && selectedData?.validation_status ? true : false;
        if (isSkipValidation && selectedData?.validation_status != 'V') disableValidation = false;
        return (
            <Fragment>
                {markValidation ?
                    <SignalMarkValidation isGrid selectedData={selectedData} onClose={(e) => { this.setState({ markValidation: false }); if (e) { onMarkValidation() } }} /> :
                    <Fragment>
                        <div className='card rounded-3'>
                            <div className=' p-2 shadow rounded-3 d-flex align-items-center justify-content-between'>
                                <h5 className="font-size-14 m-0  p-2  badge badge-soft-danger rounded">
                                    <i className="fas fa-check  me-2" /> Validation
                                </h5>
                                {disableAddValidation ? null :
                                    <AppButton icon='fas fa-plus' className="me-2 " name={'Add'} disabled={disableAddValidation} onClick={onShowValidation} />}
                            </div>
                            <div className='card-body' style={{ height: 250, overflowY: signalValidationActionList?.length ? 'scroll' : '' }}>
                                <PerfectScrollbar >
                                    {loading ?
                                        <div className='m-3'>
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder rounded col-7"></span>
                                                <span className="placeholder rounded col-4"></span>
                                                <span className="placeholder rounded col-4"></span>
                                                <span className="placeholder rounded col-6"></span>
                                                <span className="placeholder rounded col-8"></span>
                                            </p>
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder rounded col-7"></span>
                                                <span className="placeholder rounded col-4"></span>
                                                <span className="placeholder rounded col-4"></span>
                                                <span className="placeholder rounded col-6"></span>
                                                <span className="placeholder rounded col-8"></span>
                                            </p>
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder rounded col-7"></span>
                                                <span className="placeholder rounded col-4"></span>
                                                <span className="placeholder rounded col-4"></span>
                                                <span className="placeholder rounded col-6"></span>
                                                <span className="placeholder rounded col-8"></span>
                                            </p>
                                        </div>
                                        :
                                        signalValidationActionList && signalValidationActionList?.length ?
                                            signalValidationActionList.map((e, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <div className="shadow-none border mb-2 rounded-3 card m-3" >
                                                            <div className='border-bottom p-2 rounded-3  d-flex align-items-center justify-content-between'>
                                                                <AppDropDown
                                                                    menuArray={actionMenus}
                                                                    isMenu={isMenu}
                                                                    onToggle={() => { if (e.id == selectedAction.id) this.setState({ isMenu: !isMenu, selectedAction: e }); }}
                                                                    onClick={() => { this.setState({ isMenu: true, selectedAction: e }); }}
                                                                    rowId={e.id}
                                                                    selectedId={selectedAction.id}
                                                                    onClose={(id) => {
                                                                        this.setState({ isMenu: false, isDelete: id == 2 ? true : false });
                                                                        onMenu(selectedAction, id);
                                                                    }}
                                                                />
                                                                <span className={`badge p-2 badge-soft-danger rounded-2 font-size-12`}>{e?.updateData?.status ? e?.updateData?.status : 'New'}</span>
                                                            </div>
                                                            <div className="card-body">
                                                                <Row>
                                                                    <Col >
                                                                        <p className="text-muted mb-1">Action Name</p>
                                                                        <h5 className="font-size-14 text-info" role='button' onClick={() => { onActionView(e) }}>
                                                                            <AppShowmore text={e?.updateData?.measure_data?.name} />
                                                                        </h5>
                                                                    </Col>
                                                                    <Col >
                                                                        <p className="text-muted mb-1">Topic</p>
                                                                        <h5 className="font-size-14"><AppShowmore text={e?.updateData?.theme_data?.name} /></h5>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col >
                                                                        <p className="text-muted mb-1">Assignee</p>
                                                                        <h5 className="font-size-14"><AppShowmore text={e?.updateData?.assigned_to_data?.username} /></h5>
                                                                    </Col>
                                                                    <Col >
                                                                        <p className="text-muted mb-1">Attachments
                                                                        </p>
                                                                        <Badge className="me-2 p-2 bg-info" onClick={() => { onMenu(e, 3); }}>
                                                                            {e?.updateData?.measure_attachment_count ?? 0}
                                                                        </Badge>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col >
                                                                        <p className="text-muted mb-1">Comments
                                                                        </p>
                                                                        <Badge className="me-2 p-2 bg-info" onClick={() => { onMenu(e, 2); }}>
                                                                            {e?.updateData?.measure_comment_count ?? 0}
                                                                        </Badge>
                                                                    </Col>
                                                                    <Col >
                                                                        <p className="text-muted mb-1">Order
                                                                        </p>
                                                                        <Badge className="me-2 p-2 bg-black" >
                                                                            {e?.updateData?.measure_order ?? 0}
                                                                        </Badge>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }) :
                                            <div className='text-danger text-center m-3'>
                                                <span className='p-2 shadow rounded'>{isSkipValidation ? 'Action validation skipped..' : 'No action validations found..'}</span>
                                                <img src={empty} alt="" className="bx-fade-left-hover rounded mx-auto d-block w-50 h-50 mt-3" />
                                            </div>
                                    }
                                </PerfectScrollbar>
                            </div>
                            <div className='p-2 rounded text-center'>
                                {completedValidation ?
                                    <Fragment>
                                        <AppButton icon='fas fa-check' name={'Action Validation Completed'} disabled={completedValidation} />
                                    </Fragment> :
                                    <Fragment>
                                        <AppButton icon='fas fa-check' name={isSkipValidation ? 'Mark Validation Complete' : 'Mark Validation'}
                                            disabled={disableValidation || loading} onClick={() => { this.setState({ markValidation: true }); }} />
                                    </Fragment>}
                            </div>
                        </div>
                    </Fragment>
                }

            </Fragment>
        )
    }
}
