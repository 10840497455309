import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppButton, AppShowmore } from 'components/Reusable';
import { Row, Col, CardBody, Collapse, } from 'reactstrap';
import classnames from "classnames";
import { Service } from '../../Appservice';
import { signalApi } from '../../ApiConstant';
import { appDate } from '../../AppConstants';

export default class ActionDetails extends Component {
    static get propTypes() {
        return {
            updateData: PropTypes.any,
            onClose: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            isattachment: true,
            iscomment: false,
            comments: [],
            attachments: []
        }
    }
    componentDidMount = async () => {
        const { updateData } = this.props;
        const comments = await Service.appservice('get', `${signalApi.topicActions}/${updateData.id}/comments`);
        const attachments = await Service.appservice('get', `${signalApi.actionAttachmentsGet}/` + updateData.id);
        await this.setState({ comments: comments.data, attachments: attachments.data });
    }
    render() {
        const { onClose, updateData } = this.props;
        const { isattachment, iscomment, attachments, comments } = this.state
        return (
            <Fragment>
                <div className='d-flex justify-content-between'>
                    <AppButton name={`Action Details of ${updateData?.measure_data?.name}`} tooltip="Back to Action List" icon='fas fa-arrow-left' onClick={onClose} />
                </div>
                <Row className="no-gutters justify-content-center align-items-center  mt-3 mt-sm-0">
                    <Col md={6}>
                        <ul className='list-group '>
                            <li className='list-group-item text-center shadow rounded'>
                                Action Details
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Description" text={updateData?.measure_data?.description} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Signal" text={updateData?.signal_data?.signal_data?.AE} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Assigned To" text={updateData?.assigned_to_data?.first_name + ' ' + updateData?.assigned_to_data?.last_name} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Action State" text={updateData?.status} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Action State Comment" text={updateData?.status_change_comment} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Topic" text={updateData?.theme_data?.name} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Attachment Count " text={updateData?.measure_attachment_count} />
                            </li>
                            <li className='list-group-item'>
                                <AppShowmore title="Comment count" text={updateData?.measure_comment_count} />
                            </li>
                        </ul>
                    </Col>
                    <Col md={6} className='m-md-5 mt-3 mt-sm-0'>
                        <div className=" accordion accordion-flush">
                            <div className="accordion-item">
                                <h2 className="accordion-header shadow" >
                                    <AppButton name="Attachment List" onClick={() => this.setState({ isattachment: true, iscomment: false })}
                                        className={classnames("accordion-button", "fw-medium", { collapsed: isattachment })} />
                                </h2>
                                <Collapse isOpen={isattachment} className="accordion-collapse">
                                    <div className="accordion-body">
                                        {attachments?.length ? attachments.map((e, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <span className='mb-2 text-center'>
                                                        <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                    </span>
                                                    <div className='card shadow-sm p-2' >
                                                        <span className='mt-3'>
                                                            <h5>{e?.title}</h5>
                                                            <AppShowmore text={e?.description} id={'toolsattachdesc' + i} />
                                                            <small className='blockquote-footer'>Posted by {e?.created_by_data?.first_name + ' ' + e?.created_by_data?.last_name}</small>
                                                        </span>
                                                    </div>
                                                </Fragment>
                                            )
                                        }) :
                                            <CardBody className='text-center'>
                                                <span className='text-danger shadow p-2 rounded'>No Action attachments found..</span>
                                            </CardBody>}
                                    </div>
                                </Collapse>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header shadow" id="headingFlushTwo">
                                <AppButton name="Comments List" onClick={() => this.setState({ isattachment: false, iscomment: true })}
                                        className={classnames("accordion-button", "fw-medium", { collapsed: iscomment })} />
                                </h2>
                                <Collapse isOpen={iscomment} className="accordion-collapse">
                                    <div className="accordion-body">
                                        {comments?.length ? comments.map((e, i) => {
                                            return (
                                                <>
                                                    <Fragment key={i}>
                                                        <span className='mb-2 text-center'>
                                                            <i className='fas fa-calendar-day me-2'></i>{appDate(e?.created_at)}
                                                        </span>
                                                        <div className='card shadow-sm p-2' >
                                                            <span className='mt-3'>
                                                                <h5>{e?.comment}</h5>
                                                                <small className='blockquote-footer'>Posted by {e?.commented_by_data?.first_name + ' ' + e?.commented_by_data?.last_name}</small>
                                                            </span>
                                                        </div>
                                                    </Fragment>
                                                </>)
                                        }) :
                                            <CardBody className='text-center'>
                                                <span className='text-danger shadow p-2 rounded'>No Action comments found..</span>
                                            </CardBody>
                                        }
                                    </div>
                                </Collapse>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
