
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Form, Input, FormGroup, Label, InputGroup } from "reactstrap";
import { AppLoader, AppSpeechInput, AppButton, AppTooltip } from 'components/Reusable';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
export default class TabHeader extends PureComponent {
    static get propTypes() {
        return {
            isLoading: PropTypes.bool,
            onSearch: PropTypes.func,
            onAdd: PropTypes.func,
            onHeader: PropTypes.func,
            onFilter: PropTypes.func,
            headers: PropTypes.any,
            Title: PropTypes.string,
            csvFile: PropTypes.string,
            isAdd: PropTypes.bool,
            isFilter: PropTypes.bool,
            isReport: PropTypes.bool,
            isDownload: PropTypes.bool,
            searchText: PropTypes.string,
            onPdfDownload: PropTypes.func,
            onexcelDownload: PropTypes.func,
        };
    }
    constructor(props) {
        super();
        const { isAdd, isFilter, isReport, searchText, isDownload } = props;
        this.state = {
            tabbutton: [
                { isActive: false, name: 'Add', icon: 'fas fa-plus', isShow: isAdd ? true : false },
                { isActive: false, name: 'Search', icon: 'fas fa-search', isShow: true },
                { isActive: false, name: 'Filter', icon: 'fas fa-filter', isShow: isFilter ? true : false },
                { isActive: false, name: 'Header', icon: 'fas fa-table', isShow: true },
                { isActive: false, name: 'Report', icon: 'far fa-clipboard', isShow: isReport ? true : false },
                { isActive: false, name: 'Download', icon: 'fas fa-download', isShow: isDownload ? true : false }
            ],
            search: searchText ? searchText : '',
            tabHeader: props.headers
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ tabHeader: nextProps.headers });
    }
    searchKeyDown = (e) => {
        if(e.key === "Enter") {
            const { search } = this.state;
            e.preventDefault();
            if (!search) return toast.error('Search should not be empty!...');
            this.props.onSearch(search)
        }
    }
    setActive = async (i) => {
        await this.setState({ tabbutton: this.state.tabbutton.map((el, index) => (index === i ? { ...el, isActive: true } : { ...el, isActive: false })) })
        if (i == 0) this.props.onAdd(true);
        if (this.props.isAdd && i != 0) this.props.onAdd(false);
        if (this.props.isFilter) this.props.onFilter(i == 2 ? true : false);
    }
    checkedHeaders = (index) => {
        let items = [...this.state.tabHeader]
        items[index].isChecked = !items[index].isChecked;
        this.setState({ tabHeader: items });
        this.props.onHeader(items);
    }
    render() {
        const { tabbutton, search, tabHeader } = this.state;
        const { isLoading, onSearch, Title, csvFile, onPdfDownload, onexcelDownload } = this.props;
        const activeTab = tabbutton.find((e) => e.isActive == true)?.name;
        return (
            <CardBody className='mb-0 p-2'>
                <div className={`d-flex flex-row justify-content-center justify-content-sm-between align-items-center`}>
                    <div className={`text-primary fw-bold d-none d-sm-block`}>
                        <i className="fas fa-table me-2" />
                        {Title}
                    </div>
                    <div>
                        <ul className="nav nav-pills float-end">
                            {activeTab == 'Search' ?
                                <Form className="row" key={'Search'}>
                                    <InputGroup>
                                        <div className="input-group-text p-1 shadow">
                                            <AppSpeechInput onSpeech={e => { this.setState({ search: e }); onSearch(e) }} />
                                        </div>
                                        <input onChange={(e) => this.setState({ search: e.target.value })} onKeyDown={this.searchKeyDown}
                                            value={search} type="text" className="form-control" id="autoSizingInputGroup" placeholder="Search in server..." />
                                        <AppButton isIcon icon='fas fa-search me-2 ms-2 p-2 rounded-2  shadow align-self-center border border-danger' tooltip="Search"
                                            onClick={() => {
                                                if (!search) return toast.error('Search should not be empty!...');
                                                onSearch(search)
                                            }}
                                        />
                                        <AppButton isIcon icon='fas fa-times me-2 ms-2 p-2 rounded-2 shadow align-self-center border border-danger' tooltip="Close & Clear Search"
                                            onClick={() => { onSearch(); this.setState({ search: '', tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })) }) }}
                                        />
                                    </InputGroup>
                                </Form> :
                                activeTab == 'Download' ?
                                    <Fragment key={'Download'}>
                                        <Fragment>
                                            <AppButton className="me-2" name="Pdf" tooltip="Download Pdf" icon='fas fa-file-pdf' onClick={onPdfDownload} disabled={isLoading} />
                                            <AppButton className="me-2" name="Excel" tooltip="Download Xl" icon='fas fa-file-excel' onClick={onexcelDownload} disabled={isLoading} />
                                        </Fragment>
                                        <AppButton className="border border-danger" icon='fas fa-times' tooltip="Close Downloads"
                                            onClick={async () => { this.setState({ tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })) }) }}
                                        />
                                    </Fragment>
                                    :
                                    activeTab == 'Report' ?
                                        <Fragment key={'Report'}>
                                            {csvFile ?
                                                <a rel="noreferrer" href={csvFile} download={csvFile} className='btn btn-danger btn-sm text-white'>
                                                    <i className="fas fa-download me-2" />
                                                    Report
                                                </a>
                                                :
                                                <span className='text-danger shadow p-2 mt-1'>No records found..</span>
                                            }
                                            <AppButton icon='fas fa-times' tooltip="Close Report" className="ms-3"
                                                onClick={() => this.setState({ tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })) })} />
                                        </Fragment>
                                        :
                                        activeTab == 'Header' ?
                                            <Fragment key={'Report'}>
                                                <div className="dropdown">
                                                    <span className='dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="true">
                                                        <AppButton icon='fas fa-table' tooltip="Hide table headers" className="ms-3" name="Header" />
                                                    </span>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        {
                                                            tabHeader.map((e, i) => {
                                                                return (
                                                                    <li key={i} className="dropdown-item" >
                                                                        <FormGroup check inline className='font-size-12'>
                                                                            <Label check>
                                                                                <Input defaultChecked={e.isChecked ? true : false} type="checkbox" onClick={() => { this.checkedHeaders(i) }} />
                                                                                {e.name}
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <AppButton icon='fas fa-times' tooltip="Close Header" className="ms-3"
                                                    onClick={() => this.setState({ tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })) })} />
                                            </Fragment>
                                            :
                                            tabbutton.map((e, i) => {
                                                return (
                                                    e.isShow ?
                                                        <li className="nav-item" key={i}>
                                                            {
                                                                isLoading ?
                                                                    <AppLoader key={i} />
                                                                    :
                                                                    <AppTooltip tooltip={Title + ' ' + e.name}>
                                                                        <Link to={'#'} className={e.isActive ? 'nav-link active' : 'nav-link text-primary'} onClick={() => { this.setActive(i) }}>
                                                                            <i className={e.icon}></i>
                                                                        </Link>
                                                                    </AppTooltip>
                                                            }
                                                        </li>
                                                        :
                                                        <Fragment key={i}></Fragment>
                                                )
                                            })
                            }
                        </ul>
                    </div>
                </div>



            </CardBody>
        )
    }
}
