import React, { Component, Fragment } from 'react';
import { Form, InputGroup, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppLoader, AppButton, AppInput, AppTooltip } from 'components/Reusable';
import { Link } from 'react-router-dom';
import { Whisper, Popover } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import toast from 'react-hot-toast';
import { evaluationList, recommendList, riskList, validationList, priorityList } from '../AppConstants';
export default class InlineSearch extends Component {
    static get propTypes() {
        return {
            signalCopyList: PropTypes.any,
            onSearch: PropTypes.func,
            loading: PropTypes.bool,
            onSummery: PropTypes.func,
            onPbrer: PropTypes.func,
            onFilter: PropTypes.func,
            onClear: PropTypes.func,
            generateData: PropTypes.any,
            onCloseGenerateData: PropTypes.func,
        };
    }
    constructor() {
        super();
        this.state = {
            inlineSearch: '', isFiltered: false,
            tabbutton: [
                { isActive: false, name: 'Status Filter', icon: 'fas fa-filter' },
                { isActive: false, name: 'Signal Report', icon: 'far fa-clipboard' },
            ],
            postData: {
                validation: '',
                priority: '',
                evaluvation: '',
                risk: '',
                recommend: ''
            }
        }
    }
    onInlineSearch = (e) => {
        const searchText = e;
        function matchValue(input) {
            let result = input?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            return result;
        }
        const filteredData = this.props.signalCopyList.filter((row) => {
            return (
                matchValue(row.signal_data?.DRUG) || matchValue(row.signal_data?.AE) || matchValue(row.signal_data?.SRC_IDENTIFIER)
            );
        });
        this.props.onSearch(filteredData);
        this.setState({ inlineSearch: searchText });
    }
    setValue = (i, e) => {
        const value = i.target?.value == '--Select--' ? '' : i.target?.value;
        this.setState({ postData: { ...this.state.postData, [e]: value } });
    };
    resetForm = () => this.setState({ postData: { ...this.state.postData, validation: '', priority: '', evaluvation: '', risk: '', recommend: '' } })
    speaker = (
        <Popover title="Duplicate signals">
            <p>Duplicate signals marked as <span className='duplicate-legend m-0'></span> color in grid.</p>
        </Popover>
    );
    onFilterClick = () => {
        const { postData } = this.state;
        if (Object.values(postData).every(x => x === null || x === '')) return toast.error("Status filter should not be empty");
        this.props.onFilter(postData);
        this.setState({ isFiltered: true })
    }
    render() {
        const { inlineSearch, tabbutton, postData, isFiltered } = this.state;
        const activeTab = tabbutton.find((e) => e.isActive == true)?.name;
        const { onSummery, onPbrer, loading, onClear, generateData, onCloseGenerateData } = this.props;
        return (
            <div>
                {activeTab == "Signal Report" ?
                    <div className='text-end mb-3'>
                        <AppButton className="ms-2 me-2" name="SIGNAL SUMMARY" icon="fas fa-clipboard" onClick={onSummery} />
                        <AppButton className="me-2" name="PBRER" icon="fas fa-clipboard" onClick={onPbrer} />
                        <AppButton  icon='fas fa-times' tooltip="Close Reports"
                            onClick={async () => { this.setState({ tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })) }) }}
                        />
                    </div> :
                    activeTab == "Status Filter" ?
                        <Form className='mb-3'>
                            <Row className='d-flex justify-content-center'>
                                <Col lg={2} md={3} sm={4} xs={12}>
                                    <AppInput label="Validation" type="select" value={postData.validation} onChange={e => { this.setValue(e, 'validation') }} iArray={validationList} selectType='idpair' />
                                </Col>
                                <Col lg={2} md={3} sm={4} xs={12}>
                                    <AppInput label="Priority" type="select" value={postData.priority} onChange={e => { this.setValue(e, 'priority') }} iArray={priorityList} selectType='idpair' />
                                </Col>
                                <Col lg={2} md={3} sm={4} xs={12}>
                                    <AppInput label="Evaluation" type="select" value={postData.evaluvation} onChange={e => { this.setValue(e, 'evaluvation') }} iArray={evaluationList} selectType='idpair' />
                                </Col>
                                <Col lg={2} md={3} sm={4} xs={12}>
                                    <AppInput label="Risk" type="select" value={postData.risk} onChange={e => { this.setValue(e, 'risk') }} iArray={riskList} selectType='idpair' />
                                </Col>
                                <Col lg={2} md={3} sm={4} xs={12}>
                                    <AppInput label="Recommendation" type="select" value={postData.recommend} onChange={e => { this.setValue(e, 'recommend') }} iArray={recommendList} selectType='idpair' />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
                                    <AppButton icon='fas fa-filter' className=" me-2" onClick={this.onFilterClick} name='Status Filter' disabled={loading} showloader />
                                    {isFiltered ? <AppButton icon='fas fa-undo' className=" me-2" disabled={!isFiltered || loading} tooltip="Reset tooltip filter"
                                        onClick={() => { this.resetForm(); onClear(); this.setState({ tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })), isFiltered: false }) }} /> :
                                        null}
                                    <AppButton icon='fas fa-times' className=" me-2" tooltip="Close status filter"
                                        onClick={async () => { this.setState({ tabbutton: this.state.tabbutton.map((el, index) => ({ ...el, isActive: false })) }) }}
                                    />
                                </Col>
                            </Row>
                        </Form> :
                        <div className='d-flex justify-content-between align-items-center'>
                            <Form className="row col-lg-3 col-md-4 col-sm-12 col-xs-12" key={'Search'}>
                                <InputGroup>
                                    <div className="input-group-text shadow rounded text-danger" onClick={() => { this.setState({ inlineSearch: '' }); this.props.onSearch(this.props.signalCopyList); }}>
                                        {inlineSearch ? <i className='fas fa-times text-danger' /> : <i className='bx bx-search-alt-2 text-danger' />}
                                    </div>
                                    <input onKeyPress={(e) => { e.key === 'Enter' ? e.preventDefault() : ''; }}
                                        onChange={async (e) => this.onInlineSearch(e.target.value)}
                                        value={inlineSearch} type="text" className="form-control" id="autoSizingInputGroup"
                                        placeholder="Search in table by srcId,AE,Drug name..." />
                                </InputGroup>
                            </Form>
                            <div>
                                {generateData.show ?
                                    <Fragment>
                                        {
                                            generateData.loading ?
                                                <AppButton name="Generating" disabled loader />
                                                :
                                                generateData.file ?
                                                    <div className='d-flex flex-row'>
                                                        <a rel="noreferrer" href={generateData.file} download={generateData.file} className='btn btn-wrap-text btn-danger btn-sm me-2'>
                                                            <i className="fas fa-download" /> Download Data
                                                        </a>
                                                        <AppButton className="btn-danger" iconbutton tooltip="close" icon="fas fa-times" onClick={onCloseGenerateData} />
                                                    </div>
                                                    :
                                                    <div>
                                                        <AppButton className="me-2 " name="No files generated" disabled tooltip="No Record" icon="fas fa-eye-slash" onClick={onCloseGenerateData} />
                                                        <AppButton className="btn-danger" iconbutton tooltip="close" icon="fas fa-times" onClick={onCloseGenerateData} />
                                                    </div>
                                        }
                                    </Fragment> :
                                    <ul className="nav nav-pills flex-nowrap">
                                        {tabbutton.map((e, i) => {
                                            return (<li className="nav-item" key={i}>
                                                {
                                                    loading ?
                                                        <AppLoader key={i} />
                                                        :
                                                        <AppTooltip tooltip={e.name}>
                                                            <Link to={'#'} className={e.isActive ? 'nav-link active' : 'nav-link text-primary'}
                                                                onClick={() => { this.setState({ tabbutton: this.state.tabbutton.map((el, index) => (index === i ? { ...el, isActive: true } : { ...el, isActive: false })) }) }}>
                                                                <i className={e.icon}></i>
                                                            </Link>
                                                        </AppTooltip>
                                                }
                                            </li>)
                                        })}
                                        {
                                            loading ?
                                                <AppLoader /> :
                                                <li className="nav-item">
                                                    <Whisper placement="left" trigger="click" controlId="control-id-click" speaker={this.speaker}>
                                                        <span className='nav-link'>
                                                            <i className='fas fa-info text-danger' />
                                                        </span>
                                                    </Whisper>
                                                </li>
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                }

            </div>
        )
    }
}
