import React, {  Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Service } from '../Appservice';
import { Col, Form, Row } from "reactstrap";
import toast from 'react-hot-toast';
import { AppButton, AppInput } from 'components/Reusable';
import { AppApi } from 'Appfirebaseapi';
import { userApi } from '../ApiConstant';
export default class UsersAdd extends PureComponent {
  static get propTypes() {
    return {
      selectedData: PropTypes.any,
      onClose: PropTypes.func,
      userList: PropTypes.any,
      productList: PropTypes.any,
      rolesList: PropTypes.any,
      workflowteamList: PropTypes.any,
    };
  }
  constructor(props) {
    super(props);
    const { selectedData } = props;
    this.state = {
      postData: {
        first_name: selectedData?.first_name ?? '',
        last_name: selectedData?.last_name ?? '',
        username: selectedData?.username ?? '',
        password: selectedData?.password ?? '',
        confirm_password: selectedData?.confirm_password ?? '',
        email: selectedData?.email ?? '',
        is_staff: selectedData?.is_staff ?? true,
        workflow_team_id: selectedData?.workflow_team?.id ?? '',
        role_ids: this.getIdsFromArray(selectedData.role_details) ?? [],
        drug_ids: this.getIdsFromArray(selectedData.drug_details) ?? [],
        id: selectedData?.id ?? '',
      },
      postErr: {
        first_name: false,
        last_name: false,
        username: false,
        password: false,
        email: false,
        is_staff: false,
        confirm_password: false,
        role_ids: false,
        drug_ids: false,
        workflow_team_id: false,
        passwordErr: false,
        samePassword: false,
      },
      loading: false,
      staffList: [{ id: "true", name: 'Yes', }, { id: "false", name: 'No', }],
      showDrugs: false,
    }

  }
  componentDidMount = () => {
    const { selectedData } = this.props;
    if (selectedData) this.handleRoleChange();
  }
  getIdsFromArray = objArray => {
    return objArray && objArray.length > 0 ? objArray.map(a => a.id) : []
  }
  setValue = async (i, e, isArray) => {
    const value = isArray ? Array.from(i.target.selectedOptions, option => option.value) : i.target.value;
    await this.setState({ postData: { ...this.state.postData, [e]: value } });
    if (!value) this.setError(e);
    else this.removeErr(e);
    if (e == 'role_ids') await this.handleRoleChange();
  };
  setError = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: true } }); };
  removeErr = (e) => { this.setState({ postErr: { ...this.state.postErr, [e]: false } }); };
  onUser = async () => {
    const { postData, showDrugs } = this.state;
    const { selectedData, onClose } = this.props;
    if (!postData.first_name) return this.setError('first_name');
    if (!postData.last_name) return this.setError('last_name');
    if (!postData.username) return this.setError('username');
    if (!postData.email) return this.setError('email');
    if (!postData.workflow_team_id) return this.setError('workflow_team_id');
    if (!postData.role_ids) return this.setError('role_ids');
    if (showDrugs && !postData.drug_ids) return this.setError('drug_ids');
    if (!selectedData) {
      if (!postData.password) return this.setError('password');
      if (postData.password) {
        let passwordErr = "";
        const isNonWhiteSpace = /^\S*$/; const isContainsUppercase = /^(?=.*[A-Z]).*$/; const isContainsLowercase = /^(?=.*[a-z]).*$/;
        const isContainsNumber = /^(?=.*[0-9]).*$/; const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        const isValidLength = /^.{8,15}$/;
        if (!isNonWhiteSpace.test(postData.password)) passwordErr = "Password must not contain Whitespaces.";
        if (!isValidLength.test(postData.password)) passwordErr += "Password must be 8-15 Characters Long.";
        if (!isContainsUppercase.test(postData.password)) passwordErr += "Password must have at least one Uppercase Character.";
        if (!isContainsLowercase.test(postData.password)) passwordErr += "Password must have at least one Lowercase Character.";
        if (!isContainsNumber.test(postData.password)) passwordErr += "Password must contain at least one Digit.";
        if (!isContainsSymbol.test(postData.password)) passwordErr += "Password must contain at least one Special Symbol.";
        if (passwordErr) {
          this.setState({
            postErr: {
              ...this.state.postErr,
              password: true,
              passwordErr
            }
          });
          return;
        }
      }
      if (!postData.confirm_password) return this.setError('confirm_password');
      if (postData.confirm_password != postData.password) return this.setError('samePassword');
    }
    if (!selectedData) {
      delete postData.id;
      delete postData.is_staff;
    } else {
      delete postData.password;
      delete postData.confirm_password;
    }
    this.setState({ loading: true });
    const postAction = selectedData?.id ?
      await Service.appservice('patch', `${userApi.users}/${selectedData.id}`, postData) :
      await Service.appservice('post', userApi.users, postData);
    if (selectedData?.id) AppApi.FindAndCreate(postData)
    if (postAction) {
      toast.success(`User ${selectedData?.id ? 'Updated' : 'Created'} Successfully !...`);
      onClose(true);
    }
    this.setState({ loading: false });
  }
  handleRoleChange = async () => {
    const { postData } = this.state;
    this.setState({ showDrugs: false })
    let allowDrugs = this.props.rolesList.filter(e => postData.role_ids.some(e1 => e1 == e.id)).map(e => e.permissions.find(val => val == "assign_drug"));
    allowDrugs = allowDrugs.some(e => e == 'assign_drug');
    await this.setState({ showDrugs: allowDrugs })
  }
  render() {
    const { postData, postErr, loading, staffList, showDrugs } = this.state;
    const { onClose, productList, workflowteamList, rolesList, selectedData } = this.props;
    return (
      <div className='d-flex justify-content-sm-center mb-4'>
        <Fragment>
          <Form>
            <Row>
              <Col lg={3} md={3} sm={6} xs={12}>
                <AppInput label="First Name *" value={postData.first_name} isInvalid={postErr.first_name} error="First Name is required (min 1 alphabet)" onChange={e => { this.setValue(e, 'first_name') }} />
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <AppInput label="Last Name *" value={postData.last_name} isInvalid={postErr.last_name} error="Last Name is required" onChange={e => { this.setValue(e, 'last_name') }} />
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <AppInput label="User Name *" value={postData.username} isInvalid={postErr.username} error="Username is required" onChange={e => { this.setValue(e, 'username') }} />
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <AppInput type={'email'} label="Email*" value={postData.email} isInvalid={postErr.email} error="Email is required" onChange={e => { this.setValue(e, 'email') }} />
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <AppInput label="Workflow Team *" type="select" value={Number(postData.workflow_team_id)} isInvalid={postErr.workflow_team_id}
                  error="Workflow team  is required" onChange={e => { this.setValue(e, 'workflow_team_id') }} iArray={workflowteamList} selectType='idpair' />
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <AppInput label="Role *" type="select" value={Number(postData.role_ids)} isInvalid={postErr.role_ids}
                  error="Role is required" onChange={e => { this.setValue(e, 'role_ids', true); }} iArray={rolesList} selectType='idpair' />
              </Col>
              {selectedData &&
                <Col lg={3} md={3} sm={6} xs={12}>
                  <AppInput label="Staff *" type="select" value={postData.is_staff} isInvalid={postErr.is_staff}
                    error="Staff is required" onChange={e => { this.setValue(e, 'is_staff'); }} iArray={staffList} selectType='idpair' />
                </Col>
              }
              {showDrugs &&
                <Col lg={3} md={3} sm={6} xs={12}>
                  <AppInput isMultiple label="Drug *" type="select" value={postData.drug_ids} isInvalid={postErr.drug_ids}
                    error="Drug is required" onChange={e => { this.setValue(e, 'drug_ids', true) }} iArray={productList} selectType='idpair' />
                </Col>
              }
              {!selectedData &&
                <Fragment>
                  <Col lg={3} md={3} sm={6} xs={12}>
                    <AppInput type={'password'} isPasswordShow label="Password *" value={postData.password} isInvalid={postErr.password} error={postErr.passwordErr} onChange={e => { this.setValue(e, 'password') }} />
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={12}>
                    <AppInput type={'password'} label="Confirm Password *" value={postData.confirm_password} error={`Confirm Password ${postErr.samePassword ? "  is not same" : "  is required"}`}
                      isInvalid={(postErr.confirm_password || postErr.samePassword)} onChange={e => { this.setValue(e, 'confirm_password'); this.removeErr('samePassword') }} />
                  </Col>
                </Fragment>
              }
              <Col lg={12} className='text-center'>
                <AppButton icon='fas fa-paper-plane' className="me-3" onClick={this.onUser} showloader active={loading} name={`${selectedData ? 'Update ' : 'Add '} User`} />
                <AppButton className="btn-danger" icon='fas fa-times' iconbutton onClick={() => onClose()} tooltip="Close" />
              </Col>
            </Row>
          </Form>
        </Fragment>
      </div>
    )
  }
}
