import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { Card, CardBody, CardImg, CardTitle, Button } from "reactstrap"
import logo from "../../../assets/images/logo.svg"
import powerbi from '../../../assets/images/Power-BI-Desktop-Feature-image.jpg'
import signal from '../../../assets/images/signal_management.jpg'
import "./MenuList.css";
// import literature from '../../../assets/images/literature.jpg'

const MenuList = () => {
  const [menuList, setMenuList] = useState([])
  // const id = 1
  // const authdata = {
  //   domain_name: "exelixisdemo.vc-19.ai",
  //   secret_key: 12345,
  // }

  // to get the menu list of User manual
  useEffect(() => {
    async function authpost() {
      return await axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT_DOCUMENT}category/`)
        .then(response => {
          setMenuList(response.data)
        })
        .catch(err => console.log(err))
    }
    authpost()
  }, [])

  return (
    <React.Fragment>
      <div
        className="menu-page"
        style={{ border: "30px solid #295050", padding: "0px !important" }}
      >
        <div style={{ margin: "2% 0 0 2%" }}>
          <img src={logo} alt="" height="60" />
        </div>
        <div className="main-container">
          {menuList.map((val, index) => (
            <Link
              to={`/documentation/menu-details/${val.name}/${val.id}`}
              key={index}
              style={{ color: "rgb(47, 43, 43)" }}
            >
              <Card className="list_card">
                <h3 style={{ textAlign: "center", color: "#295050", paddingTop: 20 }}>{val.name}</h3>
                <CardImg
                  src={val.name.includes("Dashboard") ? powerbi : signal}
                  // src={val.name==="Signal Management"? signal: powerbi}
                  width="280px"
                  height="380px"
                />
                <CardBody style={{ textAlign: "center" }}>
                  <Button style={{ fontSize: "20px", padding: "10px 20px", borderRadius: "10px", backgroundColor: "#295050" }}>Read <i className="fas fa-eye" style={{ marginLeft: "5px" }} /></Button>
                </CardBody>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}
export default MenuList
