import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { CardBody, Table,  } from 'reactstrap';
export default class WorkflowTopicAttachment extends Component {
    static get propTypes() {
        return {
            workFlowDetails: PropTypes.any,
            topicId: PropTypes.number
        };
    }
    getTopicAttachment = (flow, id) => {
        const isTheme = flow.find(x => x.id == id);
        if (isTheme) {
            let getTheme = isTheme.themeData;
            return getTheme && getTheme.id ? [getTheme] : [];
        }
        return []
    }
    render() {
        const { workFlowDetails, topicId } = this.props;
        return (
            <Fragment>
                {
                    this.getTopicAttachment(workFlowDetails, topicId)?.length ?
                        <div className='table-responsive border rounded'>
                            <Table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Attachments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.getTopicAttachment(workFlowDetails, topicId).map((e, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr >
                                                        <td>{e?.title}</td>
                                                        <td>{e?.description}</td>
                                                        <td>
                                                            <a className='badge p-2 bg-warning text-center' href={e?.url_attachment} download={e?.url_attachment} target="_blank" rel="noreferrer"  >
                                                                <i className='fas fa-download me-2' />
                                                                {e?.url_attachment ? e?.url_attachment?.split('/').pop() : ''}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        :
                        <CardBody className='text-center'>
                            <span className='text-danger shadow p-2 rounded'>No Topic Attachment Found..</span>
                        </CardBody>
                }
            </Fragment>
        )
    }
}
