import React, { Component } from 'react';
import { Service } from '../Appservice';
import { Row, Col, } from 'reactstrap';
import ReactEcharts from "echarts-for-react"
import PropTypes from 'prop-types';
import SignalTable from './SignalTable';
import { signalApi } from '../ApiConstant';
import { chart } from '../Appchart';
import { getKeyItem, sourceId } from '../AppPermission';
export default class SignalbyStatusGraph extends Component {
    static get propTypes() {
        return {
            signalStatusGraph: PropTypes.any,
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            signalStatusGraphFilter: [
                { id: 'IN', name: 'Invalid' },
                { id: 'V', name: 'Valid' },
                { id: 'M', name: 'Monitoring' },
                { id: 'O', name: 'Ongoing' },
                { id: 'R', name: 'Refuted' },
                { id: 'C', name: 'Confirmed' },
            ],
            Filterby: '',
            page: 1,
            signalList: [],
        }
    }
    getSignals = async () => {
        const { page, Filterby } = this.state;
        let queryParam = `?page=${page}&page_size=10&src_identifier=` + getKeyItem(sourceId);
        if (Filterby == 'V' || Filterby == 'IN') queryParam += "&validation_status=" + Filterby;
        else queryParam += "&evaluation_confirmation_status=" + Filterby;
        this.setState({ loading: true });
        const signals = await Service.appservice('get', signalApi.signals + queryParam);
        if (signals?.data) {
            this.setState({
                signalList: signals.data.values, signalCopyList: signals.data.values,
                pageNumbers: signals.metadata ? Array.from({ length: signals.metadata.total_pages }, (v, k) => k + 1) : [],
                loading: false
            });
        }
    }
    paginate = async (page) => {
        await this.setState({ page });
        this.getSignals();
    }
    onChartClick = async (e) => {
        await this.setState({ Filterby: e.data.id });
        this.paginate(1);
    };
    render() {
        const { signalStatusGraph } = this.props;
        const { signalList, pageNumbers, page, loading } = this.state;
        return (
            <Row className="no-gutters align-items-center">
                <Col md={3}>
                    <ReactEcharts
                        style={{ textAlign: 'center' }}
                        option={chart(signalStatusGraph)}
                        onEvents={{
                            'click': this.onChartClick,
                        }}
                    />
                </Col>
                <Col md={9} className='card-body'>
                    <SignalTable
                        loading={loading}
                        filterStaticHeaders={e => { }}
                        signalList={signalList}
                        pageNumbers={pageNumbers}
                        page={page}
                        paginate={(e) => this.paginate(e)}
                    />
                </Col>
            </Row>
        )
    }
}
